import ContentHeader from "../layout/ContentHeader";
import { useParams, useNavigate } from "react-router-dom";
import * as Controller from "../../../controllers/cargopersona-controller";
import React from "react";
import Textbox from "../layout/Textbox";
import Label from "../layout/Label";
export default function NuevoCargoPersona() {
  const { code } = useParams();
  const Router = useNavigate();
  const [codeCargoPersona, setCodeCargoPersona] = React.useState("");
  const [nombreCargoPersona, setNombreCargoPersona] = React.useState("");
  const [tituloVentana, setTituloVentana] = React.useState(
    "Nuevo Cargo Persona"
  );
  const [valnombreCargoPersona, setValNombreCargoPersona] =
    React.useState(false);
  const [isSubmit, setIsSubmit] = React.useState(false);
  React.useEffect(() => {
    if (code != "nuevo") {
      setTituloVentana("Editar Cargo Persona");
      setCodeCargoPersona(code!);
      Controller.getByCode(code, setNombreCargoPersona);
    }
  }, []);
  function validarCampos() {
    var Result = true;
    setIsSubmit(true);
    if (nombreCargoPersona == "" || nombreCargoPersona == null) {
      Result = false;
      setValNombreCargoPersona(false);
    } else {
      setValNombreCargoPersona(true);
    }
    if (Result) {
      Controller.save(Router, codeCargoPersona, nombreCargoPersona);
    } else {
      Controller.Mensaje();
    }
  }
  return (
    <div className="content-wrapper">
      <ContentHeader
        title={tituloVentana}
        homePath={"Home"}
        currentPath={tituloVentana}
      ></ContentHeader>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card card-primary">
                <div className="card-header">
                  <div className="d-flex flex-row gap-2">
                    <div className="align-items-center">
                      <i
                        className="bx bx-arrow-back arrow-back"
                        onClick={() => Controller.toConsulta(Router)}
                      ></i>
                    </div>
                    <div className="">
                      <h4 className="card-title">{tituloVentana}</h4>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-6">
                      <h4>Datos</h4>
                      <hr />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-2">
                      <Label required={true}>Nombre Cargo Persona</Label>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <Textbox
                          value={nombreCargoPersona}
                          valueError={valnombreCargoPersona}
                          setValue={setNombreCargoPersona}
                          setValueError={setValNombreCargoPersona}
                          isSubmit={isSubmit}
                          upperCase={true}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mt-5">
                    <div className="col-lg-6">
                      <div className="form-group">
                        <button
                          className="btn btn-default"
                          onClick={() => Controller.toConsulta(Router)}
                        >
                          Cancelar
                        </button>
                        <button
                          className="btn btn-primary"
                          onClick={() => validarCampos()}
                        >
                          Guardar
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
