

export default function ContentHeader({
    children, title, homePath, currentPath
  }: {children?: React.ReactNode, title : String, homePath : String, currentPath: String}) {

    return (<section className="content-header" style={{display: "none"}}>
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                        <h1 style={{display: "none"}}>{title}</h1>
                        </div>
                        <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                            <li className="breadcrumb-item"><a href="#">{homePath}</a></li>
                            <li className="breadcrumb-item active">{currentPath}</li>
                        </ol>
                        </div>
                    </div>
                </div>
            </section>)

}