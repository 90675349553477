import * as Repository from "../repositories/analisisfrente-repository";


export async function getListaComboCadenaMaster(setListaComboCadenaMaster: any) {
    var dataResponse = await Repository.getListaComboCadenaMaster()
    setListaComboCadenaMaster(dataResponse)
}



export async function getListaComboTienda(codeCadenaMaster: string, setListaComboTienda: any) {
    if (codeCadenaMaster != "-" && codeCadenaMaster != '0') {
        var dataResponse = await Repository.getListaComboTienda(codeCadenaMaster)
        setListaComboTienda(dataResponse)
    } else {
        setListaComboTienda(dataResponse)
    }
}

export async function getListaComboMarca(setListaComboMarca: any) {
    var dataResponse = await Repository.getListaComboMarca()
    setListaComboMarca(dataResponse)
}

export async function getListaComboCategoria(codeMarca: string, setListaComboCategoria: any) {

    if (codeMarca != "-" && codeMarca != "0") {
        var dataResponse = await Repository.getListaComboCategoria(codeMarca)
        setListaComboCategoria(dataResponse)
    } else {
        setListaComboCategoria(dataResponse)

    }
}

export async function geListaProducto(setListaProducto: any, codeMarca : string , codeCategoria: string ) {
    var dataResponse = await Repository.geListaProducto(codeMarca, codeCategoria)

    console.log(dataResponse)
    if (dataResponse != null) {
        setListaProducto(dataResponse)
    }
    
}
export async function getAnalisisFrente(
    FechaInicial: Date,
    FechaFinal: Date,
    codeCadenaMaster: string,
    codeTienda: string,
    codeMarca: string,
    codeCategoria: string,
    setDataFrente: any
  ) {
    
    
    var dataResponse = await Repository.getAnalisisFrente(
      FechaInicial,
      FechaFinal,
      codeCadenaMaster,
      codeTienda,
      codeMarca,
      codeCategoria
    );
  
    if (dataResponse != null) {
      var labels = dataResponse.Labels;
      var dataResult = { labels, datasets: dataResponse.DataSets };
      console.log(dataResult);
      setDataFrente(dataResult);
    }
  }
  