
import * as Repository from  "../repositories/dashboardstock-repository";

export async function doConsultar(setListaConsulta: any, FilterCodeMarca:string, FilterCodeCategoria:string,FilterCodeSubCategoria :string, FilterCadena:string, FilterTienda:string, FilterFechaDesde: Date, FilterFechaHasta: Date) {
    var dataResponse = await Repository.getData(FilterCodeMarca, FilterCodeCategoria, FilterCodeSubCategoria,  FilterCadena, FilterTienda, FilterFechaDesde, FilterFechaHasta)
    setListaConsulta(dataResponse)
}
export async function getListaComboMarca(setListaComboMarca: any) {
    var dataResponse = await Repository.getListaComboMarca()
    setListaComboMarca(dataResponse)
}


export async function getListaComboTienda(setListaComboTienda: any) {
    var dataResponse = await Repository.getListaComboTienda()
    setListaComboTienda(dataResponse)
}

export async function getListaComboCadena(setListaComboCadena: any) {
    var dataResponse = await Repository.getListaComboCadena()
    setListaComboCadena(dataResponse)
}
export async function doExportar( FilterCodeMarca:string, FilterCodeCategoria:string,FilterCodeSubCategoria :string, FilterCadena:string, FilterTienda:string, FilterFechaDesde: Date, FilterFechaHasta: Date) {
    var dataResponse = await Repository.doExport(FilterCodeMarca, FilterCodeCategoria, FilterCodeSubCategoria,  FilterCadena, FilterTienda, FilterFechaDesde, FilterFechaHasta)
    
}

export async function getListaComboCategoria(setListaComboMarca: any) {
    var dataResponse = await Repository.getListaComboCategoria()
    setListaComboMarca(dataResponse)
}