import { useMemo } from 'react';
import { type MRT_ColumnDef } from 'material-react-table';
import * as ClientAPI from "../utils/methods";
import * as Endpoints from "../api/endpoint";
import Moment from 'moment';
import moment from 'moment';
type ChechinOutTienda = {

};
export function GetColumns() {
  const columns = useMemo<MRT_ColumnDef<ChechinOutTienda>[]>(
    () => [
      {
        accessorKey: 'NombreCompleto',
        header: 'NombreApelllidos',
        size: 20,
      },
      {
        accessorKey: 'Asistencia',
        header: 'Asistencia',
        size: 20,
      }
    ],
    [],
  );
  ;
  return columns
}

export async function getListaComboEquipo() {
  var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.GET_LISTA_COMBO_MIEMBROEQUIPO, "")
  if (dataResponse.Data != null) {
    return dataResponse.Data;
  } else {
    return null
  }
}