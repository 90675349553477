import React, { useMemo, useRef } from "react";
import {
  MRT_TableInstance,
  MaterialReactTable,
  type MRT_ColumnDef,
  type MRT_RowSelectionState,
} from "material-react-table";
import { useNavigate } from "react-router-dom";
import ContentHeader from "../layout/ContentHeader";
import ContentBody from "../layout/ContentBody";
import "react-datepicker/dist/react-datepicker.css";
import * as Controller from "../../../controllers/analisisprecio-controller";
import * as Repository from "../../../repositories/analisisprecio-repository";
import es from "date-fns/locale/es"; // the locale you want
import DatePicker, { registerLocale } from "react-datepicker";
import SelectSimple from "../layout/SelectSimple";
import {
  Line,
  Bar,
  getDatasetAtEvent,
  getElementAtEvent,
} from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import Indicator from "../layout/Indicator";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);
registerLocale("es", es); // register it with the name you want

export default function ConsultaAnalisisPrecio(): React.JSX.Element {
  const router = useNavigate();
  const chartRefLine = useRef();
  const chartRefBar = useRef();

  const [listaComboCadena, setListaComboCadena] = React.useState([]);
  const [listaComboTienda, setListaComboTienda] = React.useState([]);
  const [listaComboMarca, setListaComboMarca] = React.useState([]);
  const [listaComboCategoria, setListaComboCategoria] = React.useState([]);
  const [listaComboProducto, setListaComboProducto] = React.useState([]);
  const [listaDataPrecio, setListaDataPrecio] = React.useState([]);
  const [listaDataPrecioBar, setListaDataPrecioBar] = React.useState([]);

  const [listaProducto, setListaProducto] = React.useState([]);
  const [codeProducto, setCodeProducto] = React.useState("");
  const [codeMarca, setCodeMarca] = React.useState("");
  const [codeCategoria, setCodeCategoria] = React.useState("");
  const [filterFechaDesde, setFilterFechaDesde] = React.useState(new Date());
  const [filterFechaHasta, setFilterFechaHasta] = React.useState(new Date());
  const [dataPrecio, setDataPrecio] = React.useState({ datasets: [] });
  const [dataPrecioBar, setDataPrecioBar] = React.useState({ datasets: [] });
  const [dataPrecioCompare, setDataPrecioCompare] = React.useState({
    datasets: [],
  });
  const [dataPrecioBarMax, setDataPrecioBarMax] = React.useState(0);
  const [dataPrecioBarMin, setDataPrecioBarMin] = React.useState(0);
  const [dataPrecioBarMode, setDataPrecioBarMode] = React.useState(0);
  const [codeCadena, setCodeCadena] = React.useState("");
  const [codeTienda, setCodeTienda] = React.useState("");

  const [originalDataPrecio, setOriginalDataPrecio] = React.useState({
    DataSets: [{ data: [] }],
    Labels: [],
  });
  const [originalDataPrecioBar, setOriginalDataPrecioBar] = React.useState({
    DataSets: [{ data: [] }],
    Labels: [],
  });
  const [originalDataPrecioCompare, setOriginalDataPrecioCompare] =
    React.useState({ DataSets: [{ data: [] }], Labels: [] });

  const onchangeCadenaMaster = (e: any) => {
    setCodeCadena(e.target.value);
    Controller.getListaComboTienda(e.target.value, setListaComboTienda);
    setListaComboTienda([]);
    setCodeTienda("");
  };

  const onChangeCodeMarca = (e: any) => {
    var codeMarcaCambiar = e.target.value;
    if (
      codeMarcaCambiar != null &&
      codeMarcaCambiar != "" &&
      codeMarcaCambiar != "0"
    ) {
      Controller.getListaComboCategoria(
        codeMarcaCambiar,
        setListaComboCategoria
      );
      Controller.geListaProducto(setListaProducto, codeMarcaCambiar, "-");
    } else {
      Controller.geListaProducto(setListaProducto, "-", "-");
      setListaComboCategoria([]);
    }
  };
  const onChangeCodeCategoria = (e: any) => {
    var codeCategoriaCambiar = e.target.value;
    Controller.geListaComboProducto(
      codeCategoriaCambiar,
      setListaComboProducto
    );
  };

  const onClickBuscar = (e: any) => {

    setListaDataPrecio([]);
    setListaDataPrecioBar([]);

    Controller.getAnalisisPrecio(
      filterFechaDesde,
      filterFechaHasta,
      codeCadena,
      codeTienda,
      codeMarca,
      codeCategoria,
      codeProducto,
      setDataPrecio,
      setDataPrecioBar,
      setDataPrecioCompare,
      setOriginalDataPrecio,
      setOriginalDataPrecioBar,
      setOriginalDataPrecioCompare,
      setDataPrecioBarMax,
      setDataPrecioBarMin,
      setDataPrecioBarMode,
    );
  };

  const onClickLine = (e: any) => {
    var element = getElementAtEvent(chartRefLine.current!, e);
    
    if (element.length > 0) {
      
      if (originalDataPrecio != null) {
        var indexSelected = element[0].index;
        var Stock = originalDataPrecio.DataSets[0].data[indexSelected];
        var Fecha = originalDataPrecio.Labels[indexSelected];

        console.log(Fecha);
        console.log(Stock);
        Controller.getListaDataPrecio(
          codeCadena,
          codeTienda,
          codeProducto,
          Fecha,
          setListaDataPrecio
        );
      }
    }
  };

  const onClickBar = (e: any) => {
    var element = getElementAtEvent(chartRefBar.current!, e);
    if (element.length > 0) {
      if (originalDataPrecioBar != null) {
        var indexSelected = element[0].index;
        var Stock = originalDataPrecioBar.DataSets[0].data[indexSelected];
        var NombreTienda = originalDataPrecioBar.Labels[indexSelected];

        Controller.getListaDataPrecioBar(
          filterFechaDesde,
          filterFechaHasta,
          codeProducto,
          NombreTienda,
          setListaDataPrecioBar
        );
      }
    }
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
      },
      title: {
        display: false,
        text: "Precio por Fecha",
      },
    },
  };

  const optionsBar = {
    indexAxis: "y" as const,
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
      },
      title: {
        display: false,
        text: "Precio por tienda",
      },
    },
  };

  const optionsCompare = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
      },
      title: {
        display: false,
        text: "PVP Vs Precio",
      },
    },
  };

  React.useEffect(() => {
    Controller.getListaComboMarca(setListaComboMarca);
    Controller.getListaComboCadenaMaster(setListaComboCadena);
  }, []);
  return (
    <div className="content-wrapper">
      <ContentHeader
        title={"Analisis Precio"}
        homePath={"Home"}
        currentPath={"Analisis Precio"}
      ></ContentHeader>
      <ContentBody title={"Consulta Analisis Precio"}>
        <div className="row">
          <div className="col-lg-2">
            <label>Cadena</label>
          </div>
          <div className="col-lg-4">
            <div className="form-group">
              <SelectSimple
                value={codeCadena}
                setValue={setCodeCadena}
                lista={listaComboCadena}
                onChangeSection={onchangeCadenaMaster}
              >
                --TODOS--
              </SelectSimple>
            </div>
          </div>
          <div className="col-lg-2">
            <label>Tienda</label>
          </div>
          <div className="col-lg-4">
            <div className="form-group">
              <SelectSimple
                value={codeTienda}
                setValue={setCodeTienda}
                lista={listaComboTienda}
              >
                --TODOS--
              </SelectSimple>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-2">
            <label>Fecha Desde</label>
          </div>
          <div className="col-lg-4">
            <div className="form-group">
              <DatePicker
                locale="es"
                className="form-control-text"
                popperPlacement="top-end"
                selected={filterFechaDesde}
                onChange={(date: any) => setFilterFechaDesde(date)}
                dateFormat={"dd/MM/yyyy"}
              />
            </div>
          </div>
          <div className="col-lg-2">
            <label>Fecha Hasta</label>
          </div>
          <div className="col-lg-4">
            <div className="form-group">
              <DatePicker
                locale="es"
                className="form-control-text"
                popperPlacement="top-end"
                selected={filterFechaHasta}
                onChange={(date: any) => setFilterFechaHasta(date)}
                dateFormat={"dd/MM/yyyy"}
              />
            </div>
          </div>
        </div>

        <div className="row mt-2">
          <div className="col-md-3">
            <div className="row">
              <div className="col-md-12">
                <label>Marca</label>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <SelectSimple
                    value={codeMarca}
                    setValue={setCodeMarca}
                    lista={listaComboMarca}
                    onChangeSection={onChangeCodeMarca}
                  >
                    --TODOS--
                  </SelectSimple>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <label>Categoria</label>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <SelectSimple
                    value={codeCategoria}
                    setValue={setCodeCategoria}
                    lista={listaComboCategoria}
                    onChangeSection={onChangeCodeCategoria}
                  >
                    --TODOS--
                  </SelectSimple>
                </div>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-md-12">
                <label>Producto</label>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-md-12">
                <SelectSimple
                  value={codeProducto}
                  setValue={setCodeProducto}
                  lista={listaComboProducto}
                >
                  --SELECCIONE--
                </SelectSimple>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-md-12">
                <label>Data Stock Fecha</label>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-md-12">
                <MaterialReactTable
                  columns={Repository.GetColumns()}
                  data={listaDataPrecio}
                  enablePagination={false}
                  enableTopToolbar={false}
                />
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-md-12">
                <label>Data Stock Tienda</label>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-md-12">
                <MaterialReactTable
                  columns={Repository.GetColumnsTienda()}
                  data={listaDataPrecioBar}
                  enablePagination={false}
                  enableTopToolbar={false}
                />
              </div>
            </div>
          </div>
          <div className="col-md-9">
            <div className="row mt-2">
              <div className="col-md-12">
                <button className="btn btn-info" onClick={onClickBuscar}>
                  Consultar
                </button>
              </div>
            </div>

            <div className="row mt-4">
              <div className="col-md-12 d-flex justify-content-center">
                <h5>Precio por fecha</h5>
              </div>
            </div>

            <div className="row mt-2">
              <div className="col-md-12">
                <Line
                  ref={chartRefLine}
                  options={options}
                  data={dataPrecio}
                  onClick={onClickLine}
                />
              </div>
            </div>

            <div className="row mt-4">
              <div className="col-md-12 d-flex justify-content-center">
                <h5>Precio por tienda</h5>
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-md-4">
                <Indicator title={"Máximo"}>{dataPrecioBarMax}</Indicator>
              </div>
              <div className="col-md-4">
                <Indicator title={"Mínimo"}>{dataPrecioBarMin}</Indicator>
              </div>
              <div className="col-md-4">
                <Indicator title={"Moda"}>{dataPrecioBarMode}</Indicator>
              </div>
            </div>

            <div className="row mt-2">
              <div className="col-md-12">
                <Bar
                  ref={chartRefBar}
                  options={optionsBar}
                  data={dataPrecioBar}
                  onClick={onClickBar}
                />
              </div>
            </div>

            <div className="row mt-4">
              <div className="col-md-12 d-flex justify-content-center">
                <h5>PVP vs Precio</h5>
              </div>
            </div>

            <div className="row mt-2">
              <div className="col-md-12">
                <Line options={optionsCompare} data={dataPrecioCompare} />
              </div>
            </div>
          </div>
        </div>
      </ContentBody>
    </div>
  );
}
