import { useMemo } from 'react';
import { type MRT_ColumnDef } from 'material-react-table';
import * as ClientAPI from "../utils/methods";
import * as Endpoints from "../api/endpoint";
type Pregunta = {
  IdPregunta: string;
  DesPregunta: string;
  IdTipoArea: string;
  IdTipoGrupo: string;
  IdOpcionRespuesta: string;

};
export function GetColumns() {
  const columns = useMemo<MRT_ColumnDef<Pregunta>[]>(
    () => [
      {
        accessorKey: 'IdPregunta',
        header: 'ID',
        size: 200,
      },
    
      {
        accessorKey: 'NombreTipoArea',
        header: 'Tipo Area',
        size: 400,
      },
      {
        accessorKey: 'NombreTipoGrupo',
        header: 'Tipo Grupo',
        size: 800,
      },


    ],
    [],
  );
  return columns
}
type PreguntaDetalle = {
  CodePregunta: string,
  DesOpcionRespuesta: string,
};
export function GetMColumns() {
  const columns = useMemo<MRT_ColumnDef<PreguntaDetalle>[]>(
    () => [
      {
        accessorKey: 'DesPregunta',
        header: 'Descripcion Pregunta',
        size: 300,
      },
      {
        accessorKey: 'DesOpcionRespuesta',
        header: 'Opcion Respuesta ',
        size: 200,
      },

      {
        accessorKey: 'DesFoto',
        header: 'Tomar Foto',
        size: 50,
      },
    ],
    [],
  );
  ;
  return columns
}


export async function getData(FilterTipoArea: number, FilterTipoGrupo: number) {
  var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.GET_LISTA_PREGUNTA + "/" + FilterTipoArea + "/" + FilterTipoGrupo  , "")
  if (dataResponse.Data != null) {
    return dataResponse.Data;
  } else {
    return []
  }
}
export async function getByCode(code: string) {
  var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.GET_PREGUNTABYCODE + "/" + code, "")
  return dataResponse.Data;
}
export async function save(objSave: any) {
  var dataResponse = await ClientAPI.RequestPost(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.INSERTAR_PREGUNTA, "", objSave)
  if (dataResponse != null) {
    return dataResponse;
  } else {
    return []
  }
}
export async function update(objSave: any) {
  var dataResponse = await ClientAPI.RequestPost(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.UPDATE_PREGUNTA, "", objSave)
  if (dataResponse != null) {
    return dataResponse;
  } else {
    return null
  }
}
export async function inactiva(objSave: any) {
  var dataResponse = await ClientAPI.RequestPost(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.INACTIVO_PREGUNTA, "", objSave)
  if (dataResponse.Data != null) {
    return dataResponse.Data;
  } else {
    return null
  }
}
export async function activa(objSave: any) {
  var dataResponse = await ClientAPI.RequestPost(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.ACTIVO_PREGUNTA, "", objSave)
  if (dataResponse.Data != null) {
    return dataResponse.Data;
  } else {
    return null
  }
}
export async function getListaComboTipoGrupoSupervision(setIdTipoArea: number) {
  var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.GET_LISTA_COMBO_TIPOSUPERVISION + "/" + setIdTipoArea, "")
  console.log(dataResponse)
 
  if (dataResponse.Data != null) {
 
    return dataResponse.Data;
   
  } else {
    return null
  }
}

export async function getListaComboTipoGrupoIncidencia(setIdTipoArea: number) {
  var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.GET_LISTA_COMBO_TIPOINCIDENCIA + "/" + setIdTipoArea, "")
  console.log(dataResponse)
  if (dataResponse.Data != null) {
    return dataResponse.Data;
  } else {
    return null
  }
}




export async function getListaComboTipoArea() {
  var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.GET_LISTA_COMBO_TIPOAREA, "")
  if (dataResponse.Data != null) {
    return dataResponse.Data;
  } else {
    return null
  }
}
export async function doExport(FilterTipoGrupo: number, FilterTipoArea: number) {
  var dataResponse = await ClientAPI.RequestDownload(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.GET_LISTA_PREGUNTA_EXCEL + "/" + FilterTipoGrupo + "/" + FilterTipoArea , 'DescargarExcelPregunta.xlsx')

}

export async function getListaComboPregunta(){
  var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.GET_LISTA_COMBO_PREGUNTA, "")
  if(dataResponse.Data != null){
    return dataResponse.Data;
  }else {
    return null
  }
}
