
import { type MRT_ColumnDef } from 'material-react-table';
import { useMemo } from 'react';

type IndicadorMecaderismo = {

};
export function GetColumns() {
  const columns = useMemo<MRT_ColumnDef<IndicadorMecaderismo>[]>(
    () => [

      {
        accessorKey: 'Date',
        header: 'Date ',
        size: 100,
      },
      {
        accessorKey: 'RotacionPersonal',
        header: 'Rotacion Personal',
        size: 100,
      },
      {
        accessorKey: 'AsistenciaPersonal',
        header: 'Asistencia Personal',
        size: 100,
      },
      {
        accessorKey: 'CumpliminetoTarea',
        header: 'Cumplimiento Tarea',
        size: 100,
      },
      {
        accessorKey: 'CoberturaCompleta',
        header: 'Cobertura Completa',
        size: 100,
      },


    ],
    [],
  );
  ;
  return columns
}
export async function getData() {
}


