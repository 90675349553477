import { NavigateFunction } from 'react-router-dom';
import * as Repository from "../repositories/marcarepresenta.repository";
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { ToastContainer, toast } from 'react-toastify';    
import 'react-toastify/dist/ReactToastify.css';
import * as ClientAPI from "../utils/methods"; 
import * as Endpoints from "../api/endpoint";

import {
    CODE_SERVER_RETURN,
    UPLOAD_FILES,
    UPLOAD_FILESALTASUNAT,
    UPLOAD_FILESCONSTANCIA,
    UPLOAD_FILESCONTRATO,
    UPLOAD_FILESCS,
    UPLOAD_FILESDNI,
  } from "../utils/contants";

export function toNuevo(router: NavigateFunction) {
    router("/main/marcarepresenta/mantenimiento/nuevo")
}
export function toConsulta(router: NavigateFunction) {
    router("/main/marcarepresenta/consulta")
}
export async function toEditar(router: NavigateFunction, row: any) {
    var codeEditar = row.CodeMarcaRepresenta
    router("/main/marcarepresenta/mantenimiento/" + codeEditar)
}
export async function toQuestionInactivaActiva(row: any, setListaConsulta: any) {
    const MySwal = withReactContent(Swal)
    var question = row.Estado == 1 ? "¿Desea eliminar el registro?" : "¿Desea activar el registro?"
    MySwal.fire({
        title: <p>{question}</p>,
        icon: "question",
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: "Sí",
        cancelButtonText: "Cancelar"
    }).then((result: any) => {
        if (result.isConfirmed) {
            toInactivaActiva(row, setListaConsulta)
        }
    })
}
async function toInactivaActiva(row: any, setListaConsulta: any) {
    var codeEliminar = row.CodeMarcaRepresenta
    const objSave = {
        CodeMarcaRepresenta: codeEliminar
    }
    if (row.Estado == 1) {
        var dataResponse = await Repository.inactiva(objSave)
    } else if (row.Estado == 0) {
        var dataResponse = await Repository.activa(objSave)
    }
    doConsultar(setListaConsulta, "")
}
export async function getByCode(code: any, setNombreMarcaRepresenta: any) {
    var dataResponse = await Repository.getByCode(code)
    if (dataResponse != null) {
        setNombreMarcaRepresenta(dataResponse.NombreMarcaRepresenta)
    }
}
export async function doConsultar(setListaConsulta: any, FilterNombreMarcaRepresenta: string) {
    var dataResponse = await Repository.getData(FilterNombreMarcaRepresenta)
    setListaConsulta(dataResponse)
}
export async function save(router: NavigateFunction, codeMarcaRepresenta: string, nombreMarcaRepresenta: string) {
    const MySwal = withReactContent(Swal)
    var question = "¿Desea guardar el registro?"
    MySwal.fire({
        title: <p>{question}</p>,
        icon: "question",
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: "Sí",
        cancelButtonText: "Cancelar"
    }).then(async (result: any) => {
        if (result.isConfirmed) {
            console.log("Code a actualizar: " + codeMarcaRepresenta)
            if (codeMarcaRepresenta != null && codeMarcaRepresenta != "") {
                const objSave = {
                    CodeMarcaRepresenta: codeMarcaRepresenta,
                    NombreMarcaRepresenta: nombreMarcaRepresenta,
                }
                var dataResponse = await Repository.update(objSave)
                if (dataResponse.Code ==CODE_SERVER_RETURN.VAL){
                    MySwal.fire({
                        title: <p>{dataResponse.Message}</p>,
                        icon: "warning",
                        showConfirmButton: true,
                        showCancelButton: false,
                        confirmButtonText: "Ok"
                    });
              
                } else if(dataResponse.Code == CODE_SERVER_RETURN.ERROR) {
                    MySwal.fire({
                        title: <p>{dataResponse.Message}</p>,
                        icon: "error",
                        showConfirmButton: true,
                        showCancelButton: false,
                        confirmButtonText: "OK",
                        
                    });
                } else {
                    toast.success('Guardado satisfactoriamente!')
                    toConsulta(router)
                  
                }
            } else {
                const objSave = {
                    NombreMarcaRepresenta: nombreMarcaRepresenta,
                }
                var dataResponse = await Repository.save(objSave)
                if (dataResponse.Code ==CODE_SERVER_RETURN.VAL){
                    MySwal.fire({
                        title: <p>{dataResponse.Message}</p>,
                        icon: "warning",
                        showConfirmButton: true,
                        showCancelButton: false,
                        confirmButtonText: "Ok"
                    });
                } else if(dataResponse.Code == CODE_SERVER_RETURN.ERROR) {
                    MySwal.fire({
                        title: <p>{dataResponse.Message}</p>,
                        icon: "error",
                        showConfirmButton: true,
                        showCancelButton: false,
                        confirmButtonText: "OK",
                    });
                }else {
                    toast.success('Guardado satisfactoriamente!')
                    toConsulta(router)
                }
            }
        }
    })
}
export async function Mensaje() {
    const MySwal = withReactContent(Swal)
    var message = "Tiene que completar los campos"
    MySwal.fire({
        title: <p>{message}</p>,
        icon: "warning",
        showConfirmButton: true,
        confirmButtonText: "Aceptar",
    }
    )
}
export async function doExportar( FilterNombreMarcaRepresenta: string) {
    var dataResponse = await Repository.doExport(FilterNombreMarcaRepresenta)
    
}

export async function inactiva(objSave: any){
    var dataResponse = await ClientAPI.RequestPost(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.INACTIVO_PRODUCTO, "", objSave)
    if(dataResponse.Data != null){
      return dataResponse.Data;
    }else {
      return null
    }
  } 
  export async function activa(objSave: any){
    var dataResponse = await ClientAPI.RequestPost(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.ACTIVO_PRODUCTO, "", objSave)
    if(dataResponse.Data != null){
      return dataResponse.Data;
    }else {
      return null
    }
  } 