import { useMemo } from 'react';
import { type MRT_ColumnDef } from 'material-react-table';
import * as ClientAPI from "../utils/methods";
import * as Endpoints from "../api/endpoint";
import Moment from 'moment';
import moment from 'moment';
type Supervision = {
};
export function GetColumns() {
  const columns = useMemo<MRT_ColumnDef<Supervision>[]>(
    () => [
      {
        accessorKey: 'NombreCompleto',
        header: 'Nombre Usuario',
        size: 20,
      },
      {
        accessorKey: 'NumeroDocumentoIdentidad',
        header: 'Numero Documento',
        size: 100,
      },
      {
        accessorKey: 'NombreTienda',
        header: 'Nombre Tienda',
        size: 100,
      },
      {
        accessorKey: 'NombreCadena',
        header: 'Cadena',
        size: 100,
      },
      {
        accessorKey: 'Fecha',
        header: 'Fecha',
        size: 100,
      },
      {
        accessorKey: 'FechaHoraRegistro',
        header: 'Fecha Registro',
        size: 100,
      },
      {
        accessorKey: 'TipoSupervision',
        header: 'Tipo Incidencia',
        size: 100,
      },

      {
        accessorKey: 'Comentario',
        header: 'Comentario',
        size: 100,
      },
    ],
    [],
  );
  ;
  return columns
}
export async function getData(idPerfil: string, idTipoSupervision: string, FilterFechaInicial: Date, FilterFechaFinal: Date) {
  idPerfil = (idPerfil == "" || idPerfil == null) ? "0" : idPerfil
  idTipoSupervision = (idTipoSupervision == "" || idTipoSupervision == null) ? "0" : idTipoSupervision

  var CFilterFechaInicial = Moment(FilterFechaInicial).format("DD-MM-YYYY")
  var CFilterFechaFinal = Moment(FilterFechaFinal).format("DD-MM-YYYY")
  var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.GET_LISTA_TIPOSUPERVISION + "/" + CFilterFechaInicial + "/" + CFilterFechaFinal + "/" + idPerfil + "/" + idTipoSupervision, "")
  if (dataResponse.Data != null) {
    return dataResponse.Data;
  } else {
    return []
  }
}
export async function getListaComboPerfil() {
  var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.GET_LISTA_COMBO_PERFIL, "")
  if (dataResponse.Data != null) {
    return dataResponse.Data;
  } else {
    return []
  }
}
export async function getListaComboSupervision() {
  var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.GET_LISTA_COMBO_SUPERVISION, "")
  console.log(dataResponse)
  if (dataResponse.Data != null) {
    return dataResponse.Data;
  } else {
    return []
  }
}
export async function doExport(FilterFechaDesde: Date, FilterFechaHasta: Date, idPerfil: string, idTipoSupervision: string) {

  idPerfil = (idPerfil == "" || idPerfil == null) ? "0" : idPerfil
  idTipoSupervision = (idTipoSupervision == "" || idTipoSupervision == null) ? "0" : idTipoSupervision
  var CFilterFechaDesde = Moment(FilterFechaDesde).format("DD-MM-YYYY")
  var CFilterFechaHasta = Moment(FilterFechaHasta).format("DD-MM-YYYY")
  var dataResponse = await ClientAPI.RequestDownload(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.GET_LISTA_TIPOSUPERVISION_EXCEL + "/" + CFilterFechaDesde + "/" + CFilterFechaHasta + "/" + idPerfil + "/" + idTipoSupervision, 'DescargarExcelSupervision.xlsx')

}