import React, { useState } from 'react';
import { MaterialReactTable, type MaterialReactTableProps } from 'material-react-table';
import { useNavigate } from "react-router-dom";
import { Box, IconButton } from '@mui/material';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Email as EmailIcon,
} from '@mui/icons-material';
import ContentHeader from '../layout/ContentHeader';
import ContentBody from '../layout/ContentBody';
import * as Controller from '../../../controllers/cadenamaster-controller';
import * as Repository from '../../../repositories/cadenamaster-repository';
import Textbox from '../layout/Textbox';
export default function ConsultaCadenaMaster() {
  const router = useNavigate();
  const [ListaConsulta, setListaConsulta] = React.useState([])
  const [FilterNombreCadenaMaster, setFilterNombreCadenaMaster] = React.useState('')
  React.useEffect(() => {

    Controller.doConsultar(setListaConsulta, FilterNombreCadenaMaster)
  }, [])
  return (<div className="content-wrapper">
    <ContentHeader title={"Catalogo Cadena Master"} homePath={"Home"} currentPath={"Mantenimiento Cadena Master"} >
    </ContentHeader>
    <ContentBody title={"Catalogo Cadena Master"}>
      <div className='row'>
        <div className='col-lg-2 '>
          <label>Nombre cadena</label>
        </div>
        <div className='col-lg-4'>
          <div className='form-group'>
            <Textbox
              value={FilterNombreCadenaMaster}
              setValue={setFilterNombreCadenaMaster} />

          </div>
        </div>
      </div>
      <div className='row mt-2 mb-2'>
        <div className='col-md-12'>
          <div className='form-group'>
            <button className='btn btn-primary' onClick={() => Controller.toNuevo(router)}>Nuevo</button>
            <button className='btn btn-info' onClick={() => Controller.doConsultar(setListaConsulta, FilterNombreCadenaMaster)}>Consultar</button>
            <button className='btn btn-success' onClick={() => Controller.doExportar(FilterNombreCadenaMaster)}>Exportar</button>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <MaterialReactTable columns={Repository.GetColumns()} data={ListaConsulta}
            enableRowActions
            renderRowActions={({ row, table }) => (
              <div style={{ width: 100 }}>
              <div>
                <IconButton onClick={() => Controller.toEditar(router, row.original)}>
                  <EditIcon />
                </IconButton>
                <IconButton onClick={() => Controller.toQuestionInactivaActiva(row.original, setListaConsulta)}>
                  <DeleteIcon />
                </IconButton>
              </div>
              </div>
            )} />
        </div>
      </div>
    </ContentBody>

  </div>
  )
}