import { NavigateFunction } from 'react-router-dom';
import * as Repository from '../repositories/login-repository';


export async function toLogin(router: NavigateFunction, NombreUsuario: string, Clave: string, setValNombreUsuario: any, setValClave: any, setValMensajeLogin: any) {
    var objLogin = {
        NombreUsuario: NombreUsuario,
        Clave: Clave
    }

    setValMensajeLogin("")
    setValNombreUsuario(1)
    setValClave(1)
    if (NombreUsuario.trim() == ""){
        setValNombreUsuario(2)
    }
    
    if (Clave.trim() == ""){
        setValClave(2)
    }

    if (NombreUsuario.trim() == "" || Clave.trim() == "") {
        setValMensajeLogin("Debe ingresar usuario y clave")
        return false
    }
    console.log("AVANCE VALIDACIONES")
    var dataResponse = await Repository.doLogin(objLogin)
    if (dataResponse) {
        if (dataResponse.Success) {
            localStorage.setItem("TOKEN_JWT", dataResponse.Token)
            router('/main')
        } else {
            setValMensajeLogin("Usuario o clave incorrectos")
        }
    }

}

export async function getUsuarioInfo(setPerfilUsuario: any){
    var dataResponse = await Repository.getUsuarioInfo()
    setPerfilUsuario(dataResponse.IdPerfil)
}
