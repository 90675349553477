import { NavigateFunction } from 'react-router-dom';
import * as Repository from "../repositories/documento-repository";
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { UPLOAD_FILE_DOCUMENTO } from '../utils/contants';
import { ToastContainer, toast } from 'react-toastify';    
import 'react-toastify/dist/ReactToastify.css';

import {
    CODE_SERVER_RETURN,
  
  } from "../utils/contants";

export function toNuevo(router: NavigateFunction) {
    router("/main/documento/mantenimiento/nuevo")
}
export function toConsulta(router: NavigateFunction) {
    router("/main/documento/consulta")
}
export async function toEditar(router: NavigateFunction, row: any) {
    var codeEditar = row.CodeDocumento
    router("/main/documento/mantenimiento/" + codeEditar)
}
export async function toQuestionInactivaActiva(row: any, setListaConsulta: any) {
    const MySwal = withReactContent(Swal)
    var question = row.Estado == 1 ? "¿Desea eliminar el registro?" : "¿Desea activar el registro?"
    MySwal.fire({
        title: <p>{question}</p>,
        icon: "question",
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: "Sí",
        cancelButtonText: "Cancelar"
    }).then((result: any) => {
        if (result.isConfirmed) {
            toInactivaActiva(row, setListaConsulta)
        }
    })
}
async function toInactivaActiva(row: any, setListaConsulta: any) {
    var codeEliminar = row.CodeDocumento
    const objSave = {
        CodeDocumento: codeEliminar
    }
    if (row.Estado == 1) {
        var dataResponse = await Repository.inactiva(objSave)
    } else if (row.Estado == 0) {
        var dataResponse = await Repository.activa(objSave)
    }
    doConsultar(setListaConsulta, 0, "")
}
export async function getByCode(code: any, setNombreDocumento: any, setIdTipoArchivo: any, setContenido: any, setUrlFile: any) {
    var dataResponse = await Repository.getByCode(code)
    if (dataResponse != null) {
        setNombreDocumento(dataResponse.NombreDocumento)
        setIdTipoArchivo(dataResponse.IdTipoArchivo)
        setContenido(dataResponse.Contenido)
        setUrlFile(dataResponse.UrlFile)
    }
}
export async function doConsultar(setListaConsulta: any, FilterIdTipoArchivo: number, FilterNombreDocumento: string) {
    var dataResponse = await Repository.getData(FilterIdTipoArchivo, FilterNombreDocumento)
    setListaConsulta(dataResponse)
}
export async function save(router: NavigateFunction, codeDocumento: string, nombreDocumento: string, idTipoArchivo: number, contenido: string, file: any, urlFile: string) {
    const MySwal = withReactContent(Swal)

    var question = "¿Desea guardar el registro?"
    MySwal.fire({
        title: <p>{question}</p>,
        icon: "question",
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: "Sí",
        cancelButtonText: "Cancelar"
    }).then(async (result: any) => {
        if (result.isConfirmed) {

            if (result.isConfirmed) {
                // Subir Archivo
                var URLFile = urlFile == null || urlFile == "" ? null : urlFile;
                if (file != null) {
                    const formDataToUpload = new FormData();
                    const tipoBucket = UPLOAD_FILE_DOCUMENTO.USUARIO_ADJUNTAR_DOCUMENTO; // Documento
                    formDataToUpload.append("FileImage", file);
                    formDataToUpload.append("TipoBucket", tipoBucket.toString());
                    var dataResponseURL = await Repository.uploadFile(formDataToUpload);
                    if (dataResponseURL != null && dataResponseURL.Code === 0) {
                        URLFile = dataResponseURL.Data.PathFile;
                    }
                }
                console.log("Code a actualizar: " + codeDocumento)
                if (codeDocumento != null && codeDocumento != "") {
                    const objSave = {
                        CodeDocumento: codeDocumento,
                        NombreDocumento: nombreDocumento,
                        IdTipoArchivo: idTipoArchivo,
                        Contenido: contenido,
                        UrlFile: URLFile,
                    }
                    var dataResponse = await Repository.update(objSave)
                    if (dataResponse.Code == CODE_SERVER_RETURN.VAL) {
                        MySwal.fire({
                            title: <p>{dataResponse.Message}</p>,
                            icon: "warning",
                            showConfirmButton: true,
                            showCancelButton: false,
                            confirmButtonText: "OK",
                            
                        });
    
                    } else if(dataResponse.Code == CODE_SERVER_RETURN.ERROR) {
                        MySwal.fire({
                            title: <p>{dataResponse.Message}</p>,
                            icon: "error",
                            showConfirmButton: true,
                            showCancelButton: false,
                            confirmButtonText: "OK",
                            
                        });
                    } else {
                        toast.success('Guardado satisfactoriamente!')
                        toConsulta(router)
                      
                    }

                    toConsulta(router)
                } else {
                    const objSave = {
                        NombreDocumento: nombreDocumento,
                        IdTipoArchivo: idTipoArchivo,
                        Contenido: contenido,
                        UrlFile: URLFile,
                    }
                    var dataResponse = await Repository.save(objSave)
                    if (dataResponse.Code == CODE_SERVER_RETURN.VAL) {
                        MySwal.fire({
                            title: <p>{dataResponse.Message}</p>,
                            icon: "warning",
                            showConfirmButton: true,
                            showCancelButton: false,
                            confirmButtonText: "OK",
                            
                        });
    
                    } else if(dataResponse.Code == CODE_SERVER_RETURN.ERROR) {
                        MySwal.fire({
                            title: <p>{dataResponse.Message}</p>,
                            icon: "error",
                            showConfirmButton: true,
                            showCancelButton: false,
                            confirmButtonText: "OK",
                            
                        });
                    }else {
                        toast.success('Guardado satisfactoriamente!')
                        toConsulta(router)
                    }
                }
            }
        }
    });
}
export async function saveCadena(router: NavigateFunction, codeDocumento: string, ListaCadenas: any) {
    var newListaCadenas = ListaCadenas.map((item: any) => {
        var newObj = {
            CodeCadena: item.CodeCadena
        }
        return newObj
    })
    console.log("Code a actualizar: " + codeDocumento)
    if (codeDocumento != null && codeDocumento != "") {
        const objSave = {
            CodeDocumento: codeDocumento,
            cadenas: newListaCadenas,
        }
        var dataResponse = await Repository.saveCD(objSave)
    }
}
export async function Mensaje() {
    const MySwal = withReactContent(Swal)
    var message = "Tiene que completar los campos"
    MySwal.fire({
        title: <p>{message}</p>,
        icon: "warning",
        showConfirmButton: true,
        confirmButtonText: "Aceptar",
    }
    )
}
export function addMember(cadenas: string, ListaComboCadenas: any, ListaCadenas: any, setListaCadenas: any) {
    var NombreCadena = ""
    ListaComboCadenas.forEach((item: any) => {
        if (item.Id == cadenas) {
            NombreCadena = item.Texto
            return
        }
    });
    var existe = false
    ListaCadenas.forEach((item: any) => {
        if (item.CodeCadena == cadenas) {
            existe = true
            return
        }
    });
    console.log("Existe?: " + existe)
    if (!existe) {
        var objMember = {
            CodeCadena: cadenas,
            NombreCadena: NombreCadena
        }
        var newListaCadena = ListaCadenas.map((item: any) => { return item })
        newListaCadena.push(objMember)
        setListaCadenas(newListaCadena)
    } else {
    }
}
export async function getListaComboTipoArchivo(setListaComboTipoArchivo: any) {
    var dataResponse = await Repository.getListaComboTipoArchivo()
    setListaComboTipoArchivo(dataResponse)
}
export function toQuestionEliminarTiendas(row: any, ListaCadenas: any, setListaTiendas: any) {
    var indice = -1
    ListaCadenas.forEach((item: any, index: number) => {
        if (item.CodeTienda == row.CodeTienda) {
            indice = index
            return
        }
    });

    if (indice > -1) {
        ListaCadenas.splice(indice, 1);
        var newListaCadenas = ListaCadenas.map((item: any) => { return item })
        setListaTiendas(newListaCadenas)
    }
}

export async function doExportar(FilteIdTipoArchivo: number, FilterNombreDocumento: string) {
    var dataResponse = await Repository.doExport(FilteIdTipoArchivo, FilterNombreDocumento)
}  
