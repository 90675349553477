import React, { useMemo } from 'react';
import { MaterialReactTable, type MRT_ColumnDef } from 'material-react-table';
import { useNavigate } from "react-router-dom";
import ContentHeader from '../layout/ContentHeader';
import ContentBody from '../layout/ContentBody';
import "react-datepicker/dist/react-datepicker.css";
import * as Controller from  "../../../controllers/asistencias-controller";
import * as Repository from "../../../repositories/asistencias-repository";
import SelectSimple from '../layout/SelectSimple'
import Textbox from '../layout/Textbox'
import Label from '../layout/Label'
import DatePicker, { registerLocale } from "react-datepicker";

export default function ConsultaAsistencias(): React.JSX.Element {
  const router = useNavigate()
  const [ListaComboEquipo, setListaComboEquipo] = React.useState([])
  const [ListaConsulta, setListaConsulta] = React.useState([])
  const [periodo, setPeriodo] = React.useState(new Date)
  const [equipo, setEquipo] = React.useState('')
  const [NombresDni, setNombresDni] = React.useState('')
  const [diasLaborales, setDiasLaborales] = React.useState('')

  React.useEffect(() => {
    Controller.getListaComboEquipo(setListaComboEquipo)

  }, [])
  return (<div className="content-wrapper">
    <ContentHeader title={"Analisis de Asistencia"} homePath={"Home"} currentPath={"Analisis Asistencia"} >
    </ContentHeader>
    <ContentBody title={"Consulta Analisis de Asistencia"}>
      <div className='row'>
        <div className='col-lg-2'>
          <Label required={true}>Periodo</Label>
        </div>
        <div className='col-lg-4'>
          <div className='form-group'>
            <DatePicker locale="es" className='form-control-text' popperPlacement='top-end'
              selected={periodo}

              onChange={(date: any) => setPeriodo(date)} dateFormat={"yyyyMM"} showMonthYearPicker
              showFullMonthYearPicker
            />

          </div>
        </div>
        <div className='col-lg-2'>
          <label >Dias Laborales</label>
        </div>
        <div className='col-lg-4'>
          <div className='form-group'>
            <Textbox
              value={diasLaborales}
              setValue={setDiasLaborales}
            />
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-lg-2'>
          <label >Equipo</label>
        </div>
        <div className='col-lg-4'>
          <div className='form-group'>
            <SelectSimple
              value={equipo}
              setValue={setEquipo}
              lista={ListaComboEquipo}
            >
              --Seleccion--
            </SelectSimple>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-lg-2'>
          <label >Nombre / DNI</label>
        </div>
        <div className='col-lg-4'>
          <div className='form-group'>
            <Textbox
              value={NombresDni}
              setValue={setNombresDni}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-10">
          <MaterialReactTable columns={Repository.GetColumns()} data={ListaConsulta}
            renderRowActions={({ row, table }) => (
              <div>
              </div>
            )} />
        </div>
      </div>
    </ContentBody>
  </div>
  )
}