import clsx from 'clsx';

export default function SelectSimple({
  children, lista, value, setValue, valueError, setValueError, isSubmit, className, onChangeSection,
}: { children: React.ReactNode, lista: any, value: any, setValue?: any, valueError?: any, setValueError?: any, isSubmit?: boolean, className?: any, onChangeSection?: any }) {

  const onChangeSelect = (e: any) => {
    if (onChangeSection != undefined) {
      onChangeSection(e);
    }
    setValue(e.currentTarget.value);
    if (valueError != undefined) {
      if (e.currentTarget.value == "" || e.currentTarget.value == "0") {
        setValueError(true)
      } else {
        setValueError(false);
      }
    }
  }

  return (<select
    value={value}
    className={clsx(isSubmit ? (valueError ? "form-control-select" : "form-control-select error") : "form-control-select", className)}
    onChange={onChangeSelect}   >
    <option key='0' value="0" >{children}</option>
    {
      lista.map((el: any, key: any) =>
        <option key={el.Id} value={el.Id} >{el.Texto}</option>
      )
    }
  </select>)

}