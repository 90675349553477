import { NavigateFunction } from "react-router-dom";
import * as Repository from "../repositories/programacionusuario-repository";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CODE_SERVER_RETURN } from "../utils/contants";

export function toNuevo(router: NavigateFunction) {
  router("/main/programacionusuario/mantenimiento/nuevo");
}
export function toConsulta(router: NavigateFunction) {
  router("/main/programacionusuario/consulta");
}

export async function toEditar(router: NavigateFunction, row: any) {
  var codeEditar = row.CodeProgramacionUsuario;
  router("/main/programacionusuario/mantenimiento/" + codeEditar);
}
export async function toQuestionInactivaActiva(
  row: any,
  setListaConsulta: any
) {
  const MySwal = withReactContent(Swal);
  var question =
    row.Estado == 1
      ? "¿Desea eliminar el registro?"
      : "¿Desea activar el registro?";
  MySwal.fire({
    title: <p>{question}</p>,
    icon: "question",
    showConfirmButton: true,
    showCancelButton: true,
    confirmButtonText: "Sí",
    cancelButtonText: "Cancelar",
  }).then((result: any) => {
    if (result.isConfirmed) {
      toInactivaActiva(row, setListaConsulta);
    }
  });
}
async function toInactivaActiva(row: any, setListaConsulta: any) {
  var codeEliminar = row.CodeProgramacionUsuario;
  const objSave = {
    CodeProgramacionUsuario: codeEliminar,
  };
  if (row.Estado == 1) {
    var dataResponse = await Repository.inactiva(objSave);
  } else if (row.Estado == 0) {
    var dataResponse = await Repository.activa(objSave);
  }
  doConsultar(setListaConsulta, new Date(), new Date(), "", new Date());
}
export async function getByCode(
  code: any,
  setNombreUsuario: any,
  setCodeUsuario: any,
  setPeriodo: any
) {
  var dataResponse = await Repository.getByCode(code);
  if (dataResponse != null) {
    setNombreUsuario(dataResponse.NombreUsuario);
    setCodeUsuario(dataResponse.CodeUsuario);

    var periodostring = dataResponse.Periodo + "01";
    var PeriodoDate = moment(periodostring, "YYYYMMDD").toDate();
    setPeriodo(PeriodoDate);
  }
}
export async function doConsultar(
  setListaConsulta: any,
  filterFechaDesde: Date,
  filterFechaHasta: Date,
  FilterCodeUsuario: any,
  FilterPeriodo: Date
) {
  console.log(FilterCodeUsuario);

  var ValueFilterCodeUsuario = "";
  if (FilterCodeUsuario != null) {
    if (FilterCodeUsuario.value != undefined) {
      ValueFilterCodeUsuario = FilterCodeUsuario.value;
    }
  }

  var dataResponse = await Repository.getData(
    filterFechaDesde,
    filterFechaHasta,
    ValueFilterCodeUsuario,
    FilterPeriodo
  );
  setListaConsulta(dataResponse);
}
export async function save(
  router: NavigateFunction,
  codeProgramacionUsuario: string,
  codeUsuario: any,
  Periodo: any,
  SelRango: any,
  ListaConfiguracion: any
) {
  const MySwal = withReactContent(Swal);
  var question = "¿Desea guardar el registro?";
  MySwal.fire({
    title: <p>{question}</p>,
    icon: "question",
    showConfirmButton: true,
    showCancelButton: true,
    confirmButtonText: "Sí",
    cancelButtonText: "Cancelar",
  }).then(async (result: any) => {
    if (result.isConfirmed) {
      console.log(SelRango);
      console.log(codeUsuario);

      console.log("Code a actualizar: " + codeProgramacionUsuario);
      console.log("Code usuario: " + codeUsuario);

      var periodoMes = moment(Periodo).format("YYYYMM");

      var valueCodeUsuario = "";
      if (codeUsuario != null) {
        console.log("Ingreso: " + codeUsuario.value);
        valueCodeUsuario = codeUsuario.value;
      }

      if (codeProgramacionUsuario != null && codeProgramacionUsuario != "") {
        var Desde = moment(SelRango[0].startDate).format("DD/MM/YYYY");
        var Hasta = moment(SelRango[0].endDate).format("DD/MM/YYYY");

        const objSave = {
          CodeProgramacionUsuario: codeProgramacionUsuario,
          Detalles: ListaConfiguracion,
          CDesde: Desde,
          Chasta: Hasta,
          Periodo: periodoMes,
        };
        var dataResponse = await Repository.update(objSave);
        if (dataResponse.Code == CODE_SERVER_RETURN.VAL) {
          MySwal.fire({
            title: <p>{dataResponse.Message}</p>,
            icon: "warning",
            showConfirmButton: true,
            showCancelButton: false,
            confirmButtonText: "OK",
          });
        } else if (dataResponse.Code == CODE_SERVER_RETURN.ERROR) {
          MySwal.fire({
            title: <p>{dataResponse.Message}</p>,
            icon: "error",
            showConfirmButton: true,
            showCancelButton: false,
            confirmButtonText: "OK",
          });
        } else {
          toConsulta(router);
          toast.success("Guardado satisfactoriamente!");
        }
      } else {
        console.log("CodeUsuario: " + valueCodeUsuario);

        var Desde = moment(SelRango[0].startDate).format("DD/MM/YYYY");
        var Hasta = moment(SelRango[0].endDate).format("DD/MM/YYYY");

        const objSave = {
          CodeUsuario: valueCodeUsuario,
          CDesde: Desde,
          Chasta: Hasta,
          Detalles: ListaConfiguracion,
          Periodo: periodoMes,
        };
        var dataResponse = await Repository.save(objSave);
        if (dataResponse.Code == CODE_SERVER_RETURN.VAL) {
          MySwal.fire({
            title: <p>{dataResponse.Message}</p>,
            icon: "warning",
            showConfirmButton: true,
            showCancelButton: false,
            confirmButtonText: "OK",
          });
        } else if (dataResponse.Code == CODE_SERVER_RETURN.ERROR) {
          MySwal.fire({
            title: <p>{dataResponse.Message}</p>,
            icon: "error",
            showConfirmButton: true,
            showCancelButton: false,
            confirmButtonText: "OK",
          });
        } else {
          toast.success("Guardado satisfactoriamente!");
          toConsulta(router);
        }
      }
    }
  });
}

export async function Mensaje(mensaje: any) {
  const MySwal = withReactContent(Swal);
  var message = mensaje || "Tiene que completar los campos";
  MySwal.fire({
    title: <p>{message}</p>,
    icon: "warning",
    showConfirmButton: true,
    confirmButtonText: "Aceptar",
  });
}
export function addMember(
  diaSemana: string,
  tiendas: any,
  tareas: any,
  ListaComboDiasSemana: any,
  ListaConfiguracion: any,
  setListaConfiguracion: any
) {
  // console.log("Tareas seleccionadas: "+ tareas[0].value)

  var NombreDiaSemana = "";

  ListaComboDiasSemana.forEach((item: any) => {
    console.log("item id: " + item.Id + ", diasemana: " + diaSemana);
    if (item.Id == diaSemana) {
      NombreDiaSemana = item.Texto;
      return;
    }
  });

  var NombreTiendas = "";
  tiendas.forEach((item: any) => {
    NombreTiendas = NombreTiendas + item.label + ", ";
  });

  var NombreTareas = "";
  tareas.forEach((item: any) => {
    NombreTareas = NombreTareas + item.label + ", ";
  });

  var newTiendas = tiendas.map((item: any) => {
    var newItem = {
      CodeTienda: item.value,
    };
    return newItem;
  });

  var newTareas = tareas.map((item: any) => {
    var newItem = {
      IdTarea: item.value,
    };
    return newItem;
  });

  var objMember = {
    DiaSemana: diaSemana,
    NombreDiaSemana: NombreDiaSemana,
    Tareas: newTareas,
    Tiendas: newTiendas,
    NombreTiendas: NombreTiendas,
    NombreTareas: NombreTareas,
  };

  var newListaConfiguracion = ListaConfiguracion.map((item: any) => {
    return item;
  });
  newListaConfiguracion.push(objMember);
  setListaConfiguracion(newListaConfiguracion);
}

export function toQuestionEliminarTareas(
  row: any,
  ListaTareas: any,
  setListaTareas: any
) {
  var indice = -1;
  ListaTareas.forEach((item: any, index: number) => {
    if (item.CodeUsuario == row.CodeUsuario) {
      indice = index;
      return;
    }
  });
  if (indice > -1) {
    ListaTareas.splice(indice, 1);
    var newListaMiembros = ListaTareas.map((item: any) => {
      return item;
    });
    setListaTareas(newListaMiembros);
  }
}
export async function getListaComboTareas(setListaComboTareas: any) {
  var dataResponse = await Repository.getListaComboTareas();

  var dataResponseSelect = dataResponse.map((item: any) => {
    var newItem = {
      value: item.Id,
      label: item.Texto,
    };
    return newItem;
  });

  setListaComboTareas(dataResponseSelect);
}

export async function getListaComboTienda(setListaComboTienda: any) {
  var dataResponse = await Repository.getListaComboTienda();
  var dataResponseSelect = dataResponse.map((item: any) => {
    var newItem = {
      value: item.Id,
      label: item.Texto,
    };
    return newItem;
  });
  setListaComboTienda(dataResponseSelect);
}

export async function getListaComboUsuario(setListaComboUsuario: any) {
  var dataResponse = await Repository.getListaComboUsuario();
  var dataResponseSelect = dataResponse.map((item: any) => {
    var newItem = {
      value: item.Id,
      label: item.Texto,
    };
    return newItem;
  });

  setListaComboUsuario(dataResponseSelect);
}
export async function getListaComboSemana(setListaComboSemana: any) {
  var dataResponse = await Repository.getListaComboSemana();
  setListaComboSemana(dataResponse);
}

export async function getListaComboDiasSemana(setListaComboDiasSemana: any) {
  var dataResponse = await Repository.getListaComboDiasSemana();
  setListaComboDiasSemana(dataResponse);
}
export async function doExportar(
  FilterFechaDesde: Date,
  FilterFechaHasta: Date,
  FilterCodeUsuario: any,
  FilterPeriodo: Date
) {
  var ValueFilterCodeUsuario = "";
  if (FilterCodeUsuario != null && FilterCodeUsuario.length > 0) {
    ValueFilterCodeUsuario = FilterCodeUsuario.value;
  }

  var dataResponse = await Repository.doExport(
    FilterFechaDesde,
    FilterFechaHasta,
    ValueFilterCodeUsuario,
    FilterPeriodo
  );
}

export async function importarProgramacionUsuario(
  router: NavigateFunction,
  fileImportar: any
) {
  if (fileImportar != null) {
    const formDataToUpload = new FormData();
    formDataToUpload.append("FileExcel", fileImportar);

    var dataResponseURL = await Repository.importarProgramacionUsuario(
      formDataToUpload
    );
    if (dataResponseURL != null && dataResponseURL.Code === 0) {
      toConsulta(router);
    }
  }
}

export async function doCargarPlantillaProgramacionUsuario() {
  Repository.doCargarPlantillaProgramacionUsuario();
}
