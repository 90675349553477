import { NavigateFunction } from 'react-router-dom';
import * as Repository from "../repositories/canbioclave-repository";
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { ToastContainer, toast } from 'react-toastify';    
import 'react-toastify/dist/ReactToastify.css';
import {
    CODE_SERVER_RETURN,
    UPLOAD_FILES,
    UPLOAD_FILESALTASUNAT,
    UPLOAD_FILESCONSTANCIA,
    UPLOAD_FILESCONTRATO,
    UPLOAD_FILESCS,
    UPLOAD_FILESDNI,
  } from "../utils/contants";

export function toNuevo(router: NavigateFunction) {
    
    router("/main/canbioclave/mantenimiento/nuevo")
}
export function toConsulta(router: NavigateFunction) {
    router("/main/canbioclave/consulta");
}
export async function toEditar(router: NavigateFunction, row: any) {
    var codeEditar = row.CodeCliente
    router("/main/canbioclave/mantenimiento/" + codeEditar)
}
export async function save(router: NavigateFunction, claveActual: string, claveNueva: string,  confirmarClaveNueva:  string) {
    const MySwal = withReactContent(Swal)
    var question = "¿Desea guardar el registro?"
    MySwal.fire({
        title: <p>{question}</p>,
        icon: "question",
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: "Sí",
        cancelButtonText: "Cancelar"
    }).then(async (result: any) => {
        if (result.isConfirmed) {
            console.log("Code a actualizar: " + claveNueva)
            if (claveNueva != null && claveNueva != "") {
                const objSave = {
                    ClaveActual: claveActual ,
                    ClaveNueva : claveNueva,
                  ConfirmarClaveNueva : confirmarClaveNueva
                }
                var dataResponse = await Repository.update(objSave)
                if (dataResponse.Code == CODE_SERVER_RETURN.VAL) {
                    MySwal.fire({
                        title: <p>{dataResponse.Message}</p>,
                        icon: "warning",
                        showConfirmButton: true,
                        showCancelButton: false,
                        confirmButtonText: "OK",
                        
                    });

                } else if(dataResponse.Code == CODE_SERVER_RETURN.ERROR) {
                    MySwal.fire({
                        title: <p>{dataResponse.Message}</p>,
                        icon: "error",
                        showConfirmButton: true,
                        showCancelButton: false,
                        confirmButtonText: "OK",
                        
                    });
                } else {
                    toast.success('Actualizado Clave satisfactoriamente!')
                    toConsulta(router)
                  
                }
            }
        }
    })
}
export async function Mensaje() {
    const MySwal = withReactContent(Swal)
    var message = "Tiene que completar los campos"
    MySwal.fire({
        title: <p>{message}</p>,
        icon: "warning",
        showConfirmButton: true,
        confirmButtonText: "Aceptar",
    }
    )
}
