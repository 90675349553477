import * as ClientAPI from "../utils/methods";
import * as Endpoints from "../api/endpoint";
import Moment from "moment";

export async function getListaComboCadenaMaster() {
  var dataResponse = await ClientAPI.RequestGet(
    Endpoints.URL_BASE_SECURITY +
      Endpoints.ListEndpoint.GET_LISTA_COMBO_CADENAMASTER,
    ""
  );
  if (dataResponse.Data != null) {
    return dataResponse.Data;
  } else {
    return null;
  }
}

export async function getListaComboTienda(setCodeCadenaMaster: string) {
  var dataResponse = await ClientAPI.RequestGet(
    Endpoints.URL_BASE_ADMIN +
      Endpoints.ListEndpoint.GET_LISTA_COMBO_TIENDA +
      "/" +
      setCodeCadenaMaster,
    ""
  );
  if (dataResponse.Data != null) {
    return dataResponse.Data;
  } else {
    return null;
  }
}

export async function getListaComboMarca() {
  var dataResponse = await ClientAPI.RequestGet(
    Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.GET_LISTA_COMBO_MARCA,
    ""
  );
  if (dataResponse.Data != null) {
    return dataResponse.Data;
  } else {
    return null;
  }
}
export async function getListaComboCategoria(setCodeMarca: string) {
  var dataResponse = await ClientAPI.RequestGet(
    Endpoints.URL_BASE_ADMIN +
      Endpoints.ListEndpoint.GET_LISTA_COMBO_CATEGORIA +
      "/" +
      setCodeMarca,
    ""
  );
  if (dataResponse.Data != null) {
    return dataResponse.Data;
  } else {
    return null;
  }
}

export async function geListaComboProducto(codeProducto: string) {
  var dataResponse = await ClientAPI.RequestGet(
    Endpoints.URL_BASE_ADMIN +
      Endpoints.ListEndpoint.GET_LISTA_COMBOPRODUCTO +
      "/" +
      codeProducto,
    ""
  );
  console.log(dataResponse);

  if (dataResponse.Data != null) {
    return dataResponse.Data;
  } else {
    return [];
  }
}

export async function geListaImagenProducto(
  FilterFechaDesde: Date,
  FilterFechaHasta: Date,
  codeTienda: string
) {
  codeTienda = codeTienda == "" || codeTienda == "0" ? "-" : codeTienda;
  var CFilterFechaDesde = Moment(FilterFechaDesde).format("DD-MM-YYYY");
  var CFilterFechaHasta = Moment(FilterFechaHasta).format("DD-MM-YYYY");
  var dataResponse = await ClientAPI.RequestGet(
    Endpoints.URL_BASE_ADMIN +
      Endpoints.ListEndpoint.GET_LISTA_PRODUCTOIMAGEN +
      "/" +
      CFilterFechaDesde +
      "/" +
      CFilterFechaHasta +
      "/" +
      codeTienda,
    ""
  );

  if (dataResponse.Data != null) {
    return dataResponse.Data;
  } else {
    return [];
  }
}
