import * as Repository from "../repositories/indicadorsupervision-repository";




export async function doConsultar(setListaConsulta: any,  FilterFechaInicio: Date, FilterFechaFinal: Date, FilterNombreCompleto : string) {
    var dataResponse = await Repository.getData(FilterFechaInicio, FilterFechaFinal, FilterNombreCompleto)
    setListaConsulta(dataResponse)
}

export async function doExportar(  FilterFechaDesde: Date, FilterFechaHasta : Date) {
    var dataResponse = await Repository.doExport( FilterFechaDesde , FilterFechaHasta)
} 
