import * as ClientAPI from "../utils/methods"; 
import * as Endpoints from "../api/endpoint"; 


export async function doLogin(objLogin: any){
    var dataResponse = await ClientAPI.RequestPostApiKey(Endpoints.URL_BASE_SECURITY + Endpoints.ListEndpoint.LOGIN, objLogin)
    console.log(dataResponse)

    if(dataResponse.Data != null){
      return dataResponse.Data;
    }else {
      return null
    }
  }


  export async function getUsuarioInfo() {
    var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_SECURITY + Endpoints.ListEndpoint.GET_USUARIO_INFO , "")
    console.log(dataResponse)
    return dataResponse.Data;
  }



  