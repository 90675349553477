import * as ClientAPI from "../utils/methods"; 
import * as Endpoints from "../api/endpoint"; 

export async function importarStockB2B(formDataToUpload: FormData) {
    var dataResponse = await ClientAPI.RequestPostUpload(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.IMPORTAR_STOCKB2B, formDataToUpload)
  
    if (dataResponse != null) {
      return dataResponse;
    } else {
      return null
    }
  }
  export async function doDescargarPlantillaStockB2B() {
    var dataResponse = await ClientAPI.RequestDownload(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.GET_DESCARGAR_PLANTILLA_STOCKB2B , 'PlantillaCargaStockB2B.xlsx')
  }