
import * as Repository from "../repositories/indicadorpromotoria-repository";



export async function doConsultar(setListaConsulta: any,   FilterFechaDesde: Date , FilterFechaHasta: Date,  FilterNombreCompleto : string ) {
    var dataResponse = await Repository.getData( FilterFechaDesde, FilterFechaHasta, FilterNombreCompleto   )
    setListaConsulta(dataResponse)
}

export async function doExportar(  FilterFechaDesde: Date, FilterFechaHasta : Date) {
    var dataResponse = await Repository.doExport( FilterFechaDesde , FilterFechaHasta)
}  
