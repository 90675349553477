import ContentHeader from "../layout/ContentHeader";
import * as Controller from "../../../controllers/usuario-controller";
import React, { useContext } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SelectSimple from "../layout/SelectSimple";
import Textbox from "../layout/Textbox";
import Select from "react-select";
import Label from "../layout/Label";
import es from "date-fns/locale/es"; // the locale you want
import { useParams, useNavigate } from "react-router-dom";
import { UserContext } from "../../main/main-screen";
registerLocale("es", es); // register it with the name you want

export default function NuevoUsuario() {
  const { code } = useParams();
  const Router = useNavigate();
  const { Perfilusuario } = useContext(UserContext);

  const [ListaComboTipoDocumento, setListaComboTipoDocumento] = React.useState(
    []
  );
  const [ListaComboEmpresa, setListaComboEmpresa] = React.useState([]);
  const [ListaComboEmpresAseguradora, setListaComboEmpresaAseguradora] =
    React.useState([]);
  const [ListaComboPerfil, setListaComboPerfil] = React.useState([]);
  const [ListaComboCliente, setListaComboCliente] = React.useState([]);
  const [ListaComboCargoPersona, setListaComboCargoPersona] = React.useState(
    []
  );
  const [ListaComboMarcaRepresenta, setListaComboMarcaRepresenta] =
    React.useState([]);
  const [ListaComboUbigeo, setListaComboUbigeo] = React.useState([]);
  const [ListaComboDepartamento, setListaComboDepartamento] = React.useState(
    []
  );
  const [ListaComboProvincia, setListaComboProvincia] = React.useState([]);
  const [codeUsuario, setCodeUsuario] = React.useState("");
  const [codeEmpresa, setCodeEmpresa] = React.useState("");
  const [idPerfil, setIdPerfil] = React.useState("0");
  const [tipoDocumentoIdentidad, setTipoDocumentoIdentidad] = React.useState(0);
  const [codeCargoPersona, setCodeCargoPersona] = React.useState("");
  const [codeCliente, setCodeCliente] = React.useState("");
  const [codeMarcaRepresenta, setCodeMarcaRepresenta] = React.useState("");
  const [valCodeEmpresa, setValCodeEmpresa] = React.useState(false);
  const [nombres, setNombres] = React.useState("");
  const [apellidos, setApellidos] = React.useState("");
  const [nombreUsuario, setNombreUsuario] = React.useState("");
  const [correo, setCorreo] = React.useState("");
  const [tituloVentana, setTituloVentana] = React.useState("Nuevo Usuario");
  const [valIdPerfil, setValIdPerfil] = React.useState(false);
  const [valnombres, setValNombres] = React.useState(false);
  const [valapellidos, setValApellidos] = React.useState(false);
  const [valnombreUsuario, setValNombreUsuario] = React.useState(false);
  const [valCorreo, setValCorreo] = React.useState(false);
  const [messageError, setMessasgeError] = React.useState("");
  //const [Perfilusuario, setPerfilusuario] = React.useState(0)
  const [fechaInicioContrato, setFechaInicioContrato] = React.useState(null);
  const [fechaFinContrato, setFechaFinContrato] = React.useState(null);
  const [fechaIngreso, setFechaIngreso] = React.useState(null);
  const [valNumeroDocumento, setValNumeroDocumento] = React.useState(false);
  const [valtipoDocumentoIdentidad, setValTipoDocumentoIdentidad] =
    React.useState(false);
  const [valCodeCargoPersona, setValCodeCargoPersona] = React.useState(false);
  const [valCodeMarcaRepresenta, setValCodeMarcaRepresenta] =
    React.useState(false);
  const [numeroDocumento, setNumeroDocumento] = React.useState("");
  const [isSubmit, setIsSubmit] = React.useState(false);
  const [primernombre, setPrimerNombre] = React.useState("");
  const [segundonombre, setSegundoNombre] = React.useState("");
  const [apellidoPaterno, setApellidoPaterno] = React.useState("");
  const [apellidoMaterno, setApellidoMaterno] = React.useState("");
  const [MarcaRepresenta, setMarcaPresenta] = React.useState("");
  const [direccion, setDireccion] = React.useState("");
  const [departamento, setDepartamento] = React.useState("");
  const [provincia, setProvincia] = React.useState("");
  const [ubigeo, setUbigeo] = React.useState("");
  const [horario, setHorario] = React.useState("");
  const [refrigerio, setRefrigerio] = React.useState("");
  const [carnetSanidad, setCarnetSanidad] = React.useState("");
  const [diasTrabajo, setDiasTrabajo] = React.useState("");
  const [descanso, setDescanso] = React.useState("");
  const [valcodeCliente, setValCodeCliente] = React.useState(false);
  const [validEmpresaAseguradora, setValEmpresaAseguradora] = React.useState(0);
  const [valprimernombre, setValPrimerNombre] = React.useState(false);
  const [valsegundonombre, setValSegundoNombre] = React.useState(false);
  const [valapellidoPaterno, setValApellidoPaterno] = React.useState(false);
  const [valapellidoMaterno, setValApellidoMaterno] = React.useState(false);
  const [valubigeo, setValUbigeo] = React.useState(false);
  const [valprovincia, setValProvincia] = React.useState(false);
  const [valdepartamento, setValDepartamento] = React.useState(false);
  const [valdireccion, setValDireccion] = React.useState(false);
  const [valhorario, setValHorario] = React.useState(false);
  const [valrefrigerio, setValRefrigerio] = React.useState(false);
  const [valdescanso, setValDescanso] = React.useState(false);
  const [idEmpresaAseguradora, setEmpresaAseguradora] = React.useState(0);
  const [urlFileContrato, setUrlFileContrato] = React.useState("");
  const [urlFileCS, setUrlFileCS] = React.useState("");
  const [urlFileConstancia, setUrlFileConstancia] = React.useState("");
  const [urlFileDNI, setUrlFileDNI] = React.useState("");
  const [urlFileAltaSunat, setUrlFileAltaSunat] = React.useState("");
  const [fileContrato, setFileContrato] = React.useState(null);
  const [fileCS, setFileCS] = React.useState(null);
  const [fileConst, setFileConst] = React.useState(null);
  const [fileDNI, setFileDNI] = React.useState(null);
  const [fileAltaSunat, setFileAltaSunat] = React.useState(null);
  const [file, setFile] = React.useState(null);
  const [fechaInicioCs, setFechaInicioCs] = React.useState(null);
  const [fechaFinCs, setFechaFinCs] = React.useState(null);
  const [fechaInicioCtr, setFechaInicioCtr] = React.useState(null);
  const [fechaFinCtr, setFechaFinCtr] = React.useState(null);
  const [urlImagen, setUrlImagen] = React.useState("");

  const onchangeDepatamento = (e: any) => {
    console.log("Valor departamento:" + e.target.value);
    if (
      e.target.value != null &&
      e.target.value != "" &&
      e.target.value != "0"
    ) {
      Controller.getListaComboProvincia(e.target.value, setListaComboProvincia);
    } else {
      setListaComboProvincia([]);
    }
    setListaComboUbigeo([]);
  };

  const onChangeProvincia = (e: any) => {
    if (
      e.target.value != null &&
      e.target.value != "" &&
      e.target.value != "0"
    ) {
      Controller.getListaComboUbigeo(e.target.value, setListaComboUbigeo);
    } else {
      setListaComboUbigeo([]);
    }
  };
  React.useEffect(() => {
    Controller.getListaComboTipoDocumento(setListaComboTipoDocumento);
    Controller.getListaComboEmpresa(setListaComboEmpresa);
    Controller.getListaComboPerfil(setListaComboPerfil);
    //Controller.getUsuarioInfo(setPerfilusuario)
    Controller.getListaComboCliente(setListaComboCliente);
    Controller.getListaComboCargoPersona(setListaComboCargoPersona);
    Controller.getListaComboMarcaRepresenta(setListaComboMarcaRepresenta);
    Controller.getListaComboDepartamento(setListaComboDepartamento);
    Controller.getListaComboEmpresaAseguradora(setListaComboEmpresaAseguradora);
    if (code != "nuevo") {
      setTituloVentana("Editar Usuario");
      setCodeUsuario(code!);
      Controller.getByCode(
        code,
        setCodeEmpresa,
        setIdPerfil,
        setCodeCargoPersona,
        setNombres,
        setApellidos,
        setNombreUsuario,
        setCorreo,
        setPrimerNombre,
        setSegundoNombre,
        setApellidoPaterno,
        setApellidoMaterno,
        setHorario,
        setRefrigerio,
        setDescanso,
        setDireccion,
        setUbigeo,
        setListaComboUbigeo,
        setProvincia,
        setListaComboProvincia,
        setDepartamento,
        setEmpresaAseguradora,
        setUrlFileContrato,
        setUrlFileCS,
        setUrlFileConstancia,
        setUrlFileDNI,
        setUrlFileAltaSunat,
        setFechaInicioCs,
        setFechaFinCs,
        setFechaIngreso,
        setFechaInicioContrato,
        setFechaFinContrato,
        setFechaInicioCtr,
        setFechaFinCtr,
        setTipoDocumentoIdentidad,
        setNumeroDocumento,
        setUrlImagen,
        setCodeMarcaRepresenta,
        setCodeCliente,
        setCarnetSanidad,
        setDiasTrabajo
      );
    }
  }, []);

  function isValidEmail(email: string) {
    // Define a regular expression pattern for email validation.
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return pattern.test(email);
  }

  function validarCampos() {
    var Result = true;
    setIsSubmit(true);
    if (Perfilusuario == 1) {
      if (codeEmpresa == "" || codeEmpresa == "0" || codeEmpresa == null) {
        Result = false;
        setValCodeEmpresa(false);
      } else {
        setValCodeEmpresa(true);
      }
    }

    if (idPerfil == "0" || idPerfil == null) {
      Result = false;
      setValIdPerfil(false);
    } else {
      setValIdPerfil(true);
    }

    if (tipoDocumentoIdentidad == 0 || tipoDocumentoIdentidad == null) {
      Result = false;
      setValTipoDocumentoIdentidad(false);
    } else {
      setValTipoDocumentoIdentidad(true);
    }

    {
      /* if (nombres == "" || nombres == null) {
      Result = false
      setValNombres(false)
    } else {
      setValNombres(true)
    } */
    }

    if (primernombre == "" || primernombre == null) {
      Result = false;
      setValPrimerNombre(false);
    } else {
      setValPrimerNombre(true);
    }

    /*if (segundonombre == "" || segundonombre == null) {
      Result = false
      setValSegundoNombre(false)
    } else {
      setValSegundoNombre(true)
    }*/

    if (apellidoPaterno == "" || apellidoPaterno == null) {
      Result = false;
      setValApellidoPaterno(false);
    } else {
      setValApellidoPaterno(true);
    }

    if (apellidoMaterno == "" || apellidoMaterno == null) {
      Result = false;
      setValApellidoMaterno(false);
    } else {
      setValApellidoMaterno(true);
    }

    {
      /* if (apellidos == "" || apellidos == null) {
      Result = false
      setValApellidos(false)
    } else {
      setValApellidos(true)
    } */
    }
    if (nombreUsuario == "" || nombreUsuario == null) {
      Result = false;
      setValNombreUsuario(false);
    } else {
      setValNombreUsuario(true);
    }
    if (correo == "" || correo == null) {
      Result = false;
      setValCorreo(false);
    } else {
      if (isValidEmail(correo)) {
        setValCorreo(true);
      } else {
        Result = false;
        setValCorreo(false);
        setMessasgeError("Correo no válido");
      }
    }

    if (numeroDocumento == "" || numeroDocumento == null) {
      Result = false;
      setValNumeroDocumento(false);
    } else {
      setValNumeroDocumento(true);
    }

    if (
      codeCargoPersona == "" ||
      codeCargoPersona == "0" ||
      codeCargoPersona == null
    ) {
      Result = false;
      setValCodeCargoPersona(false);
    } else {
      setValCodeCargoPersona(true);
    }

    /*if (codeCliente == "" || codeCliente == "0" || codeCliente == null) {
      Result = false
      setValCodeCliente(false)
    } else {
      setValCodeCliente(true)
    }

    if (codeMarcaRepresenta == "" || codeMarcaRepresenta == "0" || codeMarcaRepresenta == null) {
      Result = false
      setValCodeMarcaRepresenta(false)
    } else {
      setValCodeMarcaRepresenta(true)
    }*/

    if (Result) {
      Controller.save(
        Router,
        idPerfil,
        codeUsuario,
        codeEmpresa,
        nombres,
        apellidos,
        nombreUsuario,
        correo,
        tipoDocumentoIdentidad,
        numeroDocumento,
        fechaIngreso,
        fechaInicioContrato,
        fechaFinContrato,
        fechaInicioCs,
        fechaFinCs,
        fechaInicioCtr,
        fechaFinCtr,
        codeCargoPersona,
        codeMarcaRepresenta,
        MarcaRepresenta,
        codeCliente,
        primernombre,
        segundonombre,
        apellidoPaterno,
        apellidoMaterno,
        horario,
        refrigerio,
        descanso,
        direccion,
        ubigeo,
        idEmpresaAseguradora,
        file,
        urlImagen,
        fileContrato,
        urlFileContrato,
        fileCS,
        urlFileCS,
        fileConst,
        urlFileConstancia,
        fileDNI,
        urlFileDNI,
        fileAltaSunat,
        urlFileAltaSunat,
        carnetSanidad,
        diasTrabajo
      );
    } else {
      Controller.Mensaje();
    }
  }

  function onChangeFileUpload(e: any) {
    var fileToUpload = e.target.files[0];
    //const reader = new FileReader();
    //var url = reader.readAsDataURL(file);
    setFile(fileToUpload);
  }

  function onChangeFileContratoUpload(e: any) {
    var fileToUpload = e.target.files[0];

    setFileContrato(fileToUpload);
  }
  function onChangeFileCSUpload(e: any) {
    var fileToUpload = e.target.files[0];

    setFileCS(fileToUpload);
  }

  function onChangeFileContanciaUpload(e: any) {
    var fileToUpload = e.target.files[0];

    setFileConst(fileToUpload);
  }
  function onChangeFileDNIUpload(e: any) {
    var fileToUpload = e.target.files[0];

    setFileDNI(fileToUpload);
  }

  function onChangeFileAltaSunatUpload(e: any) {
    var fileToUpload = e.target.files[0];

    setFileAltaSunat(fileToUpload);
  }
  return (
    <div className="content-wrapper">
      <ContentHeader
        title={tituloVentana}
        homePath={"Home"}
        currentPath={tituloVentana}
      ></ContentHeader>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card card-primary">
                <div className="card-header">
                  <div className="d-flex flex-row gap-2">
                    <div className="align-items-center">
                      <i
                        className="bx bx-arrow-back arrow-back"
                        onClick={() => Controller.toConsulta(Router)}
                      ></i>
                    </div>
                    <div className="">
                      <h4 className="card-title">{tituloVentana}</h4>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-6">
                      <h4>Datos</h4>
                      <hr />
                    </div>
                  </div>
                  {Perfilusuario == 1 ? (
                    <div className="row">
                      <div className="col-lg-2">
                        <Label required={true}>Empresa</Label>
                      </div>
                      <div className="col-lg-4">
                        <div className="form-group">
                          <SelectSimple
                            value={codeEmpresa}
                            setValue={setCodeEmpresa}
                            valueError={valCodeEmpresa}
                            setValueError={setValCodeEmpresa}
                            isSubmit={isSubmit}
                            lista={ListaComboEmpresa}
                          >
                            --Seleccion--
                          </SelectSimple>
                        </div>
                      </div>
                      <div className="col-lg-2">
                        <Label required={true}>Perfil</Label>
                      </div>
                      <div className="col-lg-4">
                        <div className="form-group">
                          <SelectSimple
                            value={idPerfil}
                            setValue={setIdPerfil}
                            valueError={valIdPerfil}
                            setValueError={setValIdPerfil}
                            isSubmit={isSubmit}
                            lista={ListaComboPerfil}
                          >
                            --Seleccion--
                          </SelectSimple>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="row">
                      <div className="col-lg-2">
                        <Label required={true}>Perfil</Label>
                      </div>
                      <div className="col-lg-4">
                        <div className="form-group">
                          <SelectSimple
                            value={idPerfil}
                            setValue={setIdPerfil}
                            valueError={valIdPerfil}
                            setValueError={setValIdPerfil}
                            isSubmit={isSubmit}
                            lista={ListaComboPerfil}
                          >
                            --Seleccion--
                          </SelectSimple>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="row">
                    <div className="col-lg-2">
                      <Label required={true}>Tipo Documento</Label>
                    </div>
                    <div className="col-md-4">
                      <SelectSimple
                        value={tipoDocumentoIdentidad}
                        setValue={setTipoDocumentoIdentidad}
                        valueError={valtipoDocumentoIdentidad}
                        setValueError={setValTipoDocumentoIdentidad}
                        isSubmit={isSubmit}
                        lista={ListaComboTipoDocumento}
                      >
                        --Seleccion--
                      </SelectSimple>
                    </div>
                    <div className="col-lg-2">
                      <Label required={true}>Numero Documento</Label>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <Textbox
                          value={numeroDocumento}
                          valueError={valNumeroDocumento}
                          setValue={setNumeroDocumento}
                          setValueError={setValNumeroDocumento}
                          isSubmit={isSubmit}
                          maxLenght={11}
                          onlyNumbers={true}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-2">
                      <Label required={true}>Primer Nombre</Label>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <Textbox
                          value={primernombre}
                          valueError={valprimernombre}
                          setValue={setPrimerNombre}
                          setValueError={setValPrimerNombre}
                          isSubmit={isSubmit}
                          upperCase={true}
                        />
                      </div>
                    </div>
                    <div className="col-lg-2">
                      <Label>Segundo Nombre</Label>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <Textbox
                          value={segundonombre}
                          setValue={setSegundoNombre}
                          isSubmit={isSubmit}
                          upperCase={true}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-2">
                      <Label required={true}>Apellido Paterno</Label>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <Textbox
                          value={apellidoPaterno}
                          valueError={valapellidoPaterno}
                          setValue={setApellidoPaterno}
                          setValueError={setValApellidoPaterno}
                          isSubmit={isSubmit}
                          upperCase={true}
                        />
                      </div>
                    </div>
                    <div className="col-lg-2">
                      <Label required={true}>Apellido Materno</Label>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <Textbox
                          value={apellidoMaterno}
                          valueError={valapellidoMaterno}
                          setValue={setApellidoMaterno}
                          setValueError={setValApellidoMaterno}
                          isSubmit={isSubmit}
                          upperCase={true}
                        />
                      </div>
                    </div>
                  </div>
                  {/*<!--<div className='row'>
                  <div className='col-lg-2'>
                    <Label required={true}>Nombres</Label>
                  </div>
                  <div className='col-lg-4'>
                    <div className='form-group'>
                      <Textbox
                        value={nombres}
                        valueError={valnombres}
                        setValue={setNombres}
                        setValueError={setValNombres}
                        isSubmit={isSubmit} />
                    </div>
                  </div>
                  <div className='col-lg-2'>
                    <label>Apellidos</label>
                  </div>
                  <div className='col-lg-4'>
                    <div className='form-group'>
                      <Textbox
                        value={apellidos}
                        valueError={valapellidos}
                        setValue={setApellidos}
                        setValueError={setValApellidos}
                        isSubmit={isSubmit} />
                    </div>
                  </div>
              </div>-->*/}
                  <div className="row">
                    <div className="col-lg-2">
                      <Label required={true}>Nombre Usuario</Label>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <Textbox
                          value={nombreUsuario}
                          valueError={valnombreUsuario}
                          setValue={setNombreUsuario}
                          setValueError={setValNombreUsuario}
                          isSubmit={isSubmit}
                          maxLenght={20}
                          upperCase={true}
                        />
                      </div>
                    </div>
                    <div className="col-lg-2">
                      <Label required={true}>Correo</Label>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <Textbox
                          value={correo}
                          valueError={valCorreo}
                          messageError={messageError}
                          setValue={setCorreo}
                          setValueError={setValCorreo}
                          isSubmit={isSubmit}
                          maxLenght={128}
                          lowerCase={true}
                        />
                      </div>
                    </div>
                  </div>
                  <div className=" row">
                    <div className="col-lg-6">
                      <h4>Datos Adicionales</h4>
                      <hr />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-2 ">
                      <label>Direccion</label>
                    </div>
                    <div className="col-lg-4 ">
                      <div className="form-group">
                        <Textbox
                          value={direccion}
                          setValue={setDireccion}
                          maxLenght={100}
                          upperCase={true}
                        />
                      </div>
                    </div>
                    <div className="col-lg-2 ">
                      <label>Departamentos</label>
                    </div>
                    <div className="col-lg-4 ">
                      <div className="form-group">
                        <SelectSimple
                          value={departamento}
                          setValue={setDepartamento}
                          lista={ListaComboDepartamento}
                          onChangeSection={onchangeDepatamento}
                        >
                          --Selecionar--
                        </SelectSimple>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-2 ">
                      <label>Provincia</label>
                    </div>
                    <div className="col-lg-4 ">
                      <div className="form-group">
                        <SelectSimple
                          value={provincia}
                          setValue={setProvincia}
                          lista={ListaComboProvincia}
                          onChangeSection={onChangeProvincia}
                        >
                          --Selecionar--
                        </SelectSimple>
                      </div>
                    </div>
                    <div className="col-lg-2 ">
                      <label>Ubigeo</label>
                    </div>
                    <div className="col-lg-4 ">
                      <div className="form-group">
                        <SelectSimple
                          value={ubigeo}
                          setValue={setUbigeo}
                          lista={ListaComboUbigeo}
                        >
                          --Selecionar--
                        </SelectSimple>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-lg-2">
                      <Label>Cliente</Label>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <Select
                          value={codeCliente}
                          closeMenuOnSelect={false}
                          options={ListaComboCliente}
                          isMulti
                          onChange={(e: any) => {
                            setCodeCliente(e);
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-lg-2">
                      <Label>Marca Representa</Label>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <Select
                          value={codeMarcaRepresenta}
                          closeMenuOnSelect={false}
                          options={ListaComboMarcaRepresenta}
                          isMulti
                          onChange={(e: any) => {
                            setCodeMarcaRepresenta(e);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-2">
                      <Label required={true}>Cargo Persona</Label>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <SelectSimple
                          value={codeCargoPersona}
                          setValue={setCodeCargoPersona}
                          valueError={valCodeCargoPersona}
                          setValueError={setValCodeCargoPersona}
                          isSubmit={isSubmit}
                          lista={ListaComboCargoPersona}
                        >
                          --Seleccion--
                        </SelectSimple>
                      </div>
                    </div>
                    <div className="col-lg-2">
                      <Label>Fecha Ingreso</Label>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <DatePicker
                          locale="es"
                          className="form-control-text"
                          selected={fechaIngreso}
                          onChange={(date: any) => setFechaIngreso(date)}
                          dateFormat={"dd/MM/yyyy"}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-2">
                      <Label>Fecha Inicio Contrato</Label>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <DatePicker
                          locale="es"
                          className="form-control-text"
                          selected={fechaInicioContrato}
                          onChange={(date: any) => setFechaInicioContrato(date)}
                          dateFormat={"dd/MM/yyyy"}
                        />
                      </div>
                    </div>
                    <div className="col-lg-2">
                      <label>Fecha Fin Contrato</label>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <DatePicker
                          locale="es"
                          className="form-control-text"
                          selected={fechaFinContrato}
                          onChange={(date: any) => setFechaFinContrato(date)}
                          dateFormat={"dd/MM/yyyy"}
                        />
                      </div>
                    </div>
                  </div>

                  <div className=" row">
                    <div className="col-lg-2 ">
                      <label>Carnet Sanidad</label>
                    </div>
                    <div className="col-lg-4 ">
                      <div className="form-group">
                        <Textbox
                          value={carnetSanidad}
                          setValue={setCarnetSanidad}
                          maxLenght={50}
                          upperCase={true}
                        />
                      </div>
                    </div>
                    <div className="col-lg-2 ">
                      <label>Dias de Trabajo</label>
                    </div>
                    <div className="col-lg-4 ">
                      <div className="form-group">
                        <Textbox
                          value={diasTrabajo}
                          setValue={setDiasTrabajo}
                          maxLenght={50}
                          upperCase={true}
                        />
                      </div>
                    </div>
                  </div>
                  <div className=" row">
                    <div className="col-lg-2 ">
                      <label>Horario</label>
                    </div>
                    <div className="col-lg-4 ">
                      <div className="form-group">
                        <Textbox
                          value={horario}
                          setValue={setHorario}
                          maxLenght={30}
                          upperCase={true}
                        />
                      </div>
                    </div>
                    <div className="col-lg-2 ">
                      <label>Refrigerio</label>
                    </div>
                    <div className="col-lg-4 ">
                      <div className="form-group">
                        <Textbox
                          value={refrigerio}
                          setValue={setRefrigerio}
                          maxLenght={30}
                          upperCase={true}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-2 ">
                      <label>Descanso</label>
                    </div>
                    <div className="col-lg-4 ">
                      <div className="form-group">
                        <Textbox
                          value={descanso}
                          setValue={setDescanso}
                          maxLenght={30}
                          upperCase={true}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mt-5">
                    <div className="col-lg-6">
                      <h4>Carnet Sanidad</h4>
                      <hr />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-2">
                      <label>Empresa Aseguradora</label>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <SelectSimple
                          value={idEmpresaAseguradora}
                          setValue={setEmpresaAseguradora}
                          lista={ListaComboEmpresAseguradora}
                        >
                          --Seleccion--
                        </SelectSimple>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-2">
                      <label>Inicio Vigencia</label>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <DatePicker
                          locale="es"
                          className="form-control"
                          selected={fechaInicioCs}
                          onChange={(date: any) => setFechaInicioCs(date)}
                          dateFormat={"dd/MM/yyyy"}
                        />
                      </div>
                    </div>
                    <div className="col-lg-2">
                      <label>Fin Vigencia</label>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <DatePicker
                          locale="es"
                          className="form-control"
                          selected={fechaFinCs}
                          onChange={(date: any) => setFechaFinCs(date)}
                          dateFormat={"dd/MM/yyyy"}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mt-5">
                    <div className="col-lg-6">
                      <h4>SCTR</h4>
                      <hr />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-2">
                      <label>Inicio SCTR</label>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <DatePicker
                          locale="es"
                          className="form-control"
                          selected={fechaInicioCtr}
                          onChange={(date: any) => setFechaInicioCtr(date)}
                          dateFormat={"dd/MM/yyyy"}
                        />
                      </div>
                    </div>
                    <div className="col-lg-2">
                      <label>Fin SCRT</label>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <DatePicker
                          locale="es"
                          className="form-control"
                          selected={fechaFinCtr}
                          onChange={(date: any) => setFechaFinCtr(date)}
                          dateFormat={"dd/MM/yyyy"}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mt-5">
                    <div className="col-lg-6">
                      <h4>Foto</h4>
                      <hr />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-2">
                      <Label>Selecionar imagen</Label>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <input
                          id="image"
                          accept="image/*"
                          type="file"
                          name="image"
                          onChange={onChangeFileUpload}
                        />
                      </div>
                    </div>
                    {urlImagen != null && urlImagen != "" ? (
                      <div className="col-lg-6">
                        <div className="row">
                          <div className="col-lg-4">
                            <Label>Vista Previa</Label>
                          </div>
                          <div className="col-lg-8">
                            <img src={urlImagen} style={{ width: 100 }} />
                          </div>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="row mt-5">
                    <div className="col-lg-6">
                      <h4> Documentacion </h4>
                      <hr />
                    </div>
                  </div>
                  <div className="row mt-5">
                    <div className="col-lg-2">
                      <Label>Adjuntar Contrato</Label>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <input
                          accept="docx/*"
                          type="file"
                          onChange={onChangeFileContratoUpload}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4">
                      {urlFileContrato != null && urlFileContrato != "" ? (
                        <a href={urlFileContrato}>
                          <button className="btn btn-primary">
                            Descargar Archivo
                          </button>
                        </a>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  <div className="row mt-5">
                    <div className="col-lg-2">
                      <Label>Adjuntar Carnet Sanidad</Label>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <input
                          accept="docx/*"
                          type="file"
                          onChange={onChangeFileCSUpload}
                        />
                      </div>
                    </div>
                    <div>
                      {urlFileCS != null && urlFileCS != "" ? (
                        <a href={urlFileCS}>
                          <button className="btn btn-primary">
                            Descargar Archivo
                          </button>
                        </a>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  <div className="row mt-5">
                    <div className="col-lg-2">
                      <Label>Adjuntar Constancia</Label>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <input
                          accept="docx/*"
                          type="file"
                          onChange={onChangeFileContanciaUpload}
                        />
                      </div>
                    </div>
                    <div>
                      {urlFileConstancia != null && urlFileConstancia != "" ? (
                        <a href={urlFileConstancia}>
                          <button className="btn btn-primary">
                            Descargar Archivo
                          </button>
                        </a>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  <div className="row mt-5">
                    <div className="col-lg-2">
                      <Label>Adjuntar DNI</Label>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <input
                          accept="docx/*"
                          type="file"
                          onChange={onChangeFileDNIUpload}
                        />
                      </div>
                    </div>
                    <div>
                      {urlFileDNI != null && urlFileDNI != "" ? (
                        <a href={urlFileDNI}>
                          <button className="btn btn-primary">
                            Descargar Archivo
                          </button>
                        </a>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  <div className="row mt-5">
                    <div className="col-lg-2">
                      <Label>Adjuntar Alta Sunat</Label>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <input
                          accept="docx/*"
                          type="file"
                          onChange={onChangeFileAltaSunatUpload}
                        />
                      </div>
                    </div>
                    <div>
                      {urlFileAltaSunat != null && urlFileAltaSunat != "" ? (
                        <a href={urlFileAltaSunat}>
                          <button className="btn btn-primary">
                            Descargar Archivo
                          </button>
                        </a>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  <div className="row mt-5">
                    <div className="col-lg-6">
                      <div className="col-md-12">
                        <div className="form-group">
                          <button
                            className="btn btn-default"
                            onClick={() => Controller.toConsulta(Router)}
                          >
                            Cancelar
                          </button>
                          <button
                            className="btn btn-primary"
                            onClick={() => validarCampos()}
                          >
                            Guardar
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
