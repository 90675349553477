import * as Repository from "../repositories/analisisreportefotografico-repository";
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';

export async function getListaComboCadenaMaster(setListaComboCadenaMaster: any) {
    var dataResponse = await Repository.getListaComboCadenaMaster()
    setListaComboCadenaMaster(dataResponse)
}
export async function getListaComboTienda(codeCadenaMaster: string, setListaComboTienda: any) {
    if (codeCadenaMaster != "-" && codeCadenaMaster != '0') {
        var dataResponse = await Repository.getListaComboTienda(codeCadenaMaster)
        setListaComboTienda(dataResponse)
    } else {
        setListaComboTienda(dataResponse)
    }
}
export async function getListaComboMarca(setListaComboMarca: any) {
    var dataResponse = await Repository.getListaComboMarca()
    setListaComboMarca(dataResponse)
}
export async function getListaComboCategoria(codeMarca: string, setListaComboCategoria: any) {

    if (codeMarca != "-" && codeMarca != "0") {
        var dataResponse = await Repository.getListaComboCategoria(codeMarca)
        setListaComboCategoria(dataResponse)
    } else {
        setListaComboCategoria(dataResponse)

    }
}
export async function geListaComboProducto( codeProducto : string, setListaProducto: any) {
    var dataResponse = await Repository.geListaComboProducto(codeProducto)

    console.log(dataResponse)
    if (dataResponse != null) {
        setListaProducto(dataResponse)
    }
}
export async function getListaImagenProducto(setUrlImagen: any, FilterFechaDesde: Date, FilterFechaHasta: Date, codeTienda :string  ) {
    var dataResponse = await Repository.geListaImagenProducto(FilterFechaDesde, FilterFechaHasta, codeTienda )

    console.log(dataResponse)
    if (dataResponse != null) {
        setUrlImagen(dataResponse)
    }
}

export async function Mensaje() {
  const MySwal = withReactContent(Swal)
  var message = "Tiene que completar los campos"
  MySwal.fire({
      title: <p>{message}</p>,
      icon: "warning",
      showConfirmButton: true,
      confirmButtonText: "Aceptar",
  }
  )
}