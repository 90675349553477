import { useMemo } from 'react';
import { type MRT_ColumnDef } from 'material-react-table';
import * as ClientAPI from "../utils/methods";
import * as Endpoints from "../api/endpoint";
import Moment from 'moment';
import moment from 'moment';
type ChechinOutTienda = {
  Fecha: Date,
  Fechahoracheckin: Date,
  Fechahoracheckout: Date
};
export function GetColumns() {
  const columns = useMemo<MRT_ColumnDef<ChechinOutTienda>[]>(
    () => [
      {
        accessorKey: 'NombreCompleto',
        header: 'Nombre Usuario',
        size: 20,
      },

      {
        accessorKey: 'NumeroDocumentoIdentidad',
        header: 'Documento Identidad',
        size: 100,
      },
      {
        id: 'Fecha',
        header: 'Fecha',
        size: 100,
        accessorFn: (row) => {
          return moment(row.Fecha).format("DD/MM/YYYY");
        },
      },
      {
        accessorKey: 'NombreTienda',
        header: 'Nombre Tienda',
        size: 100,
      },
      {
        id: 'Fechahoracheckin',
        header: 'Fecha hora ingreso',
        size: 100,
        accessorFn: (row) => {
          return moment(row.Fechahoracheckin).format("DD/MM/YYYY HH:mm:ss");
        },
      },
      {
        id: 'Fechahoracheckout',
        header: 'Fecha hora salida',
        size: 100,
        accessorFn: (row) => {
          return moment(row.Fechahoracheckout).format("DD/MM/YYYY HH:mm:ss");
        },
      },
    ],
    [],
  );
  ;
  return columns
}
export async function getData(idPerfil: string, FilterFechaDesde: Date, FilterFechaHasta: Date, FilterNombreCompleto: string) {
  idPerfil = (idPerfil == "" || idPerfil == null) ? "0" : idPerfil
  //FilterNombreCompleto = (FilterNombreCompleto == "" || FilterNombreCompleto == "0")? "-": FilterNombreCompleto
  var CFilterFechaDesde = Moment(FilterFechaDesde).format("DD-MM-YYYY")
  var CFilterFechaHasta = Moment(FilterFechaHasta).format("DD-MM-YYYY")
  var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.GET_LISTA_CHECKINOUTTIENDA + "/" + CFilterFechaDesde + "/" + CFilterFechaHasta + "/" + idPerfil + "/" + FilterNombreCompleto, "")
  if (dataResponse.Data != null) {
    return dataResponse.Data;
  } else {
    return []
  }
}
export async function getListaComboPerfil() {
  var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.GET_LISTA_COMBO_PERFIL, "")

  if (dataResponse.Data != null) {
    return dataResponse.Data;
  } else {
    return []
  }
}
export async function doExport(idPerfil: string, FilterFechaDesde: Date, FilterFechaHasta: Date, FilterNombreCompleto: string) {
  //idPerfil = (idPerfil == "" || idPerfil == null)? "0": idPerfil
  var CFilterFechaDesde = Moment(FilterFechaDesde).format("DD-MM-YYYY")
  var CFilterFechaHasta = Moment(FilterFechaHasta).format("DD-MM-YYYY")

  var dataResponse = await ClientAPI.RequestDownload(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.GET_LISTA_CHECKINOUTTIENDA_EXCEL + "/" + CFilterFechaDesde + "/" + CFilterFechaHasta + "/" + idPerfil + "/" + FilterNombreCompleto, 'DescargarExcelCkeckinOutTienda.xlsx')

}