import { useMemo } from 'react';
import { type MRT_ColumnDef } from 'material-react-table';
import * as ClientAPI from "../utils/methods";
import * as Endpoints from "../api/endpoint";
import Moment from 'moment';
import moment from 'moment';
type dashboardstock = {
  FechaHoraRegistro: Date
};
export function GetColumns() {
  const columns = useMemo<MRT_ColumnDef<dashboardstock>[]>(
    () => [
    
      {
        accessorKey: 'Nombres',
        header: 'Nombres',
        size: 100,
      },
      {
        accessorKey: 'NumeroDocumento',
        header: 'DNI ',
        size: 100,
      },
      {
        accessorKey: 'NombrePerfil',
        header: 'Perfil ',
        size: 100,
      },
      {
        accessorKey: 'SkuMaestro',
        header: 'Codigo Sku Maestro ',
        size: 100,
      },
      {
        accessorKey: 'SkuCadena',
        header: 'Codigo Sku Cadena',
        size: 100,
      },
      {
        accessorKey: 'Ean',
        header: 'EAN ',
        size: 100,
      },
      {
        accessorKey: 'NombreMarca',
        header: 'Marca ',
        size: 100,
      },
      {
        accessorKey: 'NombreCategoria',
        header: 'Categoria ',
        size: 100,
      },
      {
        accessorKey: 'NombreProducto',
        header: 'Producto ',
        size: 100,
      },
      {
        id: 'FechaHoraRegistro',
        header: 'Fecha hora registro',
        size: 100,
        accessorFn: (row) => {
          return moment(row.FechaHoraRegistro).format("DD/MM/YYYY HH:mm:ss");
        },
      },
      {
        accessorKey: 'NombreTienda',
        header: 'Tienda ',
        size: 100,
      },
      {
        accessorKey: 'NombreCadena',
        header: 'Cadena ',
        size: 100,
      },
      {
        accessorKey: 'TipoStock',
        header: 'Tipo Stock ',
        size: 100,
      },
      {
        accessorKey: 'StockTeorico',
        header: 'Stock On Hand B2B',
        size: 100,
      },
      {
        accessorKey: 'StockReal',
        header: 'Tipo Real Stock ',
        size: 100,
      },

      {
        accessorKey: 'Estado',
        header: 'Estado ',
        size: 100,
      },

    ],
    [],
  );
  ;
  return columns
}
export async function getData(FilterCodeMarca: string, FilterCategoria: string, FilterSubCategoria : string,  FilterCadena:string, FilterTienda: string, FilterFechaDesde:Date, FilterFechaHasta: Date) {
  
  FilterCodeMarca = (FilterCodeMarca == "" || FilterCodeMarca == "0")? "-": FilterCodeMarca
  FilterCategoria = (FilterCategoria == "" || FilterCategoria == "0")? "-": FilterCategoria
  FilterSubCategoria = (FilterSubCategoria == "" || FilterSubCategoria == "0")? "-": FilterSubCategoria
    FilterCadena = (FilterCadena == "" || FilterCadena == "0")? "-": FilterCadena
    FilterTienda = (FilterTienda == "" || FilterTienda == "0")? "-": FilterTienda

    var CFilterFechaDesde = Moment(FilterFechaDesde).format("DD-MM-YYYY") //dd-MM-yyyy
    var CFilterFechaHasta = Moment(FilterFechaHasta).format("DD-MM-YYYY")
   
    var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.GET_LISTA_STOCKTIENDA  + "/" +  FilterCodeMarca + "/" + FilterCategoria + "/" +  FilterSubCategoria + "/" + FilterCadena + "/" + FilterTienda + "/" + CFilterFechaDesde + "/" + CFilterFechaHasta , "")
    if (dataResponse.Data != null) {
      return dataResponse.Data;
    } else {
      return []
    }
  }
  
  export async function getListaComboMarca(){
    var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.GET_LISTA_COMBO_MARCA , "")
    if(dataResponse.Data != null){
      return dataResponse.Data;
    }else {
      return null
    }
  }

  export async function getListaComboTienda() {
    var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.GET_LISTA_COMBO_TIENDA + "/-" , "")
    if (dataResponse.Data != null) {
      return dataResponse.Data;
    } else {
      return null
    }
  }
  
  
   export async function getListaComboCadena() {
    var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.GET_LISTA_COMBO_CADENA, "")
    if (dataResponse.Data != null) {
      return dataResponse.Data;
    } else {
      return null
    }
  }
  
  export async function doExport(FilterCodeMarca: string, FilterCategoria: string, FilterSubCategoria : string,  FilterCadena:string, FilterTienda: string, FilterFechaDesde:Date, FilterFechaHasta: Date) {
  
    FilterCodeMarca = (FilterCodeMarca == "" || FilterCodeMarca == "0")? "-": FilterCodeMarca
    FilterCategoria = (FilterCategoria == "" || FilterCategoria == "0")? "-": FilterCategoria
    FilterSubCategoria = (FilterSubCategoria == "" || FilterSubCategoria == "0")? "-": FilterSubCategoria
      FilterCadena = (FilterCadena == "" || FilterCadena == "0")? "-": FilterCadena
      FilterTienda = (FilterTienda == "" || FilterTienda == "0")? "-": FilterTienda
  
      var CFilterFechaDesde = Moment(FilterFechaDesde).format("DD-MM-YYYY") //dd-MM-yyyy
      var CFilterFechaHasta = Moment(FilterFechaHasta).format("DD-MM-YYYY")
     
      var dataResponse = await ClientAPI.RequestDownload(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.GET_LISTA_STOCKTIENDA_EXCEL  + "/" +  FilterCodeMarca + "/" + FilterCategoria + "/" +  FilterSubCategoria + "/" + FilterCadena + "/" + FilterTienda + "/" + CFilterFechaDesde + "/" + CFilterFechaHasta , 'DescargarExcelStockTienda.xlsx')
      
    }

    export async function getListaComboCategoria(){
      var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.GET_LISTA_COMBO_CATEGORIAS, "")
      if(dataResponse.Data != null){
        return dataResponse.Data;
      }else {
        return null
      }
    }