
import * as Repository from "../repositories/incidencia-repository";

export async function doConsultar(setListaConsulta: any, idPerfil : string ,idTipoIncidencia : string , FilterFechaInicial: Date , FilterFechaFinal: Date   ) {
    var dataResponse = await Repository.getData(idPerfil, idTipoIncidencia,  FilterFechaInicial, FilterFechaFinal )
    setListaConsulta(dataResponse)
}
export async function getListaComboPerfil(setListaComboPerfil: any) {
    var dataResponse = await Repository.getListaComboPerfil()
    setListaComboPerfil(dataResponse)
}

export async function getListaComboIncidencia(setListaComboTipoGrupo: any) {
        var dataResponse = await Repository.getListaComboIncidencia()
        setListaComboTipoGrupo(dataResponse)
  
}

export async function doExportar( idPerfil : string, idTipoIncidencia : string, FilterFechaDesde: Date, FilterFechaHasta : Date) {
    var dataResponse = await Repository.doExport(idPerfil, idTipoIncidencia, FilterFechaDesde , FilterFechaHasta)
}  