import { useMemo } from 'react';
import { type MRT_ColumnDef } from 'material-react-table';
import * as ClientAPI from "../utils/methods";
import * as Endpoints from "../api/endpoint";
type Categoria = {
  IdCategoria: string,
  NombreCategoria: string,
  NombreMarca: string,
};
export function GetColumns() {
  const columns = useMemo<MRT_ColumnDef<Categoria>[]>(
    () => [
      {
        accessorKey: 'IdCategoria',
        header: 'ID',
        size: 100,
      },
      {
        accessorKey: 'NombreCategoria',
        header: ' Categoria',
        size: 200,
      },

      {
        accessorKey: 'NombreMarca',
        header: ' Marca',
        size: 300,
      },
    ],
    [],
  );
  ;
  return columns
}
export async function getData(FilterMarca :string, FilterNombreCategoria: string) {
  FilterMarca = (FilterMarca == "" || FilterMarca == "0")? "-": FilterMarca
 

  var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.GET_LISTA_CATEGORIA  + "/" + FilterMarca  + "/" + FilterNombreCategoria, "")
  if (dataResponse.Data != null) {
    return dataResponse.Data;
  } else {
    return []
  }
}
export async function getByCode(code: string) {
  var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.GET_CATEGORIA_BYCODE + "/" + code, "")
  return dataResponse.Data;
}
export async function save(objSave: any) {
  var dataResponse = await ClientAPI.RequestPost(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.INSERTAR_CATEGORIA, "", objSave)
  if (dataResponse != null) {
    return dataResponse;
  } else {
    return null
  }
}
export async function update(objSave: any) {
  var dataResponse = await ClientAPI.RequestPost(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.UPDATE_CATEGORIA, "", objSave)
  if (dataResponse != null) {
    return dataResponse;
  } else {
    return null
  }
}
export async function inactiva(objSave: any) {
  var dataResponse = await ClientAPI.RequestPost(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.INACTIVO_CATEGORIA, "", objSave)
  if (dataResponse.Data != null) {
    return dataResponse.Data;
  } else {
    return null
  }
}
export async function activa(objSave: any) {
  var dataResponse = await ClientAPI.RequestPost(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.ACTIVO_CATEGORIA, "", objSave)
  if (dataResponse.Data != null) {
    return dataResponse.Data;
  } else {
    return null
  }
} 

export async function getListaComboMarca(){
  var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.GET_LISTA_COMBO_MARCA, "")
  if(dataResponse.Data != null){
    return dataResponse.Data;
  }else {
    return null
  }
}

export async function doExport(FilterMarca :string, FilterNombreCategoria: string) {
  FilterMarca = (FilterMarca == "" || FilterMarca == "0")? "-": FilterMarca
  var dataResponse = await ClientAPI.RequestDownload(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.GET_LISTA_CATEGORIA_EXCEL  + "/" + FilterMarca  + "/" + FilterNombreCategoria, 'DescargarExcelCategoria.xlsx')
  
}