import ContentHeader from '../layout/ContentHeader'
import { useParams, useNavigate } from 'react-router-dom'
import * as Controller from  "../../../controllers/canbioclave-controller";
import React from 'react'
import Textbox from '../layout/Textbox'
import Label from '../layout/Label'
export default function ConsultaCanbioClave() {
  const { code } = useParams()
  const Router = useNavigate()
  const [codeUsuario, setCodeUsuario] = React.useState('')
  const [claveActual, setClaveActual] = React.useState('')
  const [claveNueva, setnuevaClave] = React.useState('')
  const [confirmarClaveNueva, setConfirmarNuevaClave] = React.useState('')
  const [valclaveActual, setValClaveActual] = React.useState(false)
  const [valclaveNueva, setValclavenueva] = React.useState(false)
  const [valconfirmarNuevaClave, setValConfirmarNuevaClave] = React.useState(false)
  const [tituloVentana, setTituloVentana] = React.useState(" Cambiar Clave")
  const [isSubmit, setIsSubmit] = React.useState(false)
  React.useEffect(() => {
    if (code != "nuevo") {
      setTituloVentana("Editar Clave")
      setCodeUsuario(code!)
      
    }
  }, [])
  function validarCampos() {
    var Result = true
    setIsSubmit(true)
    if (claveActual == "" || claveActual == null) {
      Result = false
      setValClaveActual(false)
    } else {
      setValClaveActual(true)
    }

    if (claveNueva == "" || claveNueva == null) {
      Result = false
      setValclavenueva(false)
    } else {
      setValclavenueva(true)
    }
    if (confirmarClaveNueva == "" || confirmarClaveNueva == null) {
      Result = false
      setValConfirmarNuevaClave(false)
    } else {
      setValConfirmarNuevaClave(true)
    }
    if (Result) {
      Controller.save(Router, claveActual, claveNueva ,confirmarClaveNueva)
    } else {
      Controller.Mensaje()
    }
  }
  return (<div className="content-wrapper">
    <ContentHeader title={tituloVentana} homePath={"Home"} currentPath={tituloVentana}>
    </ContentHeader>
    <section className="content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="card card-primary">
              <div className="card-header">
                <h4 className="card-title">{tituloVentana}</h4>
              </div>
              <div className="card-body">
              <div className='row'>
                  <div className='col-lg-6'>
                    <h4>Datos</h4>
                    <hr/>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-lg-2'>
                    <Label required ={true}>Clave Actual</Label>
                  </div>
                  <div className='col-lg-4'>
                    <div className='form-group'>
                    <Textbox
                       value={claveActual}
                       valueError={valclaveActual}
                       setValue={setClaveActual}
                       setValueError={setValClaveActual}
                       isSubmit ={isSubmit}
                       secureEntry={true} />
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-lg-2'>
                    <Label required ={true}>Nueva Clave</Label>
                  </div>
                  <div className='col-lg-4'>
                    <div className='form-group'>
                    <Textbox
                       value={claveNueva}
                       valueError={valclaveNueva}
                       setValue={setnuevaClave}
                       setValueError={setValclavenueva}
                       isSubmit ={isSubmit}
                       secureEntry={true} />
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-lg-2'>
                    <Label required ={true}>Comfirmar Clave</Label>
                  </div>
                  <div className='col-lg-4'>
                    <div className='form-group'>
                    <Textbox
                       value={confirmarClaveNueva}
                       valueError={valconfirmarNuevaClave}
                       setValue={setConfirmarNuevaClave}
                       setValueError={setValConfirmarNuevaClave}
                       isSubmit ={isSubmit}
                       secureEntry={true} />
                    </div>
                  </div>
                </div>
                <div className="row mt-5">
                  <div className="col-lg-6">
                    <div className='form-group'>
                      <button className='btn btn-primary' onClick={() => validarCampos()}>Actualizar</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
  )
}