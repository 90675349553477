import clsx from "clsx";

export default function Textbox({
  children,
  value,
  setValue,
  valueError,
  setValueError,
  messageError,
  isSubmit,
  maxLenght,
  onlyNumbers,
  upperCase,
  lowerCase,
  className,
  onChangeSection,
  secureEntry,
  readOnly
}: {
  children?: React.ReactNode;
  value: any;
  setValue: any;
  valueError?: any;
  setValueError?: any;
  messageError?: string;
  isSubmit?: boolean;
  maxLenght?: number;
  onlyNumbers?: boolean;
  upperCase?: boolean;
  lowerCase?: boolean;
  className?: any;
  onChangeSection?: any;
  secureEntry?: boolean;
  readOnly?: boolean;
}) {


  var type_input = "text";

  if (secureEntry != undefined) {
    if (secureEntry) {
      type_input = "password";
    }
  }

  if (maxLenght == undefined) {
    maxLenght = 100;
  }

  if (valueError == undefined) {
    valueError = true;
  }

  if (isSubmit == undefined) {
    isSubmit = false;
  }

  
  if (readOnly == undefined) {
    readOnly = false;
  }


  const onChangeTextbox = (e: any) => {
    if (onChangeSection != undefined) {
      onChangeSection(e);
    }
    if (onlyNumbers) {
      const re = /^[0-9\b]+$/;
      if (e.currentTarget.value === '' || re.test(e.currentTarget.value)) {
        setValue(e.currentTarget.value);
      }
    } else {
      setValue(e.currentTarget.value);
    }

    if (setValueError != undefined) {
      if (e.currentTarget.value != "") {
        setValueError(true);
      } else {
        setValueError(false);
      }
    }
  };

  const onInputTextbox = (e: any) => {
    if (upperCase != undefined || lowerCase != undefined) {
      if (upperCase) {
        e.currentTarget.value = e.currentTarget.value.toUpperCase()
      } else if (lowerCase) {
        e.currentTarget.value = e.currentTarget.value.toLowerCase()
      }
    }
  };

  return (
    <div>
      <input
        type={type_input}

        value={value}
        maxLength={maxLenght}
        className={clsx(
          isSubmit
            ? valueError
              ? "form-control-text"
              : "form-control-text error"
            : "form-control-text",
          className
        )}
        onChange={onChangeTextbox}
        onInput={onInputTextbox}
        readOnly={readOnly}
      />
      {(isSubmit && !valueError && messageError) ? <label style={{ color: 'red', fontWeight: 'normal' }}>{messageError}</label> : <></>}
    </div>)




}
