import * as Repository from "../repositories/supervision-repository";

export async function doConsultar(setListaConsulta: any, idPerfil : string ,idTipoSupervision: string , FilterFechaInicial: Date , FilterFechaFinal: Date   ) {
    var dataResponse = await Repository.getData(idPerfil, idTipoSupervision,  FilterFechaInicial, FilterFechaFinal )
    setListaConsulta(dataResponse)
}

export async function getListaComboPerfil(setListaComboPerfil: any) {
    var dataResponse = await Repository.getListaComboPerfil()
    setListaComboPerfil(dataResponse)
}

export async function getListaComboSupervision(setListaComboSupervision: any) {
        var dataResponse = await Repository.getListaComboSupervision()
        setListaComboSupervision(dataResponse)
  
}

export async function doExportar(  FilterFechaInicio: Date, FilterFechaFinal : Date, idPerfil : string, idTipoSupervision : string) {
    var dataResponse = await Repository.doExport( FilterFechaInicio , FilterFechaFinal, idPerfil, idTipoSupervision)
}  