import React, { useMemo, useRef } from "react";
import { MRT_TableInstance, MaterialReactTable, type MRT_ColumnDef, type MRT_RowSelectionState } from "material-react-table";
import { useNavigate } from "react-router-dom";
import ContentHeader from "../layout/ContentHeader";
import ContentBody from "../layout/ContentBody";
import { Box, IconButton } from "@mui/material";
import "react-datepicker/dist/react-datepicker.css";
import * as Controller from "../../../controllers/analisisosa-controller";
import * as Repository from "../../../repositories/analisisosa-repository";
import es from "date-fns/locale/es"; // the locale you want
import DatePicker, { registerLocale } from "react-datepicker";
import { Line } from "react-chartjs-2";
import SelectSimple from "../layout/SelectSimple";
import {
  RemoveRedEye as RemoveRedEyeIcon,
  Delete as DeleteIcon,
  Email as EmailIcon,
} from "@mui/icons-material";
registerLocale("es", es); // register it with the name you want
export default function ConsultaAnalisisOsa(): React.JSX.Element {
  const router = useNavigate();
  const [listaComboCadena, setListaComboCadena] = React.useState([]);
  const [listaComboTienda, setListaComboTienda] = React.useState([]);
  const [listaComboMarca, setListaComboMarca] = React.useState([]);
  const [listaComboCategoria, setListaComboCategoria] = React.useState([]);
  const [listaProducto, setListaProducto] = React.useState([]);
  const [listaResumenOsa, setListaResumenOsa] = React.useState([]);
  const [ListaDetalle, setListaDetalle] = React.useState([]);
  const [codeCadena, setCodeCadena] = React.useState("");
  const [codeTienda, setCodeTienda] = React.useState("");
  const [codeMarca, setCodeMarca] = React.useState("");
  const [codeProducto, setCodeProducto] = React.useState("");
  const [codeCategoria, setCodeCategoria] = React.useState("");
  const [filterFechaDesde, setFilterFechaDesde] = React.useState(new Date());
  const [filterFechaHasta, setFilterFechaHasta] = React.useState(new Date());
  const [dataOsa, setDataOsa] = React.useState([]);
  const tableInstanceRef = useRef<MRT_TableInstance>(null); //ts
  const [rowSelection, setRowSelection] = React.useState<MRT_RowSelectionState>({});
  const [nombreProductoSeleccionado, setNombreProductoSeleccionado] = React.useState("");
  const onchangeCadenaMaster = (e: any) => {
    setCodeCadena(e.target.value);
    Controller.getListaComboTienda(e.target.value, setListaComboTienda);
    setListaComboTienda([]);
  };
  const onChangeCodeMarca = (e: any) => {
    var codeMarcaCambiar = e.target.value;
    if (
      codeMarcaCambiar != null &&
      codeMarcaCambiar != "" &&
      codeMarcaCambiar != "0"
    ) {
      Controller.getListaComboCategoria(
        codeMarcaCambiar,
        setListaComboCategoria
      );
      Controller.geListaProducto(setListaProducto, codeMarcaCambiar, "-");
    } else {
      Controller.geListaProducto(setListaProducto, "-", "-");

      setListaComboCategoria([]);
    }
  };

  const onChangeCodeCategoria = (e: any) => {
    var codeCategoriaCambiar = e.target.value;
    if (
      codeCategoriaCambiar != null &&
      codeCategoriaCambiar != "" &&
      codeCategoriaCambiar != "0"
    ) {
      Controller.geListaProducto(
        setListaProducto,
        codeMarca,
        codeCategoriaCambiar
      );
    }
  };
  const onClickBuscar = (e: any) => {
    const rowsSelection = tableInstanceRef!.current!.getState().rowSelection;
    const rows = tableInstanceRef!.current!.getSelectedRowModel().rows;


    const listaProductoSelected = rows.map((element, index) => {
      var newitem = Object();
      var originalRow = element.original as any;
      newitem.IdProducto = originalRow.id;
      return newitem;
    });

    console.log(listaProductoSelected)

    Controller.getAnalisisOsa(
      filterFechaDesde,
      filterFechaHasta,
      codeCadena,
      codeTienda,
      codeMarca,
      codeCategoria,
      listaProductoSelected,
      setDataOsa
    );
    setNombreProductoSeleccionado("");
    setListaDetalle([])

  }
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
      },
      title: {
        display: true,
        text: "Stock por Fecha",
      },
    },
  };
  React.useEffect(() => {
    Controller.getListaComboMarca(setListaComboMarca);
    Controller.getListaComboCadenaMaster(setListaComboCadena);
  }, []);
  return (
    <div className="content-wrapper">
      <ContentHeader
        title={"Analisis de Osa"}
        homePath={"Home"}
        currentPath={"Analisis Osa"}
      ></ContentHeader>
      <ContentBody title={"Consulta Analisis Osa"}>
        <div className="row">
          <div className="col-lg-2">
            <label>Cadena</label>
          </div>
          <div className="col-lg-4">
            <div className="form-group">
              <SelectSimple
                value={codeCadena}
                setValue={setCodeCadena}
                lista={listaComboCadena}
                onChangeSection={onchangeCadenaMaster}
              >
                --TODOS--
              </SelectSimple>
            </div>
          </div>
          <div className="col-lg-2">
            <label>Tienda</label>
          </div>
          <div className="col-lg-4">
            <div className="form-group">
              <SelectSimple
                value={codeTienda}
                setValue={setCodeTienda}
                lista={listaComboTienda}
              >
                --TODOS--
              </SelectSimple>
            </div>
          </div>
        </div>
        <div className="row mt-6">
          <div className="col-lg-2 ">
            <label>Fecha Desde</label>
          </div>
          <div className="col-lg-4">
            <div className="form-group">
              <DatePicker
                locale="es"
                className="form-control-text"
                popperPlacement="top-end"
                selected={filterFechaDesde}
                onChange={(date: any) => setFilterFechaDesde(date)}
                dateFormat={"dd/MM/yyyy"}
              />
            </div>
          </div>
          <div className="col-lg-2">
            <label>Fecha Hasta</label>
          </div>
          <div className="col-lg-4">
            <div className="form-group">
              <DatePicker
                locale="es"
                className="form-control-text"
                popperPlacement="top-end"
                selected={filterFechaHasta}
                onChange={(date: any) => setFilterFechaHasta(date)}
                dateFormat={"dd/MM/yyyy"}
              />
            </div>
          </div>

        </div>

        <div className="row mt-2">
          <div className="col-md-3">
            <div className="row">
              <div className="col-md-12">
                <label>Marca</label>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <SelectSimple
                    value={codeMarca}
                    setValue={setCodeMarca}
                    lista={listaComboMarca}
                    onChangeSection={onChangeCodeMarca}
                  >
                    --TODOS--
                  </SelectSimple>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <label>Categoria</label>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <SelectSimple
                    value={codeCategoria}
                    setValue={setCodeCategoria}
                    lista={listaComboCategoria}
                    onChangeSection={onChangeCodeCategoria}
                  >
                    --TODOS--
                  </SelectSimple>
                </div>
              </div>
            </div>

            <div className="row mt-2">
              <div className="col-md-12">
                <label>Producto</label>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-md-12">
                <MaterialReactTable
                  columns={Repository.GetColumns()}
                  data={listaProducto}
                  enablePagination={false}
                  enableRowSelection
                  muiTableContainerProps={{
                    sx: { maxHeight: '300px' },
                  }}
                  enableTopToolbar={false}
                  onRowSelectionChange={setRowSelection}
                  state={{ rowSelection }}
                  tableInstanceRef={tableInstanceRef}
                />
              </div>
            </div>


          </div>
          <div className="col-md-9">
            <div className="row mt-2">
              <div className="col-md-12">
                <button
                  className="btn btn-info"
                  onClick={onClickBuscar}
                >
                  Consultar
                </button>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-md-12">
                <div className="form-group">
                  <MaterialReactTable
                    columns={Repository.GetColumnsResumen()}
                    data={dataOsa}
                    enableRowActions
                    muiTableContainerProps={{
                      sx: { maxHeight: '400px' },
                    }}
                    enablePagination={false}
                    enableTopToolbar={false}
                    renderRowActions={({ row, table }) => (
                      <div>
                        <IconButton
                          onClick={() => {
                            Controller.getListaDetalle(
                              setListaDetalle,
                              filterFechaDesde,
                              filterFechaHasta,
                              codeCadena,
                              codeTienda,
                              row.original
                            );
                            setNombreProductoSeleccionado(row.original.NombreProducto);
                          }
                          }
                        >
                          <RemoveRedEyeIcon />
                        </IconButton>
                      </div>
                    )}
                  />
                  <br />
                  <div className="row mt-1">
                    <div className="col-md-12">
                      <h5>{nombreProductoSeleccionado}</h5>
                    </div>
                  </div>
                  <div className="form-group">
                    <MaterialReactTable
                      columns={Repository.GetColumnsResumenDetalles()}
                      data={ListaDetalle}
                      muiTableContainerProps={{
                        sx: { maxHeight: '400px' },
                      }}
                      enableTopToolbar={false}
                      enablePagination={false}
                      renderRowActions={({ row, table }) => <div></div>}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ContentBody>
    </div>
  );
}
