import { NavigateFunction } from 'react-router-dom';
import * as Repository from "../repositories/equipotrabajo-repository";
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
    CODE_SERVER_RETURN,
    UPLOAD_FILES,
    UPLOAD_FILESALTASUNAT,
    UPLOAD_FILESCONSTANCIA,
    UPLOAD_FILESCONTRATO,
    UPLOAD_FILESCS,
    UPLOAD_FILESDNI,
} from "../utils/contants";

export function toNuevo(router: NavigateFunction) {
    router("/main/equipotrabajo/mantenimiento/nuevo")
}
export function toConsulta(router: NavigateFunction) {
    router("/main/equipotrabajo/consulta")
}
export async function toEditar(router: NavigateFunction, row: any) {
    var codeEditar = row.CodeEquipoTrabajo
    router("/main/equipotrabajo/mantenimiento/" + codeEditar)
}
export async function toQuestionInactivaActiva(row: any, setListaConsulta: any) {
    const MySwal = withReactContent(Swal)
    var question = row.Estado == 1 ? "¿Desea eliminar el registro?" : "¿Desea activar el registro?"
    MySwal.fire({
        title: <p>{question}</p>,
        icon: "question",
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: "Sí",
        cancelButtonText: "Cancelar"
    }).then((result: any) => {
        if (result.isConfirmed) {
            toInactivaActiva(row, setListaConsulta)
        }
    })
}
async function toInactivaActiva(row: any, setListaConsulta: any) {
    var codeEliminar = row.CodeEquipoTrabajo
    const objSave = {
        CodeEquipoTrabajo: codeEliminar
    }
    if (row.Estado == 1) {
        var dataResponse = await Repository.inactiva(objSave)
    } else if (row.Estado == 0) {
        var dataResponse = await Repository.activa(objSave)
    }
    doConsultar(setListaConsulta, "", "")
}
export async function getByCode(code: any, setNombreEquipoTrabajo: any, setCodeusuarioSupervisor: any, setListaMiembros: any) {

    var dataResponse = await Repository.getByCode(code)
    if (dataResponse != null) {
        setNombreEquipoTrabajo(dataResponse.NombreEquipoTrabajo)
        setCodeusuarioSupervisor(dataResponse.CodeUsuario)
        setListaMiembros(dataResponse.miembros)
    }
}
export async function doConsultar(setListaConsulta: any, FilterEmpresa: string, FilterNombreEquipoTrabajo: string) {
    var dataResponse = await Repository.getData(FilterEmpresa, FilterNombreEquipoTrabajo)
    setListaConsulta(dataResponse)
}
export async function save(router: NavigateFunction, codeEquipoTrabajo: string, codeUsuarioSupervisor: string, codeEmpresa: string, nombreEquipoTrabajo: string, ListaMiembros: any) {
    const MySwal = withReactContent(Swal)
    var question = "¿Desea guardar el registro?"
    MySwal.fire({
        title: <p>{question}</p>,
        icon: "question",
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: "Sí",
        cancelButtonText: "Cancelar"
    }).then(async (result: any) => {
        if (result.isConfirmed) {
            var newListaMiembros = ListaMiembros.map((item: any) => {
                var newObj = {
                    CodeUsuario: item.CodeUsuario
                }
                return newObj
            })
            console.log("Code a actualizar: " + codeEquipoTrabajo)
            if (codeEquipoTrabajo != null && codeEquipoTrabajo != "") {
                const objSave = {
                    CodeEquipoTrabajo: codeEquipoTrabajo,
                    CodeUsuarioSupervisor: codeUsuarioSupervisor,
                    CodeEmpresa: codeEmpresa,
                    NombreEquipoTrabajo: nombreEquipoTrabajo,
                    miembros: newListaMiembros,
                }
                var dataResponse = await Repository.update(objSave)
                if (dataResponse.Code == CODE_SERVER_RETURN.VAL) {
                    MySwal.fire({
                        title: <p>{dataResponse.Message}</p>,
                        icon: "warning",
                        showConfirmButton: true,
                        showCancelButton: false,
                        confirmButtonText: "OK",

                    });

                } else if (dataResponse.Code == CODE_SERVER_RETURN.ERROR) {
                    MySwal.fire({
                        title: <p>{dataResponse.Message}</p>,
                        icon: "error",
                        showConfirmButton: true,
                        showCancelButton: false,
                        confirmButtonText: "OK",

                    });
                } else {
                    toast.success('Guardado satisfactoriamente!')
                    toConsulta(router)

                }
            } else {
                const objSave = {
                    CodeUsuarioSupervisor: codeUsuarioSupervisor,
                    NombreEquipoTrabajo: nombreEquipoTrabajo,
                    miembros: newListaMiembros,
                    CodeEmpresa: codeEmpresa,
                }
                var dataResponse = await Repository.save(objSave)
                if (dataResponse.Code == CODE_SERVER_RETURN.VAL) {
                    MySwal.fire({
                        title: <p>{dataResponse.Message}</p>,
                        icon: "warning",
                        showConfirmButton: true,
                        showCancelButton: false,
                        confirmButtonText: "OK",

                    });

                } else if (dataResponse.Code == CODE_SERVER_RETURN.ERROR) {
                    MySwal.fire({
                        title: <p>{dataResponse.Message}</p>,
                        icon: "error",
                        showConfirmButton: true,
                        showCancelButton: false,
                        confirmButtonText: "OK",

                    });
                } else {
                    toast.success('Guardado satisfactoriamente!')
                    toConsulta(router)
                }
            }
        }
    })
}
export async function Mensaje() {
    const MySwal = withReactContent(Swal)
    var message = "Tiene que completar los campos"
    MySwal.fire({
        title: <p>{message}</p>,
        icon: "warning",
        showConfirmButton: true,
        confirmButtonText: "Aceptar",
    }
    )
}
export async function getListaComboSupervisor(setListaComboSupervisor: any) {
    var dataResponse = await Repository.getListaComboSupervisor()
    setListaComboSupervisor(dataResponse)
}
export async function getListaComboMiembroEquipo(setListaComboMiembroEquipo: any) {
    var dataResponse = await Repository.getListaComboMiembroEquipo()
    setListaComboMiembroEquipo(dataResponse)
}
export function addMember(miembros: string, ListaComboMiembroEquipo: any, ListaMiembros: any, setListaMiembros: any) {
    var NombreUsuario = ""
    ListaComboMiembroEquipo.forEach((item: any) => {
        if (item.Id == miembros) {
            NombreUsuario = item.Texto
            return
        }
    });
    var existe = false
    ListaMiembros.forEach((item: any) => {
        if (item.CodeUsuario == miembros) {
            console.log(item)
            existe = true
            return
        }
    });
    console.log("Existe?: " + existe)

    if (!existe) {
        var objMember = {
            CodeUsuario: miembros,
            NombreUsuario: NombreUsuario
        }
        var newListaMiembros = ListaMiembros.map((item: any) => { return item })
        newListaMiembros.push(objMember)
        setListaMiembros(newListaMiembros)
    } else {
    }
}
export function toQuestionEliminarMiembro(row: any, ListaMiembros: any, setListaMiembros: any) {
    var indice = -1
    ListaMiembros.forEach((item: any, index: number) => {
        if (item.CodeUsuario == row.CodeUsuario) {
            console.log(item)
            indice = index
            return
        }
    });
    if (indice > -1) {
        ListaMiembros.splice(indice, 1);
        var newListaMiembros = ListaMiembros.map((item: any) => { return item })
        setListaMiembros(newListaMiembros)
    }
}

export async function doExportar(FilterEmpresa: string, FilterNombreEquipoTrabajo: string) {
    var dataResponse = await Repository.doExport(FilterEmpresa, FilterNombreEquipoTrabajo)

}