import { useParams, useNavigate } from "react-router-dom";
import * as Controller from "../../../controllers/empresa-controller";
import ContentHeader from "../layout/ContentHeader";
import React from "react";
import SelectSimple from "../layout/SelectSimple";
import Textbox from "../layout/Textbox";
import Label from "../layout/Label";

export default function NuevoEmpresa() {
  const [ListaComboTipoDocumento, setListaComboTipoDocumento] = React.useState(
    []
  );
  const { code } = useParams();
  const router = useNavigate();
  const [codeEmpresa, setCodeEmpresa] = React.useState("");
  const [tipoDocumentoIdentidad, setTipoDocumentoIdentidad] = React.useState(0);
  const [nombreEmpresa, setNombreEmpresa] = React.useState("");
  const [numeroDocumentoIdentidad, setNumeroDocumentoIdentidad] =
    React.useState("");
  const [direccionFiscal, setDireccionFiscal] = React.useState("");
  const [direccionOficina, setDireccionOficina] = React.useState("");
  const [telefono, setTelefono] = React.useState("");
  const [correoElectronico, setCorreoElectronico] = React.useState("");
  const [rubro, setRubro] = React.useState("");
  const [nombreContactoComercial, setNombreContactoComercial] =
    React.useState("");
  const [apellidoContactoComercial, setApellidoContactoComercial] =
    React.useState("");
  const [dniContactoComercial, setDniContactoComercial] = React.useState("");
  const [telefonoContactoComercial, setTelefonoContactoComercial] =
    React.useState("");
  const [
    correoElectronicoContactoComercial,
    setCorreoElectronicoContactoComercial,
  ] = React.useState("");
  const [nombreContactoContabilidad, setNombreContactoContabilidad] =
    React.useState("");
  const [apellidoContactoContabilidad, setApellidoContactoContabilidad] =
    React.useState("");
  const [dniContactoContabilidad, setDniContactoContabilidad] =
    React.useState("");
  const [telefonoContactoContabilidad, setTelefonoContactoContabilidad] =
    React.useState("");
  const [nombreRepresentanteLegal, setNombreRepresentanteLegal] =
    React.useState("");
  const [apellidoRepresentanteLegal, setApelllidoRepresentanteLegal] =
    React.useState("");
  const [dniRepresentanteLegal, setDniRespresentanteLegal] = React.useState("");
  const [telefonoRepresentanteLegal, setTelefonoRespresentanteLegal] =
    React.useState("");
  const [correoRepresentanteLegal, setCorreoRepresentanteLegal] =
    React.useState("");
  const [nombreSupervisor, setNombreSupervisor] = React.useState("");
  const [apellidoSupervisor, setApelllidoSupervisor] = React.useState("");
  const [dniSupervisor, setDniSupervisor] = React.useState("");
  const [telefonoSupervisor, setTelefonoSupervisor] = React.useState("");
  const [correoSupervisor, setCorreoSupervisor] = React.useState("");
  const [valnombreRepresentanteLegal, setValNombreRepresentanteLegal] =
    React.useState(false);
  const [valapellidoRepresentanteLegal, setValApelllidoRepresentanteLegal] =
    React.useState(false);
  const [valdniRepresentanteLegal, setValDniRespresentanteLegal] =
    React.useState(false);
  const [valtelefonoRepresentanteLegal, setValTelefonoRespresentanteLegal] =
    React.useState(false);
  const [valcorreoRepresentanteLegal, setValCorreoRepresentanteLegal] =
    React.useState(false);
  const [valnombreSupervisor, setValNombreSupervisor] = React.useState(false);
  const [valapellidoSupervisor, setValApelllidoSupervisor] =
    React.useState(false);
  const [valdniSupervisor, setValDniSupervisor] = React.useState(false);
  const [valtelefonoSupervisor, setValTelefonoSupervisor] =
    React.useState(false);
  const [valcorreoSupervisor, setValCorreoSupervisor] = React.useState(false);
  const [
    correoElectronicoContactoContabilidad,
    setCorreoElectronicoContactoContabilidad,
  ] = React.useState("");
  const [valdireccionFiscal, setValDireccionFiscal] = React.useState(false);
  const [valdireccionOficina, setValDireccionOficina] = React.useState(false);
  const [valtelefono, setValTelefono] = React.useState(false);
  const [valcorreoElectronico, setValCorreoElectronico] = React.useState(false);
  const [valrubro, setValRubro] = React.useState(false);
  const [valnombreContactoComercial, setValNombreContactoComercial] =
    React.useState(false);
  const [valapellidoContactoComercial, setValApellidoContactoComercial] =
    React.useState(false);
  const [valdniContactoComercial, setValDniContactoComercial] =
    React.useState(false);
  const [valtelefonoContactoComercial, setValTelefonoContactoComercial] =
    React.useState(false);
  const [
    valcorreoElectronicoContactoComercial,
    setValCorreoElectronicoContactoComercial,
  ] = React.useState(false);
  const [valnombreContactoContabilidad, setValNombreContactoContabilidad] =
    React.useState(false);
  const [valapellidoContactoContabilidad, setValApellidoContactoContabilidad] =
    React.useState(false);
  const [valdniContactoContabilidad, setValDniContactoContabilidad] =
    React.useState(false);
  const [valtelefonoContactoContabilidad, setValTelefonoContactoContabilidad] =
    React.useState(false);
  const [
    valcorreoElectroniContactoContabilidad,
    setValCorreoElectronicoContactoContabilidad,
  ] = React.useState(false);
  const [valtipoDocumentoIdentidad, setValTipoDocumentoIdentidad] =
    React.useState(false);
  const [valnombreEmpresa, setValNombreEmpresa] = React.useState(false);
  const [valnumeroDocumentoIdentidad, setValNumeroDocumentoIdentidad] =
    React.useState(false);
  const [tituloVentana, setTituloVentana] = React.useState("Nueva Empresa");
  const [isSubmit, setIsSubmit] = React.useState(false);
  const [file, setFile] = React.useState(null);
  const [fileFirma, setFileFirma] = React.useState(null);
  const [urlImagen, setUrlImagen] = React.useState("");
  const [urlImagenFirmaRepresenta, setUrlImagenFirmaRepresenta] =
    React.useState("");
  const [messageError, setMessasgeError] = React.useState("");

  React.useEffect(() => {
    Controller.getListaComboTipoDocumento(setListaComboTipoDocumento);
    if (code != "nuevo") {
      setTituloVentana("Editar Empresa");
      setCodeEmpresa(code!);
      Controller.getByCode(
        code,
        setTipoDocumentoIdentidad,
        setNumeroDocumentoIdentidad,
        setNombreEmpresa,
        setDireccionFiscal,
        setDireccionOficina,
        setTelefono,
        setCorreoElectronico,
        setRubro,
        setNombreContactoComercial,
        setApellidoContactoComercial,
        setDniContactoComercial,
        setTelefonoContactoComercial,
        setCorreoElectronicoContactoComercial,
        setNombreContactoContabilidad,
        setApellidoContactoContabilidad,
        setDniContactoContabilidad,
        setTelefonoContactoContabilidad,
        setCorreoElectronicoContactoContabilidad,
        setUrlImagen,
        setNombreRepresentanteLegal,
        setApelllidoRepresentanteLegal,
        setTelefonoRespresentanteLegal,
        setCorreoRepresentanteLegal,
        setDniRespresentanteLegal,
        setNombreSupervisor,
        setApelllidoSupervisor,
        setTelefonoSupervisor,
        setCorreoSupervisor,
        setDniSupervisor,
        setUrlImagenFirmaRepresenta
      );
    }
  }, []);

  function isValidEmail(email: string) {
    // Define a regular expression pattern for email validation.
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return pattern.test(email);
  }

  function validarCampos() {
    var Result = true;
    setIsSubmit(true);
    if (numeroDocumentoIdentidad == "" || numeroDocumentoIdentidad == null) {
      Result = false;
      setValNumeroDocumentoIdentidad(false);
    } else {
      setValNumeroDocumentoIdentidad(true);
    }

    if (nombreEmpresa == "" || nombreEmpresa == null) {
      Result = false;
      setValNombreEmpresa(false);
    } else {
      setValNombreEmpresa(true);
    }

    if (direccionFiscal == "" || direccionFiscal == null) {
      Result = false;
      setValDireccionFiscal(false);
    } else {
      setValDireccionFiscal(true);
    }
    if (direccionOficina == "" || direccionOficina == null) {
      Result = false;
      setValDireccionOficina(false);
    } else {
      setValDireccionOficina(true);
    }

    if (telefono == "" || telefono == null) {
      Result = false;
      setValTelefono(false);
    } else {
      setValTelefono(true);
    }
    if (correoElectronico == "" || correoElectronico == null) {
      Result = false;
      setValCorreoElectronico(false);
    } else {
      if (isValidEmail(correoElectronico)) {
        setValCorreoElectronico(true);
      } else {
        Result = false;
        setValCorreoElectronico(false);
        setMessasgeError("Correo no válido");
      }
    }
    if (rubro == "" || rubro == null) {
      Result = false;
      setValRubro(false);
    } else {
      setValRubro(true);
    }

    if (nombreContactoComercial == "" || nombreContactoComercial == null) {
      Result = false;
      setValNombreContactoComercial(false);
    } else {
      setValNombreContactoComercial(true);
    }
    if (apellidoContactoComercial == "" || apellidoContactoComercial == null) {
      Result = false;
      setValApellidoContactoComercial(false);
    } else {
      setValApellidoContactoComercial(true);
    }

    if (dniContactoComercial == "" || dniContactoComercial == null) {
      Result = false;
      setValDniContactoComercial(false);
    } else {
      setValDniContactoComercial(true);
    }

    if (telefonoContactoComercial == "" || telefonoContactoComercial == null) {
      Result = false;
      setValTelefonoContactoComercial(false);
    } else {
      setValTelefonoContactoComercial(true);
    }

    if (
      correoElectronicoContactoComercial == "" ||
      correoElectronicoContactoComercial == null
    ) {
      Result = false;
      setValCorreoElectronicoContactoComercial(false);
    } else {
      if (isValidEmail(correoElectronicoContactoComercial)) {
        setValCorreoElectronicoContactoComercial(true);
      } else {
        Result = false;
        setValCorreoElectronicoContactoComercial(false);
        setMessasgeError("Correo no válido");
      }
    }
    if (nombreRepresentanteLegal == "" || nombreRepresentanteLegal == null) {
      Result = false;
      setValNombreRepresentanteLegal(false);
    } else {
      setValNombreRepresentanteLegal(true);
    }
    if (
      apellidoRepresentanteLegal == "" ||
      apellidoRepresentanteLegal == null
    ) {
      Result = false;
      setValApelllidoRepresentanteLegal(false);
    } else {
      setValApelllidoRepresentanteLegal(true);
    }

    if (dniRepresentanteLegal == "" || dniRepresentanteLegal == null) {
      Result = false;
      setValDniRespresentanteLegal(false);
    } else {
      setValDniRespresentanteLegal(true);
    }

    if (
      telefonoRepresentanteLegal == "" ||
      telefonoRepresentanteLegal == null
    ) {
      Result = false;
      setValTelefonoRespresentanteLegal(false);
    } else {
      setValTelefonoRespresentanteLegal(true);
    }

    if (correoRepresentanteLegal == "" || correoRepresentanteLegal == null) {
      Result = false;
      setValCorreoRepresentanteLegal(false);
    } else {
      if (isValidEmail(correoRepresentanteLegal)) {
        setValCorreoRepresentanteLegal(true);
      } else {
        Result = false;
        setValCorreoRepresentanteLegal(false);
        setMessasgeError("Correo no válido");
      }
    }
    if (nombreSupervisor == "" || nombreSupervisor == null) {
      Result = false;
      setValNombreSupervisor(false);
    } else {
      setValNombreSupervisor(true);
    }
    if (apellidoSupervisor == "" || apellidoSupervisor == null) {
      Result = false;
      setValApelllidoSupervisor(false);
    } else {
      setValApelllidoSupervisor(true);
    }

    if (dniSupervisor == "" || dniSupervisor == null) {
      Result = false;
      setValDniSupervisor(false);
    } else {
      setValDniSupervisor(true);
    }

    if (telefonoSupervisor == "" || telefonoSupervisor == null) {
      Result = false;
      setValTelefonoSupervisor(false);
    } else {
      setValTelefonoSupervisor(true);
    }

    if (correoSupervisor == "" || correoSupervisor == null) {
      Result = false;
      setValCorreoSupervisor(false);
    } else {
      if (isValidEmail(correoSupervisor)) {
        setValCorreoSupervisor(true);
      } else {
        Result = false;
        setValCorreoSupervisor(false);
        setMessasgeError("Correo no válido");
      }
    }
    if (
      nombreContactoContabilidad == "" ||
      nombreContactoContabilidad == null
    ) {
      Result = false;
      setValNombreContactoContabilidad(false);
    } else {
      setValNombreContactoContabilidad(true);
    }
    if (
      apellidoContactoContabilidad == "" ||
      apellidoContactoContabilidad == null
    ) {
      Result = false;
      setValApellidoContactoContabilidad(false);
    } else {
      setValApellidoContactoContabilidad(true);
    }
    if (dniContactoContabilidad == "" || dniContactoContabilidad == null) {
      Result = false;
      setValDniContactoContabilidad(false);
    } else {
      setValDniContactoContabilidad(true);
    }
    if (
      telefonoContactoContabilidad == "" ||
      telefonoContactoContabilidad == null
    ) {
      Result = false;
      setValTelefonoContactoContabilidad(false);
    } else {
      setValTelefonoContactoContabilidad(true);
    }

    if (
      correoElectronicoContactoContabilidad == "" ||
      correoElectronicoContactoContabilidad == null
    ) {
      Result = false;
      setValCorreoElectronicoContactoContabilidad(false);
    } else {
      if (isValidEmail(correoElectronicoContactoContabilidad)) {
        setValCorreoElectronicoContactoContabilidad(true);
      } else {
        Result = false;
        setValCorreoElectronicoContactoContabilidad(false);
        setMessasgeError("Correo no válido");
      }
    }
    if (
      tipoDocumentoIdentidad == 0 ||
      tipoDocumentoIdentidad == 0 ||
      tipoDocumentoIdentidad == null
    ) {
      Result = false;
      setValTipoDocumentoIdentidad(false);
    } else {
      setValTipoDocumentoIdentidad(true);
    }
    if (Result) {
      Controller.save(
        router,
        codeEmpresa,
        tipoDocumentoIdentidad,
        numeroDocumentoIdentidad,
        nombreEmpresa,
        direccionFiscal,
        direccionOficina,
        telefono,
        correoElectronico,
        rubro,
        nombreContactoComercial,
        apellidoContactoComercial,
        dniContactoComercial,
        telefonoContactoComercial,
        correoElectronicoContactoComercial,
        nombreContactoContabilidad,
        apellidoContactoContabilidad,
        dniContactoContabilidad,
        telefonoContactoContabilidad,
        correoElectronicoContactoContabilidad,
        file,
        urlImagen,
        nombreRepresentanteLegal,
        apellidoRepresentanteLegal,
        telefonoRepresentanteLegal,
        correoRepresentanteLegal,
        dniRepresentanteLegal,
        nombreSupervisor,
        apellidoSupervisor,
        telefonoSupervisor,
        correoSupervisor,
        dniSupervisor,
        fileFirma,
        urlImagenFirmaRepresenta
      );
    } else {
      Controller.Mensaje();
    }
  }

  function onChangeFileUpload(e: any) {
    var fileToUpload = e.target.files[0];
    //const reader = new FileReader();
    //var url = reader.readAsDataURL(file);
    setFile(fileToUpload);
  }

  function onChangeFileFirmaRepresentante(e: any) {
    var fileToUpload = e.target.files[0];
    //const reader = new FileReader();
    //var url = reader.readAsDataURL(file);
    setFileFirma(fileToUpload);
  }
  return (
    <div className="content-wrapper">
      <ContentHeader
        title={tituloVentana}
        homePath={"Home"}
        currentPath={tituloVentana}
      ></ContentHeader>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card card-primary">
                <div className="card-header">
                  <div className="d-flex flex-row gap-2">
                    <div className="align-items-center">
                      <i
                        className="bx bx-arrow-back arrow-back"
                        onClick={() => Controller.toConsulta(router)}
                      ></i>
                    </div>
                    <div className="">
                      <h4 className="card-title">{tituloVentana}</h4>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-6">
                      <h4>Datos</h4>
                      <hr />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-2">
                      <Label required={true}>Tipo Documento</Label>
                    </div>
                    <div className="col-md-4">
                      <SelectSimple
                        value={tipoDocumentoIdentidad}
                        setValue={setTipoDocumentoIdentidad}
                        valueError={valnumeroDocumentoIdentidad}
                        setValueError={setValTipoDocumentoIdentidad}
                        isSubmit={isSubmit}
                        lista={ListaComboTipoDocumento}
                      >
                        --Seleccion--
                      </SelectSimple>
                    </div>
                    <div className="col-lg-2">
                      <Label required={true}>Documento</Label>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <Textbox
                          value={numeroDocumentoIdentidad}
                          valueError={valnumeroDocumentoIdentidad}
                          setValue={setNumeroDocumentoIdentidad}
                          setValueError={setValNumeroDocumentoIdentidad}
                          isSubmit={isSubmit}
                          maxLenght={11}
                          onlyNumbers={true}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-2">
                      <Label required={true}>Nombre Empresa</Label>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <Textbox
                          value={nombreEmpresa}
                          valueError={valnombreEmpresa}
                          setValue={setNombreEmpresa}
                          setValueError={setValNombreEmpresa}
                          isSubmit={isSubmit}
                          upperCase={true}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-2">
                      <Label required={true}>Rubro</Label>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <Textbox
                          value={rubro}
                          valueError={valrubro}
                          setValue={setRubro}
                          setValueError={setValRubro}
                          isSubmit={isSubmit}
                          upperCase={true}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-2">
                      <Label required={true}>Direccion Fiscal</Label>
                    </div>
                    <div className="col-md-4">
                      <Textbox
                        value={direccionFiscal}
                        valueError={valdireccionFiscal}
                        setValue={setDireccionFiscal}
                        setValueError={setValDireccionFiscal}
                        isSubmit={isSubmit}
                        upperCase={true}
                      />
                    </div>
                    <div className="col-lg-2">
                      <Label required={true}>Direccion Oficina</Label>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <Textbox
                          value={direccionOficina}
                          valueError={valdireccionOficina}
                          setValue={setDireccionOficina}
                          setValueError={setValDireccionOficina}
                          isSubmit={isSubmit}
                          upperCase={true}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-2">
                      <Label required={true}>Telefono</Label>
                    </div>
                    <div className="col-md-4">
                      <Textbox
                        value={telefono}
                        valueError={valtelefono}
                        setValue={setTelefono}
                        setValueError={setValTelefono}
                        isSubmit={isSubmit}
                        maxLenght={11}
                        onlyNumbers={true}
                      />
                    </div>
                    <div className="col-lg-2">
                      <Label required={true}>Correo Electronico</Label>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <Textbox
                          value={correoElectronico}
                          valueError={valcorreoElectronico}
                          messageError={messageError}
                          setValue={setCorreoElectronico}
                          setValueError={setValCorreoElectronico}
                          isSubmit={isSubmit}
                          maxLenght={128}
                          lowerCase={true}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6">
                      <h4>Datos Comercial</h4>
                      <hr />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-2">
                      <Label required={true}>Nombre </Label>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <Textbox
                          value={nombreContactoComercial}
                          valueError={valnombreContactoComercial}
                          setValue={setNombreContactoComercial}
                          setValueError={setValNombreContactoComercial}
                          isSubmit={isSubmit}
                          upperCase={true}
                        />
                      </div>
                    </div>
                    <div className="col-lg-2">
                      <Label required={true}>Apellido </Label>
                    </div>
                    <div className="col-md-4">
                      <Textbox
                        value={apellidoContactoComercial}
                        valueError={apellidoContactoComercial}
                        setValue={setApellidoContactoComercial}
                        setValueError={setValApellidoContactoComercial}
                        isSubmit={isSubmit}
                        upperCase={true}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-2">
                      <Label required={true}>Telefono</Label>
                    </div>
                    <div className="col-md-4">
                      <Textbox
                        value={telefonoContactoComercial}
                        valueError={valtelefonoContactoComercial}
                        setValue={setTelefonoContactoComercial}
                        setValueError={setValTelefonoContactoComercial}
                        isSubmit={isSubmit}
                        maxLenght={11}
                        onlyNumbers={true}
                      />
                    </div>
                    <div className="col-lg-2">
                      <Label required={true}>Correo Electronico </Label>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <Textbox
                          value={correoElectronicoContactoComercial}
                          valueError={valcorreoElectronicoContactoComercial}
                          messageError={messageError}
                          setValue={setCorreoElectronicoContactoComercial}
                          setValueError={setValCorreoSupervisor}
                          isSubmit={isSubmit}
                          maxLenght={128}
                          lowerCase={true}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-2">
                      <Label required={true}>DNI</Label>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <Textbox
                          value={dniContactoComercial}
                          valueError={valdniContactoComercial}
                          setValue={setDniContactoComercial}
                          setValueError={setValDniContactoComercial}
                          isSubmit={isSubmit}
                          maxLenght={11}
                          onlyNumbers={true}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-6">
                      <h4>Datos Contabilidad </h4>
                      <hr />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-2">
                      <Label required={true}>Nombre </Label>
                    </div>
                    <div className="col-md-4">
                      <Textbox
                        value={nombreContactoContabilidad}
                        valueError={valnombreContactoContabilidad}
                        setValue={setNombreContactoContabilidad}
                        setValueError={setValNombreContactoContabilidad}
                        isSubmit={isSubmit}
                        upperCase={true}
                      />
                    </div>
                    <div className="col-lg-2">
                      <Label required={true}>Apellido </Label>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <Textbox
                          value={apellidoContactoContabilidad}
                          valueError={valapellidoContactoContabilidad}
                          setValue={setApellidoContactoContabilidad}
                          setValueError={setValApellidoContactoContabilidad}
                          isSubmit={isSubmit}
                          upperCase={true}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-2">
                      <Label required={true}>DNI</Label>
                    </div>
                    <div className="col-md-4">
                      <Textbox
                        value={dniContactoContabilidad}
                        valueError={valdniContactoContabilidad}
                        setValue={setDniContactoContabilidad}
                        setValueError={setValDniContactoContabilidad}
                        isSubmit={isSubmit}
                        maxLenght={11}
                        onlyNumbers={true}
                      />
                    </div>
                    <div className="col-lg-2">
                      <Label required={true}>Telefono </Label>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <Textbox
                          value={telefonoContactoContabilidad}
                          valueError={valtelefonoContactoContabilidad}
                          setValue={setTelefonoContactoContabilidad}
                          setValueError={setValTelefonoContactoContabilidad}
                          isSubmit={isSubmit}
                          maxLenght={11}
                          onlyNumbers={true}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-2">
                      <Label required={true}>Correo Electronico </Label>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <Textbox
                          value={correoElectronicoContactoContabilidad}
                          valueError={valcorreoElectroniContactoContabilidad}
                          messageError={messageError}
                          setValue={setCorreoElectronicoContactoContabilidad}
                          setValueError={
                            setValCorreoElectronicoContactoContabilidad
                          }
                          isSubmit={isSubmit}
                          maxLenght={128}
                          lowerCase={true}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6">
                      <h4>Datos Representante Legal</h4>
                      <hr />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-2">
                      <Label required={true}>Nombre </Label>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <Textbox
                          value={nombreRepresentanteLegal}
                          valueError={valnombreRepresentanteLegal}
                          setValue={setNombreRepresentanteLegal}
                          setValueError={setValNombreRepresentanteLegal}
                          isSubmit={isSubmit}
                          upperCase={true}
                        />
                      </div>
                    </div>
                    <div className="col-lg-2">
                      <Label required={true}>Apellido </Label>
                    </div>
                    <div className="col-md-4">
                      <Textbox
                        value={apellidoRepresentanteLegal}
                        valueError={valapellidoRepresentanteLegal}
                        setValue={setApelllidoRepresentanteLegal}
                        setValueError={setValApelllidoRepresentanteLegal}
                        isSubmit={isSubmit}
                        upperCase={true}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-2">
                      <Label required={true}>Telefono</Label>
                    </div>
                    <div className="col-md-4">
                      <Textbox
                        value={telefonoRepresentanteLegal}
                        valueError={valtelefonoRepresentanteLegal}
                        setValue={setTelefonoRespresentanteLegal}
                        setValueError={setValTelefonoRespresentanteLegal}
                        isSubmit={isSubmit}
                        maxLenght={11}
                        onlyNumbers={true}
                      />
                    </div>
                    <div className="col-lg-2">
                      <Label required={true}>Correo Electronico </Label>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <Textbox
                          value={correoRepresentanteLegal}
                          valueError={valcorreoRepresentanteLegal}
                          messageError={messageError}
                          setValue={setCorreoRepresentanteLegal}
                          setValueError={setValCorreoRepresentanteLegal}
                          isSubmit={isSubmit}
                          maxLenght={128}
                          lowerCase={true}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-2">
                      <Label required={true}>DNI</Label>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <Textbox
                          value={dniRepresentanteLegal}
                          valueError={valdniRepresentanteLegal}
                          setValue={setDniRespresentanteLegal}
                          setValueError={setValDniRespresentanteLegal}
                          isSubmit={isSubmit}
                          maxLenght={11}
                          onlyNumbers={true}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6">
                      <h4>Datos Supervisor</h4>
                      <hr />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-2">
                      <Label required={true}>Nombre </Label>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <Textbox
                          value={nombreSupervisor}
                          valueError={valnombreSupervisor}
                          setValue={setNombreSupervisor}
                          setValueError={setValNombreSupervisor}
                          isSubmit={isSubmit}
                          upperCase={true}
                        />
                      </div>
                    </div>
                    <div className="col-lg-2">
                      <Label required={true}>Apellido </Label>
                    </div>
                    <div className="col-md-4">
                      <Textbox
                        value={apellidoSupervisor}
                        valueError={valapellidoSupervisor}
                        setValue={setApelllidoSupervisor}
                        setValueError={setValApelllidoSupervisor}
                        isSubmit={isSubmit}
                        upperCase={true}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-2">
                      <Label required={true}>Telefono</Label>
                    </div>
                    <div className="col-md-4">
                      <Textbox
                        value={telefonoSupervisor}
                        valueError={valtelefonoSupervisor}
                        setValue={setTelefonoSupervisor}
                        setValueError={setValTelefonoSupervisor}
                        isSubmit={isSubmit}
                        maxLenght={11}
                        onlyNumbers={true}
                      />
                    </div>
                    <div className="col-lg-2">
                      <Label required={true}>Correo Electronico </Label>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <Textbox
                          value={correoSupervisor}
                          valueError={valcorreoSupervisor}
                          messageError={messageError}
                          setValue={setCorreoSupervisor}
                          setValueError={setValCorreoSupervisor}
                          isSubmit={isSubmit}
                          maxLenght={128}
                          lowerCase={true}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-2">
                      <Label required={true}>DNI</Label>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <Textbox
                          value={dniSupervisor}
                          valueError={valdniSupervisor}
                          setValue={setDniSupervisor}
                          setValueError={setValDniSupervisor}
                          isSubmit={isSubmit}
                          maxLenght={11}
                          onlyNumbers={true}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6">
                      <h4>Imagenes Empresa </h4>
                      <hr />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-2">
                      <Label>Logo Empresa</Label>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <input
                          id="image"
                          accept="image/*"
                          type="file"
                          name="image"
                          onChange={onChangeFileUpload}
                        />
                      </div>
                    </div>
                    {urlImagen != null && urlImagen != "" ? (
                      <div className="col-lg-6">
                        <div className="row">
                          <div className="col-lg-4">
                            <Label>Vista Previa</Label>
                          </div>
                          <div className="col-lg-8">
                            <img src={urlImagen} style={{ width: 200 }} />
                          </div>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="row mt-2">
                    <div className="col-lg-2">
                      <Label>Firma Representante Legal</Label>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <input
                          id="image"
                          accept="image/*"
                          type="file"
                          name="image"
                          onChange={onChangeFileFirmaRepresentante}
                        />
                      </div>
                    </div>
                    {urlImagenFirmaRepresenta != null &&
                    urlImagenFirmaRepresenta != "" ? (
                      <div className="col-lg-6">
                        <div className="row">
                          <div className="col-lg-4">
                            <Label>Vista Previa</Label>
                          </div>
                          <div className="col-lg-8">
                            <img
                              src={urlImagenFirmaRepresenta}
                              style={{ width: 200 }}
                            />
                          </div>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="row mt-5">
                    <div className="col-lg-6">
                      <div className="form-group">
                        <button
                          className="btn btn-default"
                          onClick={() => Controller.toConsulta(router)}
                        >
                          Cancelar
                        </button>
                        <button
                          className="btn btn-primary"
                          onClick={() => validarCampos()}
                        >
                          Guardar
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
