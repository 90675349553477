import { useMemo } from 'react';
import { type MRT_ColumnDef } from 'material-react-table';
import * as ClientAPI from "../utils/methods";
import * as Endpoints from "../api/endpoint";


type CadenaMaster = {
  IdCadena: string;
  NombreCadenaMaste: string;
  IdEmpresa: string;
};

export function GetColumns() {
  const columns = useMemo<MRT_ColumnDef<CadenaMaster>[]>(
    () => [
      {
        accessorKey: 'IdCadenaMaster',
        header: 'ID',
        size: 100,
      },
      {
        accessorKey: 'NombreCadenaMaster',
        header: 'Cadena',
        size: 300,
      },
      {
        accessorKey: 'RazonSocial',
        header: 'Razon Social',
        size: 500,
      },
    ],
    [],
  );

  return columns
}

type Documento = {
  CodeDocumento: string,
  NombreDocumento: string,

};

export function GetTColumns() {
  const columns = useMemo<MRT_ColumnDef<Documento>[]>(
    () => [
      {
        accessorKey: 'CodeDocumento',
        header: 'ID',
        size: 20,
      },
      {
        accessorKey: 'NombreDocumento',
        header: 'Nombres de Documentos',
        size: 400,
      },

    ],
    [],
  );
  ;
  return columns
}
export async function getData( FilterNombreCadenaMaster: string) {

  FilterNombreCadenaMaster = FilterNombreCadenaMaster == "" ? "-" : FilterNombreCadenaMaster

  var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_SECURITY + Endpoints.ListEndpoint.GET_LISTA_CADENAMASTER + "/" + FilterNombreCadenaMaster, "")
  console.log(dataResponse)
  if (dataResponse.Data != null) {
    return dataResponse.Data;
  } else {
    return null
  }
}

export async function doExport(FilterNombreCadenaMaster: string) {
  FilterNombreCadenaMaster = FilterNombreCadenaMaster == "" ? "-" : FilterNombreCadenaMaster

  var dataResponse = await ClientAPI.RequestDownload(Endpoints.URL_BASE_SECURITY + Endpoints.ListEndpoint.GET_LISTA_CADENAMASTER_EXCEL + "/" + FilterNombreCadenaMaster, 'DescargarExcelCadena.xlsx')
}

export async function getByCode(code: string) {
  var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_SECURITY + Endpoints.ListEndpoint.GET_CADENAMASTER_BYCODE  + "/" + code, "")

  return dataResponse.Data;
}

export async function save(objSave: any) {
  var dataResponse = await ClientAPI.RequestPost(Endpoints.URL_BASE_SECURITY + Endpoints.ListEndpoint.INSERTAR_CADENAMASTER, "", objSave)
  console.log(dataResponse);
  if (dataResponse != null) {
    return dataResponse;
  } else {
    return null
  }
}
export async function geListaCadenaDocumentoDetalleByCodeCadena(code: string) {
  var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_SECURITY + Endpoints.ListEndpoint.GET_LISTA_CADENAMASTER_DOCUMENTO_BYCODEDOCUMENTO + "/" + code, "")
  if (dataResponse.Data != null) {
    return dataResponse.Data;
  } else {
    return []
  }
}
export async function update(objSave: any) {
  var dataResponse = await ClientAPI.RequestPost(Endpoints.URL_BASE_SECURITY + Endpoints.ListEndpoint.UPDATE_CADENA_MASTER, "", objSave)

  if (dataResponse != null) {
    return dataResponse;
  } else {
    return null
  }
}

export async function uploadFile(formDataToUpload: FormData) {
  var dataResponse = await ClientAPI.RequestPostUpload(Endpoints.URL_BASE_FILE + Endpoints.ListEndpoint.UPLOAD_FILE, formDataToUpload)

  if (dataResponse != null) {
    return dataResponse;
  } else {
    return null
  }
}
 
export async function getListaComboDocumento() {
  var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_SECURITY + Endpoints.ListEndpoint.GET_LISTA_COMBO_DOCUMENTO, "")
  if (dataResponse.Data != null) {
    return dataResponse.Data;
  } else {
    return null
  }
}

export async function inactiva(objSave: any) {
  var dataResponse = await ClientAPI.RequestPost(Endpoints.URL_BASE_SECURITY + Endpoints.ListEndpoint.INACTIVO_CADENA_MASTER, "", objSave)
  if (dataResponse.Data != null) {
    return dataResponse.Data;
  } else {
    return null
  }
}
export async function activa(objSave: any) {
  var dataResponse = await ClientAPI.RequestPost(Endpoints.URL_BASE_SECURITY + Endpoints.ListEndpoint.ACTIVO_CADENA_MASTER, "", objSave)
 
  if (dataResponse.Data != null) {
    return dataResponse.Data;
  } else {
    return null
  }
}
export async function saveCD(objSave: any) {
  var dataResponse = await ClientAPI.RequestPost(Endpoints.URL_BASE_SECURITY + Endpoints.ListEndpoint.GUARDAR_CADENAMASTERDOCUMENTO, "", objSave)

  if (dataResponse.Data != null) {
    return dataResponse.Data;
  } else {
    return null
  }
}

