"use client";

import ContentHeader from "../layout/ContentHeader";
import { useParams, useNavigate } from "react-router-dom";
import * as Controller from "../../../controllers/registrometaventa-controller";
import React from "react";
import Textbox from "../layout/Textbox";
import Label from "../layout/Label";
import SelectSimple from "../layout/SelectSimple";
import DatePicker, { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es"; // the locale you want
registerLocale("es", es);

export default function NuevoRegistroMetaVenta() {
  const { code } = useParams();
  const router = useNavigate();
  const [codeMetaTiendaVenta, setCodeMetaVentaTienda] = React.useState("");
  const [ListaComboCadena, setListaComboCadena] = React.useState([]);
  const [listaComboTiendaCadena, setListaComboTiendaCadena] = React.useState(
    []
  );
  const [ListaComboUsuario, setListaComboUsuario] = React.useState([]);
  const [periodo, setPeriodo] = React.useState(new Date());
  const [CodeCadena, setCodeCadena] = React.useState("");
  const [CodeTienda, setCodeTienda] = React.useState("");
  const [CodeUsuario, setCodeUsuario] = React.useState("");
  const [metaMensual, setMetaMensual] = React.useState(0);
  const [metaSemanal, setMetaSemanal] = React.useState(0);
  const [tituloVentana, setTituloVentana] = React.useState(
    "Nueva  Registro Meta Venta"
  );
  const [valperiodo, setValPeriodo] = React.useState(false);
  const [valCodeTienda, setValCodeTienda] = React.useState(false);
  const [valCodeUsuario, setValCodeUsuario] = React.useState(false);
  const [valmetaMensual, seValtMetaMensual] = React.useState("");
  const [valmetaSemanal, setValMetaSemanal] = React.useState("");
  const [isSubmit, setIsSubmit] = React.useState(false);

  const onchangeCadenaMaster = (e: any) => {
    setCodeCadena(e.target.value);
    Controller.getListaComboTiendaCadena(
      e.target.value,
      setListaComboTiendaCadena
    );
    setListaComboTiendaCadena([]);
  };
  React.useEffect(() => {
    Controller.getListaComboUsuario(setListaComboUsuario);
    Controller.getListaComboCadenaMaster(setListaComboCadena);

    if (code != "nuevo") {
      setTituloVentana("Editar Registro Meta Venta");
      setCodeMetaVentaTienda(code!);
      Controller.getByCode(
        code,
        setCodeCadena,
        setCodeUsuario,
        setCodeTienda,
        setPeriodo,
        setMetaMensual,
        setMetaSemanal,
        setListaComboTiendaCadena
      );
    }
  }, []);

  function ValidarCampos() {
    var Result = true;
    setIsSubmit(true);
    if (CodeUsuario == "" || CodeUsuario == "0" || CodeUsuario == null) {
      Result = false;
      setValCodeUsuario(false);
    } else {
      setValCodeUsuario(true);
    }

    if (CodeTienda == "" || CodeTienda == "0" || CodeTienda == null) {
      Result = false;
      setValCodeTienda(false);
    } else {
      setValCodeTienda(true);
    }

    if (Result) {
      Controller.save(
        router,
        codeMetaTiendaVenta,
        CodeUsuario,
        CodeTienda,
        metaSemanal,
        metaMensual,
        periodo
      );
    } else {
      Controller.Mensaje();
    }
  }

  return (
    <div className="content-wrapper">
      <ContentHeader
        title={tituloVentana}
        homePath={"Home"}
        currentPath={tituloVentana}
      ></ContentHeader>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card card-primary">
                <div className="card-header">
                  <div className="d-flex flex-row gap-2">
                    <div className="align-items-center">
                      <i
                        className="bx bx-arrow-back arrow-back"
                        onClick={() => Controller.toConsulta(router)}
                      ></i>
                    </div>
                    <div className="">
                      <h4 className="card-title">{tituloVentana}</h4>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-6">
                      <h4>Datos</h4>
                      <hr />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-2">
                      <label>Cadena</label>
                    </div>
                    <div className="col-lg-4 ">
                      <SelectSimple
                        className={"form-control-text"}
                        lista={ListaComboCadena}
                        value={CodeCadena}
                        setValue={setCodeCadena}
                        onChangeSection={onchangeCadenaMaster}
                      >
                        --TODOS--
                      </SelectSimple>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-2 ">
                      <label>Tienda</label>
                    </div>
                    <div className="col-lg-4 ">
                      <div className="form-group">
                        <SelectSimple
                          className={"form-control-text"}
                          lista={listaComboTiendaCadena}
                          value={CodeTienda}
                          setValue={setCodeTienda}
                        >
                          --TODOS--
                        </SelectSimple>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-2">
                      <Label required={true}>Periodo</Label>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <DatePicker
                          locale="es"
                          className="form-control-text"
                          popperPlacement="top-end"
                          selected={periodo}
                          onChange={(date: any) => setPeriodo(date)}
                          dateFormat={"yyyy-MM"}
                          showMonthYearPicker
                          showFullMonthYearPicker
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-2">
                      <label>Usuario</label>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <SelectSimple
                          value={CodeUsuario}
                          setValue={setCodeUsuario}
                          lista={ListaComboUsuario}
                          valueError={valCodeUsuario}
                          setValueError={setValCodeUsuario}
                          isSubmit={isSubmit}
                        >
                          --TODOS--
                        </SelectSimple>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-2">
                      <Label required={true}>Meta Mensual</Label>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <Textbox
                          value={metaMensual}
                          valueError={valmetaMensual}
                          setValue={setMetaMensual}
                          setValueError={setValMetaSemanal}
                          isSubmit={isSubmit}
                          upperCase={true}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-2">
                      <Label required={true}>Meta Semanal</Label>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <Textbox
                          value={metaSemanal}
                          valueError={valmetaSemanal}
                          setValue={setMetaSemanal}
                          setValueError={setValMetaSemanal}
                          isSubmit={isSubmit}
                          upperCase={true}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mt-5">
                    <div className="col-lg-6">
                      <div className="form-group">
                        <button
                          className="btn btn-default"
                          onClick={() => Controller.toConsulta(router)}
                        >
                          Cancelar
                        </button>
                        <button
                          className="btn btn-primary"
                          onClick={() => ValidarCampos()}
                        >
                          Guardar
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
