import { NavigateFunction } from 'react-router-dom';
import * as Repository from "../repositories/registroalerta-repository";
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { Result } from 'postcss';

export function toNuevo(router: NavigateFunction) {
    router("/main/registroalerta/mantenimiento/nuevo")
}
export function toConsulta(router:NavigateFunction) {
    router("/main/registroalerta/consulta")
}
export async function toEditar(router: NavigateFunction, row: any) {
    var codeEditar = row.CodeRegistroAlerta
    router("/main/registroalerta/mantenimiento/" + codeEditar)
}
export async function toQuestionInactivaActiva(row: any, setListaConsulta: any) {
    const MySwal = withReactContent(Swal)
    var question = row.Estado == 1 ? "¿Desea eliminar el registro?" : "¿Desea activar el registro?"
    MySwal.fire({
        title: <p>{question}</p>,
        icon: "question",
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: "Sí",
        cancelButtonText: "Cancelar"
    }).then((result: any) => {
        if (result.isConfirmed) {
            toInactivaActiva(row, setListaConsulta)
        }
    })
}
async function toInactivaActiva(row: any, setListaConsulta: any) {
    var codeEliminar = row.CodeRegistroAlerta
    const objSave = {
        CodeRegistroAlerta: codeEliminar
    }
    if (row.Estado == 1) {
        var dataResponse = await Repository.inactiva(objSave)
    } else if (row.Estado == 0) {
        var dataResponse = await Repository.activa(objSave)
    }
    doConsultar(setListaConsulta, "", "", "", new Date(), new Date())
}

export async function doConsultar(setListaConsulta: any, FilterCoeUsuario: string, FilterCodeCadena: string, FilterCodeTienda: string, FilterFechaDesde: Date, FilterFechaHasta: Date) {
    var dataResponse = await Repository.getData(FilterCoeUsuario, FilterCodeCadena, FilterCodeTienda, FilterFechaDesde, FilterFechaHasta)
    setListaConsulta(dataResponse)
}
export async function save(router: NavigateFunction, codeRegistroAlerta: string, codeEmpresa: string,  codeTienda: string, codeUsuario: string,  descripcion: string, fechaProgramado: Date) {
    const MySwal = withReactContent(Swal)
    var question = "¿Desea guardar el registro?"
    MySwal.fire({
        title: <p>{question}</p>,
        icon: "question",
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: "Sí",
        cancelButtonText: "Cancelar"
    }).then(async (result: any) => {
        if (result.isConfirmed) {
            console.log("Code a actualizar: " + codeRegistroAlerta)
            if (codeRegistroAlerta != null && codeRegistroAlerta != "") {
                const objSave = {
                    CodeRegistroAlerta: codeRegistroAlerta,
                    CodeEmpresa: codeEmpresa,
                    CodeTienda: codeTienda,
                    CodeUsuario: codeUsuario,
                    DescripcionAlerta: descripcion,
                    FechaProgramado: fechaProgramado,

                }
                var dataResponse = await Repository.update(objSave)
                toConsulta(router)
            } else {
                const objSave = {
                    CodeEmpresa: codeEmpresa,
                    CodeTienda: codeTienda,
                    CodeUsuario: codeUsuario,
                    DescripcionAlerta: descripcion,
                    FechaProgramado: fechaProgramado,

                }
                var dataResponse = await Repository.save(objSave)
                toConsulta(router)
            }
        }
    })
}
export async function Mensaje() {
    const MySwal = withReactContent(Swal)
    var message = "Tiene que completar los campos"
    MySwal.fire({
        title: <p>{message}</p>,
        icon: "warning",
        showConfirmButton: true,
        confirmButtonText: "Aceptar",
    }
    )
}

export async function getListaComboUsuario(setListaComboUsuario: any) {
    var dataResponse = await Repository.getListaComboUsuario()
    setListaComboUsuario(dataResponse)
}

export async function getListaComboTienda(setListaComboTienda: any) {
    var dataResponse = await Repository.getListaComboTienda()
    setListaComboTienda(dataResponse)
}

export async function getListaComboCadena(setListaComboCadena: any) {
    var dataResponse = await Repository.getListaComboCadena()
    setListaComboCadena(dataResponse)
}


export async function doExportar( FilterCoeUsuario: string, FilterCodeCadena: string, FilterCodeTienda: string, FilterFechaDesde: Date, FilterFechaHasta: Date) {
    var dataResponse = await Repository.doExport(FilterCoeUsuario, FilterCodeCadena, FilterCodeTienda, FilterFechaDesde, FilterFechaHasta)
    
}