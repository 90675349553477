import React, { useMemo } from 'react';
import { MaterialReactTable, type MRT_ColumnDef } from 'material-react-table';
import { useNavigate } from "react-router-dom";
import ContentHeader from '../layout/ContentHeader';
import ContentBody from '../layout/ContentBody';
import "react-datepicker/dist/react-datepicker.css";
import * as Controller from "../../../controllers/supervision-controller";
import * as Repository from "../../../repositories/supervision-repository";
import es from "date-fns/locale/es"; // the locale you want
import DatePicker, { registerLocale } from "react-datepicker";
import SelectSimple from '../layout/SelectSimple';
registerLocale("es", es); // register it with the name you want

export default function ConsultaSupervision(): React.JSX.Element {
    const router = useNavigate()
    const [listaComboPerfil, setListaComboPerfil] = React.useState([])
    const [listaComboSupervision, setListaComboSupervision] = React.useState([])
    const [ListaConsulta, setListaConsulta] = React.useState([])
    const [idPerfil, setIdPerfil] = React.useState('0')
    const [idTipoSupervision, setIdTipoSupervision] = React.useState('0')
    const [filterFechaInicial, setFilterFechaInicial] = React.useState(new Date)
    const [filterFechaFinal, setFilterFechaFinal] = React.useState(new Date)
    React.useEffect(() => {
        Controller.getListaComboSupervision( setListaComboSupervision);
        Controller.doConsultar(setListaConsulta,idPerfil, idTipoSupervision, filterFechaInicial, filterFechaFinal,  )
        Controller.getListaComboPerfil(setListaComboPerfil)
    }, [])
    return (<div className="content-wrapper">
        <ContentHeader title={"Consulta Supervision"} homePath={"Home"} currentPath={"Mantenimiento Supervision"} >
        </ContentHeader>
        <ContentBody title={"Consulta Supervision"}>
            <div className='row'>
                <div className='col-lg-2 '>
                    <label>Perfil</label>
                </div>
                <div className='col-lg-4 '>
                    <div className='form-group'>
                        <SelectSimple
                            value={idPerfil}
                            setValue={setIdPerfil}
                            lista={listaComboPerfil} >
                            --TODOS--
                        </SelectSimple>
                    </div>
                </div>
                <div className='col-lg-2'>
                    <label>Supervision</label>
                </div>
                <div className='col-lg-4 '>
                    <div className='form-group'>
                    <SelectSimple
                        value={idTipoSupervision}
                        setValue={setIdTipoSupervision}
                       
                        lista={listaComboSupervision} >
                        --Todos--
                      </SelectSimple>
                    </div>
                </div>
            </div>
            <div className='row mt-6'>
                <div className='col-lg-2'>
                    <label>Fecha Inicio</label>
                </div>
                <div className='col-lg-4 '>
                    <div className='form-group'>
                        <DatePicker locale="es" className='form-control-text' popperPlacement='top-end' selected={filterFechaInicial} onChange={(date: any) => setFilterFechaInicial(date)} dateFormat={"dd/MM/yyyy"}   />
                    </div>
                </div>
                <div className='col-lg-2'>
                    <label>Fecha Final</label>
                </div>
                <div className='col-lg-4 '>
                    <div className='form-group'>
                        <DatePicker locale="es" className='form-control-text' popperPlacement='top-end' selected={filterFechaFinal} onChange={(date: any) => setFilterFechaFinal(date)} dateFormat={"dd/MM/yyyy"} />
                    </div>
                </div>
            </div>

            <div className='row mt-2 mb-2'>
                <div className='col-gl-6'>
                    <div className='form-group'>

                        <button className='btn btn-info' onClick={() => Controller.doConsultar(setListaConsulta,idPerfil, idTipoSupervision, filterFechaInicial, filterFechaFinal)}>Consultar</button>
                        <button className='btn btn-success' onClick={() => Controller.doExportar( filterFechaInicial, filterFechaFinal, idPerfil, idTipoSupervision )}  >Exportar</button>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <MaterialReactTable columns={Repository.GetColumns()} data={ListaConsulta}

                        renderRowActions={({ row, table }) => (
                            <div>
                            </div>
                        )} />
                </div>
            </div>
        </ContentBody>
    </div>
    )
}
