import * as Repository from '../repositories/integracion-repository';
import { NavigateFunction } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { ToastContainer, toast } from 'react-toastify';    
import 'react-toastify/dist/ReactToastify.css';
import {
    CODE_SERVER_RETURN,
 
  } from "../utils/contants";

export async function importarStockB2B(
    router: NavigateFunction,
    fileImportar: any
  ) {
    if (fileImportar != null) {
      const formDataToUpload = new FormData();
      formDataToUpload.append("FileExcel", fileImportar);
  
      var dataResponseURL = await Repository.importarStockB2B(formDataToUpload);

      if(dataResponseURL.Code == CODE_SERVER_RETURN.ERROR) {
        const MySwal = withReactContent(Swal)
        var textMessage = dataResponseURL.Data.Message;
        MySwal.fire({
            title: <p>Error</p>,
            text: textMessage,
            icon: "error",
            showConfirmButton: true,
            showCancelButton: false,
            confirmButtonText: "OK",
        });
    }else {
        toast.success('Se importó el archivo satisfactoriamente!')
    }


    }
  }

  export async function doDescargarPlantillaStockB2B() {
    Repository.doDescargarPlantillaStockB2B()
}