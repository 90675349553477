import React, { useMemo } from "react";
import { MaterialReactTable, type MRT_ColumnDef } from "material-react-table";
import { useNavigate } from "react-router-dom";
import ContentHeader from "../layout/ContentHeader";
import ContentBody from "../layout/ContentBody";
import "react-datepicker/dist/react-datepicker.css";
import * as Controller from "../../../controllers/analisisexhibidores-controller";
import * as Repository from "../../../repositories/analisisexhibidores-repository";
import es from "date-fns/locale/es"; // the locale you want
import DatePicker, { registerLocale } from "react-datepicker";
import SelectSimple from "../layout/SelectSimple";
import Label from '../layout/Label'
registerLocale("es", es); // register it with the name you want
export default function ConsultaAnalisisExhibidores(): React.JSX.Element {
  const router = useNavigate();
  const [listaComboTipoExhibicion, setListaComboTipoExhibicion] =
    React.useState([]);
  const [listaComboMarcaExhibicion, setListaComboMarcaExhibicion] =
    React.useState([]);
  const [listaImagenesExhibicion, setListaImagenesExhibicion] = React.useState(
    []
  );
  const [listaComboCadena, setListaComboCadena] = React.useState([]);
  const [listaComboTienda, setListaComboTienda] = React.useState([]);
  const [codeTipoExhibicion, setCodeTipoExhibicion] = React.useState("");
  const [codeMarcaExhibicion, setCodeMarcaExhibicion] = React.useState("");
  const [codeCadena, setCodeCadena] = React.useState("");
  const [valcodeCadena, setValCodeCadena] = React.useState(false);
  const [codeTienda, setCodeTienda] = React.useState("");
  const [valcodeTienda, setValCodeTienda] = React.useState(false);
  const [valcodeMarcaExhibicion, setValCodeMarcaExhibicion] = React.useState(false);
  const [valcodeTipoExhibicion, setValCodeTipoExhibicion] = React.useState(false);
  const [filterFecha, setFilterFecha] = React.useState(new Date());
  const [isSubmit, setIsSubmit] = React.useState(false)
  const onchangeCadenaMaster = (e: any) => {
    setCodeCadena(e.target.value);
    Controller.getListaComboTienda(e.target.value, setListaComboTienda);
    setListaComboTienda([]);
  };
  React.useEffect(() => {
    Controller.getListaComboTipoExhibicion(setListaComboTipoExhibicion);
    Controller.getListaComboMarcaExhibicion(setListaComboMarcaExhibicion);
    Controller.getListaComboCadenaMaster(setListaComboCadena);
  }, []);

  function onClickBuscar() {
    var Result = true
    setIsSubmit(true)
    if (codeMarcaExhibicion == "" || codeMarcaExhibicion == "0" || codeMarcaExhibicion == null) {
      Result = false
      setValCodeMarcaExhibicion(false)
    } else {
      setValCodeMarcaExhibicion(true)
    }
    if (codeTipoExhibicion == "" || codeTipoExhibicion == "0" || codeTipoExhibicion == null) {
      Result = false
      setValCodeMarcaExhibicion(false)
    } else {
      setValCodeMarcaExhibicion(true)
    }
    if (codeCadena == "" || codeCadena == "0" || codeCadena == null) {
      Result = false
      setValCodeCadena(false)
    } else {
      setValCodeCadena(true)
    }

    if (codeTienda == "" || codeTienda == "0" || codeTienda == null) {
      Result = false
      setValCodeTienda(false)
    } else {
      setValCodeTienda(true)
    }

    if (Result) {
      Controller.getListaImagenExhibicion(
        setListaImagenesExhibicion,
        filterFecha,
        codeTienda,
        codeMarcaExhibicion,
        codeTipoExhibicion
      );
    } else {
      Controller.Mensaje()
    }

  }
  return (
    <div className="content-wrapper">
      <ContentHeader
        title={"Exhibidores"}
        homePath={"Home"}
        currentPath={"Exhibidores"}
      ></ContentHeader>
      <ContentBody title={"Exhibidores"}>
        <div className="row ">
          <div className="row">
            <div className="col-lg-2">
              <label>Fecha</label>
            </div>
            <div className="col-lg-4">
              <div className="form-group">
                <DatePicker
                  locale="es"
                  className="form-control-text"
                  popperPlacement="top-end"
                  selected={filterFecha}
                  onChange={(date: any) => setFilterFecha(date)}
                  dateFormat={"dd/MM/yyyy"}
                />
              </div>
            </div>
          </div>
          <div className="row">
          <div className="col-lg-2">
          <Label required={true}>Cadena</Label>
          </div>
          <div className="col-lg-4">
            <div className="form-group">
              <SelectSimple
                value={codeCadena}
                setValue={setCodeCadena}
                lista={listaComboCadena}
                onChangeSection={onchangeCadenaMaster}
              >
                --SELECCIONE--
              </SelectSimple>
            </div>
          </div>
          <div className="col-lg-2">
          <Label required={true}>Tienda</Label>
          </div>
          <div className="col-lg-4">
            <div className="form-group">
              <SelectSimple
                value={codeTienda}
                setValue={setCodeTienda}
                lista={listaComboTienda}
              >
                --SELECCIONE--
              </SelectSimple>
            </div>
          </div>
        </div>
          <br />
          <div className="row">
            <div className="col-2">
            <Label required={true}>Tipo Mueble</Label>
            </div>
            <div className="col-lg-4">
              <div className="form-group">
                <SelectSimple
                  value={codeTipoExhibicion}
                  setValue={setCodeTipoExhibicion}
                  valueError={valcodeTipoExhibicion}
                  setValueError={setValCodeTipoExhibicion}
                  isSubmit={isSubmit}
                  lista={listaComboTipoExhibicion}
                >
                  --SELECCIONE--
                </SelectSimple>
              </div>
            </div>
            <div className="col-lg-2">
            <Label required={true}>Marca Mueble</Label>
            </div>
            <div className="col-lg-4">
              <div className="form-group">
                <SelectSimple
                  value={codeMarcaExhibicion}
                  setValue={setCodeMarcaExhibicion}
                  valueError={valcodeMarcaExhibicion}
                  setValueError={setValCodeMarcaExhibicion}
                  isSubmit ={isSubmit}
                  lista={listaComboMarcaExhibicion}
                >
                  --SELECCIONE--
                </SelectSimple>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-2"></div>
            <div className="col-lg-4">
              <button className="btn btn-info" onClick={onClickBuscar}>
                Consultar
              </button>
            </div>
          </div>

          <div className="col-lg-12">
            {listaImagenesExhibicion.map((el: any, key: any) => (
              <div className="row mt-5">
                <div className="col-lg-12">
                  <label>Foto</label>
                </div>
                <div className="col-lg-12">
                  <img src={el.UrlFoto} className="img-fluid img-thumbnail"
                        style={{ maxWidth: 300 }} />
                </div>
              </div>
            ))}
          </div>
        </div>
      </ContentBody>
    </div>
  );
}
