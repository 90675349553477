import { useMemo } from 'react';
import { type MRT_ColumnDef } from 'material-react-table';
import * as ClientAPI from "../utils/methods";
import * as Endpoints from "../api/endpoint";
import Moment from 'moment';

type Registroalerta = {

};
export function GetColumns() {
  const columns = useMemo<MRT_ColumnDef<Registroalerta>[]>(
    () => [
     
      {
        accessorKey: 'NombreUsuario',
        header: 'Usuario',
        size: 200,
      },
      {
        accessorKey: 'NombreCadena',
        header: 'Cadena',
        size: 200,
      },
      {
        accessorKey: 'NombreTienda',
        header: 'Tienda',
        size: 200,
      },
      {
        accessorKey: 'DescripcionAlerta',
        header: 'Descripcion',
        size: 200,
      },
      {
        accessorKey: 'FechaHoraRegistro',
        header: 'Fecha Registro',
        size: 200,
      },
      {
        accessorKey: 'FechaProgramado',
        header: 'Fecha Programado',
        size: 200,
      },
    ],
    [],
  );
  ;
  return columns
}
export async function getData(FilterCodeUsuario: string, FilterCodeCadena: string, FilterCodeTienda: string, FilterFechaDesde: Date, FilterFechaHasta: Date) {
  FilterCodeUsuario = (FilterCodeUsuario == "" || FilterCodeUsuario == "0") ? "-" : FilterCodeUsuario
  FilterCodeCadena = (FilterCodeCadena == "" || FilterCodeCadena == "0") ? "-" : FilterCodeCadena
  FilterCodeTienda = (FilterCodeTienda == "" || FilterCodeTienda == "0") ? "-" : FilterCodeTienda

  var CFilterFechaDesde = Moment(FilterFechaDesde).format("DD-MM-YYYY") //dd-MM-yyyy
  var CFilterFechaHasta = Moment(FilterFechaHasta).format("DD-MM-YYYY")

  var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.GET_LISTA_REGISTROALERTA + "/" + FilterCodeUsuario + "/" + FilterCodeCadena + "/" + FilterCodeTienda + "/" + CFilterFechaDesde + "/" + CFilterFechaHasta, "")
  if (dataResponse.Data != null) {
    return dataResponse.Data;
  } else {
    return []
  }
}

export async function save(objSave: any) {
  var dataResponse = await ClientAPI.RequestPost(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.INSERTAR_REGISTROALERTA, "", objSave)
  if (dataResponse.Data != null) {
    return dataResponse.Data;
  } else {
    return []
  }
}
export async function update(objSave: any) {
  var dataResponse = await ClientAPI.RequestPost(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.UPDATE_REGISTROALERTA, "", objSave)
  if (dataResponse.Data != null) {
    return dataResponse.Data;
  } else {
    return null
  }
}
export async function inactiva(objSave: any) {
  var dataResponse = await ClientAPI.RequestPost(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.INACTIVO_REGISTROALERTA, "", objSave)
  if (dataResponse.Data != null) {
    return dataResponse.Data;
  } else {
    return null
  }
}

export async function getListaComboUsuario() {
  var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.GET_LISTA_COMBO_PROGRAMACIONUSUARIO, "")
  if (dataResponse.Data != null) {
    return dataResponse.Data;
  } else {
    return null
  }
}

export async function activa(objSave: any) {
  var dataResponse = await ClientAPI.RequestPost(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.ACTIVO_REGISTROALERTA, "", objSave)
  if (dataResponse.Data != null) {
    return dataResponse.Data;
  } else {
    return null
  }
}

export async function getListaComboTienda() {
  var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.GET_LISTA_COMBO_TIENDA + "/-" ,"")
  if (dataResponse.Data != null) {
    return dataResponse.Data;
  } else {
    return null
  }
}


 export async function getListaComboCadena() {
  var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.GET_LISTA_COMBO_CADENA, "")
  if (dataResponse.Data != null) {
    return dataResponse.Data;
  } else {
    return null
  }
}

export async function doExport(FilterCodeUsuario: string, FilterCodeCadena: string, FilterCodeTienda: string, FilterFechaDesde: Date, FilterFechaHasta: Date) {
  FilterCodeUsuario = (FilterCodeUsuario == "" || FilterCodeUsuario == "0") ? "-" : FilterCodeUsuario
  FilterCodeCadena = (FilterCodeCadena == "" || FilterCodeCadena == "0") ? "-" : FilterCodeCadena
  FilterCodeTienda = (FilterCodeTienda == "" || FilterCodeTienda == "0") ? "-" : FilterCodeTienda

  var CFilterFechaDesde = Moment(FilterFechaDesde).format("DD-MM-YYYY") //dd-MM-yyyy
  var CFilterFechaHasta = Moment(FilterFechaHasta).format("DD-MM-YYYY")

  var dataResponse = await ClientAPI.RequestDownload(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.GET_LISTA_REGISTROALERTA_EXCEL + "/" + FilterCodeUsuario + "/" + FilterCodeCadena + "/" + FilterCodeTienda + "/" + CFilterFechaDesde + "/" + CFilterFechaHasta, 'DescargarExcelRegistroAlerta.xlsx')
  
}
