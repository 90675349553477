import { useMemo } from 'react';
import { type MRT_ColumnDef } from 'material-react-table';
import * as ClientAPI from "../utils/methods";
import * as Endpoints from "../api/endpoint";
import Moment from 'moment';
type IndicadorVendedores = {
 
};
export function GetColumns() {
  const columns = useMemo<MRT_ColumnDef<IndicadorVendedores>[]>(
    () => [
    
      {
        accessorKey: 'NombreCompleto',
        header: 'Nombre Completo ',
        size: 100,
      },
     
      {
        accessorKey: 'NumeroDocumentoIdentidad',
        header: 'Documento',
        size: 100,
      },
      {
        accessorKey: 'Fecha',
        header: 'Fecha',
        size: 100,
      },
      {
        accessorKey: 'Cobeertura',
        header: 'Cobertura',
        size: 100,
      },
      {
        accessorKey: 'Ventas',
        header: 'Ventas',
        size: 100,
      },
      {
        accessorKey: 'Asistencia',
        header: 'Asistencia ',
        size: 100,
      },
      
    ],
    [],
  );
  ;
  return columns
}
export async function getData(  FilterFechaInicio :Date, FilterFechaFinal: Date, FilterNombreCompleto : string  )   {
  var CFilterFechaInicio = Moment(FilterFechaInicio).format("DD-MM-YYYY") 
  var CFilterFechaFinal = Moment(FilterFechaFinal).format("DD-MM-YYYY")
  var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.GET_LISTA_INDICADORVENDEDORES + "/" + CFilterFechaInicio  + "/" + CFilterFechaFinal   + "/" + FilterNombreCompleto,   "")
  if (dataResponse.Data != null) {
    return dataResponse.Data;
  } else {
    return []
  }
}

export async function doExport( FilterFechaDesde: Date, FilterFechaHasta : Date ) {
  
  var CFilterFechaDesde = Moment(FilterFechaDesde).format("DD-MM-YYYY") 
  var CFilterFechaHasta = Moment(FilterFechaHasta).format("DD-MM-YYYY")
 
  var dataResponse = await ClientAPI.RequestDownload(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.GET_LISTA_INDICADORVENDEDOR_EXCEL  + "/" +  CFilterFechaDesde + "/" + CFilterFechaHasta, 'DescargarExcelVendedor.xlsx')
  
}