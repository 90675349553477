
import { NavigateFunction } from 'react-router-dom';
import * as Repository from "../repositories/cliente-repository";
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { Result } from 'postcss';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CODE_SERVER_RETURN } from '../utils/contants';

export function toNuevo(router: NavigateFunction) {
    router("/main/cliente/mantenimiento/nuevo")
}
export function toConsulta(router: NavigateFunction) {
    router("/main/cliente/consulta")
}
export async function toEditar(router: NavigateFunction, row: any) {
    var codeEditar = row.CodeCliente
    router("/main/cliente/mantenimiento/" + codeEditar)
}
export async function toQuestionInactivaActiva(row: any, setListaConsulta: any) {
    const MySwal = withReactContent(Swal)
    var question = row.Estado == 1 ? "¿Desea eliminar el registro?" : "¿Desea activar el registro?"
    MySwal.fire({
        title: <p>{question}</p>,
        icon: "question",
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: "Sí",
        cancelButtonText: "Cancelar"

    }).then((result: any) => {
        if (result.isConfirmed) {
            toInactivaActiva(row, setListaConsulta)
        }
    })
}
async function toInactivaActiva(row: any, setListaConsulta: any) {
    var codeEliminar = row.CodeCliente
    const objSave = {
        CodeCliente: codeEliminar
    }
    if (row.Estado == 1) {
        var dataResponse = await Repository.inactiva(objSave)
    } else if (row.Estado == 0) {
        var dataResponse = await Repository.activa(objSave)
    }
    doConsultar(setListaConsulta, "", "")
}
export async function getByCode(code: any, setNombreCliente: any) {

    var dataResponse = await Repository.getByCode(code)
    if (dataResponse != null) {
        setNombreCliente(dataResponse.NombreCliente)
    }
}
export async function doConsultar(setListaConsulta: any, FilterCodeEmpresa: any, FilterNombreCliente: string) {
    var dataResponse = await Repository.getData(FilterCodeEmpresa, FilterNombreCliente)
    setListaConsulta(dataResponse)
}
export async function save(router: NavigateFunction, codeCliente: string, nombreCliente: string) {
    const MySwal = withReactContent(Swal)
    var question = "¿Desea guardar el registro?"
    MySwal.fire({
        title: <p>{question}</p>,
        icon: "question",
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: "Sí",
        cancelButtonText: "Cancelar"
    }).then(async (result: any) => {
        if (result.isConfirmed) {
            console.log("Code a actualizar: " + codeCliente)
            if (codeCliente != null && codeCliente != "") {
                const objSave = {
                    CodeCliente: codeCliente,
                    NombreCliente: nombreCliente,
                }
                var dataResponse = await Repository.update(objSave)
                if (dataResponse.Code == CODE_SERVER_RETURN.VAL) {
                    MySwal.fire({
                        title: <p>{dataResponse.Message}</p>,
                        icon: "warning",
                        showConfirmButton: true,
                        showCancelButton: false,
                        confirmButtonText: "Ok"
                    });
                } else if (dataResponse.Code == CODE_SERVER_RETURN.ERROR) {
                    MySwal.fire({
                        title: <p>{dataResponse.Message}</p>,
                        icon: "error",
                        showConfirmButton: true,
                        showCancelButton: false,
                        confirmButtonText: "OK",

                    });
                } else {
                    toast.success('Guardado satisfactoriamente!')
                    toConsulta(router)

                }
            } else {
                const objSave = {
                    NombreCliente: nombreCliente,
                }

                var dataResponse = await Repository.save(objSave)
                if (dataResponse.Code == CODE_SERVER_RETURN.VAL) {
                    MySwal.fire({
                        title: <p>{dataResponse.Message}</p>,
                        icon: "warning",
                        showConfirmButton: true,
                        showCancelButton: false,
                        confirmButtonText: "Ok"
                    });
                } else if (dataResponse.Code == CODE_SERVER_RETURN.ERROR) {
                    MySwal.fire({
                        title: <p>{dataResponse.Message}</p>,
                        icon: "error",
                        showConfirmButton: true,
                        showCancelButton: false,
                        confirmButtonText: "OK",

                    });
                } else {
                    toast.success('Guardado satisfactoriamente!')
                    toConsulta(router)
                }
            }
        }
    })
}
export async function Mensaje() {
    const MySwal = withReactContent(Swal)
    var message = "Tiene que completar los campos"
    MySwal.fire({
        title: <p>{message}</p>,
        icon: "warning",
        showConfirmButton: true,
        confirmButtonText: "Aceptar",
    }
    )
}

export async function doExportar(FilterNombreCliente: string) {
    var dataResponse = await Repository.doExport(FilterNombreCliente)

}