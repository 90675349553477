import { useMemo } from 'react';
import { type MRT_ColumnDef } from 'material-react-table';
import * as ClientAPI from "../utils/methods";
import * as Endpoints from "../api/endpoint";
type Cliente = {
  IdCliente: string,
  NombreCliente: string,
};
export function GetColumns() {
  const columns = useMemo<MRT_ColumnDef<Cliente>[]>(
    () => [
      {
        accessorKey: 'IdCliente',
        header: 'ID',
        size: 80,
      },
      {
        accessorKey: 'NombreCliente',
        header: 'Nombre Cliente',
        size: 400,
      },

    ],
    [],
  );
  ;
  return columns
}
export async function getData(FilterEmpresa : string, FilterNombreCliente: string) {
  FilterEmpresa = (FilterEmpresa == "" || FilterEmpresa == "0")? "-": FilterEmpresa
  var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.GET_LISTA_CLIENTE + "/" +  FilterEmpresa + "/" +  FilterNombreCliente, "")
  if (dataResponse.Data != null) {
    return dataResponse.Data;
  } else {
    return []
  }
}
export async function getByCode(code: string) {
  var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.GET_CLIENTE_BYCODE + "/" + code, "")
  return dataResponse.Data;
}
export async function save(objSave: any) {
  var dataResponse = await ClientAPI.RequestPost(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.INSERTAR_CLIENTE, "", objSave)
  if (dataResponse != null) {
    return dataResponse;
  } else {
    return []
  }
}
export async function update(objSave: any) {
  var dataResponse = await ClientAPI.RequestPost(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.UPDATE_CLIENTE, "", objSave)
  if (dataResponse != null) {
    return dataResponse;
  } else {
    return null
  }
}
export async function inactiva(objSave: any) {
  var dataResponse = await ClientAPI.RequestPost(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.INACTIVO_CLIENTE, "", objSave)
  if (dataResponse.Data != null) {
    return dataResponse.Data;
  } else {
    return null
  }
}
export async function activa(objSave: any) {
  var dataResponse = await ClientAPI.RequestPost(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.ACTIVO_CLIENTE, "", objSave)
  if (dataResponse.Data != null) {
    return dataResponse.Data;
  } else {
    return null
  }
} 

export async function doExport(FilterNombreCliente: string) {
  var dataResponse = await ClientAPI.RequestDownload(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.GET_LISTA_CLIENTE_EXCEL + "/" + FilterNombreCliente, 'DescargarExcelCliente.xlsx')
 
}