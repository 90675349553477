import React, { useMemo } from 'react';
import { useNavigate } from "react-router-dom";
import ContentHeader from '../layout/ContentHeader';
import ContentBody from '../layout/ContentBody';
import * as Controller from '../../../controllers/integracion-controller';
export default function Consulta() {
  const router = useNavigate()
  
  const [FileExcel, setFileExcel] = React.useState(null);
  
  function onChangeFileUpload(e: any) {
    var fileToUpload = e.target.files[0];
    setFileExcel(fileToUpload);
  }

  return (<div className="content-wrapper">
    <ContentHeader title={"Importar Stock B2B"} homePath={"Home"} currentPath={"Importar Stock B2B"} >
    </ContentHeader>
    <ContentBody title={"Importar Stock B2B"}>

      <div className='row mt-2 mb-2'>
        <div className="col-lg-2">
            <label>Seleccionar Stock B2B</label>
        </div>
        <div className="col-lg-4">
            <div className="mt-2">
            <input
                type="file"
                id="fileExcel"
                onChange={onChangeFileUpload}
            />
            </div>
        </div>

        <div className="col-lg-2">
            <button
            className="btn btn-primary"
            onClick={() => Controller.importarStockB2B(router, FileExcel)}
            >
            Importar
            </button>
        </div>
        <div className='col-lg-4'>
            <button className='btn btn-info' onClick={() => Controller.doDescargarPlantillaStockB2B()}  >Descargar Plantilla</button>
        </div>
      </div>
    </ContentBody>
  </div>
  )
}
