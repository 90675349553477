
import clsx from "clsx";
import { useState } from "react";

export default function TextArea({


  children,
  value,
  setValue,
  valueError,
  setValueError,
  messageError,
  isSubmit,
  upperCase,
  lowerCase,
  className,
  onChangeSection,
}: {
  children?: React.ReactNode;
  value: any;
  setValue: any;
  valueError?: any;
  setValueError?: any;
  messageError?: string;
  isSubmit?: boolean;
  upperCase?: boolean;
  lowerCase?: boolean;
  className?: any;
  onChangeSection?: any;

}) {

  if (valueError == undefined) {
    valueError = true;
  }

  if (isSubmit == undefined) {
    isSubmit = false;
  }

  const onChangeTextbox = (e: any) => {
    if (onChangeSection != undefined) {
      onChangeSection(e); 
    }

    setValue(e.currentTarget.value);

    if (setValueError != undefined) {
      if (e.currentTarget.value != "") {
        setValueError(true);
      } else {
        setValueError(false);
      }
    }
  };

  const onInputTextbox = (e: any) => {
    if (upperCase != undefined || lowerCase != undefined) {
      if (upperCase) {
        e.currentTarget.value = e.currentTarget.value.toUpperCase()
      } else if (lowerCase) {
        e.currentTarget.value = e.currentTarget.value.toLowerCase()
      }
    }
  };


  return (
    <div>

      <textarea

        value={value}

        className={clsx(
          isSubmit
            ? valueError
              ? "form-control-text"
              : "form-control-text error"
            : "form-control-text",
          className
        )}
        onChange={onChangeTextbox}
        onInput={onInputTextbox}

      />
      {(isSubmit && !valueError && messageError) ? <label style={{ color: 'red', fontWeight: 'normal' }}>{messageError}</label> : <></>}
    </div>)


}
