
import { NavigateFunction } from 'react-router-dom';
import * as Repository from "../repositories/empresa-repository";
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { UPLOAD_FILEPRODUCTO } from "../utils/contants";
import { ToastContainer, toast } from 'react-toastify';    
import 'react-toastify/dist/ReactToastify.css';
import {
    CODE_SERVER_RETURN,
  
  } from "../utils/contants";

export function toNuevo(router: NavigateFunction) {
    router("/main/empresa/mantenimiento/nuevo")
}
export function toConsulta(router: NavigateFunction) {
    router("/main/empresa/consulta")
}
export async function doConsultar(setListaConsulta: any, FilterTipoDocumentoIdentidad: number, FilterNumeroDocumentoIdentidad: string, FilterNombreEmpresa: string) {
    var dataResponse = await Repository.getData(FilterTipoDocumentoIdentidad, FilterNumeroDocumentoIdentidad, FilterNombreEmpresa)
    setListaConsulta(dataResponse)
}
export async function toEditar(router: NavigateFunction, row: any) {
    var codeEditar = row.CodeEmpresa
    router("/main/empresa/mantenimiento/" + codeEditar)
}
export async function toQuestionInactivaActiva(row: any, setListaConsulta: any) {
    const MySwal = withReactContent(Swal)
    var question = row.Estado == 1 ? "¿Desea eliminar el registro?" : "¿Desea activar el registro?"
    MySwal.fire({
        title: <p>{question}</p>,
        icon: "question",
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: "Sí",
        cancelButtonText: "Cancelar"
    }).then((result: any) => {
        if (result.isConfirmed) {
            toInactivaActiva(row, setListaConsulta)
        }
    })
}
async function toInactivaActiva(row: any, setListaConsulta: any) {
    var codeEliminar = row.CodeEmpresa
    const objSave = {
        CodeEmpresa: codeEliminar
    }
    if (row.Estado == 1) {
        var dataResponse = await Repository.inactiva(objSave)
    } else if (row.Estado == 0) {
        var dataResponse = await Repository.activa(objSave)
    }
    doConsultar(setListaConsulta, 0, "", "")
}
export async function getByCode(code: any, setTipoDocumentoIdentidad: any, setNumeroDocumentoIdentidad: any, setNombreEmpresa: any,setDireccionFiscal:any, setDireccionOficina:any, setTelefono:any, setCorreoElectronico:any,
    setRubro:any, setNombreContactoComercial:any, setApellidoContactoComercial:any, setDniContactoComercial:any, setTelefonoContactoComercial:any, setCorreoElectronicoContactoComercial:any, setNombreContactoContabilidad:any, setApellidoContactoContabilidad:any,
    setDniContactoContabilidad:any, setTelefonoContactoContabilidad:any, setCorreoElectronicoContactoContabilidad:any, setUrlImagen : any, setNombreRepresentanteLegal : any, setApelllidoRepresentanteLegal : any, setTelefonoRespresentanteLegal: any, setCorreoRepresentanteLegal : any, setDniRespresentanteLegal : any, setnombreSupervisor : any, setApelllidoSupervisor : any, setTelefonoSupervisor : any, setCorreoSupervisor : any, setDniSupervisor : any, setUrlImagenFirmaRepresentante : any) {
    var dataResponse = await Repository.getByCode(code)
    if (dataResponse != null) {
        setTipoDocumentoIdentidad(dataResponse.TipoDocumentoIdentidad)
        setNumeroDocumentoIdentidad(dataResponse.NumeroDocumentoIdentidad)
        setNombreEmpresa(dataResponse.NombreEmpresa)
        setDireccionFiscal(dataResponse.DireccionFiscal)
        setDireccionOficina(dataResponse.DireccionOficina)
        setTelefono(dataResponse.Telefono)
        setCorreoElectronico(dataResponse.CorreoElectronico)
        setRubro(dataResponse.Rubro)
        setNombreContactoComercial(dataResponse.NombreContactoComercial)
        setApellidoContactoComercial(dataResponse.ApellidoContactoComercial)
        setDniContactoComercial(dataResponse.DniContactoComercial)
        setTelefonoContactoComercial(dataResponse.TelefonoContactoComercial)
        setCorreoElectronicoContactoComercial(dataResponse.CorreoElectronicoContactoComercial)
        setNombreContactoContabilidad(dataResponse.NombreContactoContabilidad)
        setApellidoContactoContabilidad(dataResponse.ApellidoContactoContabilidad)
        setDniContactoContabilidad(dataResponse.DniContactoContabilidad)
        setTelefonoContactoContabilidad(dataResponse.TelefonoContactoContabilidad)
        setCorreoElectronicoContactoContabilidad(dataResponse.CorreoElectronicoContactoContabilidad)
        setUrlImagen(dataResponse.UrlImagenLogo)

        setNombreRepresentanteLegal(dataResponse.NombreRepresentanteLegal)
        setApelllidoRepresentanteLegal(dataResponse.ApellidoRepresentanteLegal)
        setTelefonoRespresentanteLegal(dataResponse.TelefonoRepresentanteLegal)
        setCorreoRepresentanteLegal(dataResponse.CorreoRepresentanteLegal)
        setDniRespresentanteLegal(dataResponse.DniRepresentanteLegal)
        setnombreSupervisor(dataResponse.NombreSupervisor)
        setApelllidoSupervisor(dataResponse.ApellidoSupervisor)
        setTelefonoSupervisor(dataResponse.TelefonoSupervisor)
        setCorreoSupervisor(dataResponse.CorreoSupervisor)
        setDniSupervisor(dataResponse.DniSupervisor)
        setUrlImagenFirmaRepresentante(dataResponse.UrlImagenFirmaRepresentante)

    }
}
export async function save(router: NavigateFunction, codeEmpresa: string, tipoDocumentoIdentidad: number, numeroDocumentoIdentidad: string, nombreEmpresa: string,direccionFiscal:string, direccionOficina:string, telefono:string, correoElectronico:string,
    rubro:string, nombreContactoComercial:string, apellidoContactoComercial:string, dniContactoComercial:string, telefonoContactoComercial:string, correoElectronicoContactoComercial:string, nombreContactoContabilidad:string, apellidoContactoContabilidad:string, dniContactoContabilidad:string, telefonoContactoContabilidad:string, correoElectronicoContactoContabilidad:string, file : any, urlImagenLogo : string, nombreRepresentanteLegal: string, apellidoRepresentanteLegal : string,
    telefonoRepresentanteLegal : string, correoRepresentanteLegal : string, dniRepresentanteLegal : string, nombreSupervisor : string, apellidoSupervisor : string, telefonoSupervisor : string, correoSupervisor : string, dniSupervisor : string, fileFirmaRepresentante : any, urlImagenFirmaRepresenta : string ) {
    const MySwal = withReactContent(Swal)
    var question = "¿Desea guardar el registro?"
    MySwal.fire({
        title: <p>{question}</p>,
        icon: "question",
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: "Sí",
        cancelButtonText: "Cancelar"
    }).then(async (result: any) => {
        if (result.isConfirmed) {
            
             // Subir Archivo
            var URLFile = urlImagenLogo == null || urlImagenLogo == "" ? null : urlImagenLogo
            if (file != null) {
                const formDataToUpload = new FormData();
                const tipoBucket = UPLOAD_FILEPRODUCTO.USUARIO_FOTO_FOTOPRODUCTO; // Empresa
                formDataToUpload.append('FileImage', file);
                formDataToUpload.append('TipoBucket', tipoBucket.toString());
                var dataResponseURL = await Repository.uploadFile(formDataToUpload)
                if (dataResponseURL != null && dataResponseURL.Code === 0) {
                    URLFile = dataResponseURL.Data.PathFile
                }
            }

               // Subir Archivo
               var URLFILEFIRMAREPRESENTA = urlImagenFirmaRepresenta == null || urlImagenFirmaRepresenta == "" ? null : urlImagenFirmaRepresenta
               if (fileFirmaRepresentante != null) {
                   const formDataToUpload = new FormData();
                   const tipoBucket = UPLOAD_FILEPRODUCTO.USUARIO_FOTO_FOTOPRODUCTO; // Empresa
                   formDataToUpload.append('FileImage', fileFirmaRepresentante);
                   formDataToUpload.append('TipoBucket', tipoBucket.toString());
                   var dataResponseURL = await Repository.uploadFile(formDataToUpload)
                   if (dataResponseURL != null && dataResponseURL.Code === 0) {
                       URLFILEFIRMAREPRESENTA = dataResponseURL.Data.PathFile
                   }
               }

            console.log("Code a actualizar: " + codeEmpresa)
            if (codeEmpresa != null && codeEmpresa != "") {
                const objSave = {
                    CodeEmpresa: codeEmpresa,
                    TipoDocumentoIdentidad: tipoDocumentoIdentidad,
                    NumeroDocumentoIdentidad: numeroDocumentoIdentidad,
                    NombreEmpresa: nombreEmpresa,
                    DireccionFiscal :direccionFiscal,
                    DireccionOficina : direccionOficina,
                    Telefono: telefono,
                    CorreoElectronico: correoElectronico,
                    Rubro: rubro,
                    NombreContactoComercial: nombreContactoComercial,
                    ApellidoContactoComercial: apellidoContactoComercial,
                    DniContactoComercial: dniContactoComercial,
                    TelefonoContactoComercial: telefonoContactoComercial,
                    CorreoElectronicoContactoComercial: correoElectronicoContactoComercial ,
                    NombreContactoContabilidad: nombreContactoContabilidad,
                    ApellidoContactoContabilidad: apellidoContactoContabilidad,
                    DniContactoContabilidad: dniContactoContabilidad,
                    TelefonoContactoContabilidad: telefonoContactoContabilidad,
                    CorreoElectronicoContactoContabilidad: correoElectronicoContactoContabilidad,
                    UrlImagenLogo: URLFile,
                    NombreRepresentanteLegal : nombreRepresentanteLegal,
                    ApellidoRepresentanteLegal : apellidoRepresentanteLegal,
                    TelefonoRepresentanteLegal : telefonoRepresentanteLegal,
                    CorreoRepresentanteLegal : correoRepresentanteLegal,
                    DniRepresentanteLegal : dniRepresentanteLegal,
                    NombreSupervisor : nombreSupervisor,
                    ApellidoSupervisor : apellidoSupervisor,
                   TelefonoSupervisor : telefonoSupervisor,
                    CorreoSupervisor : correoSupervisor,
                    DniSupervisor : dniSupervisor,
                    UrlImagenFirmaRepresentante : URLFILEFIRMAREPRESENTA
                }
                var dataResponse = await Repository.update(objSave)
                if (dataResponse.Code == CODE_SERVER_RETURN.VAL) {
                    MySwal.fire({
                        title: <p>{dataResponse.Message}</p>,
                        icon: "warning",
                        showConfirmButton: true,
                        showCancelButton: false,
                        confirmButtonText: "OK",
                        
                    });

                } else if(dataResponse.Code == CODE_SERVER_RETURN.ERROR) {
                    MySwal.fire({
                        title: <p>{dataResponse.Message}</p>,
                        icon: "error",
                        showConfirmButton: true,
                        showCancelButton: false,
                        confirmButtonText: "OK",
                        
                    });
                } else {
                    toast.success('Guardado satisfactoriamente!')
                    toConsulta(router)
                  
                }
            } else {
                const objSave = {
                    TipoDocumentoIdentidad: tipoDocumentoIdentidad,
                    NumeroDocumentoIdentidad: numeroDocumentoIdentidad,
                    NombreEmpresa: nombreEmpresa,
                    DireccionFiscal :direccionFiscal,
                    DireccionOficina : direccionOficina,
                    Telefono: telefono,
                    CorreoElectronico: correoElectronico,
                    Rubro: rubro,
                    NombreContactoComercial: nombreContactoComercial,
                    ApellidoContactoComercial: apellidoContactoComercial,
                    DniContactoComercial: dniContactoComercial,
                    TelefonoContactoComercial: telefonoContactoComercial,
                    CorreoElectronicoContactoComercial: correoElectronicoContactoComercial ,
                    NombreContactoContabilidad: nombreContactoContabilidad,
                    ApellidoContactoContabilidad: apellidoContactoContabilidad,
                    DniContactoContabilidad: dniContactoContabilidad,
                    TelefonoContactoContabilidad: telefonoContactoContabilidad,
                    CorreoElectronicoContactoContabilidad: correoElectronicoContactoContabilidad,
                    UrlImagenLogo: URLFile,
                    NombreRepresentanteLegal : nombreRepresentanteLegal,
                    ApellidoRepresentanteLegal : apellidoRepresentanteLegal,
                    TelefonoRepresentanteLegal : telefonoRepresentanteLegal,
                    CorreoRepresentanteLegal : correoRepresentanteLegal,
                    DniRepresentanteLegal : dniRepresentanteLegal,
                    NombreSupervisor : nombreSupervisor,
                    ApellidoSupervisor : apellidoSupervisor,
                   TelefonoSupervisor : telefonoSupervisor,
                    CorreoSupervisor : correoSupervisor,
                    DniSupervisor : dniSupervisor,
                    UrlImagenFirmaRepresentante : URLFILEFIRMAREPRESENTA
                }
                var dataResponse = await Repository.save(objSave)
                if (dataResponse.Code == CODE_SERVER_RETURN.VAL) {
                    MySwal.fire({
                        title: <p>{dataResponse.Message}</p>,
                        icon: "warning",
                        showConfirmButton: true,
                        showCancelButton: false,
                        confirmButtonText: "OK",
                        
                    });

                } else if(dataResponse.Code == CODE_SERVER_RETURN.ERROR) {
                    MySwal.fire({
                        title: <p>{dataResponse.Message}</p>,
                        icon: "error",
                        showConfirmButton: true,
                        showCancelButton: false,
                        confirmButtonText: "OK",
                        
                    });
                }else {
                    toast.success('Guardado satisfactoriamente!')
                    toConsulta(router)
                }
            }
        }
    })
}
export async function Mensaje() {
    const MySwal = withReactContent(Swal)
    var message = "Tiene que completar los campos"
    MySwal.fire({
        title: <p>{message}</p>,
        icon: "warning",
        showConfirmButton: true,
        confirmButtonText: "Aceptar",
    }
    )
}
export async function getListaComboTipoDocumento(setListaComboTipoDocumento: any) {
    var dataResponse = await Repository.getListaComboTipoDocumento()
    setListaComboTipoDocumento(dataResponse)
}
export async function doExportar(FilterNombreEmpresa: string, FilterTipoDocumentoIdentidad: number, FilterNumeroDocumentoIdentidad: string ) {
    var dataResponse = await Repository.doExport(FilterNombreEmpresa, FilterTipoDocumentoIdentidad, FilterNumeroDocumentoIdentidad )
    
}
