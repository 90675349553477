import ContentHeader from "../layout/ContentHeader";
import { useParams, useNavigate } from "react-router-dom";
import * as Controller from "../../../controllers/equipotrabajo-controller";
import React from "react";
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Email as EmailIcon,
} from "@mui/icons-material";
import SelectSimple from "../layout/SelectSimple";
import MaterialReactTable from "material-react-table";
import { IconButton } from "@mui/material";
import * as Repository from "../../../repositories/equipotrabajo-repository";
import Textbox from "../layout/Textbox";
import Label from "../layout/Label";
export default function NuevoEquipoTrabajo() {
  const [ListaComboSupervisor, setListaComboSupervisor] = React.useState([]);
  const [ListaComboMiembroEquipo, setListaComboMiembroEquipo] = React.useState(
    []
  );
  const { code } = useParams();
  const router = useNavigate();
  const [ListaMiembros, setListaMiembros] = React.useState([]);
  const [codeEquipoTrabajo, setCodeEquipoTrabajo] = React.useState("");
  const [codeUsuarioSupervisor, setCodeusuarioSupervisor] = React.useState("");
  const [miembros, setMiembros] = React.useState("");
  const [nombreEquipoTrabajo, setNombreEquipoTrabajo] = React.useState("");
  const [CodeEmpresa, setCodeEmpresa] = React.useState("");
  const [tituloVentana, setTituloVentana] = React.useState(
    "Nuevo Equipo de trabajo"
  );
  const [valnombreEquipoTrabajo, setValNombreEquipoTrabajo] =
    React.useState(false);
  const [valmiembros, setValMiembros] = React.useState(false);
  const [valcodeUsuarioSupervisor, setValCodeusuarioSupervisor] =
    React.useState(false);
  const [isSubmit, setIsSubmit] = React.useState(false);
  React.useEffect(() => {
    Controller.getListaComboSupervisor(setListaComboSupervisor);
    Controller.getListaComboMiembroEquipo(setListaComboMiembroEquipo);

    if (code != "nuevo") {
      setTituloVentana("Editar Equipo Trabajo");
      setCodeEquipoTrabajo(code!);
      Controller.getByCode(
        code,
        setNombreEquipoTrabajo,
        setCodeEmpresa,
        setListaMiembros
      );
    }
  }, []);

  function validarCampos() {
    var Result = true;
    setIsSubmit(true);
    if (nombreEquipoTrabajo == "" || nombreEquipoTrabajo == null) {
      Result = false;
      setValNombreEquipoTrabajo(false);
    } else {
      setValNombreEquipoTrabajo(true);
    }
    if (
      codeUsuarioSupervisor == "" ||
      codeUsuarioSupervisor == "0" ||
      codeUsuarioSupervisor == null
    ) {
      Result = false;
      setValCodeusuarioSupervisor(false);
    } else {
      setValCodeusuarioSupervisor(true);
    }

    if (miembros == "" || miembros == "0" || miembros == null) {
      Result = false;
      setValMiembros(false);
    } else {
      setValMiembros(true);
    }
    if (Result) {
      Controller.save(
        router,
        codeEquipoTrabajo,
        codeUsuarioSupervisor,
        CodeEmpresa,
        nombreEquipoTrabajo,
        ListaMiembros
      );
    } else {
      Controller.Mensaje();
    }
  }
  return (
    <div className="content-wrapper">
      <ContentHeader
        title={tituloVentana}
        homePath={"Home"}
        currentPath={tituloVentana}
      ></ContentHeader>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card card-primary">
                <div className="card-header">
                  <div className="d-flex flex-row gap-2">
                    <div className="align-items-center">
                      <i
                        className="bx bx-arrow-back arrow-back"
                        onClick={() => Controller.toConsulta(router)}
                      ></i>
                    </div>
                    <div className="">
                      <h4 className="card-title">{tituloVentana}</h4>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-6">
                      <h4>Datos</h4>
                      <hr />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-2">
                      <Label required={true}>Nombres Equipo </Label>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <Textbox
                          value={nombreEquipoTrabajo}
                          valueError={valnombreEquipoTrabajo}
                          setValue={setNombreEquipoTrabajo}
                          setValueError={setValNombreEquipoTrabajo}
                          isSubmit={isSubmit}
                          upperCase={true}
                        />
                      </div>
                    </div>
                    <div className="col-lg-2">
                      <Label required={true}>Supervisor</Label>
                    </div>
                    <div className="col-lg-4">
                      <SelectSimple
                        value={codeUsuarioSupervisor}
                        setValue={setCodeusuarioSupervisor}
                        valueError={valcodeUsuarioSupervisor}
                        setValueError={setValCodeusuarioSupervisor}
                        isSubmit={isSubmit}
                        lista={ListaComboSupervisor}
                      >
                        --Seleccion--
                      </SelectSimple>
                    </div>
                  </div>
                  <div className="row mt-5">
                    <div className="col-lg-6">
                      <h4>Equipos</h4>
                      <hr />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-2">
                      <Label required={true}> Miembros de Equipo</Label>
                    </div>
                    <div className="col-lg-4">
                      <SelectSimple
                        value={miembros}
                        setValue={setMiembros}
                        valueError={valmiembros}
                        setValueError={setValMiembros}
                        isSubmit={isSubmit}
                        lista={ListaComboMiembroEquipo}
                      >
                        --Seleccion--
                      </SelectSimple>
                    </div>
                    <div className="col-lg-4">
                      <button
                        className="btn btn-info"
                        onClick={() =>
                          Controller.addMember(
                            miembros,
                            ListaComboMiembroEquipo,
                            ListaMiembros,
                            setListaMiembros
                          )
                        }
                      >
                        +
                      </button>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <MaterialReactTable
                        columns={Repository.GetMColumns()}
                        data={ListaMiembros}
                        enableRowActions
                        enablePagination={false}
                        enableRowSelection
                        enableTopToolbar={false}
                        renderRowActions={({ row, table }) => (
                          <div>
                            <IconButton
                              onClick={() =>
                                Controller.toQuestionEliminarMiembro(
                                  row.original,
                                  ListaMiembros,
                                  setListaMiembros
                                )
                              }
                            >
                              <DeleteIcon />
                            </IconButton>
                          </div>
                        )}
                      />
                      <div className=" row mt-5">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <button
                              className="btn btn-default"
                              onClick={() => Controller.toConsulta(router)}
                            >
                              Cancelar
                            </button>
                            <button
                              className="btn btn-primary"
                              onClick={() => validarCampos()}
                            >
                              Guardar
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
