"use client";

import ContentHeader from "../layout/ContentHeader";
import { useParams, useNavigate } from "react-router-dom";
import * as Controller from "../../../controllers/registroalerta-controller";
import React from "react";
import SelectSimple from "../layout/SelectSimple";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Textbox from "../layout/Textbox";
import Label from "../layout/Label";
import es from "date-fns/locale/es"; // the locale you want
registerLocale("es", es); // register it with the name you want

export default function NuevoRegistroAlerta() {
  const { code } = useParams();
  const Router = useNavigate();
  const [ListaComboUsuario, setListaComboUsuario] = React.useState([]);
  const [ListaComboTienda, setListaComboTienda] = React.useState([]);
  const [codeRegistroAlerta, setCodeRegistroAlerta] = React.useState("");
  const [codeEmpresa, setCodeEmpresa] = React.useState("");
  const [codeUsuario, setCodeUsuario] = React.useState("");
  const [codeTienda, setCodeTienda] = React.useState("");
  const [fechaProgramado, setFechaProgramado] = React.useState(new Date());
  const [notificacion, setNotificacion] = React.useState("");
  const [tituloVentana, setTituloVentana] = React.useState(
    "Nuevo Registro Alerta"
  );
  const [valcodeUsuario, setValCodeUsuario] = React.useState(false);
  const [valcodeTienda, setValCodeTienda] = React.useState(false);
  const [valnotificacion, setValNotificacion] = React.useState(false);
  const [isSubmit, setIsSubmit] = React.useState(false);
  React.useEffect(() => {
    Controller.getListaComboTienda(setListaComboTienda);
    Controller.getListaComboUsuario(setListaComboUsuario);
    if (code != "nuevo") {
      setTituloVentana("Editar Registro Alerta");
      setCodeRegistroAlerta(code!);
    }
  }, []);
  function validarCampos() {
    var Result = true;
    setIsSubmit(true);
    if (notificacion == "" || notificacion == null) {
      Result = false;
      setValNotificacion(false);
    } else {
      setValNotificacion(true);
    }
    if (codeUsuario == "" || codeUsuario == "0" || codeUsuario == null) {
      Result = false;
      setValCodeUsuario(false);
    } else {
      setValCodeUsuario(true);
    }

    if (codeTienda == "" || codeTienda == "0" || codeTienda == null) {
      Result = false;
      setValCodeTienda(false);
    } else {
      setValCodeTienda(true);
    }
    if (Result) {
      Controller.save(
        Router,
        codeRegistroAlerta,
        codeEmpresa,
        codeTienda,
        codeUsuario,
        notificacion,
        fechaProgramado
      );
    } else {
      Controller.Mensaje();
    }
  }
  return (
    <div className="content-wrapper">
      <ContentHeader
        title={tituloVentana}
        homePath={"Home"}
        currentPath={tituloVentana}
      ></ContentHeader>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card card-primary">
                <div className="card-header">
                  <div className="d-flex flex-row gap-2">
                    <div className="align-items-center">
                      <i
                        className="bx bx-arrow-back arrow-back"
                        onClick={() => Controller.toConsulta(Router)}
                      ></i>
                    </div>
                    <div className="">
                      <h4 className="card-title">{tituloVentana}</h4>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-6">
                      <h4>Datos</h4>
                      <hr />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-2">
                      <Label required={true}> Usuarios</Label>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <SelectSimple
                          value={codeUsuario}
                          setValue={setCodeUsuario}
                          valueError={valcodeUsuario}
                          setValueError={setValCodeUsuario}
                          isSubmit={isSubmit}
                          lista={ListaComboUsuario}
                        >
                          --Seleccion--
                        </SelectSimple>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-2">
                      <Label required={true}>Tiendas</Label>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <SelectSimple
                          value={codeTienda}
                          setValue={setCodeTienda}
                          valueError={valcodeTienda}
                          setValueError={setValCodeTienda}
                          isSubmit={isSubmit}
                          lista={ListaComboTienda}
                        >
                          --Seleccion--
                        </SelectSimple>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-1">
                    <div className="col-lg-2">
                      <label>Fecha Programada</label>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <DatePicker
                          locale="es"
                          className="form-control-text"
                          selected={fechaProgramado}
                          onChange={(date: any) => setFechaProgramado(date)}
                          dateFormat={"dd/MM/yyyy"}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-2">
                      <Label required={true}>Notificacion</Label>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <Textbox
                          value={notificacion}
                          valueError={valnotificacion}
                          setValue={setNotificacion}
                          setValueError={setValNotificacion}
                          isSubmit={isSubmit}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mt-5">
                    <div className="col-lg-6">
                      <div className="form-group">
                        <button
                          className="btn btn-default"
                          onClick={() => Controller.toConsulta(Router)}
                        >
                          Cancelar
                        </button>
                        <button
                          className="btn btn-primary"
                          onClick={() => validarCampos()}
                        >
                          Guardar
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
