import { useMemo } from 'react';
import { type MRT_ColumnDef } from 'material-react-table';
import * as ClientAPI from "../utils/methods";
import * as Endpoints from "../api/endpoint";
type Marca = {
  IdMarcaExhibicion: string,
  NombreMarcaExhibicion: string,
};
export function GetColumns() {
  const columns = useMemo<MRT_ColumnDef<Marca>[]>(
    () => [
      {
        accessorKey: 'IdMarcaExhibicion',
        header: 'ID',
        size: 100,
      },
      {
        accessorKey: 'NombreMarcaExhibicion',
        header: 'Nombre Marca Exhibicion',
        size: 400,
      },
    ],
    [],
  );
  ;
  return columns
}
export async function getData(FilterNombreMarcaExhibicion: string) {
  var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.GET_LISTA_MARCAEXHIBICION + "/" + FilterNombreMarcaExhibicion, "")
  if (dataResponse.Data != null) {
    return dataResponse.Data;
  } else {
    return []
  }
}
export async function getByCode(code: string) {
  var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.GET_MARCAEXHIBICION_BYCODE + "/" + code, "")
  return dataResponse.Data;
}
export async function save(objSave: any) {
  var dataResponse = await ClientAPI.RequestPost(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.INSERTAR_MARCAEXHIBICION, "", objSave)
  if (dataResponse != null) {
    return dataResponse;
  } else {
    return null
  }
}
export async function update(objSave: any) {
  var dataResponse = await ClientAPI.RequestPost(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.UPDATE_MARCAEXHIBICION, "", objSave)
  if (dataResponse != null) {
    return dataResponse;
  } else {
    return null
  }
}
export async function inactiva(objSave: any) {
  var dataResponse = await ClientAPI.RequestPost(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.INACTIVO_MARCAEXHIBICION, "", objSave)
  if (dataResponse.Data != null) {
    return dataResponse.Data;
  } else {
    return null
  }
}
export async function activa(objSave: any) {
  var dataResponse = await ClientAPI.RequestPost(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.ACTIVO_MARCAEXHIBICION, "", objSave)
  if (dataResponse.Data != null) {
    return dataResponse.Data;
  } else {
    return null
  }
} 
export async function doExport(FilterNombreMarca: string) {
  var dataResponse = await ClientAPI.RequestDownload(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.GET_LISTA_MARCAEXHIBICION_EXCEL + "/" + FilterNombreMarca, 'DescargarExcelMarcaExhibicion.xlsx')
 
}