import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import ContentHeader from "../layout/ContentHeader";
import ContentBody from "../layout/ContentBody";
import "react-datepicker/dist/react-datepicker.css";
import * as Controller from "../../../controllers/analisisreportefotografico-controller";
import es from "date-fns/locale/es"; // the locale you want
import DatePicker, { registerLocale } from "react-datepicker";
import SelectSimple from "../layout/SelectSimple";
import Label from "../layout/Label";
import moment from "moment";
registerLocale("es", es); // register it with the name you want

export default function ConsultaAnalisisReporteFotografico(): React.JSX.Element {
  const router = useNavigate();
  const [listaComboCadena, setListaComboCadena] = React.useState([]);
  const [listaComboTienda, setListaComboTienda] = React.useState([]);
  const [listaImagenesReporte, setListaImagenesReporte] = React.useState([]);
  const [filterFechaDesde, setFilterFechaDesde] = React.useState(new Date());
  const [filterFechaHasta, setFilterFechaHasta] = React.useState(new Date());
  const [codeCadena, setCodeCadena] = React.useState("");
  const [valcodeCadena, setValCodeCadena] = React.useState(false);
  const [codeTienda, setCodeTienda] = React.useState("");
  const [valcodeTienda, setValCodeTienda] = React.useState(false);
  const [isSubmit, setIsSubmit] = React.useState(false);
  const onchangeCadenaMaster = (e: any) => {
    setCodeCadena(e.target.value);
    Controller.getListaComboTienda(e.target.value, setListaComboTienda);
    setListaComboTienda([]);
  };

  React.useEffect(() => {
    Controller.getListaComboCadenaMaster(setListaComboCadena);
  }, []);

  function onClickBuscar() {
    var Result = true;
    setIsSubmit(true);

    if (codeCadena == "" || codeCadena == "0" || codeCadena == null) {
      Result = false;
      setValCodeCadena(false);
    } else {
      setValCodeCadena(true);
    }

    if (codeTienda == "" || codeTienda == "0" || codeTienda == null) {
      Result = false;
      setValCodeTienda(false);
    } else {
      setValCodeTienda(true);
    }

    if (Result) {
      Controller.getListaImagenProducto(
        setListaImagenesReporte,
        filterFechaDesde,
        filterFechaHasta,
        codeTienda
      );
    } else {
      Controller.Mensaje();
    }
  }
  return (
    <div className="content-wrapper">
      <ContentHeader
        title={"Reporte Fotografico"}
        homePath={"Home"}
        currentPath={"Reporte Fotografico"}
      ></ContentHeader>
      <ContentBody title={"Reporte Fotografico"}>
        <div className="row">
          <div className="col-lg-2">
            <label>Fecha Desde</label>
          </div>
          <div className="col-lg-4">
            <div className="form-group">
              <DatePicker
                locale="es"
                className="form-control-text"
                popperPlacement="top-end"
                selected={filterFechaDesde}
                onChange={(date: any) => setFilterFechaDesde(date)}
                dateFormat={"dd/MM/yyyy"}
              />
            </div>
          </div>
          <div className="col-lg-2">
            <label>Fecha Hasta</label>
          </div>
          <div className="col-lg-4">
            <div className="form-group">
              <DatePicker
                locale="es"
                className="form-control-text"
                popperPlacement="top-end"
                selected={filterFechaHasta}
                onChange={(date: any) => setFilterFechaHasta(date)}
                dateFormat={"dd/MM/yyyy"}
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-2">
            <Label required={true}>Cadena</Label>
          </div>
          <div className="col-lg-4">
            <div className="form-group">
              <SelectSimple
                value={codeCadena}
                setValue={setCodeCadena}
                valueError={valcodeCadena}
                setValueError={setValCodeCadena}
                isSubmit={isSubmit}
                lista={listaComboCadena}
                onChangeSection={onchangeCadenaMaster}
              >
                --SELECCIONE--
              </SelectSimple>
            </div>
          </div>
          <div className="col-lg-2">
            <Label required={true}>Tienda</Label>
          </div>
          <div className="col-lg-4">
            <div className="form-group">
              <SelectSimple
                value={codeTienda}
                setValue={setCodeTienda}
                valueError={valcodeTienda}
                setValueError={setValCodeTienda}
                isSubmit={isSubmit}
                lista={listaComboTienda}
              >
                --SELECCIONE--
              </SelectSimple>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-2"></div>
          <div className="col-lg-4">
            <button className="btn btn-info" onClick={onClickBuscar}>
              Consultar
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            {listaImagenesReporte.map((el: any, key: any) => (
              <div>
                <div className="row mt-4 bg-light">
                  <div className="col-md-2 pt-2">
                    <h3>Fecha:</h3>
                  </div>
                  <div className="col-md-10 pt-2">
                    <h3>{moment(el.Fecha).format("DD/MM/YYYY")}</h3>
                  </div>
                </div>
                <div className="row">
                  {el.Marcas.map((elMarca: any, keyMarca: any) => (
                    <div>
                      <div className="row bg-light">
                        <div className="col-md-2">
                          <h4>Marca:</h4>
                        </div>
                        <div className="col-md-10">
                          <h4>{elMarca.Marca}</h4>
                        </div>
                      </div>
                      <div className="row">
                        {elMarca.Categorias.map(
                          (elCategoria: any, keyCategoria: any) => (
                            <div>
                              <div className="row bg-light">
                                <div className="col-md-2">
                                  <h5>Categoría:</h5>
                                </div>
                                <div className="col-md-10">
                                  <h5>{elCategoria.Categoria}</h5>
                                </div>
                              </div>
                              <div className="row mt-1">
                                {elCategoria.CodeReporte.map(
                                  (elCodeReporte: any, keyCodeReporte: any) => (
                                    <div>
                                      <div className="row mt-3">
                                        <div className="col-md-6">
                                          {elCodeReporte.CodeReporte}
                                        </div>
                                      </div>
                                      <div className="row mt-1">
                                        {elCodeReporte.Items.map(
                                          (elItem: any, keyItem: any) => (
                                            <div className="row mt-1">
                                              <div className="col-lg-6">
                                                <div className="row">
                                                  <div className="col-md-12">
                                                    <label>Foto Antes</label>
                                                  </div>
                                                </div>
                                                <div className="row">
                                                  <div className="col-md-12">
                                                    <img
                                                      src={elItem.UrlFotoAntes}
                                                      className="img-fluid img-thumbnail"
                                                      style={{ maxWidth: 300 }}
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="col-lg-6">
                                                <div className="row">
                                                  <div className="col-md-12">
                                                    <label>Foto Despues</label>
                                                  </div>
                                                </div>
                                                <div className="row">
                                                  <div className="col-md-12">
                                                    <img
                                                      src={
                                                        elItem.UrlFotoDespues
                                                      }
                                                      className="img-fluid img-thumbnail"
                                                      style={{ maxWidth: 300 }}
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          )
                                        )}
                                      </div>
                                    </div>
                                  )
                                )}
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </ContentBody>
    </div>
  );
}
