import { useMemo } from 'react';
import { type MRT_ColumnDef } from 'material-react-table';

import * as ClientAPI from "../utils/methods"; 
import * as Endpoints from "../api/endpoint"; 
type Tienda = {
    IdTienda:string;
    IdEmpresa:string;
    CodigoTienda:string;
    NombreTiendaMaster:string;
    Direccion:string;
    Ubigeo:string;
  
  };
  export function GetColumns(){
    const columns = useMemo<MRT_ColumnDef<Tienda>[]>(
        () => [
          {
            accessorKey: 'IdTiendaMaster',
            header: 'ID',
            size: 50,
          },
               
            {
              accessorKey: 'NombreCadenaMaster',
              header: 'Cadena',
              size: 400,
            },
            { accessorKey: 'CodigoTienda',
              header: 'Codigo Tienda',
              size: 400,
          },
          {
            accessorKey: 'NombreTiendaMaster',
            header: 'Nombre Tienda',
            size: 400,
          },
          {
            accessorKey: 'Direccion',
            header: 'Direccion',
            size: 400,
          },
          {
            accessorKey: 'NombreUbigeo',
            header: 'Ubigeo',
            size: 400,
          },

         
        ],
        [],
    );
    return columns
  }
  export async function getData(FilterCadenaMaster:string,FilterCodigoTienda:string,FilterNombreTiendaMaster:string){
    
FilterCadenaMaster = (FilterCadenaMaster == "" || FilterCadenaMaster == "0")? "-": FilterCadenaMaster
    FilterNombreTiendaMaster = FilterNombreTiendaMaster == "" ? "-" : FilterNombreTiendaMaster
    FilterCodigoTienda = FilterCodigoTienda == "" ? "-" : FilterCodigoTienda
    
    var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_SECURITY + Endpoints.ListEndpoint.GET_LISTA_TIENDAMASTER  + "/" + FilterCadenaMaster + "/" + FilterCodigoTienda + "/" + FilterNombreTiendaMaster, "")
    if(dataResponse.Data != null){
      return dataResponse.Data;
    }else {
      return []
    }
  }
  export async function getByCode(code: string){
    var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_SECURITY + Endpoints.ListEndpoint.GET_TIENDA_AMASTER_BYCODE + "/" + code, "")
    return dataResponse.Data;
  }


  export async function save(objSave: any){
    var dataResponse = await ClientAPI.RequestPost(Endpoints.URL_BASE_SECURITY + Endpoints.ListEndpoint.INSERTAR_TIENDAMASTER, "", objSave)
    if(dataResponse != null){
      return dataResponse;
    }else {
      return null
    }
  }
  export async function update(objSave: any){
    var dataResponse = await ClientAPI.RequestPost(Endpoints.URL_BASE_SECURITY + Endpoints.ListEndpoint.UPDATE_TIENDA_MASTER, "", objSave)
    if(dataResponse != null){
      return dataResponse;
    }else {
      return null
    }
  } 
  export async function inactiva(objSave: any){
    var dataResponse = await ClientAPI.RequestPost(Endpoints.URL_BASE_SECURITY + Endpoints.ListEndpoint.INACTIVO_TIENDA_MASTER, "", objSave)
    if(dataResponse.Data != null){
      return dataResponse;
    }else {
      return null
    }
  } 
  export async function activa(objSave: any){
    var dataResponse = await ClientAPI.RequestPost(Endpoints.URL_BASE_SECURITY + Endpoints.ListEndpoint.ACTIVO_TIENDA_MASTER, "", objSave)
    if(dataResponse.Data != null){
      return dataResponse.Data;
    }else {
      return null
    }
  } 
  export async function getListaComboEmpresa(){
    var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_SECURITY + Endpoints.ListEndpoint.GET_LISTA_COMBO_EMPRESA, "")
    if(dataResponse.Data != null){
      return dataResponse.Data;
    }else {
      return null
    }
  }
  export async function getListaComboCadenaMaster(){
    var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_SECURITY + Endpoints.ListEndpoint.GET_LISTA_COMBO_CADENAMASTER, "")
    if(dataResponse.Data != null){
      return dataResponse.Data;
    }else {
      return null
    }
  }
  
  export async function getListaComboDepartamento(){
    var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.GET_LISTA_COMBO_DEPARTAMENTO, "")
    if(dataResponse.Data != null){
      return dataResponse.Data;
    }else {
      return null
    }
  }


  export async function getListaComboProvincia(setProvincia : string){
    var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.GET_LISTA_COMBO_PROVINCIA + "/" + setProvincia , "")
    if(dataResponse.Data != null){
      return dataResponse.Data;
    }else {
      return null
    }
  }
  export async function getListaComboUbigeo(setUbigeo : string){
    var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.GET_LISTA_COMBO_UBIGEO + "/" + setUbigeo , "")
    if(dataResponse.Data != null){
      return dataResponse.Data;
    }else {
      return null
    }
  }

  export async function doExport(FilterNombreCadenaMaster: string, FilterCodigoTienda : string, FilterNombreTiendaMaster : string) {
    FilterNombreCadenaMaster = FilterNombreCadenaMaster == "" ? "-" : FilterNombreCadenaMaster
    FilterCodigoTienda = FilterCodigoTienda == "" ? "-" : FilterCodigoTienda
    FilterNombreTiendaMaster = FilterNombreTiendaMaster == "" ? "-" : FilterNombreTiendaMaster
  
    var dataResponse = await ClientAPI.RequestDownload(Endpoints.URL_BASE_SECURITY + Endpoints.ListEndpoint.GET_LISTA_TIENDAMASTER_EXCEL + "/" + FilterNombreCadenaMaster + "/" + FilterCodigoTienda + "/" +  FilterNombreTiendaMaster, 'DescargarExcelTienda.xlsx')
  }


  export async function importarTiendas(formDataToUpload: FormData) {
    var dataResponse = await ClientAPI.RequestPostUpload(Endpoints.URL_BASE_SECURITY + Endpoints.ListEndpoint.IMPORTAR_TIENDAS, formDataToUpload)
  
    if (dataResponse != null) {
      return dataResponse;
    } else {
      return null
    }
  }
  export async function doDescargarPlantilla() {
   
  
    var dataResponse = await ClientAPI.RequestDownload(Endpoints.URL_BASE_SECURITY + Endpoints.ListEndpoint. GET_DESCARGAR_TIENDAMASTER_EXCEL , 'PlantillaCargaTiendaExcel.xlsx')
  }