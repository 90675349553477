import { useMemo } from 'react';
import { type MRT_ColumnDef } from 'material-react-table';
import * as ClientAPI from "../utils/methods";
import * as Endpoints from "../api/endpoint";
type Empresa = {
  IdEmpresa: string;
  NombreEmpresa: string;
  TipoDocumentoIdentidad: string;
  NumeroDocumentoIndentida: string;
};

export function GetColumns() {
  const columns = useMemo<MRT_ColumnDef<Empresa>[]>(
    () => [
      {
        accessorKey: 'IdEmpresa',
        header: 'ID',
        size: 20,
      },
      {
        accessorKey: 'NombreEmpresa',
        header: 'Empresa',
        size: 200,
      },
      {
        accessorKey: 'NombreTipoDocumentoIdentidad',
        header: 'Tipo Documento',
        size: 200,
      },
      {
        accessorKey: 'NumeroDocumentoIdentidad',
        header: 'Documento Indentida',
        size: 100,
      },

     
    ],
    [],
  );
  return columns
}
export async function getData(FilterTipoDocumentoIdentidad: number, FilterNumeroDocumentoIdentidad: string, FilterNombreEmpresa: string) {

  FilterNumeroDocumentoIdentidad = FilterNumeroDocumentoIdentidad == "" ? "-" : FilterNumeroDocumentoIdentidad
  FilterNombreEmpresa = FilterNombreEmpresa == "" ? "-" : FilterNombreEmpresa

  var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_SECURITY + Endpoints.ListEndpoint.GET_LISTA_EMPRESA + "/" + FilterTipoDocumentoIdentidad + "/" + FilterNumeroDocumentoIdentidad + "/" + FilterNombreEmpresa, "")
  if (dataResponse.Data != null) {
    return dataResponse.Data;
  } else {
    return []
  }
}
export async function getByCode(code: string) {
  var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_SECURITY + Endpoints.ListEndpoint.GET_EMPRESA_BYCODE + "/" + code, "")
  return dataResponse.Data;
}
export async function save(objSave: any) {
  var dataResponse = await ClientAPI.RequestPost(Endpoints.URL_BASE_SECURITY + Endpoints.ListEndpoint.INSERTAR_EMPRESA, "", objSave)
  if (dataResponse.Data != null) {
    return dataResponse.Data;
  } else {
    return []
  }
}
export async function update(objSave: any) {
  var dataResponse = await ClientAPI.RequestPost(Endpoints.URL_BASE_SECURITY + Endpoints.ListEndpoint.UPDATE_EMPRESA, "", objSave)
  if (dataResponse.Data != null) {
    return dataResponse.Data;
  } else {
    return null
  }
}
export async function inactiva(objSave: any) {
  var dataResponse = await ClientAPI.RequestPost(Endpoints.URL_BASE_SECURITY + Endpoints.ListEndpoint.INACTIVO_EMPRESA, "", objSave)
  if (dataResponse.Data != null) {
    return dataResponse.Data;
  } else {
    return null
  }
}
export async function activa(objSave: any) {
  var dataResponse = await ClientAPI.RequestPost(Endpoints.URL_BASE_SECURITY + Endpoints.ListEndpoint.ACTIVO_EMPRESA, "", objSave)
  if (dataResponse.Data != null) {
    return dataResponse.Data;
  } else {
    return null
  }
}
export async function getListaComboTipoDocumento() {
  var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.GET_LISTA_COMBO_TIPODOCUMENTO, "")
  if (dataResponse.Data != null) {
    return dataResponse.Data;
  } else {
    return null
  }
}

export async function uploadFile(formDataToUpload: FormData) {
  var dataResponse = await ClientAPI.RequestPostUpload(Endpoints.URL_BASE_FILE + Endpoints.ListEndpoint.UPLOAD_FILE, formDataToUpload)

  if (dataResponse != null) {
    return dataResponse;
  } else {
    return null
  }
}


export async function doExport(FilterNombreEmpresa: string, FilterTipoDocumentoIdentidad: number, FilterNumeroDocumentoIdentidad: string ) {
  FilterNombreEmpresa = FilterNombreEmpresa == "" ? "-" : FilterNombreEmpresa
  FilterNumeroDocumentoIdentidad = FilterNumeroDocumentoIdentidad == "" ? "-" : FilterNumeroDocumentoIdentidad
 

  var dataResponse = await ClientAPI.RequestDownload(Endpoints.URL_BASE_SECURITY + Endpoints.ListEndpoint.GET_LISTA_EMPRESA_EXCEL + "/" + FilterTipoDocumentoIdentidad + "/" + FilterNumeroDocumentoIdentidad + "/" + FilterNombreEmpresa, 'DescargarExcelEmpresa.xlsx')
  
}