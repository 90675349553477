import { useMemo } from 'react';
import { type MRT_ColumnDef } from 'material-react-table';
import * as ClientAPI from "../utils/methods";
import * as Endpoints from "../api/endpoint";
type Documento = {
  IdCategoria: string,
  NombreCategoria: string,
};
export function GetColumns() {
  const columns = useMemo<MRT_ColumnDef<Documento>[]>(
    () => [
      {
        accessorKey: 'IdDocumento',
        header: 'ID',
        size: 20,
      },
      {
        accessorKey: 'NombreDocumento',
        header: 'Nombre Documento',
        size: 300,
      },
      {
        accessorKey: 'NombreArchivo',
        header: 'Tipo Archivo',
        size: 600,
      },
    ],
    [],
  );
  ;
  return columns
}
type Cadena = {
  CodeCadena: string,
  NombreCadena: string,

};
export async function getData(FilterIdTipoArchivo: number ,FilterNombreDocumento: string) {
  
  FilterNombreDocumento = FilterNombreDocumento == "" ? "-" : FilterNombreDocumento

  var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_SECURITY + Endpoints.ListEndpoint.GET_LISTA_DOCUMENTO + "/" + FilterIdTipoArchivo + "/" + FilterNombreDocumento, "")
  if (dataResponse.Data != null) {
    return dataResponse.Data;
  } else {
    return []
  }
}
export async function getByCode(code: string) {
  var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_SECURITY + Endpoints.ListEndpoint.GET_DOCUMENTO_BYCODE + "/" + code, "")
  return dataResponse.Data;
}
export async function save(objSave: any) {
  var dataResponse = await ClientAPI.RequestPost(Endpoints.URL_BASE_SECURITY + Endpoints.ListEndpoint.INSERTAR_DOCUMENTO, "", objSave)
  if (dataResponse.Data != null) {
    return dataResponse.Data;
  } else {
    return []
  }
}
export async function saveCD(objSave: any) {
  var dataResponse = await ClientAPI.RequestPost(Endpoints.URL_BASE_SECURITY + Endpoints.ListEndpoint.GUARDAR_CADENADOCUMENTO, "", objSave)
  if (dataResponse.Data != null) {
    return dataResponse.Data;
  } else {
    return []
  }
}
export async function update(objSave: any) {
  var dataResponse = await ClientAPI.RequestPost(Endpoints.URL_BASE_SECURITY + Endpoints.ListEndpoint.UPDATE_DOCUMENTO, "", objSave)
  if (dataResponse.Data != null) {
    return dataResponse.Data;
  } else {
    return null
  }
}
export async function inactiva(objSave: any) {
  var dataResponse = await ClientAPI.RequestPost(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.INACTIVO_DOCUMENTO, "", objSave)
  if (dataResponse.Data != null) {
    return dataResponse.Data;
  } else {
    return null
  }
}
export async function activa(objSave: any) {
  var dataResponse = await ClientAPI.RequestPost(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.ACTIVO_DOCUMENTO, "", objSave)
  if (dataResponse.Data != null) {
    return dataResponse.Data;
  } else {
    return null
  }
}
export async function getListaComboTipoArchivo() {
  var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.GET_LISTA_COMBO_TIPOARCHIVO, "")
  if (dataResponse.Data != null) {
    return dataResponse.Data;
  } else {
    return null
  }
} 
export async function uploadFile(formDataToUpload: FormData) {
  var dataResponse = await ClientAPI.RequestPostUpload(Endpoints.URL_BASE_FILE + Endpoints.ListEndpoint.UPLOAD_FILE, formDataToUpload)

  if (dataResponse != null) {
    return dataResponse;
  } else {
    return null
  }
}
export async function doExport(  FilterIdTipoArchivo: number, FilterNombreDocumento : string ) {
  FilterNombreDocumento = FilterNombreDocumento == "" ? "-" : FilterNombreDocumento
  var dataResponse = await ClientAPI.RequestDownload(Endpoints.URL_BASE_SECURITY + Endpoints.ListEndpoint.GET_LISTA_DOCUMENTO_EXCEL  +  "/" + FilterIdTipoArchivo + "/" + FilterNombreDocumento, 'DescargarExcelDocumento.xlsx')
  
}