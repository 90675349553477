import clsx from "clsx";

export default function Textbox({
    children,
    required,
    className
}: {
    children: React.ReactNode;
    required?: boolean;
    className?: any;
}) {


    return (
        <label className={className}>
            {children} {required ? <span style={{color: 'red'}}> (*) </span> : <></>}
        </label>
    )
}
