import * as Repository from "../repositories/analisisexhibidores-repository";
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';

export async function getListaComboTipoExhibicion(setListaComboTipoExhibicion: any, ) {
    var dataResponse = await Repository.getListaComboTipoExhibicion()
    setListaComboTipoExhibicion(dataResponse)
}

export async function getListaComboMarcaExhibicion( setListaComboMarcaExhibicion: any) {

        var dataResponse = await Repository.getListaComboMarcaExhibicion()
        setListaComboMarcaExhibicion(dataResponse)
   
}
export async function getListaImagenExhibicion(setListaImagenesExhibicion: any, FilterFecha : Date,codeTienda :string,  codeMarcaExhibicion : string , codeTipoExhibicion : string  ) {
    var dataResponse = await Repository.geListaImagenProducto(FilterFecha,codeTienda, codeMarcaExhibicion, codeTipoExhibicion)

    console.log(dataResponse)
    if (dataResponse != null) {
        setListaImagenesExhibicion(dataResponse)
    }
}
export async function Mensaje() {
    const MySwal = withReactContent(Swal)
    var message = "Tiene que completar los campos"
    MySwal.fire({
        title: <p>{message}</p>,
        icon: "warning",
        showConfirmButton: true,
        confirmButtonText: "Aceptar",
    }
    )
}
export async function getListaComboTienda(codeCadenaMaster: string, setListaComboTienda: any) {
    if (codeCadenaMaster != "-" && codeCadenaMaster != '0') {
        var dataResponse = await Repository.getListaComboTienda(codeCadenaMaster)
        setListaComboTienda(dataResponse)
    } else {
        setListaComboTienda(dataResponse)
    }
}
export async function getListaComboCadenaMaster(
    setListaComboCadenaMaster: any
  ) {
    var dataResponse = await Repository.getListaComboCadenaMaster();
    setListaComboCadenaMaster(dataResponse);
  }

  