

import * as Repository from '../repositories/tiendamaster-repository';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { NavigateFunction } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';    
import 'react-toastify/dist/ReactToastify.css';

import {
    CODE_SERVER_RETURN,
    UPLOAD_FILES,
    UPLOAD_FILESALTASUNAT,
    UPLOAD_FILESCONSTANCIA,
    UPLOAD_FILESCONTRATO,
    UPLOAD_FILESCS,
    UPLOAD_FILESDNI,
  } from "../utils/contants";

export function toNuevo(router: NavigateFunction) {
    router('/main/tiendamaster/mantenimiento/nuevo')
}
export function toConsulta(router: NavigateFunction) {
    router('/main/tiendamaster/consulta')
}
export async function doConsultar(setListaConsulta: any, FilterCadenaMaster: string, FilterCodigoTienda: string, FilterNombreTiendaMaster: string) {
    var dataResponse = await Repository.getData(FilterCadenaMaster, FilterCodigoTienda, FilterNombreTiendaMaster)
    setListaConsulta(dataResponse)
}
export async function toEditar(router: NavigateFunction,row: any) {
    var codeEditar = row.CodeTiendaMaster
    router('/main/tiendamaster/mantenimiento/' + codeEditar)
}
export async function toQuestionInactivaActiva(row: any, setListaConsulta: any) {
    const MySwal = withReactContent(Swal)
    var question = row.Estado == 1 ? "¿Desea eliminar el registro?" : "¿Desea activar el registro?"
    MySwal.fire({
        title: <p>{question}</p>,
        icon: "question",
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: "Sí",
        cancelButtonText: "Cancelar"
    }).then((result: any) => {
        if (result.isConfirmed) {
            toInactivaActiva(row, setListaConsulta)
        }
    })
}
async function toInactivaActiva(row: any, setListaConsulta: any) {
    var codeEliminar = row.CodeTiendaMaster
    const objSave = {
        CodeTiendaMaster: codeEliminar
    }
    if (row.Estado == 1) {
        var dataResponse = await Repository.inactiva(objSave)
    } else if (row.Estado == 0) {
        var dataResponse = await Repository.activa(objSave)
    }
    doConsultar(setListaConsulta, "0", "", "")
}
export async function getByCode(code: any, setProvincia: any, setListaConboProvincia: any, setUbigeo: any, setListaComboUbigeo: any, setDepartamento: any, setCodeCadenaMaster: any, setCodigoTienda: any, setNombreTiendaMaster: any, setDireccion: any, setLatitud : any, setLongitud : any) {
    var dataResponse = await Repository.getByCode(code)
    if (dataResponse != null) {

        setUbigeo(dataResponse.IdUbigeo)
        setProvincia(dataResponse.IdProvincia)
        setDepartamento(dataResponse.IdDepartamento)
        setCodigoTienda(dataResponse.CodigoTienda)
        setCodeCadenaMaster(dataResponse.CodeCadenaMaster)
        setNombreTiendaMaster(dataResponse.NombreTiendaMaster)
        setDireccion(dataResponse.Direccion)
        setLatitud(dataResponse.Latitud)
        setLongitud(dataResponse.Longitud)
        getListaComboProvincia(dataResponse.IdDepartamento, setListaConboProvincia)
        getListaComboUbigeo(dataResponse.IdProvincia, setListaComboUbigeo)

    }
}

export async function save(router: NavigateFunction, codeTiendaMaster: string, codeCadenaMaster: string, codigoTienda: string, nombreTiendaMaster: string, direccion: string, ubigeo: string, latitud: number, longitud: number) {
    const MySwal = withReactContent(Swal)
    var question = "¿Desea guardar el registro?"
    MySwal.fire({
        title: <p>{question}</p>,
        icon: "question",
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: "Sí",
        cancelButtonText: "Cancelar"
    }).then(async (result: any) => {
        if (result.isConfirmed) {
            console.log("Code a actualizar: " + codeTiendaMaster)
            if (codeTiendaMaster != null && codeTiendaMaster != "") {
                const objSave = {
                    CodeTiendaMaster: codeTiendaMaster,
                    CodeCadenaMaster: codeCadenaMaster,
                    CodigoTienda: codigoTienda,
                    NombreTiendaMaster: nombreTiendaMaster,
                    Direccion: direccion,
                    IdUbigeo: ubigeo,
                    Latitud: latitud,
                    Longitud: longitud,

                }
                var dataResponse = await Repository.update(objSave)
                if (dataResponse.Code == CODE_SERVER_RETURN.VAL) {
                    MySwal.fire({
                        title: <p>{dataResponse.Message}</p>,
                        icon: "warning",
                        showConfirmButton: true,
                        showCancelButton: false,
                        confirmButtonText: "OK",
                        
                    });

                } else if(dataResponse.Code == CODE_SERVER_RETURN.ERROR) {
                    MySwal.fire({
                        title: <p>{dataResponse.Message}</p>,
                        icon: "error",
                        showConfirmButton: true,
                        showCancelButton: false,
                        confirmButtonText: "OK",
                        
                    });
                } else {
                    toast.success('Guardado satisfactoriamente!')
                    toConsulta(router)
                  
                }
            } else {
                const objSave = {
                    CodeCadenaMaster: codeCadenaMaster,
                    CodigoTienda: codigoTienda,
                    NombreTiendaMaster: nombreTiendaMaster,
                    Direccion: direccion,
                    IdUbigeo: ubigeo,
                    Latitud: latitud,
                    Longitud: longitud,

                }
                var dataResponse = await Repository.save(objSave)

                if (dataResponse.Code == CODE_SERVER_RETURN.VAL) {
                    MySwal.fire({
                        title: <p>{dataResponse.Message}</p>,
                        icon: "warning",
                        showConfirmButton: true,
                        showCancelButton: false,
                        confirmButtonText: "OK",
                        
                    });

                } else if(dataResponse.Code == CODE_SERVER_RETURN.ERROR) {
                    MySwal.fire({
                        title: <p>{dataResponse.Message}</p>,
                        icon: "error",
                        showConfirmButton: true,
                        showCancelButton: false,
                        confirmButtonText: "OK",
                        
                    });
                }else {
                    toast.success('Guardado satisfactoriamente!')
                    toConsulta(router)
                }
            }
        }
    })
}
export async function Mensaje() {
    const MySwal = withReactContent(Swal)
    var message = "Tiene que completar los campos"
    MySwal.fire({
        title: <p>{message}</p>,
        icon: "warning",
        showConfirmButton: true,
        confirmButtonText: "Aceptar",
    }
    )
}
export async function getListaComboCadenaMaster(setListaComboCadenaMaster: any) {
    var dataResponse = await Repository.getListaComboCadenaMaster()
    setListaComboCadenaMaster(dataResponse)
}
export async function getListaComboDepartamento(setListaComboDepartamento: any) {
    var dataResponse = await Repository.getListaComboDepartamento()
    setListaComboDepartamento(dataResponse)
}
export async function getListaComboProvincia(departamento: string, setListaComboProvincia: any) {

    if (departamento != "-" && departamento != '0') {
        var dataResponse = await Repository.getListaComboProvincia(departamento)
        setListaComboProvincia(dataResponse)
    } else {
        setListaComboProvincia(dataResponse)
    }
}
export async function getListaComboUbigeo(ubigeo: string, setListaComboUbigeo: any) {

    if (ubigeo != "-" && ubigeo != '0') {
        var dataResponse = await Repository.getListaComboUbigeo(ubigeo)
        setListaComboUbigeo(dataResponse)
    } else {
        setListaComboUbigeo(dataResponse)

    }
}

export async function doExportar(FilterNombreCadenaMaster: string, FilterCodigoTienda: string, FilterNombreTiendaMaster: string) {
    Repository.doExport(FilterNombreCadenaMaster, FilterCodigoTienda, FilterNombreTiendaMaster)
}
export async function importarTiendas(
    router: NavigateFunction,
    fileImportar: any
  ) {
    if (fileImportar != null) {
      const formDataToUpload = new FormData();
      formDataToUpload.append("FileExcel", fileImportar);
  
      var dataResponseURL = await Repository.importarTiendas(formDataToUpload);
      if (dataResponseURL != null && dataResponseURL.Code === 0) {
        toConsulta(router);
      }
    }
  }
  export async function doDescargarPlantilla() {
    Repository.doDescargarPlantilla()
}