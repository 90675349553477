//export const URL_BASE_ADMIN = "https://localhost:44325/api/";

export const URL_BASE_ADMIN = "https://apiservices.panto-group.com:81/api/";
//export const URL_BASE_SECURITY = "http://localhost/api/";
export const URL_BASE_SECURITY = "https://apiservices.panto-group.com/api/";
//export const URL_BASE_FILE = "http://localhost:5242/api/";
export const URL_BASE_FILE = "https://apiservices.panto-group.com:83/api/";
export const API_KEY = "B5FF40FF-5527-4053-B9BC-D6B81F70E635";
//export const TOKEN = "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1bmlxdWVfbmFtZSI6Im9saWJlcnQiLCJuYW1laWQiOiJmYWJhYjk0NC04MWI4LTQyZGUtOWVhMS01MTgwYmE2N2I1OTMiLCJnaXZlbl9uYW1lIjoib2xpYmVydCBnYXJjaWEiLCJqdGkiOiI3MGM3MGI2MC0yMGJlLTQ1YjktOGRiMy1kOTBhOTMzMDVmODgiLCJJZFVzdWFyaW8iOiIxMyIsIm5iZiI6MTcwMTI5NTYwOSwiZXhwIjoxNzAxMzgyMDA5LCJpYXQiOjE3MDEyOTU2MDl9.mGtwMtRpawbKLAfjCVdW2b-GwebO8_-GQaE9R87Q7VQW_jisMD2pjeQZvR3YMgmALBUbCCIhwR2mJfNjNX8YdfN3kTuDPoWq6xOqYHonoiq5nGQj3Jp02Fvhfj-josGwfYQ3W7FGDtFVfTPtXo-kSjVbX8akRnoXfYooMqBt6EM3ImTiauJyXmTRc6HMwJSrD3TFgBMmTZswCN7z2wkcvdTyayGlan35hOOgDAUFSBqnnXPe9MWd-MG5HTFgFljO7QMfmqAaTSd-VdqxxfAB5ckJ9lTgMiDZM_dm56_BBO7e4SxAcZH94dzcPELjOfkJx9mFnhW6kR4jQ4p2rg__yA";

export const ListEndpoint = {
  LOGIN: "auth/login",

  // DOCUMENTOS
  INACTIVO_DOCUMENTO: "documento/Inactivodocumento",
  ACTIVO_DOCUMENTO: "documento/Activodocumento",
  GET_LISTA_COMBO_DOCUMENTO: "documento/listaCombodocumento",
  INSERTAR_DOCUMENTO: "documento/insertardocumento",
  UPDATE_DOCUMENTO: "documento/updatedocumento",
  GET_DOCUMENTO_BYCODE: "documento/getdocumentobycode",
  GET_LISTA_DOCUMENTO: "documento/listadocumento",
  GET_LISTA_DOCUMENTO_EXCEL: "documento/listadocumentoexcel",

  // TABLAS TABLAS
  GET_LISTA_COMBO_TAREAS: "tablatablas/listaComboTareas",
  GET_LISTA_COMBO_TIPOGRUPO: "tablatablas/listaComboTipoGrupo",
  GET_LISTA_COMBO_TIPOAREA: "tablatablas/listaComboTipoArea",
  GET_LISTA_COMBO_TIPODOCUMENTO: "tablatablas/listaComboTipoDocumentoIdentidad",
  GET_LISTA_COMBO_TIPOARCHIVO: "tablatablas/listaComboTipoArchivo",
  GET_LISTA_COMBO_TIPOINCIDENCIA: "tablatablas/listaComboTipoIncidencia",
  GET_LISTA_COMBO_TIPOSUPERVISION: "tablatablas/listaComboTipoSupervision",
  GET_LISTA_COMBO_EMPRESAASEGURADORA:
    "tablatablas/listacomboempresaaseguradora",
  GET_LISTA_COMBO_DIASSEMANA: "tablatablas/listacombodiassemana",

  //LINEA PRODUCTO
  INACTIVO_LINEAPRODUCTO: "lineaproducto/inactivolineaproducto",
  ACTIVO_LINEAPRODUCTO: "lineaproducto/activolineaproducto",
  UPDATE_LINEAPRODUCTO: "lineaproducto/updatelineaproducto",
  INSERTAR_LINEAPRODUCTO: "lineaproducto/insertarlineaproducto",
  GET_LISTA_LINEAPRODUCTO: "lineaproducto/listalineaproducto",
  GET_LINEAPRODUCTO_BYCODE: "lineaproducto/getlineaproductobycode",
  GET_LISTA_COMBO_LINEAPRODUCTO: "lineaproducto/listaComboLineaProducto",

  // PERFIL
  GET_LISTA_COMBO_PERFIL: "tablatablas/listaComboPerfil",
  //STOCK_TIENDA
  GET_LISTA_STOCKTIENDA: "stocktienda/listastocktienda",
  GET_LISTA_STOCKTIENDA_EXCEL: "stocktienda/listastocktiendaexcel",

  //PREGUNTA
  INSERTAR_PREGUNTA: "pregunta/insertarpregunta",
  GET_PREGUNTABYCODE: "pregunta/getpreguntabycode",
  UPDATE_PREGUNTA: "Pregunta/updatePregunta",
  INACTIVO_PREGUNTA: "Pregunta/inactivoPregunta",
  ACTIVO_PREGUNTA: "Pregunta/activoPregunta",
  GET_LISTA_PREGUNTA: "pregunta/listapregunta",
  GET_LISTA_PREGUNTA_EXCEL: "pregunta/listapreguntaexcel",

  //MARCA
  INSERTAR_MARCA: "marca/insertarmarca",
  GET_LISTA_MARCA: "marca/listamarca",
  UPDATE_MARCA: "marca/updatemarca",
  INACTIVO_MARCA: "marca/inactivomarca",
  ACTIVO_MARCA: "marca/activomarca",
  GET_LISTA_MARCA_EXCEL: "marca/listamarcaexcel",
  GET_MARCA_BYCODE: "marca/getmarcabycode",
  GET_LISTA_COMBO_MARCA: "marca/listaComboMarca",

  //Marca Exhibicion
  UPDATE_MARCAEXHIBICION: "marcaexhibicion/updatemarcaexhibicion",
  INSERTAR_MARCAEXHIBICION: "marcaexhibicion/insertarmarcaexhibicion",
  GET_MARCAEXHIBICION_BYCODE: "marcaexhibicion/getmarcaexhibicionbycode",
  GET_LISTA_COMBO_MARCAEXHIBICION:
    "marcaexhibicion/listadocombomarcaexhibicion",
  GET_LISTA_MARCAEXHIBICION: "marcaexhibicion/listamarcaexhibicion",
  INACTIVO_MARCAEXHIBICION: "marcaexhibicion/inactivomarcaexhibicion",
  ACTIVO_MARCAEXHIBICION: "marcaexhibicion/activomarcaexhibicion",
  GET_LISTA_MARCAEXHIBICION_EXCEL: "marcaexhibicion/listamarcaexhibicionexcel",
  //CLIENTE
  INSERTAR_CLIENTE: "cliente/insertarcliente",
  GET_LISTA_CLIENTE: "cliente/listacliente",
  GET_CLIENTE_BYCODE: "cliente/getclientebycode",
  UPDATE_CLIENTE: "cliente/updatecliente",
  ACTIVO_CLIENTE: "cliente/activocliente",
  INACTIVO_CLIENTE: "cliente/inactivocliente",
  GET_LISTA_CLIENTE_EXCEL: "cliente/listaclienteexcel",
  GET_LISTA_COMBO_CLIENTE: "cliente/listacombocliente",

  //PRODUCTO
  INSERTAR_PRODUCTO: "producto/insertarproducto",
  INACTIVO_PRODUCTO: "producto/inactivoProducto",
  ACTIVO_PRODUCTO: "producto/activoProducto",
  GET_PRODUCTO_BYCODE: "producto/getproductobycode",
  UPDATE_PRODUCTO: "producto/updateproducto",
  GET_LISTA_PRODUCTO: "producto/listaproducto",
  GET_LISTA_PRODUCTO_EXCEL: "producto/listaproductoexcel",
  GUARDAR_TIENDAPRODUCTO: "producto/guardarproductotienda",
  GET_LISTA_PRODUCTO_TIENDA_BYCODEPRODUCTO:
    "producto/GetListaProductoTiendaByCodeProducto",
  GET_LISTA_COMBOPRODUCTO: "producto/listacomboproducto",
  //<<<<<<< HEAD
  IMPORTAR_PRODUCTO: "producto/importarproducto",
  //=======
  //IMPORTAR_PRODUCTO :"producto/importarproducto",
  //>>>>>>> b453d708783ad9371597b7fbbff9e386be932e89
  GET_CARGA_PRODUCTO_EXCEL: "producto/plantillaproductoexcel",

  // Tienda
  INACTIVO_TIENDA: "tienda/inactivoTienda",
  ACTIVO_TIENDA: "tienda/activoTienda",
  UPDATE_TIENDA: "tienda/updatetienda",
  GET_LISTA_TIENDA: "tienda/listatienda",
  GET_LISTA_TIENDA_EXCEL: "tienda/listatiendaexcel",
  GET_LISTA_COMBO_TIENDA: "tienda/listaComboTienda",

  GET_TIENDA_BYCODE: "tienda/gettiendabycode",

  //Equipo Trabajo
  INACTIVO_EQUIPOTRABAJO: "equipotrabajo/Inactivoequipotrabajo",
  ACTIVO_EQUIPOTRABAJO: "equipotrabajo/Activoequipotrabajo",
  INSERTAR_EQUIPOTRABAJO: "equipotrabajo/insertarequipotrabajo",
  GET_LISTA_EQUIPOTRABAJO: "equipotrabajo/listaequipotrabajo",
  GET_LISTA_EQUIPOTRABAJO_EXCEL: "equipotrabajo/listaequipotrabajoexcel",
  UPDATE_EQUIPOTRABAJO: "equipotrabajo/updateequipotrabajo",
  GET_EQUIPOTRABAJO_BYCODE: "equipotrabajo/getequipotrabajobycode",

  // Categoria
  GET_LISTA_CATEGORIA: "categoria/listaCategoria",
  GET_CATEGORIA_BYCODE: "categoria/getcategoriabycode",
  INSERTAR_CATEGORIA: "categoria/insertarcategoria",
  UPDATE_CATEGORIA: "categoria/updatecategoria",
  INACTIVO_CATEGORIA: "Categoria/InactivoCategoria",
  ACTIVO_CATEGORIA: "Categoria/ActivoCategoria",
  GET_LISTA_COMBO_CATEGORIA: "categoria/listaComboCategoria",
  GET_LISTA_COMBO_CATEGORIAS: "categoria/listaComboCategorias",
  GET_LISTA_CATEGORIA_EXCEL: "categoria/listacategoriaexcel",

  //Sub Categoria
  GET_LISTA_SUB_CATEGORIA: "subcategoria/listasubcategoria",
  INSERTAR_SUB_CATEGORIA: "subcategoria/insertarsubcategoria",
  UPDATE_SUB_CATEGORIA: "subcategoria/updatesubcategoria",
  GET_SUB_CATEGORIA_BYCODE: "subcategoria/getsubcategoriabycode",
  GET_LISTA_COMBO_SUB_CATEGORIA: "subcategoria/listacombosubcategoria",
  GET_LISTA_SUB_CATEGORIA_EXCEL: "subcategoria/listasubcategoriaexcel",
  INACTIVO_SUBCATEGORIA: "SubCategoria/InactivoSubCategoria",
  ACTIVO_SUBCATEGORIA: "SubCategoria/ActivoSubCategoria",
  GET_LISTA_COMBO_SUBCATEGORIA: "subcategoria/listacombosubcategorias",

  // Insertar Tienda (PANTOCORE)
  INSERTAR_TIENDA: "tienda/insertartienda",

  // Tienda Master (SECURITY)
  GET_LISTA_TIENDAMASTER: "tiendamaster/listatiendamaster",
  GET_LISTA_TIENDAMASTER_EXCEL: "tiendamaster/listatiendamasterexcel",
  INSERTAR_TIENDAMASTER: "tiendamaster/insertartiendamaster",
  UPDATE_TIENDA_MASTER: "tiendamaster/updatetiendamaster",
  ACTIVO_TIENDA_MASTER: "tiendamaster/activotiendamaster",
  INACTIVO_TIENDA_MASTER: "tiendamaster/inactivotiendamaster",
  GET_LISTA_COMBO_TIENDAMASTER: "tiendamaster/listacombotiendamaster",
  GET_TIENDA_AMASTER_BYCODE: "tiendamaster/gettiendamasterbycode",
  GET_LISTA_COMBO_CADENA_MASTER: "tiendamaster/listacombotiendamaster",
  IMPORTAR_TIENDAS: "tiendamaster/importartiendas",
  GET_DESCARGAR_TIENDAMASTER_EXCEL: "tiendamaster/plantillatiendamasterexcel",

  // CADENA
  UPDATE_CADENA: "cadena/updateCadena",
  INACTIVO_CADENA: "cadena/inactivoCadena",
  ACTIVO_CADENA: "cadena/activoCadena",
  GET_LISTA_CADENA: "cadena/listacadena",
  INSERTAR_CADENA: "cadena/insertarcadena",
  GET_LISTA_COMBO_CADENA: "cadena/listaComboCadena",
  GET_CADENA_BYCODE: "cadena/getCadenaByCode",
  GUARDAR_CADENADOCUMENTO: "cadena/guardarcadenadocumento",
  GET_LISTA_CADENA_DOCUMENTO_BYCODEDOCUMENTO:
    "cadena/GetListaCadenaDocumentoByCodeCadena",

  // Cadena Master(SECURITY)
  GET_LISTA_CADENAMASTER: "cadenamaster/listacadenamaster",
  GET_LISTA_CADENAMASTER_EXCEL: "cadenamaster/listacadenamasterexcel",
  INSERTAR_CADENAMASTER: "cadenamaster/insertarcadenamaster",
  GET_LISTA_COMBO_CADENAMASTER: "cadenamaster/listacombocadenamaster",
  GET_CADENAMASTER_BYCODE: "cadenamaster/getCadenaMasterByCode",
  ACTIVO_CADENA_MASTER: "cadenamaster/activocadenamaster",
  INACTIVO_CADENA_MASTER: "cadenamaster/inactivocadenamaster",
  UPDATE_CADENA_MASTER: "cadenamaster/updatecadenamaster",
  GUARDAR_CADENAMASTERDOCUMENTO: "cadenamaster/guardardocumentocadenamaster",
  GET_LISTA_CADENAMASTER_DOCUMENTO_BYCODEDOCUMENTO:
    "cadenamaster/getlistacadenamasterbydocumento",

  // Usuario
  GET_LISTA_USUARIO: "usuario/listausuario",
  GET_USUARIO_INFO: "usuario/getusuarioinfo",
  GET_LISTA_COMBO_SUPERVISOR: "usuario/listaComboUsuariosupervisor",
  GET_LISTA_COMBO_MIEMBROEQUIPO:
    "usuario/listacombousuariovendedorymecaderista",
  GET_LISTA_USUARIO_EXCEL: "usuario/listausuarioexcel",
  GET_LISTA_COMBO_USUARIO: "usuario/listaComboUsuario",
  INACTIVO_USUARIO: "usuario/inactivousuario",
  ACTIVO_USUARIO: "usuario/activousuario",
  GET_USUARIO_BYCODE: "usuario/getusuariobycode",
  UPDATE_USUARIO: "usuario/updateusuario",
  INSERTAR_USUARIO: "usuario/insertarusuario",
  GET_LISTA_COMBO_CARGOPERSONA: "usuario/listaCombocargopersona",
  GET_LISTA_COMBO_MARCAREPRESENTA: "usuario/listaCombomarcarepresenta",
  GET_USUARIOPERSONA: "usuario/combousuariopersona",
  IMPORTAR_USUARIO: "usuario/importarusuarios",
  GET_CARGAPLANTILLA_USUARIO_EXCEL: "usuario/plantillausuarioexcel",

  // Programacion Usuario
  INSERTAR_PROGRAMACIONUSUARIO:
    "programacionusuario/insertarprogramacionusuario",
  UPDATE_PROGRAMACION_USUARIO: "programacionusuario/updateprogramacionusuario",
  GET_PROGRAMACION_USUARIO_BYCODE:
    "programacionusuario/getprogramacionusuariobycode",
  ACTIVO_PROGRAMACION_USUARIO: "programacionusuario/activoprogramacionusuario",
  INACTIVO_PROGRAMACION_USUARIO:
    "programacionusuario/inactivoprogramacionusuario",
  GET_LISTA_COMBO_SEMANA: "semana/listacombosemana",
  GET_LISTA_PROGRAMACIONUSUARIO: "programacionusuario/listaprogramacionusuario",
  GET_LISTA_COMBO_PROGRAMACIONUSUARIO:
    "usuario/listacombousuariovendedorymecaderistaysupervisor",
  GET_LISTA_PROGRAMACIONUSUARIO_EXCEL:
    "programacionusuario/listaprogramacionusuarioexcel",
  IMPORTAR_PROGRAMACION_USUARIO: "programacionusuario/importarprogramacionusuario",
  GET_CARGAPLANTILLA_PROGRAMACION_USUARIO_EXCEL: "programacionusuario/plantillaprogramacionusuario",
  // Registro Alerta
  INSERTAR_REGISTROALERTA: "registroalerta/insertarregistroalerta",
  GET_LISTA_REGISTROALERTA: "registroalerta/listaregistroalerta",
  UPDATE_REGISTROALERTA: "registroalerta/updateregistroalerta",
  INACTIVO_REGISTROALERTA: "registroalerta/inactivoregistroalerta",
  ACTIVO_REGISTROALERTA: "registroalerta/activoregistroalerta",
  GET_LISTA_REGISTROALERTA_EXCEL: "registroalerta/listaregistroalertaexcel",

  // Canbiar Contraseña
  UPDATE_CLAVE: "usuario/updateclave",
  RESET_CLAVE: "usuario/resetclave",
  GET_LISTA_COMBO_UBIGEO: "ubigeo/listaComboubigeo",
  GET_LISTA_COMBO_DEPARTAMENTO: "departamento/listaCombodepartamento",
  GET_LISTA_COMBO_PROVINCIA: "provincia/listaComboprovincia",
  GET_RECUPERAR_UBIGEO: "ubigeo/recuperarubigeo",

  // EMPRESA
  GET_LISTA_EMPRESA: "empresa/listaempresa",
  GET_LISTA_EMPRESA_EXCEL: "empresa/listaempresaexcel",
  INSERTAR_EMPRESA: "empresa/insertarempresa",
  INACTIVO_EMPRESA: "empresa/inactivoempresa",
  ACTIVO_EMPRESA: "empresa/activoempresa",
  GET_EMPRESA_BYCODE: "empresa/getempresabycode",
  UPDATE_EMPRESA: "empresa/updateempresa",
  GET_LISTA_COMBO_EMPRESA: "empresa/listaComboEmpresa",

  // Cargo Persona
  GET_LISTA_CARGOPERSONA_EXCEL: "cargopersona/listacargopersonaexcel",
  INSERTAR_CARGOPERSONA: "cargopersona/insertarcargopersona",
  GET_LISTA_CARGOPERSONA: "cargopersona/listacargopersona",
  GET_CARGOPERSONA_BYCODE: "cargopersona/getcargopersonabycode",
  UPDATE_CARGOPERSONA: "cargopersona/updatecargopersona",
  INACTIVO_CARGOPERSONA: "cargopersona/inactivocargopersona",
  ACTIVO_CARGOPERSONA: "cargopersona/activocargopersona",

  // Marca Represetante
  INSERTAR_MARCAREPRESENTA: "marcarepresenta/insertarmarcarepresenta",
  GET_LISTA_MARCAREPRESENTA: "marcarepresenta/listamarcarepresenta",
  INACTIVO_MARCAREPRESENTA: "marcarepresenta/inactivomarcarepresenta",
  ACTIVO_MARCAREPRESENTA: "marcarepresenta/activomarcarepresenta",
  GET_MARCAREPRESENTA_BYCODE: "marcarepresenta/getmarcarepresentabycode",
  UPDATE_MARCAREPRESENTA: "marcarepresenta/updatemarcarepresenta",
  GET_LISTA_MARCAREPRESETANTE_EXCEL:
    "marcarepresenta/listamarcarepresentaexcel",

  //Upload Files
  UPLOAD_FILE: "file/upload",

  // RESPUESTA
  GET_LISTA_COMBO_PREGUNTA: "respuesta/listacomborespuesta",
  GET_LISTA_RESPUESTA: "respuesta/listarespuesta",
  GET_RESPUESTA_BYCODE: "respuesta/getrespuestabycode",
  UPDATE_RESPUESTA: "respuesta/updaterespuesta",
  INACTIVO_RESPUESTA: "respuesta/inactivorespuesta",
  ACTIVO_RESPUESTA: "respuesta/activorespuesta",
  INSERTAR_RESPUESTA: "respuesta/insertarrespuesta",
  //DOCUMENTO GENERADO
  INSERTAR_DOCUMENTOGENERADO: "documentogenerado/insertardocumentogenerado",
  UPDATE_DOCUMENTOGENERADO: "documentogenerado/updatedocumentogenerado",
  GET_LISTA_DOCUMENTOGENERADO: "documentogenerado/listadocumentogenerado",
  GET_DOCUMENTOGENERADO_BYCODE: "documentogenerado/getdocumentogeneradobycode",
  DESCARGA_DOCUMENTOGENERADOPERSONA:
    "documentogenerado/DescargaDocumentoGeneradoPersona",
  INACTIVO_DOCUMENTOGENERADODETALLE:
    "documentogenerado/inactivodocumentogeneradodetalle",

  GET_LISTA_CHECKINOUTTIENDA: "checkinouttienda/listacheckinouttienda",

  GET_LISTA_CHECKINOUTTIENDA_EXCEL:
    "checkinouttienda/listacheckinouttiendaexcel",

  GET_LISTA_COMBO_INCIDENCIA: "tablatablas/listaComboIncidencia",
  GET_LISTA_INCIDENCIA: "incidencia/listaincidencia",
  GET_LISTA_COMBO_SUPERVISION: "tablatablas/listaComboSupervision",
  GET_LISTA_TIPOSUPERVISION: "supervision/listasupervision",
  GET_LISTA_INCIDENCIA_EXCEL: "incidencia/listaincidenciaexcel",

  GET_LISTA_TIPOSUPERVISION_EXCEL: "supervision/listasupervisionexcel",

  GET_LISTA_INDICADORMERCADERISMO: "indicadores/listamercaderista",
  GET_LISTA_INDICADORPROMOTORIA: "indicadores/listapromotoria",
  GET_LISTA_INDICADORSUPERVISION: "indicadores/listasupervision",
  GET_LISTA_INDICADORPROMOTORIA_EXCEL: "indicadores/listapromotoriaexcel",
  GET_LISTA_INDICADORVENDEDORES: "indicadores/listavendedores",
  GET_LISTA_INDICADORSUPERVISION_EXCEL:"indicadores/listaindicadoressupervisionexcel",
  GET_LISTA_PRODUCTOMARCA: "analisis/listaproductomarca",
  GET_LISTA_INDICADORVENDEDOR_EXCEL: "indicadores/listavendedoresexcel",

  GET_LISTA_OSAPRODUCTO: "osa/getlistaosa",

  GET_LISTA_PRODUCTOIMAGEN: "reportefotografico/listareportefotografico",
  GET_LISTA_COMBO_TIPOEXHIBICION: "tablatablas/listacombotipoexhibicion",
  GET_LISTA_INDICADORMERCADERISTA_EXCEL:"indicadores/listamercaderistaexcel",
  GET_LISTA_OSADETALLEPRODUCTO: "analisis/ListaAnalisisOsaDetalleProducto",
  GET_LISTA_REGISTROMETAVENTA: "metaventatienda/listametatiendaventa",
  INSERTAR_METATIENDAVENTA: "metaventatienda/insertarmetaventatienda",
  UPDATE_METATIENDAVENTA: "metaventatienda/updatemetaventatienda",
  GET_METAVENTATIENDA_BYCODE: "metaventatienda/getmetaventatiendabycode",
  INACTIVO_METAVENTATIENDA: "metaventatienda/inactivometaventatienda",
  ACTIVO_METAVENTATIENDA: "metaventatienda/activometaventatienda",

  //Analisis Stock
  GET_ANALISIS_STOCK: "analisis/ListaAnalisisStock",
  GET_DATA_ANALISIS_STOCK: "analisis/ListaDataAnalisisStock",
  GET_DATA_ANALISIS_STOCK_BAR: "analisis/ListaDataAnalisisStockBar",
  // Analisis Precio
  GET_ANALISIS_PRECIO: "analisis/listaanalisisprecio",
  GET_DATA_ANALISIS_PRECIO: "analisis/ListaDataAnalisisPrecio",
  GET_DATA_ANALISIS_PRECIO_BAR: "analisis/ListaDataAnalisisPrecioBar",

  GET_ANALISIS_OSA: "analisis/listaanalisisosa",
  // Analisis Frente
  GET_ANALISIS_FRENTE: "analisis/ListaAnalisisFrente",
  //Exhibicion
  GET_LISTA_EXHIBICION_IMAGEN: "exhibicion/ListaExhibicion",
 
  //Integracion
  IMPORTAR_STOCKB2B: "integracion/ImportarStockB2B",
  GET_DESCARGAR_PLANTILLA_STOCKB2B: "integracion/PlantillaStockB2B",
 
};
