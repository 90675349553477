export const CODE_SERVER_RETURN = {
    OK: 0,
    ERROR: 1,
    VAL: 2,
};

export const PERFIL_USUARIO = {
    SUPERADMINISTRADOR: 1,
    ADMINISTRADOR: 2,
    SUPERVISOR: 3,
    MERCADERISTA: 4,
    VENDEDOR: 5
}

export const UPLOAD_FILESCADENAMASTER = {
    USUARIO_FOTO_CADENAMASTER: 1
};


export const UPLOAD_FILESEMPRESA = {
    USUARIO_FOTO_EMPRESA: 2
};


export const UPLOAD_FILEPRODUCTO = {
    USUARIO_FOTO_FOTOPRODUCTO: 3
};

export const UPLOAD_FILES = {
    USUARIO_FOTO_AVATAR: 4
};

export const UPLOAD_FILE_DOCUMENTO = {
    USUARIO_ADJUNTAR_DOCUMENTO: 5
};

export const UPLOAD_FILESCONTRATO = {
    USUARIO_ADJUNTAR_CONTRATO: 6
};

export const UPLOAD_FILESCS = {
    USUARIO_ADJUNTAR_CS: 7
};

export const UPLOAD_FILESCONSTANCIA = {
    USUARIO_ADJUNTAR_CONSTANCIA: 8

};

export const UPLOAD_FILESDNI = {
    USUARIO_ADJUNTAR_DNI: 9
};

export const UPLOAD_FILESALTASUNAT = {
    USUARIO_ADJUNTAR_ALTASUNAT: 10
};