import { useMemo } from 'react';
import { type MRT_ColumnDef } from 'material-react-table';
import * as ClientAPI from "../utils/methods";
import * as Endpoints from "../api/endpoint";
import Moment from 'moment';
import moment from 'moment';
type ProgramacionUsuario = {
  CodeIsuario: string,
  Periodo: string,
  FechaProgramada: Date
};
export function GetColumns() {
  const columns = useMemo<MRT_ColumnDef<ProgramacionUsuario>[]>(
    () => [
      {
        accessorKey: 'Periodo',
        header: 'Periodo',
        size: 50,
      },
      {
        accessorKey: 'NombreCompleto',
        header: 'Nombres',
        size: 200,
      },
      {
        id: 'FechaProgramada',
        header: 'Fecha Programada',
        size: 50,
        accessorFn: (row) => {
          return moment(row.FechaProgramada).format("DD/MM/YYYY");
        },
      },
      {
        accessorKey: 'NombresTienda',
        header: 'Tiendas',
        size: 200,
      },
      {
        accessorKey: 'NombresTarea',
        header: 'Tareas',
        size: 200,
      }
    ],
    [],
  );
  ;
  return columns
}
type Tarea = {
  IdTarea: string,
  NombreTarea: string,
};

export function GetMColumns() {
  const columns = useMemo<MRT_ColumnDef<Tarea>[]>(
    () => [
      {
        accessorKey: 'DiaSemana',
        header: 'ID',
        size: 20,
      },
      {
        accessorKey: 'NombreDiaSemana',
        header: 'Día Semana',
        size: 100,
      },
      {
        accessorKey: 'NombreTiendas',
        header: 'Tiendas',
        size: 100,
      },
      {
        accessorKey: 'NombreTareas',
        header: 'Tareas',
        size: 200,
      },
    ],
    [],
  );
  ;
  return columns
}
export async function getData(filterFechaDesde: Date, filterFechaHasta : Date, FilterCodeUsuario: string, FilterPeriodo:Date) {
 
  FilterCodeUsuario = (FilterCodeUsuario == "" || FilterCodeUsuario == "0") ? "-": FilterCodeUsuario

  var CFilterFechaDesde = Moment(filterFechaDesde).format("DD-MM-YYYY") 
  var CFilterFechaHasta = Moment(filterFechaHasta).format("DD-MM-YYYY")
  var FilterPeriodoFormat = moment(FilterPeriodo).format("YYYYMM");
  var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.GET_LISTA_PROGRAMACIONUSUARIO  + "/" +  CFilterFechaDesde + "/" +  CFilterFechaHasta + "/" + FilterCodeUsuario  + "/" + FilterPeriodoFormat , "")
  if (dataResponse.Data != null) {
    return dataResponse.Data;
  } else {
    return []
  }
}
export async function getByCode(code: string) {
  var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.GET_PROGRAMACION_USUARIO_BYCODE + "/" + code, "")
  return dataResponse.Data;
}
export async function save(objSave: any) {
  var dataResponse = await ClientAPI.RequestPost(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.INSERTAR_PROGRAMACIONUSUARIO, "", objSave)
  if (dataResponse.Data != null) {
    return dataResponse.Data;
  } else {
    return []
  }
}
export async function update(objSave: any) {
  var dataResponse = await ClientAPI.RequestPost(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.UPDATE_PROGRAMACION_USUARIO, "", objSave)
  if (dataResponse.Data != null) {
    return dataResponse.Data;
  } else {
    return null
  }
}
export async function inactiva(objSave: any) {
  var dataResponse = await ClientAPI.RequestPost(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.INACTIVO_PROGRAMACION_USUARIO, "", objSave)
  if (dataResponse.Data != null) {
    return dataResponse.Data;
  } else {
    return null
  }
}
export async function activa(objSave: any) {
  var dataResponse = await ClientAPI.RequestPost(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.ACTIVO_PROGRAMACION_USUARIO, "", objSave)
  if (dataResponse.Data != null) {
    return dataResponse.Data;
  } else {
    return null
  }
}
export async function getListaComboTareas() {
  var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.GET_LISTA_COMBO_TAREAS, "")
  if (dataResponse.Data != null) {
    return dataResponse.Data;
  } else {
    return null
  }
}
export async function getListaComboTienda() {
  var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint. GET_LISTA_COMBO_TIENDA +  "/-", "")
    if (dataResponse.Data != null) {
      return dataResponse.Data;
    } else {
      return null
    }  
}

export async function getListaComboUsuario() {
  var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.GET_LISTA_COMBO_PROGRAMACIONUSUARIO, "")
  if (dataResponse.Data != null) {
    return dataResponse.Data;
  } else {
    return null
  }
}

export async function getListaComboSemana(){
  var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_SECURITY + Endpoints.ListEndpoint.GET_LISTA_COMBO_SEMANA, "")
  if(dataResponse.Data != null){
    return dataResponse.Data;
  }else {
    return null
  }
}

export async function getListaComboDiasSemana(){
  var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.GET_LISTA_COMBO_DIASSEMANA, "")
  if(dataResponse.Data != null){
    return dataResponse.Data;
  }else {
    return null
  }
}
export async function doExport(  FilterFechaDesde: Date, FilterFechaHasta : Date, FilterCodeUsuario : string, FilterPeriodo : Date ) {
  
  FilterCodeUsuario = (FilterCodeUsuario == "" || FilterCodeUsuario == "0")? "-": FilterCodeUsuario

  var CFilterFechaDesde = Moment(FilterFechaDesde).format("DD-MM-YYYY") 
  var CFilterFechaHasta = Moment(FilterFechaHasta).format("DD-MM-YYYY")
  var FilterPeriodoFormat = moment(FilterPeriodo).format("YYYYMM");
  var dataResponse = await ClientAPI.RequestDownload(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.GET_LISTA_PROGRAMACIONUSUARIO_EXCEL  +  "/" + CFilterFechaDesde + "/" + CFilterFechaHasta  + "/" + FilterCodeUsuario  + "/" + FilterPeriodoFormat, 'DescargarExcelProgramacionUsuario.xlsx')
  
}

export async function importarProgramacionUsuario(formDataToUpload: FormData) {
  var dataResponse = await ClientAPI.RequestPostUpload(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.IMPORTAR_PROGRAMACION_USUARIO, formDataToUpload)
 console.log(dataResponse);
  if (dataResponse != null) {
    return dataResponse;
  } else {
    return null
  }
}

export async function doCargarPlantillaProgramacionUsuario() {
 
  var dataResponse = await ClientAPI.RequestDownload(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.GET_CARGAPLANTILLA_PROGRAMACION_USUARIO_EXCEL, 'PlantillaCargaProgramacionUsuario.xlsx')
}
