import { useMemo } from 'react';
import { type MRT_ColumnDef } from 'material-react-table';
import * as ClientAPI from "../utils/methods";
import * as Endpoints from "../api/endpoint";
type Usuario = {
  IdUsuario: string,
  Nombres: string,
  APellidos: string,
  NombresUsuario: string,
  Estado: number,
};
export function GetColumns() {
  const columns = useMemo<MRT_ColumnDef<Usuario>[]>(
    () => [
      {
        accessorKey: 'IdUsuario',
        header: 'ID',
        size: 30,
        muiTableBodyCellProps: {
          align: 'center',
        },
      },

      {
        accessorKey: 'NombresPerfil',
        header: 'Perfil',
        size: 200,
      },     
      {
        accessorKey: 'Nombres',
        header: 'Nombres',
        size: 250,
      },
      {
        accessorKey: 'Apellidos',
        header: 'Apellidos',
        size: 250,
      },
      {
        accessorKey: 'NombreUsuario',
        header: 'Nombre Usuarios',
        size: 200,
      },

     
      {
        accessorKey: 'NombreTipoDocumentoIdentidad',
        header: '  Tipo Documento',
        size: 200,
      },
      {
        accessorKey: 'NumeroDocumentoIdentidad',
        header: 'Numero  Documento',
        size: 200,
      },

      {
        accessorKey: 'Correo',
        header: 'Correo Electronico',
        size: 200,
      },
    ],
    [],
  );
  ;
  return columns
}
export async function getData(FilterEmpresa:string, FilterApeNom: string, FilterNombreUsuario: string, SoloActivos: boolean) {
  FilterEmpresa = FilterEmpresa == "" ? "-" : FilterEmpresa
  FilterApeNom = FilterApeNom == "" ? "-" : FilterApeNom
  FilterNombreUsuario = FilterNombreUsuario == "" ? "-" : FilterNombreUsuario
  var FilterSoloActivos = SoloActivos ? 1: 0;
  var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_SECURITY + Endpoints.ListEndpoint.GET_LISTA_USUARIO + "/" + FilterEmpresa   + "/" + FilterApeNom + "/" + FilterNombreUsuario + "/" + FilterSoloActivos, "")
  if (dataResponse.Data != null) {
    return dataResponse.Data;
  } else {
    return []
  }
}

export async function getListaComboEmpresa(){
  var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_SECURITY + Endpoints.ListEndpoint.GET_LISTA_COMBO_EMPRESA, "")
  if(dataResponse.Data != null){
    return dataResponse.Data;
  }else {
    return []
  }
}

export async function getListaComboCliente() {
  var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.GET_LISTA_COMBO_CLIENTE, "")
  if (dataResponse.Data != null) {
    return dataResponse.Data;
  } else {
    return null
  }
}

export async function getListaComboPerfil() {
  var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.GET_LISTA_COMBO_PERFIL, "")
  if (dataResponse.Data != null) {
    return dataResponse.Data;
  } else {
    return []
  }
}

export async function getByCode(code: string) {
  var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_SECURITY + Endpoints.ListEndpoint.GET_USUARIO_BYCODE + "/" + code, "")
  return dataResponse.Data;
}

export async function getUsuarioInfo() {
  var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_SECURITY + Endpoints.ListEndpoint.GET_USUARIO_INFO , "")
  console.log(dataResponse)
  return dataResponse.Data;
}

export async function save(objSave: any) {
  var dataResponse = await ClientAPI.RequestPost(Endpoints.URL_BASE_SECURITY + Endpoints.ListEndpoint.INSERTAR_USUARIO, "", objSave)
  if (dataResponse != null) {
    return dataResponse;
  } else {
    return null
  }
}
export async function update(objSave: any) {
  var dataResponse = await ClientAPI.RequestPost(Endpoints.URL_BASE_SECURITY + Endpoints.ListEndpoint.UPDATE_USUARIO, "", objSave)
  console.log(dataResponse)
  if (dataResponse != null) {
    return dataResponse;
  } else {
    return null
  }
}

export async function uploadFile(formDataToUpload: FormData) {
  var dataResponse = await ClientAPI.RequestPostUpload(Endpoints.URL_BASE_FILE + Endpoints.ListEndpoint.UPLOAD_FILE, formDataToUpload)

  if (dataResponse != null) {
    return dataResponse;
  } else {
    return null
  }
}


export async function inactiva(objSave: any) {
  var dataResponse = await ClientAPI.RequestPost(Endpoints.URL_BASE_SECURITY + Endpoints.ListEndpoint.INACTIVO_USUARIO, "", objSave)
  if (dataResponse.Data != null) {
    return dataResponse.Data;
  } else {
    return null
  }
}

export async function resetclave(objSave: any) {
  var dataResponse = await ClientAPI.RequestPost(Endpoints.URL_BASE_SECURITY + Endpoints.ListEndpoint.RESET_CLAVE, "", objSave)
  if (dataResponse.Data != null) {
    return dataResponse.Data;
  } else {
    return null
  }
}

export async function activa(objSave: any) {
  var dataResponse = await ClientAPI.RequestPost(Endpoints.URL_BASE_SECURITY + Endpoints.ListEndpoint.ACTIVO_USUARIO, "", objSave)
  if (dataResponse.Data != null) {
    return dataResponse.Data;
  } else {
    return null
  }
} 

export async function getListaComboTipoArchivo() {
  var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.GET_LISTA_COMBO_TIPOARCHIVO, "")
  if (dataResponse.Data != null) {
    return dataResponse.Data;
  } else {
    return null
  }
}

export async function getListaComboTipoDocumento() {
  var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.GET_LISTA_COMBO_TIPODOCUMENTO, "")
  if (dataResponse.Data != null) {
    return dataResponse.Data;
  } else {
    return null
  }
}

export async function doExport(FilterEmpresa:string, FilterApeNom: string, FilterNombreUsuario: string, SoloActivos: boolean) {
  FilterEmpresa = FilterEmpresa == "" ? "-" : FilterEmpresa
  FilterApeNom = FilterApeNom == "" ? "-" : FilterApeNom
  FilterNombreUsuario = FilterNombreUsuario == "" ? "-" : FilterNombreUsuario
  var FilterSoloActivos = SoloActivos ? 1: 0;
  var dataResponse = await ClientAPI.RequestDownload(Endpoints.URL_BASE_SECURITY + Endpoints.ListEndpoint.GET_LISTA_USUARIO_EXCEL + "/" + FilterEmpresa   + "/" + FilterApeNom + "/" + FilterNombreUsuario + "/" + SoloActivos, 'DescargarExcelUsuario.xlsx')
}

export async function doCargarPlantillaUsuario() {
 
  var dataResponse = await ClientAPI.RequestDownload(Endpoints.URL_BASE_SECURITY + Endpoints.ListEndpoint.GET_CARGAPLANTILLA_USUARIO_EXCEL, 'PlantillaCargaUsuarioExcel.xlsx')
}

export async function getListaComboCargoPersona(){
  var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.GET_LISTA_COMBO_CARGOPERSONA, "")
  if(dataResponse.Data != null){
    return dataResponse.Data;
  }else {
    return null
  }
}

export async function getListaComboMarcaRepresenta(){
  var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.GET_LISTA_COMBO_MARCAREPRESENTA, "")
  if(dataResponse.Data != null){
    return dataResponse.Data;
  }else {
    return null
  }
}

export async function getListaComboDepartamento(){
  var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.GET_LISTA_COMBO_DEPARTAMENTO, "")
  if(dataResponse.Data != null){
    return dataResponse.Data;
  }else {
    return null
  }
}


export async function getListaComboProvincia(setProvincia : string){
  var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.GET_LISTA_COMBO_PROVINCIA + "/" + setProvincia , "")
  if(dataResponse.Data != null){
    return dataResponse.Data;
  }else {
    return null
  }
}
export async function getListaComboUbigeo(setUbigeo : string){
  var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.GET_LISTA_COMBO_UBIGEO + "/" + setUbigeo , "")
  if(dataResponse.Data != null){
    return dataResponse.Data;
  }else {
    return null
  }
}
 
export async function getListaComboEmpresaAseguradora() {
  var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.GET_LISTA_COMBO_EMPRESAASEGURADORA, "")
  if (dataResponse.Data != null) {
    return dataResponse.Data;
  } else {
    return null
  }

  
} 

export async function importarUsuarios(formDataToUpload: FormData) {
  var dataResponse = await ClientAPI.RequestPostUpload(Endpoints.URL_BASE_SECURITY + Endpoints.ListEndpoint.IMPORTAR_USUARIO, formDataToUpload)
 console.log(dataResponse);
  if (dataResponse != null) {
    return dataResponse;
  } else {
    return null
  }
}
