import { useMemo } from 'react';
import { type MRT_ColumnDef } from 'material-react-table';
import * as ClientAPI from "../utils/methods"; 
import * as Endpoints from "../api/endpoint"; 
type EquipoTrabajo = {
   IdEquipoTrabajo : string,
   NombreEquipoTrabajo  : string,
   NombreSupervisor : string,
};
  export function GetColumns(){
    const columns = useMemo<MRT_ColumnDef<EquipoTrabajo>[]>(
        () => [
            {
                accessorKey: 'IdEquipoTrabajo',
                header: 'ID',
                size: 20,
              },
              {
                accessorKey: 'NombreEquipoTrabajo',
                header: 'Nombre Equipo',
                size: 400,
              },
              {
                accessorKey: 'NombreSupervisor',
                header: 'Nombre Supervisor',
                size: 400,
              },
            ],
            [],
        );
        ;
    return columns
  }
  type MiembroTrabajo = {
    CodeUsuario : string,
    NombreUsuario  : string,
  };
  export function GetMColumns(){
    const columns = useMemo<MRT_ColumnDef<MiembroTrabajo>[]>(
        () => [
            {
                accessorKey: 'CodeUsuario',
                header: 'ID',
                size: 20,
              },
              {
                accessorKey: 'NombreUsuario',
                header: 'Nombre de los miembros de equipo',
                size: 400,
              },
            ],
            [],
        );
        ;
    return columns
  }
  export async function getData(FilterEmpresa: string, FilterNombreEquipoTrabajo:string){
    FilterEmpresa = (FilterEmpresa == "" || FilterEmpresa == "0")? "-": FilterEmpresa
    var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.GET_LISTA_EQUIPOTRABAJO  + "/" +  FilterEmpresa + "/" + FilterNombreEquipoTrabajo, "")
    if(dataResponse.Data != null){
      return dataResponse.Data;
    }else {
      return []
    }
  }
  export async function getByCode(code: string){
    var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.GET_EQUIPOTRABAJO_BYCODE + "/" + code, "")
    return dataResponse.Data;
  }
  export async function save(objSave: any){
    var dataResponse = await ClientAPI.RequestPost(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.INSERTAR_EQUIPOTRABAJO,   "", objSave)
    if(dataResponse.Data != null){
      return dataResponse.Data;
    }else {
      return []
    }
  }
  export async function update(objSave: any){
    var dataResponse = await ClientAPI.RequestPost(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.UPDATE_EQUIPOTRABAJO, "", objSave)
    if(dataResponse.Data != null){
      return dataResponse.Data;
    }else {
      return null
    }
  }  
  export async function inactiva(objSave: any){
    var dataResponse = await ClientAPI.RequestPost(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.INACTIVO_EQUIPOTRABAJO, "", objSave)
    if(dataResponse.Data != null){
      return dataResponse.Data;
    }else {
      return null
    }
  } 
  export async function activa(objSave: any){
    var dataResponse = await ClientAPI.RequestPost(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.ACTIVO_EQUIPOTRABAJO, "", objSave)
    if(dataResponse.Data != null){
      return dataResponse.Data;
    }else {
      return null
    }
  } 
  export async function getListaComboSupervisor(){
    var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.GET_LISTA_COMBO_SUPERVISOR, "")
    if(dataResponse.Data != null){
      return dataResponse.Data;
    }else {
      return null
    }
  }
  export async function getListaComboMiembroEquipo(){
    var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.GET_LISTA_COMBO_MIEMBROEQUIPO, "")
    if(dataResponse.Data != null){
      return dataResponse.Data;
    }else {
      return null
    }
  }

  export async function doExport(FilterEmpresa: string, FilterNombreEquipoTrabajo:string){
    FilterEmpresa = (FilterEmpresa == "" || FilterEmpresa == "0")? "-": FilterEmpresa
    var dataResponse = await ClientAPI.RequestDownload(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.GET_LISTA_EQUIPOTRABAJO_EXCEL  + "/" +  FilterEmpresa + "/" + FilterNombreEquipoTrabajo, "DescargarExcelEquipoTrabajo.xlsx")
   
  }