import { useMemo } from 'react';
import { type MRT_ColumnDef } from 'material-react-table';
import * as ClientAPI from "../utils/methods"; 
import * as Endpoints from "../api/endpoint"; 
import { type } from 'os';

type Producto = {
   IdProducto : string;
   NombreProducto : string;
   IdEmpresa : string;
   SkuMaestro : string;
   SkuCadena : string;
   Ean : string;
   IdCategoria : string;
   IdLineaProducto : string;
   IdMarca : string;
   GramajeTamanio : string;
   Pvp : string;
};
  export function GetColumns(){
    const columns = useMemo<MRT_ColumnDef<Producto>[]>(
        () => [
            {
                accessorKey: 'IdProducto',
                header: 'ID',
                size: 20,
              },
              {
                accessorKey: 'NombreProducto',
                header: 'Nombre Producto',
                size: 300,
              },
              {
                accessorKey: 'NombreEmpresa',
                header: 'Empresa',
                size: 200,
              },
              {
                accessorKey: 'NombreSubCategoria',
                header: 'Sub Categoria',
                size: 300,
              },
               
              {
                accessorKey: 'NombreCategoria',
                header: 'Categoria',
                size: 300,
              },
              {
                accessorKey: 'NombreMarca',
                header: 'Marca',
                size: 300,
              },
              {
                accessorKey: 'SkuMaestro',
                header: 'SKU Maestro',
                size: 300,
              },
        
              {
                accessorKey: 'SkuCadena',
                header: 'SKU Cadena',
                size: 300,
              },
        
              {
                accessorKey: 'Ean',
                header: 'EAN',
                size: 300,
              },
             
              {
                accessorKey: 'GramajeTamanio',
                header: 'Gramaje',
                size: 300,
              },
              {
                accessorKey: 'Pvp',
                header: 'PVP',
                size: 300,
              },
            ],
            [],
        );
    return columns
  }
  type Tiendas ={
   CodeTienda: string,
   NombreTienda :string,

  };
  export function GetTColumns(){
    const columns = useMemo<MRT_ColumnDef<Tiendas>[]>(
        () => [
            {
                accessorKey: 'CodeTienda',
                header: 'ID',
                size: 20,
              },
              {
                accessorKey: 'NombreTienda',
                header: 'Nombres de Tiendas',
                size: 400,
              },
            ],
            [],
        );
        ;
    return columns
  }
  export async function getData(FilterEmpresa: string,FilterSubCategoria:string,FilterMarca:string,FilterCategoria:string,FilterNombreProducto:string){
    FilterEmpresa = (FilterEmpresa == "" || FilterEmpresa == "0")? "-": FilterEmpresa
    FilterSubCategoria = (FilterSubCategoria == "" || FilterSubCategoria == "0")? "-": FilterSubCategoria
    FilterMarca = (FilterMarca == "" || FilterMarca == "0")? "-": FilterMarca
    FilterCategoria = (FilterCategoria == "" || FilterCategoria == "0")? "-": FilterCategoria
    var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.GET_LISTA_PRODUCTO + "/" + FilterEmpresa  + "/" + FilterSubCategoria + "/" + FilterMarca  + "/" + FilterCategoria   + "/" + FilterNombreProducto , "")
    if(dataResponse.Data != null){
      return dataResponse.Data;
    }else {
      return []
    }
  }
  export async function getByCode(code: string){
    var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.GET_PRODUCTO_BYCODE + "/" + code, "")
    return dataResponse.Data;
  }
  export async function getListaProductoTiendaDetalleByCodeProducto(code: string) {
    var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.GET_LISTA_PRODUCTO_TIENDA_BYCODEPRODUCTO + "/" + code, "")
    if(dataResponse.Data != null){
      return dataResponse.Data;
    }else {
      return []
    }
  }
  export async function save(objSave: any){
    var dataResponse = await ClientAPI.RequestPost(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.INSERTAR_PRODUCTO, "", objSave)
    if(dataResponse != null){
      return dataResponse;
    }else {
      return null
    }
  }
  export async function savePT(objSave: any){
    var dataResponse = await ClientAPI.RequestPost(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.GUARDAR_TIENDAPRODUCTO, "", objSave)
    if(dataResponse.Data != null){
      return dataResponse.Data;
    }else {
      return []
    }
  }
  export async function update(objSave: any){
    var dataResponse = await ClientAPI.RequestPost(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.UPDATE_PRODUCTO, "", objSave)
    if(dataResponse != null){
      return dataResponse;
    }else {
      return null
    }
  } 
  export async function inactiva(objSave: any){
    var dataResponse = await ClientAPI.RequestPost(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.INACTIVO_PRODUCTO, "", objSave)
    if(dataResponse.Data != null){
      return dataResponse.Data;
    }else {
      return null
    }
  } 
  export async function activa(objSave: any){
    var dataResponse = await ClientAPI.RequestPost(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.ACTIVO_PRODUCTO, "", objSave)
    if(dataResponse.Data != null){
      return dataResponse.Data;
    }else {
      return null
    }
  } 
  export async function getListaComboCategoria(setCodeMarca : string){
    var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.GET_LISTA_COMBO_CATEGORIA + "/" + setCodeMarca , "")
    if(dataResponse.Data != null){
      return dataResponse.Data;
    }else {
      return null
    }
  }

  export async function uploadFile(formDataToUpload: FormData) {
    var dataResponse = await ClientAPI.RequestPostUpload(Endpoints.URL_BASE_FILE + Endpoints.ListEndpoint.UPLOAD_FILE, formDataToUpload)
  
    if (dataResponse != null) {
      return dataResponse;
    } else {
      return null
    }
  }

  export async function getListaComboCategorias(){
    var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.GET_LISTA_COMBO_CATEGORIAS  , "")
    if(dataResponse.Data != null){
      return dataResponse.Data;
    }else {
      return null
    }
  }
  export async function getListaComboSubCategorias(){
    var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.GET_LISTA_COMBO_SUBCATEGORIA  , "")
    if(dataResponse.Data != null){
      return dataResponse.Data;
    }else {
      return null
    }
  }

  

  export async function getListaComboMarca(){
    var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.GET_LISTA_COMBO_MARCA , "")
    if(dataResponse.Data != null){
      return dataResponse.Data;
    }else {
      return null
    }
  }
 
  export async function getListaComboSubCategoria(setCodeCategoria : string){
    var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.GET_LISTA_COMBO_SUB_CATEGORIA + "/" + setCodeCategoria  ,"")
    if(dataResponse.Data != null){
      return dataResponse.Data;
    }else {
      return null
    }
  }
  export async function getListaComboTienda(){
    var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.GET_LISTA_COMBO_TIENDA + "/-" , "")
    if(dataResponse.Data != null){
      return dataResponse.Data;
    }else {
      return null
    }
  }

  export async function doExport(FilterEmpresa: string,FilterSubCategoria:string,FilterMarca:string,FilterCategoria:string,FilterNombreProducto:string){
    FilterEmpresa = (FilterEmpresa == "" || FilterEmpresa == "0")? "-": FilterEmpresa
    FilterSubCategoria = (FilterSubCategoria == "" || FilterSubCategoria == "0")? "-": FilterSubCategoria
    FilterMarca = (FilterMarca == "" || FilterMarca == "0")? "-": FilterMarca
    FilterCategoria = (FilterCategoria == "" || FilterCategoria == "0")? "-": FilterCategoria
    var dataResponse = await ClientAPI.RequestDownload(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.GET_LISTA_PRODUCTO_EXCEL + "/" + FilterEmpresa  + "/" + FilterSubCategoria + "/" + FilterMarca  + "/" + FilterCategoria   + "/" + FilterNombreProducto , 'DescargarExcelProducto.xlsx')
   
  }

  export async function doCargaPlantillaProducto(){
   
    var dataResponse = await ClientAPI.RequestDownload(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.GET_CARGA_PRODUCTO_EXCEL , 'PlantillaCargaProducto.xlsx')
   
  }
   
  export async function importarProducto(formDataToUpload: FormData) {
    var dataResponse = await ClientAPI.RequestPostUpload(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.IMPORTAR_PRODUCTO, formDataToUpload)
  
    if (dataResponse != null) {
      return dataResponse;
    } else {
      return null
    }
  }