import { useMemo } from 'react';
import { type MRT_ColumnDef } from 'material-react-table';
import * as ClientAPI from "../utils/methods";
import * as Endpoints from "../api/endpoint";

export async function update(objSave: any) {
  var dataResponse = await ClientAPI.RequestPost(Endpoints.URL_BASE_SECURITY + Endpoints.ListEndpoint.UPDATE_CLAVE, "", objSave)
  if (dataResponse.Data != null) {
    return dataResponse.Data;
  } else {
    return null
  }
}