import { useParams, useNavigate } from "react-router-dom";
import * as Controller from "../../../controllers/cadenamaster-controller";
import ContentHeader from "../layout/ContentHeader";
import React from "react";
import Textbox from "../layout/Textbox";
import Label from "../layout/Label";
import SelectSimple from "../layout/SelectSimple";
import * as Repository from "../../../repositories/cadenamaster-repository";
import MaterialReactTable from "material-react-table";
import { IconButton } from "@mui/material";
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Email as EmailIcon,
} from "@mui/icons-material";
export default function NuevoCadenaMaster() {
  const { code } = useParams();
  const router = useNavigate();
  const [codeCadenaMaster, setCodeCadenaMaster] = React.useState("");
  const [nombreCadenaMaster, setNombreCadenaMaster] = React.useState("");
  const [razonSocial, setRazonSocial] = React.useState("");
  const [ListaComboDocumento, setListaComboDocumento] = React.useState([]);
  const [documentos, setDocumentos] = React.useState("");
  const [ListaDocumentoss, setListaDocumentos] = React.useState([]);
  const [urlImagen, setUrlImagen] = React.useState("");
  const [editando, setEditando] = React.useState(0);
  const [tituloVentana, setTituloVentana] = React.useState("Nueva Cadena");
  const [valNombreCadenaMaster, setValNombreCadenaMaster] =
    React.useState(false);
  const [valrazonSocial, setValRazonSocial] = React.useState(false);
  const [isSubmit, setIsSubmit] = React.useState(false);
  const [file, setFile] = React.useState(null);

  React.useEffect(() => {
    Controller.getListaComboDocumento(setListaComboDocumento);
    if (code != "nuevo") {
      setEditando(1);
      setTituloVentana("Editar Cadena");
      setCodeCadenaMaster(code!);
      Controller.getByCode(
        code,
        setNombreCadenaMaster,
        setUrlImagen,
        setRazonSocial
      );
      Controller.geListaCadenaDocumentoDetalleByCodeCadena(
        code,
        setListaDocumentos
      );
    }
  }, []);
  function validateCampos() {
    setIsSubmit(true);
    var Result = true;
    if (nombreCadenaMaster == "" || nombreCadenaMaster == null) {
      Result = false;
      setValNombreCadenaMaster(false);
    } else {
      setValNombreCadenaMaster(true);
    }

    if (razonSocial == "" || razonSocial == null) {
      Result = false;
      setValRazonSocial(false);
    } else {
      setValRazonSocial(true);
    }

    if (Result) {
      Controller.save(
        router,
        codeCadenaMaster,
        nombreCadenaMaster,
        file,
        urlImagen,
        razonSocial
      );
    } else {
      Controller.Mensaje();
    }
  }

  function onChangeFileUpload(e: any) {
    var fileToUpload = e.target.files[0];
    //const reader = new FileReader();
    //var url = reader.readAsDataURL(file);
    setFile(fileToUpload);
  }

  return (
    <div className="content-wrapper">
      <ContentHeader
        title={tituloVentana}
        homePath={"Home"}
        currentPath={tituloVentana}
      ></ContentHeader>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card card-primary">
                <div className="card-header">
                  <div className="d-flex flex-row gap-2">
                    <div className="align-items-center">
                      <i
                        className="bx bx-arrow-back arrow-back"
                        onClick={() => Controller.toConsulta(router)}
                      ></i>
                    </div>
                    <div className="">
                      <h4 className="card-title">{tituloVentana}</h4>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-6">
                      <h4>Datos</h4>
                      <hr />
                    </div>
                  </div>
                  <div className="row"></div>
                  <div className="row">
                    <div className="col-lg-2">
                      <Label required={true}>Nombre cadena</Label>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <Textbox
                          value={nombreCadenaMaster}
                          valueError={valNombreCadenaMaster}
                          setValue={setNombreCadenaMaster}
                          setValueError={setValNombreCadenaMaster}
                          isSubmit={isSubmit}
                          upperCase={true}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-2">
                      <Label required={true}>Razon Social</Label>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <Textbox
                          value={razonSocial}
                          valueError={valrazonSocial}
                          setValue={setRazonSocial}
                          setValueError={setValRazonSocial}
                          isSubmit={isSubmit}
                          upperCase={true}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row mt-5">
                    <div className="col-lg-2">
                      <Label>Imagen</Label>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <input
                          id="image"
                          accept="image/*"
                          type="file"
                          name="image"
                          onChange={onChangeFileUpload}
                        />
                      </div>
                    </div>
                    {urlImagen != null && urlImagen != "" ? (
                      <div className="col-lg-6">
                        <div className="row">
                          <div className="col-lg-4">
                            <Label>Vista Previa</Label>
                          </div>
                          <div className="col-lg-8">
                            <img src={urlImagen} style={{ width: 200 }} />
                          </div>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="row mt-5">
                    <div className="col-lg-6">
                      <div className="form-group">
                        <button
                          className="btn btn-default"
                          onClick={() => Controller.toConsulta(router)}
                        >
                          Cancelar
                        </button>
                        <button
                          className="btn btn-primary"
                          onClick={() => validateCampos()}
                        >
                          Guardar
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row mt-5">
                    <div className="col-lg-6">
                      <h3>Documentos</h3>
                      <hr />
                    </div>
                  </div>
                  <div className="row mt-5">
                    <div className="col-lg-2">
                      <Label>Nombre Documentos</Label>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <SelectSimple
                          value={documentos}
                          setValue={setDocumentos}
                          lista={ListaComboDocumento}
                        >
                          --Seleccion--
                        </SelectSimple>
                      </div>
                    </div>
                    <div className="col-lg-1">
                      <button
                        className="btn btn-info"
                        onClick={() =>
                          Controller.addMember(
                            documentos,
                            ListaComboDocumento,
                            ListaDocumentoss,
                            setListaDocumentos
                          )
                        }
                      >
                        +
                      </button>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <MaterialReactTable
                        columns={Repository.GetTColumns()}
                        data={ListaDocumentoss}
                        enableRowActions
                        renderRowActions={({ row, table }) => (
                          <div>
                            <IconButton
                              onClick={() =>
                                Controller.toQuestionEliminarTiendas(
                                  row.original,
                                  ListaDocumentoss,
                                  setListaDocumentos
                                )
                              }
                            >
                              <DeleteIcon />
                            </IconButton>
                          </div>
                        )}
                      />
                    </div>
                  </div>
                  <div className="row mt-5">
                    <div className="col-lg-6">
                      <div className="form-group">
                        <button
                          className="btn btn-default"
                          onClick={() => Controller.toConsulta(router)}
                        >
                          Cancelar
                        </button>
                        <button
                          className="btn btn-primary"
                          onClick={() =>
                            Controller.GuardarDocumentos(
                              router,
                              codeCadenaMaster,
                              ListaDocumentoss
                            )
                          }
                        >
                          Guardar Documentos
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
