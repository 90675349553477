export default function ContentBody({
  children,
  title,
}: {
  children: React.ReactNode;
  title: String;
}) {
  return (
    <section className="content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="card card-primary">
              <div className="card-header">
                <h4 className="card-title">{title}</h4>
              </div>
              <div className="card-body">{children}</div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
