'use client'

import React, { useMemo } from 'react';
import { MaterialReactTable, type MRT_ColumnDef } from 'material-react-table';
import { useNavigate } from "react-router-dom";
import { Box, IconButton } from '@mui/material';

import ContentHeader from '../layout/ContentHeader';
import ContentBody from '../layout/ContentBody';
import * as Controller from "../../../controllers/indicadorsupervision-controller";
import * as Repository from "../../../repositories/indicadorsupervision-repository";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default function ConsultaIndicadorSupervision(): React.JSX.Element {
    const router = useNavigate()
    const [ListaConsulta, setListaConsulta] = React.useState([])
    const [FilterFechaInicio, setFilterFechaInicio] = React.useState(new Date)
    const [FilterFechaFinal, setFilterFechaFinal] = React.useState(new Date)
    const [FilterNombreCompleto, setFilterNombreApellidoDni] = React.useState('')
    React.useEffect(() => {
        Controller.doConsultar(setListaConsulta, FilterFechaInicio, FilterFechaFinal, FilterNombreCompleto )
    }, [])
    return (<div className="content-wrapper">
        <ContentHeader title={"Consulta Supervision "} homePath={"Home"} currentPath={"Mantenimiento Supervision "} >
        </ContentHeader>
        <ContentBody title={"Consulta Supervision"}>
            
        <div className='row mt-5'>
                <div className='col-lg-2'>
                    <label>Fecha Inicio</label>
                </div>
                <div className='col-lg-4'>
                    <div className='form-group'>
                        <DatePicker locale="es" className='form-control-text' popperPlacement='top-end' selected={FilterFechaInicio} onChange={(date: any) => setFilterFechaInicio(date)} dateFormat={"dd/MM/yyyy"}   />
                    </div>
                </div>
                <div className='col-lg-2'>
                    <label>Fecha Final</label>
                </div>
                <div className='col-lg-4'>
                    <div className='form-group'>
                        <DatePicker locale="es" className='form-control-text' popperPlacement='top-end' selected={FilterFechaFinal} onChange={(date: any) => setFilterFechaFinal(date)} dateFormat={"dd/MM/yyyy"} />
                    </div>
                </div>
            </div>
                <div className='row mt-5'>
                    <div className='col-lg-2'>
                        <label> Nombre Apellido  / DNI</label>
                    </div>
                    <div className='col-lg-4'>
                        <div className='form-group'>
                            <input type="text" className="form-control-text" value={FilterNombreCompleto}
                                onChange={e => { setFilterNombreApellidoDni(e.currentTarget.value); }} />
                        </div>
                    </div>
                </div>
            <div className='row mt-2 mb-2'>
                <div className='col-gl-6'>
                    <div className='form-group'>

                        <button className='btn btn-info' onClick={() => Controller.doConsultar(setListaConsulta, FilterFechaInicio, FilterFechaFinal, FilterNombreCompleto)}>Consultar</button>
                        <button className='btn btn-success' onClick={() => Controller.doExportar(FilterFechaInicio, FilterFechaFinal)}  >Exportar</button>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className='form-group' >
                    <MaterialReactTable columns={Repository.GetColumns()} data={ListaConsulta}

                        renderRowActions={({ row, table }) => (
                            <div>
                            </div>
                        )} />
                </div>
                </div>
            </div>
        </ContentBody>
    </div>
    )
}
