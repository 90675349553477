
import { NavigateFunction } from 'react-router-dom';
import * as Repository from "../repositories/pregunta-repository";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';
import { ToastContainer, toast } from 'react-toastify';    
import 'react-toastify/dist/ReactToastify.css';
import { CODE_SERVER_RETURN } from '../utils/contants';
export function toNuevo(router: NavigateFunction) {
    router("/main/pregunta/mantenimiento/nuevo")
}
export function toConsulta(router: NavigateFunction) {
    router("/main/pregunta/consulta")
}
export async function doConsultar(setListaConsulta: any, FilterTipoArea: number, FilterTipoGrupo: number) {
    var dataResponse = await Repository.getData(FilterTipoArea, FilterTipoGrupo)
    setListaConsulta(dataResponse)
}
export async function toEditar(router: NavigateFunction, row: any) {
    var codeEditar = row.CodePregunta
    router("/main/pregunta/mantenimiento/" + codeEditar)
}
export async function toQuestionInactivaActiva(row: any, setListaConsulta: any) {
    const MySwal = withReactContent(Swal)
    var question = row.Estado == 1 ? "¿Desea eliminar el registro?" : "¿Desea activar el registro?"

    MySwal.fire({
        title: <p>{question}</p>,
        icon: "question",
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: "Sí",
        cancelButtonText: "Cancelar"

    }).then((result: any) => {
        if (result.isConfirmed) {
            toInactivaActiva(row, setListaConsulta)
        }
    })
}
async function toInactivaActiva(row: any, setListaConsulta: any) {
    var codeEliminar = row.CodePregunta
    const objSave = {
        CodePregunta: codeEliminar
    }
    if (row.Estado == 1) {
        var dataResponse = await Repository.inactiva(objSave)
    } else if (row.Estado == 0) {
        var dataResponse = await Repository.activa(objSave)
    }
    doConsultar(setListaConsulta, 0, 0,)
}
export async function getByCode(code: any, setIdTipoGrupo: any, setIdTipoArea: any, setListaPregunta: any, setListaComboTipoGrupo: any) {

    var dataResponse = await Repository.getByCode(code)
    if (dataResponse != null) {
        setIdTipoArea(dataResponse.IdTipoArea)
        
        if (dataResponse.IdTipoArea == 1) { //SUPERVISION
            getListaComboTipoGrupoSupervision(dataResponse. IdTipoArea,setListaComboTipoGrupo);
          } else {
            getListaComboTipoGrupoIncidencia(dataResponse.IdTipoArea, setListaComboTipoGrupo);
          }
        setIdTipoGrupo(dataResponse.IdTipoGrupo)

        if (dataResponse.PreguntaDetalle != null) {
            setListaPregunta(dataResponse.PreguntaDetalle)
        } else {
            setListaPregunta([])
        }
    }
}
export async function save(router: NavigateFunction, codePregunta: string, idTipoGrupo: number, idTipoArea: number, listapregunta: any) {

    const MySwal = withReactContent(Swal)
    var question = "¿Desea guardar el registro?"
    MySwal.fire({
        title: <p>{question}</p>,
        icon: "question",
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: "Sí",
        cancelButtonText: "Cancelar"
    }).then(async (result: any) => {
        if (result.isConfirmed) {

            var newListaPregunta = listapregunta.map((item: any) => {
                var newObj = {
                    DesPregunta: item.DesPregunta,
                    CodeRespuesta: item.CodeRespuesta,
                    DesOpcionRespuesta: item.DesOpcionRespuesta,
                    TomarFoto: item.TomarFoto,
                   
                }
                console.log(item)
                return newObj
            })

            console.log("Code a actualizar: " + codePregunta)
            if (codePregunta != null && codePregunta != "") {
                const objSave = {
                    CodePregunta: codePregunta,
                    IdTipoGrupo: Number.parseInt(idTipoGrupo.toString()),
                    IdTipoArea: Number.parseInt(idTipoArea.toString()),
                    PreguntaDetalle: newListaPregunta,

                }
                var dataResponse = await Repository.update(objSave)

                if (dataResponse.Code == CODE_SERVER_RETURN.VAL){
                    MySwal.fire({
                        title: <p>{dataResponse.Message}</p>,
                        icon: "warning",
                        showConfirmButton: true,
                        showCancelButton: false,
                        confirmButtonText: "Ok"
                    });
                } else if(dataResponse.Code == CODE_SERVER_RETURN.ERROR) {
                    MySwal.fire({
                        title: <p>{dataResponse.Message}</p>,
                        icon: "error",
                        showConfirmButton: true,
                        showCancelButton: false,
                        confirmButtonText: "OK",
                        
                    });
                } else {
                    toast.success('Guardado satisfactoriamente!')
                    toConsulta(router)
                  
                }
            } else {
                const objSave = {
                    IdTipoGrupo: Number.parseInt(idTipoGrupo.toString()),
                    IdTipoArea: Number.parseInt(idTipoArea.toString()),
                    PreguntaDetalle: newListaPregunta,

                }
                var dataResponse = await Repository.save(objSave)
                if (dataResponse.Code == CODE_SERVER_RETURN.VAL){
                    MySwal.fire({
                        title: <p>{dataResponse.Message}</p>,
                        icon: "warning",
                        showConfirmButton: true,
                        showCancelButton: false,
                        confirmButtonText: "Ok"
                    });
                } else if(dataResponse.Code == CODE_SERVER_RETURN.ERROR) {
                    MySwal.fire({
                        title: <p>{dataResponse.Message}</p>,
                        icon: "error",
                        showConfirmButton: true,
                        showCancelButton: false,
                        confirmButtonText: "OK",
                    });
                }else {
                    toast.success('Guardado satisfactoriamente!')
                    toConsulta(router)
                }
            }
        }
    })
}
export async function Mensaje() {
    const MySwal = withReactContent(Swal)
    var message = "Tiene que completar los campos"
    MySwal.fire({
        title: <p>{message}</p>,
        icon: "warning",
        showConfirmButton: true,
        confirmButtonText: "Aceptar",
    }
    )
}
export async function getListaComboTipoGrupoSupervision(idTipoArea: number, setListaComboTipoGrupo: any) {

    if (idTipoArea != 0 && idTipoArea != 0) {
        var dataResponse = await Repository.getListaComboTipoGrupoSupervision(idTipoArea)
        setListaComboTipoGrupo(dataResponse)

    } else {
        setListaComboTipoGrupo([])

    }
}

export async function getListaComboTipoGrupoIncidencia(idTipoArea: number, setListaComboTipoGrupo: any) {

    if (idTipoArea != 0 && idTipoArea != 0) {
        var dataResponse = await Repository.getListaComboTipoGrupoIncidencia(idTipoArea)
        setListaComboTipoGrupo(dataResponse)
    } else {
        setListaComboTipoGrupo([])
    }
}
export async function getListaComboTipoArea(setListaComboTipoArea: any) {
    var dataResponse = await Repository.getListaComboTipoArea()
    setListaComboTipoArea(dataResponse)
}

export async function doExportar(FilterTipoGrupo: number, FilterTipoArea: number) {
    var dataResponse = await Repository.doExport(FilterTipoGrupo, FilterTipoArea)

}

export function addMember(codeRespuesta: string, desPregunta: string, tomarFoto: boolean, ListaPregunta: any, listacomborespuesta: any, setListaPregunta: any, setCodeRespuesta: any, setDesPregunta: any) {

    var desOpcionRespuesta = ""
    listacomborespuesta.forEach((item: any) => {
        
        if (item.Id == codeRespuesta) {
            desOpcionRespuesta = item.Texto
            console.log(item)
            return
        }
    });

    var existe = false
    ListaPregunta.forEach((item: any) => {
        if (item.DesPregunta == desPregunta) {
            console.log(item)
            existe = true
            return
        }
    });

    console.log("Existe?: " + existe)

    if (!existe) {
        var objMember = {
            CodeRespuesta: codeRespuesta,
            DesPregunta: desPregunta,
            DesOpcionRespuesta: desOpcionRespuesta,
            DesFoto:  tomarFoto ? "Sí" : "No",
            TomarFoto:  tomarFoto 
        }
        var newListaPregunta = ListaPregunta.map((item: any) => { return item })
        newListaPregunta.push(objMember)
        setListaPregunta(newListaPregunta)
        setCodeRespuesta("")
        setDesPregunta("")
    } else {
        //CUANDO EL ITEM YA EXISTE
    }
}

export function toQuestionEliminarMiembro(row: any, ListaPregunta: any, setListaPregunta: any) {
    var indice = -1
    ListaPregunta.forEach((item: any, index: number) => {
        if (item.CodePreguntaDetalle == row.CodePreguntaDetalle) {
            console.log(item)
            indice = index
            return
        }
    });
    if (indice > -1) {
        ListaPregunta.splice(indice, 1);
        var newListaPregunta = ListaPregunta.map((item: any) => { return item })
        setListaPregunta(newListaPregunta)
    }
}

export async function getListaComboPregunta(setListaComboMiembroEquipo: any) {
    var dataResponse = await Repository.getListaComboPregunta()
    setListaComboMiembroEquipo(dataResponse)
}