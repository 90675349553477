import * as ClientAPI from "../utils/methods";
import * as Endpoints from "../api/endpoint";
import Moment from 'moment';


export async function getListaComboTipoExhibicion(){
  var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.GET_LISTA_COMBO_TIPOEXHIBICION, "")
  if(dataResponse.Data != null){
    return dataResponse.Data;
  }else {
    return null
  }
}

export async function getListaComboMarcaExhibicion() {
  var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint. GET_LISTA_COMBO_MARCAEXHIBICION , "")
  if (dataResponse.Data != null) {
    return dataResponse.Data;
  } else {
    return null
  }
}
export async function geListaImagenProducto(FilterFecha : Date, codeTienda :string, codeMarcaExhibicion : string, codeTipoExhibicion : string )
  
{ codeTienda = (codeTienda == "" || codeTienda == "0")? "-": codeTienda
   codeMarcaExhibicion = (codeMarcaExhibicion == "" || codeMarcaExhibicion == "0")? "-": codeMarcaExhibicion
 
  var CFilterFecha = Moment(FilterFecha).format("DD-MM-YYYY")
  var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.GET_LISTA_EXHIBICION_IMAGEN + "/" + CFilterFecha + "/" + codeTienda  + "/" + codeMarcaExhibicion + "/" + codeTipoExhibicion ,  "")
 console.log(dataResponse)
 
  if (dataResponse.Data != null) {
    return dataResponse.Data;
  } else {
    return []
  }
}
export async function getListaComboTienda(setCodeCadenaMaster : string) {
  var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint. GET_LISTA_COMBO_TIENDA +  "/" + setCodeCadenaMaster , "")
  if (dataResponse.Data != null) {
    return dataResponse.Data;
  } else {
    return null
  }
}
export async function getListaComboCadenaMaster(){
  var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_SECURITY + Endpoints.ListEndpoint.GET_LISTA_COMBO_CADENAMASTER, "")
  if(dataResponse.Data != null){
    return dataResponse.Data;
  }else {
    return null
  }
}