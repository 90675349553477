

import { useMemo } from 'react';
import { type MRT_ColumnDef } from 'material-react-table';
import * as ClientAPI from "../utils/methods"; 
import * as Endpoints from "../api/endpoint"; 

type Categoria = {
  IdCategoria: string,
  NombreCategoria: string,
  NombreMarca: string,
};
export function GetColumns() {
  const columns = useMemo<MRT_ColumnDef<Categoria>[]>(
    () => [
      {
        accessorKey: 'IdSubCategoria',
        header: 'ID',
        size: 20,
      },
      {
        accessorKey: 'NombreSubCategoria',
        header: 'Sub Categoria',
        size: 200,
      },

      {
        accessorKey: 'NombreCategoria',
        header: ' Categoria',
        size: 200,
      },
    ],
    [],
  );
  ;
  return columns
}
export async function getData(FilterCategoria :string, FilterNombreSubCategoria: string) {
  FilterCategoria = (FilterCategoria == "" || FilterCategoria == "0")? "-": FilterCategoria
 

  var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.GET_LISTA_SUB_CATEGORIA + "/" + FilterCategoria + "/" + FilterNombreSubCategoria, "")
  if (dataResponse.Data != null) {
    return dataResponse.Data;
  } else {
    return []
  }
}
export async function getByCode(code: string) {
  var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.GET_SUB_CATEGORIA_BYCODE + "/" + code, "")
  return dataResponse.Data;
}
export async function save(objSave: any) {
  var dataResponse = await ClientAPI.RequestPost(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.INSERTAR_SUB_CATEGORIA, "", objSave)
  if (dataResponse != null) {
    return dataResponse;
  } else {
    return null
  }
}
export async function update(objSave: any) {
  var dataResponse = await ClientAPI.RequestPost(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.UPDATE_SUB_CATEGORIA, "", objSave)
  if (dataResponse != null) {
    return dataResponse;
  } else {
    return null
  }
}
export async function inactiva(objSave: any) {
  var dataResponse = await ClientAPI.RequestPost(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.INACTIVO_SUBCATEGORIA, "", objSave)
  if (dataResponse.Data != null) {
    return dataResponse.Data;
  } else {
    return null
  }
}
export async function activa(objSave: any) {
  var dataResponse = await ClientAPI.RequestPost(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.ACTIVO_SUBCATEGORIA, "", objSave)
  if (dataResponse.Data != null) {
    return dataResponse.Data;
  } else {
    return null
  }
} 


export async function getListaComboCategoria(){
  var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.GET_LISTA_COMBO_CATEGORIAS, "")
  if(dataResponse.Data != null){
    return dataResponse.Data;
  }else {
    return null
  }
}

export async function doExport(FilterCategoria :string, FilterNombreSubCategoria: string) {
  FilterCategoria = (FilterCategoria == "" || FilterCategoria == "0")? "-": FilterCategoria
 

  var dataResponse = await ClientAPI.RequestDownload(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.GET_LISTA_SUB_CATEGORIA_EXCEL  + "/" + FilterCategoria  + "/" + FilterNombreSubCategoria, 'DescargarExcelSubCategoria.xlsx')
  
}