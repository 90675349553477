
import { NavigateFunction } from 'react-router-dom';
import * as Repository from "../repositories/cadenamaster-repository";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { CODE_SERVER_RETURN, UPLOAD_FILESCADENAMASTER } from '../utils/contants';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export function toNuevo(router: NavigateFunction) {
    router("/main/cadenamaster/mantenimiento/nuevo")
}

export function toConsulta(router: NavigateFunction) {
    router("/main/cadenamaster/consulta")
}
export async function doConsultar(setListaConsulta: any, FilterNombreCadenaMaster: string) {
    var dataResponse = await Repository.getData(FilterNombreCadenaMaster)
    setListaConsulta(dataResponse)
}
export async function doExportar(FilterNombreCadenaMaster: string) {
    Repository.doExport(FilterNombreCadenaMaster)
}

export async function toEditar(router: NavigateFunction, row: any) {
    console.log(row)
    var codeEditar = row.CodeCadenaMaster
    router("/main/cadenamaster/mantenimiento/" + codeEditar)
}
export async function toQuestionInactivaActiva(row: any, setListaConsulta: any) {
    console.log(row)

    const MySwal = withReactContent(Swal)

    var question = row.Estado == 1 ? "¿Desea eliminar el registro?" : "¿Desea activar el registro?"

    MySwal.fire({
        title: <p>{question}</p>,
        icon: "question",
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: "Sí",
        cancelButtonText: "Cancelar"

    }).then((result: any) => {
        if (result.isConfirmed) {
            toInactivaActiva(row, setListaConsulta)
        }
    })
}
async function toInactivaActiva(row: any, setListaConsulta: any) {
    console.log(row)
    var codeEliminar = row.CodeCadenaMaster
    const objSave = {
        CodeCadenaMaster: codeEliminar
    }
    if (row.Estado == 1) {
        var dataResponse = await Repository.inactiva(objSave)
    } else if (row.Estado == 0) {
        var dataResponse = await Repository.activa(objSave)
    }
    doConsultar(setListaConsulta, "")
}
export async function getByCode(code: any, setNombreCadenaMaster: any, setUrlImagen: any, setRazonSocial: any) {

    var dataResponse = await Repository.getByCode(code)
    if (dataResponse != null) {
        setNombreCadenaMaster(dataResponse.NombreCadenaMaster)
        setUrlImagen(dataResponse.UrlImagen)
        setRazonSocial(dataResponse.RazonSocial)
    }
}

export async function save(router: NavigateFunction, codeCadenaMaster: string, nombreCadenaMaster: string, file: any, urlImagen: string, razonSocial: string) {

    const MySwal = withReactContent(Swal)
    var question = "¿Desea guardar el registro?"
    MySwal.fire({
        title: <p>{question}</p>,
        icon: "question",
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: "Sí",
        cancelButtonText: "Cancelar"

    }).then(async (result: any) => {
        if (result.isConfirmed) {

            // Subir Archivo
            var URLFile = urlImagen == null || urlImagen == "" ? null : urlImagen
            if (file != null) {
                const formDataToUpload = new FormData();
                const tipoBucket = UPLOAD_FILESCADENAMASTER.USUARIO_FOTO_CADENAMASTER; // cadenamaster
                formDataToUpload.append('FileImage', file);
                formDataToUpload.append('TipoBucket', tipoBucket.toString());
                var dataResponseURL = await Repository.uploadFile(formDataToUpload)
                if (dataResponseURL != null && dataResponseURL.Code === 0) {
                    URLFile = dataResponseURL.Data.PathFile
                }
            }

            console.log("Code a actualizar: " + codeCadenaMaster)
            if (codeCadenaMaster != null && codeCadenaMaster != "") {
                const objSave = {
                    CodeCadenaMaster: codeCadenaMaster,
                    NombreCadenaMaster: nombreCadenaMaster,
                    UrlImagen: URLFile,
                    RazonSocial: razonSocial,
                }
                var dataResponse = await Repository.update(objSave)
                if (dataResponse.Code == CODE_SERVER_RETURN.VAL) {
                    MySwal.fire({
                        title: <p>{dataResponse.Message}</p>,
                        icon: "warning",
                        showConfirmButton: true,
                        showCancelButton: false,
                        confirmButtonText: "Ok"
                    });
                } else if (dataResponse.Code == CODE_SERVER_RETURN.ERROR) {
                    MySwal.fire({
                        title: <p>{dataResponse.Message}</p>,
                        icon: "error",
                        showConfirmButton: true,
                        showCancelButton: false,
                        confirmButtonText: "OK",

                    });
                } else {
                    toast.success('Guardado satisfactoriamente!')
                    toConsulta(router)
                }
            } else {
                const objSave = {
                    NombreCadenaMaster: nombreCadenaMaster,
                    UrlImagen: URLFile,
                    RazonSocial: razonSocial,
                }
                var dataResponse = await Repository.save(objSave)
                if (dataResponse.Code == CODE_SERVER_RETURN.VAL) {
                    MySwal.fire({
                        title: <p>{dataResponse.Message}</p>,
                        icon: "warning",
                        showConfirmButton: true,
                        showCancelButton: false,
                        confirmButtonText: "Ok"
                    });
                } else if (dataResponse.Code == CODE_SERVER_RETURN.ERROR) {
                    MySwal.fire({
                        title: <p>{dataResponse.Message}</p>,
                        icon: "error",
                        showConfirmButton: true,
                        showCancelButton: false,
                        confirmButtonText: "OK",

                    });

                } else {
                    toast.success('Guardado satisfactoriamente!')
                    toConsulta(router)
                }
            }
        }
    })
}

export async function Mensaje() {
    const MySwal = withReactContent(Swal)
    var message = "Tiene que completar los campos"
    MySwal.fire({
        title: <p>{message}</p>,
        icon: "warning",
        showConfirmButton: true,
        confirmButtonText: "Aceptar",
    }
    )
}

export async function GuardarDocumentos(router: NavigateFunction, codeCadenaMaster: string, ListaDocumentos: any) {
    var newListaDocumentos = ListaDocumentos.map((item: any) => {
        var newObj = {
            CodeDocumento: item.CodeDocumento
        }
        return newObj
    })
    console.log("Code a actualizar: " + codeCadenaMaster)
    if (codeCadenaMaster != null && codeCadenaMaster != "") {
        const objSave = {

            CodeCadenaMaster: codeCadenaMaster,
            documentos: newListaDocumentos,
        }
        var dataResponse = await Repository.saveCD(objSave)
    }
}

export async function getListaComboDocumento(setListaComboDocumento: any) {
    var dataResponse = await Repository.getListaComboDocumento()
    setListaComboDocumento(dataResponse)
}

export function addMember(documentos: string, ListaComboDocumentos: any, ListaDocumentos: any, setListaDocumentos: any) {
    console.log(documentos)
    var NombreDocumento = ""
    ListaComboDocumentos.forEach((item: any) => {
        if (item.Id == documentos) {
            NombreDocumento = item.Texto
            return
        }
    });
    var existe = false
    ListaDocumentos.forEach((item: any) => {
        if (item.CodeCadena == documentos) {
            console.log(item)
            existe = true
            return
        }
    });
    console.log("Existe?: " + existe)

    if (!existe) {
        var objMember = {
            CodeDocumento: documentos,
            NombreDocumento: NombreDocumento
        }
        var newListaDocumentos = ListaDocumentos.map((item: any) => { return item })
        newListaDocumentos.push(objMember)
        setListaDocumentos(newListaDocumentos)
    } else {
    }
}
export function toQuestionEliminarTiendas(row: any, ListaCadenas: any, setListaDocumentos: any) {
    var indice = -1
    ListaCadenas.forEach((item: any, index: number) => {
        if (item.CodeDocumento == row.CodeDocumento) {
            console.log(item)
            indice = index
            return
        }
    });

    if (indice > -1) {
        ListaCadenas.splice(indice, 1);
        var newListaCadenas = ListaCadenas.map((item: any) => { return item })
        setListaDocumentos(newListaCadenas)
    }
}

export async function saveDocumentos(router: NavigateFunction, codeCadena: string, ListaDocumentos: any) {
    var newListaDocumentos = ListaDocumentos.map((item: any) => {
        var newObj = {
            CodeDocumento: item.CodeDocumento
        }
        return newObj
    })
    console.log("Code a actualizar: " + codeCadena)
    if (codeCadena != null && codeCadena != "") {
        const objSave = {

            CodeCadena: codeCadena,
            documentos: newListaDocumentos,
        }
        var dataResponse = await Repository.saveCD(objSave)
    }
}

export async function geListaCadenaDocumentoDetalleByCodeCadena(code: any, setListaDocumentos: any) {
    var dataResponse = await Repository.geListaCadenaDocumentoDetalleByCodeCadena(code)
    if (dataResponse != null) {
        setListaDocumentos(dataResponse)
    }
}
