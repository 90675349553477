import { NavigateFunction } from "react-router-dom";
import withReactContent from "sweetalert2-react-content";
import * as Repository from "../repositories/respuesta-repository";
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import { CODE_SERVER_RETURN } from "../utils/contants";
export function toNuevo(router: NavigateFunction) {
  router("/main/respuesta/mantenimiento/nuevo");
}
export function toConsulta(router: NavigateFunction) {
  router("/main/respuesta/consulta");
}
export async function toEditar(router: NavigateFunction, row: any) {
  var codeEditar = row.CodeRespuesta;
  router("/main/respuesta/mantenimiento/" + codeEditar);
}
export async function toQuestionInactivaActiva(
  row: any,
  setListaConsulta: any
) {
  const MySwal = withReactContent(Swal);
  var question =
    row.Estado == 1
      ? "¿Desea eliminar el registro?"
      : "¿Desea activar el registro?";
  MySwal.fire({
    title: <p>{question}</p>,
    icon: "question",
    showConfirmButton: true,
    showCancelButton: true,
    confirmButtonText: "Sí",
    cancelButtonText: "Cancelar",
  }).then((result: any) => {
    if (result.isConfirmed) {
      toInactivaActiva(row, setListaConsulta);
    }
  });
}
async function toInactivaActiva(row: any, setListaConsulta: any) {
  var codeEliminar = row.CodeRespuesta;
  const objSave = {
    CodeRespuesta: codeEliminar,
  };
  if (row.Estado == 1) {
    var dataResponse = await Repository.inactiva(objSave);
  } else if (row.Estado == 0) {
    var dataResponse = await Repository.activa(objSave);
  }
  doConsultar(setListaConsulta, "");
}
export async function getByCode(
  code: any,
  setDesOpcionRespuesta: any,
  setListaOpcion: any
) {
  var dataResponse = await Repository.getByCode(code);
  if (dataResponse != null) {
    setDesOpcionRespuesta(dataResponse.DesOpcionRespuesta);
    if (dataResponse.RespuestaDetalle != null) {
      setListaOpcion(dataResponse.RespuestaDetalle);
    } else {
      setListaOpcion([]);
    }
  }
}
export async function doConsultar(
  setListaConsulta: any,
  FilterDesOpcionRespuesta: string
) {
  var dataResponse = await Repository.getData(FilterDesOpcionRespuesta);
  setListaConsulta(dataResponse);
}
export async function save(
  router: NavigateFunction,
  codeRespuesta: string,
  desopcionRespuesta: string,
  ListaOpcion: any
) {
  const MySwal = withReactContent(Swal);
  var question = "¿Desea guardar el registro?";
  MySwal.fire({
    title: <p>{question}</p>,
    icon: "question",
    showConfirmButton: true,
    showCancelButton: true,
    confirmButtonText: "Sí",
    cancelButtonText: "Cancelar",
  }).then(async (result: any) => {
    if (result.isConfirmed) {
      var newListaOpcion = ListaOpcion.map((item: any) => {
        var newObj = {
          DesRespuesta: item.DesRespuesta,
        };
        return newObj;
      });
      console.log("Code a actualizar: " + codeRespuesta);
      if (codeRespuesta != null && codeRespuesta != "") {
        const objSave = {
          CodeRespuesta: codeRespuesta,
          DesOpcionRespuesta: desopcionRespuesta,
          RespuestaDetalle: newListaOpcion,
        };
        var dataResponse = await Repository.update(objSave);
        if (dataResponse.Code == CODE_SERVER_RETURN.VAL) {
          MySwal.fire({
            title: <p>{dataResponse.Message}</p>,
            icon: "warning",
            showConfirmButton: true,
            showCancelButton: false,
            confirmButtonText: "Ok",
          });
        } else {
          toast.success("Guardado satisfactoriamente!");
          toConsulta(router);
        }
      } else {
        const objSave = {
          DesOpcionRespuesta: desopcionRespuesta,
          RespuestaDetalle: newListaOpcion,
        };
        var dataResponse = await Repository.save(objSave);

        if (dataResponse.Code == CODE_SERVER_RETURN.VAL) {
          MySwal.fire({
            title: <p>{dataResponse.Message}</p>,
            icon: "warning",
            showConfirmButton: true,
            showCancelButton: false,
            confirmButtonText: "Ok",
          });
        } else {
          toast.success("Guardado satisfactoriamente!");
          toConsulta(router);
        }
      }
    }
  });
}
export async function Mensaje() {
  const MySwal = withReactContent(Swal);
  var message = "Tiene que completar los campos";
  MySwal.fire({
    title: <p>{message}</p>,
    icon: "warning",
    showConfirmButton: true,
    confirmButtonText: "Aceptar",
  });
}

export async function doExportar(FilterNombreCliente: string) {
  var dataResponse = await Repository.doExport(FilterNombreCliente);
}

export function addMember(
  desRespuesta: string,
  ListaRespuesta: any,
  setListaRespuesta: any,
  setDesRespuesta: any
) {
  console.log(desRespuesta);

  var existe = false;
  ListaRespuesta.forEach((item: any) => {
    if (item.DesRespuesta == desRespuesta) {
      console.log(item);
      existe = true;
      return;
    }
  });
  console.log("Existe?: " + existe);

  if (!existe) {
    var objMember = {
      DesRespuesta: desRespuesta,
    };
    var newListaOpcion = ListaRespuesta.map((item: any) => {
      return item;
    });
    newListaOpcion.push(objMember);
    setListaRespuesta(newListaOpcion);
    setDesRespuesta("");
  } else {
  }
}
export function toQuestionEliminarOpcion(
  row: any,
  ListaRespuesta: any,
  setListaRespuesta: any
) {
  var indice = -1;
  ListaRespuesta.forEach((item: any, index: number) => {
    if (item.CodeRespuestaDetalle == row.CodeRespuestaDetalle) {
      console.log(item);
      indice = index;
      return;
    }
  });

  if (indice > -1) {
    ListaRespuesta.splice(indice, 1);
    var newListaOpcion = ListaRespuesta.map((item: any) => {
      return item;
    });
    setListaRespuesta(newListaOpcion);
  }
}
