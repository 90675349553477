"use client";

import ContentHeader from "../layout/ContentHeader";
import * as Controller from "../../../controllers/tiendamaster-controller";
import React from "react";
import SelectSimple from "../layout/SelectSimple";
import Textbox from "../layout/Textbox";
import Label from "../layout/Label";
import { useParams, useNavigate } from "react-router-dom";

export default function NuevoTiendaMaster() {
  const [ListaComboCadenaMaster, setListaComboCadenaMaster] = React.useState(
    []
  );
  const [ListaComboUbigeo, setListaComboUbigeo] = React.useState([]);
  const [ListaComboDepartamento, setListaComboDepartamento] = React.useState(
    []
  );
  const [ListaComboProvincia, setListaComboProvincia] = React.useState([]);
  const { code } = useParams();
  const Router = useNavigate();
  const [codeTiendaMaster, setCodeTiendaMaster] = React.useState("");
  const [codeCadenaMaster, setCodeCadenaMaster] = React.useState("");
  const [codigoTienda, setCodigoTienda] = React.useState("");
  const [nombreTiendaMaster, setNombreTiendaMaster] = React.useState("");
  const [direccion, setDireccion] = React.useState("");
  const [ubigeo, setUbigeo] = React.useState("");
  const [provincia, setProvincia] = React.useState("");
  const [departamento, setDepartamento] = React.useState("");
  const [latitud, setLatitud] = React.useState(0.0);
  const [longitud, setLongitud] = React.useState(0.0);
  const [tituloVentana, setTituloVentana] = React.useState(
    "Nueva Tienda Master"
  );
  const [ValCodeCadena, setValCodeCadena] = React.useState(false);
  const [ValCodigoTienda, setValCodigoTienda] = React.useState(false);
  const [ValNombreTienda, setValNombreTienda] = React.useState(false);
  const [ValDireccion, setValDireccion] = React.useState(false);
  const [ValUbigeo, setValUbigeo] = React.useState(false);
  const [ValDepartamento, setValDepartamento] = React.useState(false);
  const [ValProvincia, setValProvincia] = React.useState(false);
  const [isSubmit, setIsSubmit] = React.useState(false);

  const onchangeDepartamento = (e: any) => {
    if (
      e.target.value != null &&
      e.target.value != "" &&
      e.target.value != "0"
    ) {
      Controller.getListaComboProvincia(e.target.value, setListaComboProvincia);
    } else {
      setListaComboProvincia([]);
    }
    setListaComboUbigeo([]);
  };

  const onChangeProvincia = (e: any) => {
    if (
      e.target.value != null &&
      e.target.value != "" &&
      e.target.value != "0"
    ) {
      Controller.getListaComboUbigeo(e.target.value, setListaComboUbigeo);
    } else {
      setListaComboUbigeo([]);
    }
  };

  React.useEffect(() => {
    Controller.getListaComboDepartamento(setListaComboDepartamento);
    Controller.getListaComboCadenaMaster(setListaComboCadenaMaster);
    if (code != "nuevo") {
      setTituloVentana("Editar Tienda Master");
      setCodeTiendaMaster(code!);
      Controller.getByCode(
        code,
        setProvincia,
        setListaComboProvincia,
        setUbigeo,
        setListaComboUbigeo,
        setDepartamento,
        setCodeCadenaMaster,
        setCodigoTienda,
        setNombreTiendaMaster,
        setDireccion,
        setLatitud,
        setLongitud
      );
    }
  }, []);
  function ValidateCampos() {
    var Result = true;
    setIsSubmit(true);
    if (codigoTienda == "" || codigoTienda == null) {
      Result = false;
      setValCodigoTienda(false);
    } else {
      setValCodigoTienda(true);
    }
    if (nombreTiendaMaster == "" || nombreTiendaMaster == null) {
      Result = false;
      setValNombreTienda(false);
    } else {
      setValNombreTienda(true);
    }
    if (direccion == "" || direccion == null) {
      Result = false;
      setValDireccion(false);
    } else {
      setValDireccion(true);
    }
    if (ubigeo == "" || ubigeo == null) {
      Result = false;
      setValUbigeo(false);
    } else {
      setValUbigeo(true);
    }

    if (
      codeCadenaMaster == "" ||
      codeCadenaMaster == "0" ||
      codeCadenaMaster == null
    ) {
      Result = false;
      setValCodeCadena(false);
    } else {
      setValCodeCadena(true);
    }
    if (departamento == "" || departamento == "0" || departamento == null) {
      Result = false;
      setValDepartamento(false);
    } else {
      setValDepartamento(true);
    }
    if (provincia == "" || provincia == "0" || provincia == null) {
      Result = false;
      setValProvincia(false);
    } else {
      setValProvincia(true);
    }
    if (Result) {
      Controller.save(
        Router,
        codeTiendaMaster,
        codeCadenaMaster,
        codigoTienda,
        nombreTiendaMaster,
        direccion,
        ubigeo,
        latitud,
        longitud
      );
    } else {
      Controller.Mensaje();
    }
  }
  return (
    <div className="content-wrapper">
      <ContentHeader
        title={tituloVentana}
        homePath={"Home"}
        currentPath={tituloVentana}
      ></ContentHeader>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card card-primary">
                <div className="card-header">
                  <div className="d-flex flex-row gap-2">
                    <div className="align-items-center">
                      <i
                        className="bx bx-arrow-back arrow-back"
                        onClick={() => Controller.toConsulta(Router)}
                      ></i>
                    </div>
                    <div className="">
                      <h4 className="card-title">{tituloVentana}</h4>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-1">
                      <h4>Datos</h4>
                      <hr />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-2">
                      <Label required={true}>Cadena</Label>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <SelectSimple
                          value={codeCadenaMaster}
                          valueError={ValCodeCadena}
                          setValue={setCodeCadenaMaster}
                          setValueError={setValCodeCadena}
                          isSubmit={isSubmit}
                          lista={ListaComboCadenaMaster}
                        >
                          --Selecionar--
                        </SelectSimple>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-2">
                      <Label required={true}>Direccion</Label>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <Textbox
                          value={direccion}
                          valueError={ValDireccion}
                          setValue={setDireccion}
                          setValueError={setValDireccion}
                          isSubmit={isSubmit}
                          maxLenght={100}
                          upperCase={true}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-2">
                      <Label required={true}>Codigo Tienda</Label>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <Textbox
                          value={codigoTienda}
                          valueError={ValCodigoTienda}
                          setValue={setCodigoTienda}
                          setValueError={setValCodigoTienda}
                          isSubmit={isSubmit}
                          maxLenght={20}
                          upperCase={true}
                        />
                      </div>
                    </div>
                    <div className="col-lg-2">
                      <Label required={true}>Nombre Tienda</Label>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <Textbox
                          value={nombreTiendaMaster}
                          valueError={ValNombreTienda}
                          setValue={setNombreTiendaMaster}
                          setValueError={setValNombreTienda}
                          isSubmit={isSubmit}
                          upperCase={true}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-2">
                      <label>Longitud</label>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <Textbox value={longitud} setValue={setLongitud} />
                      </div>
                    </div>
                    <div className="col-lg-2">
                      <label>Latitud</label>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <Textbox value={latitud} setValue={setLatitud} />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-2">
                      <Label required={true}>Departamentos</Label>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <SelectSimple
                          value={departamento}
                          setValue={setDepartamento}
                          valueError={ValDepartamento}
                          setValueError={setValDepartamento}
                          isSubmit={isSubmit}
                          lista={ListaComboDepartamento}
                          onChangeSection={onchangeDepartamento}
                        >
                          --Selecionar--
                        </SelectSimple>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-2">
                      <Label required={true}>Provincia</Label>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <SelectSimple
                          value={provincia}
                          setValue={setProvincia}
                          valueError={ValProvincia}
                          setValueError={setValProvincia}
                          isSubmit={isSubmit}
                          lista={ListaComboProvincia}
                          onChangeSection={onChangeProvincia}
                        >
                          --Selecionar--
                        </SelectSimple>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-2">
                      <Label required={true}>Ubigeo</Label>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <SelectSimple
                          value={ubigeo}
                          setValue={setUbigeo}
                          valueError={ValUbigeo}
                          setValueError={setValUbigeo}
                          isSubmit={isSubmit}
                          lista={ListaComboUbigeo}
                        >
                          --Selecionar--
                        </SelectSimple>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-5">
                    <div className="col-lg-6">
                      <div className="form-group">
                        <button
                          className="btn btn-default"
                          onClick={() => Controller.toConsulta(Router)}
                        >
                          Cancelar
                        </button>
                        <button
                          className="btn btn-primary"
                          onClick={() => ValidateCampos()}
                        >
                          Guardar
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
