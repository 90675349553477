"use client";

import ContentHeader from "../layout/ContentHeader";
import * as Controller from "../../../controllers/subcategoria-controller";
import React from "react";
import SelectSimple from "../layout/SelectSimple";
import Textbox from "../layout/Textbox";
import Label from "../layout/Label";
import { useParams, useNavigate } from "react-router-dom";
export default function NuevoSubCategoria() {
  const { code } = useParams();
  const Router = useNavigate();
  const [ListaComboCodeCategoria, setListaComboCategoria] = React.useState([]);
  const [codeSubCategoria, setCodeSubCategoria] = React.useState("");
  const [nombreSubCategoria, setNombreSubCategoria] = React.useState("");
  const [codeCategoria, setCodeCategoria] = React.useState("");
  const [valCodeCategoria, setValCodeCategoria] = React.useState(false);
  const [tituloVentana, setTituloVentana] =
    React.useState("Nueva SubCategoria");
  const [valnombreSubCategoria, setValNombreSubCategoria] =
    React.useState(false);
  const [isSubmit, setIsSubmit] = React.useState(false);
  React.useEffect(() => {
    Controller.getListaComboCategoria(setListaComboCategoria);
    if (code != "nuevo") {
      setTituloVentana("Editar SubCategoria");
      setCodeSubCategoria(code!);
      Controller.getByCode(code, setCodeCategoria, setNombreSubCategoria);
    }
  }, []);
  function validarCampos() {
    var Result = true;
    setIsSubmit(true);
    if (nombreSubCategoria == "" || nombreSubCategoria == null) {
      Result = false;
      setValNombreSubCategoria(false);
    } else {
      setValNombreSubCategoria(true);
    }
    if (Result) {
      Controller.save(
        Router,
        codeSubCategoria,
        nombreSubCategoria,
        codeCategoria
      );
    } else {
      Controller.Mensaje();
    }
    if (codeCategoria == "" || codeCategoria == "0" || codeCategoria == null) {
      Result = false;
      setValCodeCategoria(false);
    } else {
      setValCodeCategoria(true);
    }
  }
  return (
    <div className="content-wrapper">
      <ContentHeader
        title={tituloVentana}
        homePath={"Home"}
        currentPath={tituloVentana}
      ></ContentHeader>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card card-primary">
                <div className="card-header">
                  <div className="d-flex flex-row gap-2">
                    <div className="align-items-center">
                      <i
                        className="bx bx-arrow-back arrow-back"
                        onClick={() => Controller.toConsulta(Router)}
                      ></i>
                    </div>
                    <div className="">
                      <h4 className="card-title">{tituloVentana}</h4>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-6">
                      <h4>Datos</h4>
                      <hr />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-2">
                      <Label required={true}>Categoria</Label>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <SelectSimple
                          value={codeCategoria}
                          setValue={setCodeCategoria}
                          valueError={valCodeCategoria}
                          setValueError={setValCodeCategoria}
                          isSubmit={isSubmit}
                          lista={ListaComboCodeCategoria}
                        >
                          --Seleccion--
                        </SelectSimple>
                      </div>
                    </div>
                    <div className="col-lg-2">
                      <Label required={true}>Sub Categoria</Label>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <Textbox
                          value={nombreSubCategoria}
                          valueError={valnombreSubCategoria}
                          setValue={setNombreSubCategoria}
                          setValueError={setValNombreSubCategoria}
                          isSubmit={isSubmit}
                          upperCase={true}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mt-5">
                    <div className="col-lg-6">
                      <div className="form-group">
                        <button
                          className="btn btn-default"
                          onClick={() => Controller.toConsulta(Router)}
                        >
                          Cancelar
                        </button>
                        <button
                          className="btn btn-primary"
                          onClick={() => validarCampos()}
                        >
                          Guardar
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
