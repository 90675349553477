import * as Repository from "../repositories/analisisprecio-repository";

export async function getListaComboCadenaMaster(
  setListaComboCadenaMaster: any
) {
  var dataResponse = await Repository.getListaComboCadenaMaster();
  setListaComboCadenaMaster(dataResponse);
}

export async function getListaComboTienda(
  codeCadenaMaster: string,
  setListaComboTienda: any
) {
  if (codeCadenaMaster != "-" && codeCadenaMaster != "0") {
    var dataResponse = await Repository.getListaComboTienda(codeCadenaMaster);
    setListaComboTienda(dataResponse);
  } else {
    setListaComboTienda(dataResponse);
  }
}

export async function getListaComboMarca(setListaComboMarca: any) {
  var dataResponse = await Repository.getListaComboMarca();
  setListaComboMarca(dataResponse);
}
export async function getListaComboCategoria(
  codeMarca: string,
  setListaComboCategoria: any
) {
  if (codeMarca != "-" && codeMarca != "0") {
    var dataResponse = await Repository.getListaComboCategoria(codeMarca);
    setListaComboCategoria(dataResponse);
  } else {
    setListaComboCategoria(dataResponse);
  }
}
export async function geListaProducto(
  setListaProducto: any,
  codeMarca: string,
  codeCategoria: string
) {
  var dataResponse = await Repository.geListaProducto(codeMarca, codeCategoria);

  console.log(dataResponse);
  if (dataResponse != null) {
    setListaProducto(dataResponse);
  }
}

export async function getAnalisisPrecio(
  FechaInicial: Date,
  FechaFinal: Date,
  codeCadenaMaster: string,
  codeTienda: string,
  codeMarca: string,
  codeCategoria: string,
  codeProducto: string,
  setDataPrecio: any,
  setDataPrecioBar: any,
  setDataPrecioCompare: any,
  setOriginalDataPrecio: any,
  setOriginalDataPrecioBar: any,
  setOriginalDataPrecioCompare: any,
  setDataPrecioBarMax: any,
  setDataPrecioBarMin: any,
  setDataPrecioBarMode: any,
) {
  var dataResponse = await Repository.getAnalisisPrecio(
    FechaInicial,
    FechaFinal,
    codeCadenaMaster,
    codeTienda,
    codeMarca,
    codeCategoria,
    codeProducto
  );

  if (dataResponse != null) {
    var GraphicLine = dataResponse.GraphicLine;
    var GraphicBar = dataResponse.GraphicBar;
    var GraphicLineCompare = dataResponse.GraphicLineCompare;

    setGraphicData(GraphicLine, setDataPrecio, setOriginalDataPrecio);
    setGraphicData(GraphicBar, setDataPrecioBar, setOriginalDataPrecioBar);
    setGraphicData(GraphicLineCompare, setDataPrecioCompare, setOriginalDataPrecioCompare);
    setIndicator(GraphicBar, setDataPrecioBarMax, setDataPrecioBarMin, setDataPrecioBarMode);
  }
}

async function setIndicator(graphic: any, setDataStockBarMax: any, setDataStockBarMin: any, setDataStockBarMode: any) {
  setDataStockBarMax(graphic.Maximun);
  setDataStockBarMin(graphic.Minimun);
  setDataStockBarMode(graphic.Mode);
}


async function setGraphicData(graphic: any, setGraphic: any, setOriginalData: any) {
  var labels = graphic.Labels
  var dataResult = { labels, datasets: graphic.DataSets }
  console.log(dataResult)
  setGraphic(dataResult)
  setOriginalData(graphic)
}

export async function geListaComboProducto(
  codeProducto: string,
  setListaProducto: any
) {
  var dataResponse = await Repository.geListaComboProducto(codeProducto);

  console.log(dataResponse);
  if (dataResponse != null) {
    setListaProducto(dataResponse);
  }
}

export async function getListaDataPrecio(
  codeCadenaMaster: string,
  codeTienda: string,
  codeProducto: string,
  Fecha: string,
  setListaDataPrecio: any
) {
  var dataResponse = await Repository.getListaDataPrecio(
    codeCadenaMaster,
    codeTienda,
    codeProducto,
    Fecha
  );

  if (dataResponse != null) {
    setListaDataPrecio(dataResponse);
  }
}

export async function getListaDataPrecioBar(
  FechaInicial: Date,
  FechaFinal: Date,
  codeProducto: string,
  nombreTienda: string,
  setListaDataPrecioBar: any
) {
  var dataResponse = await Repository.getListaDataPrecioBar(
    FechaInicial,
    FechaFinal,
    codeProducto,
    nombreTienda
  );

  if (dataResponse != null) {
    setListaDataPrecioBar(dataResponse);
  }
}
