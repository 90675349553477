import React, { useMemo } from "react";
import { MaterialReactTable, type MRT_ColumnDef } from "material-react-table";
import { useNavigate } from "react-router-dom";
import ContentHeader from "../layout/ContentHeader";
import ContentBody from "../layout/ContentBody";
import * as Controller from "../../../controllers/indicadoresinternogestion-controller";
import * as Repository from "../../../repositories/indicadoresinternogestion-repository";
import "react-datepicker/dist/react-datepicker.css";
import Textbox from "../layout/Textbox";
export default function ConsultaIndicadorInternoGestion(): React.JSX.Element {
  const router = useNavigate();
  const [ListaConsulta, setListaConsulta] = React.useState([]);
  const [FilterFechaDesde, setFilterFechaInicio] = React.useState(new Date());
  const [FilterFechaHasta, setFilterFechaHasta] = React.useState(new Date());
  const [FilterNombreApellidoDni, setFilterNombreApellidoDni] =
    React.useState("");
  React.useEffect(() => { }, []);
  return (
    <div className="content-wrapper">
      <ContentHeader
        title={"Consulta Interno Gestion"}
        homePath={"Home"}
        currentPath={"Mantenimiento Interno Gestion"}
      ></ContentHeader>
      <ContentBody title={"Consulta Interno Gestion"}>
        <div className="row ">
          <div className="col-lg-2">
            <label> Nombre Apellido / DNI</label>
          </div>
          <div className="col-lg-4">
            <div className="form-group">
              <Textbox
                value={FilterNombreApellidoDni}
                setValue={setFilterNombreApellidoDni} />

            </div>
          </div>
        </div>
        <div className="row mt-2 mb-2">
          <div className="col-gl-6">
            <div className="form-group">
              <button
                className="btn btn-info"
                onClick={() =>
                  Controller.doConsultar(
                    setListaConsulta,
                    FilterFechaDesde,
                    FilterFechaHasta
                  )
                }
              >
                Consultar
              </button>
              <button className="btn btn-success">Exportar</button>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <MaterialReactTable
                columns={Repository.GetColumns()}
                data={ListaConsulta}
                renderRowActions={({ row, table }) => <div></div>}
              />
            </div>
          </div>
        </div>
      </ContentBody>
    </div>
  );
}
