import { NavigateFunction } from 'react-router-dom';
import * as Repository from "../repositories/generardocumentos-repository";
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
    CODE_SERVER_RETURN,
} from "../utils/contants";
export function toNuevo(router: NavigateFunction) {
    router("/main/generardocumentos/mantenimiento/nuevo")
}
export function toConsulta(router: NavigateFunction) {
    router("/main/generardocumentos/consulta")
}
export async function toEditar(router: NavigateFunction, row: any) {
    var codeEditar = row.CodeDocumentoGenerado;
    router("/main/generardocumentos/mantenimiento/" + codeEditar);
}
export async function toQuestionInactivaActiva(row: any, setListaConsulta: any, functionCallback: any) {
    const MySwal = withReactContent(Swal)
    var question = "Eliminar Registro"
    MySwal.fire({
        title: <p>{question}</p>,
        icon: "question",
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: "Sí",
        cancelButtonText: "Cancelar"
    }).then((result: any) => {
        if (result.isConfirmed) {
            console.log("Confirmado para eliminar")
            toInactivaActiva(row, setListaConsulta, functionCallback)
        }
    })
}
async function toInactivaActiva(row: any, setListaConsulta: any, functionCallback: any) {
    var codeEliminar = row.IdUsuario
    var PeriodoEliminar = row.Periodo
    const objSave = {
        IdUsuario: codeEliminar,
        Periodo: PeriodoEliminar
    }

    var dataResponse = await Repository.inactiva(objSave)

    functionCallback()
}

export async function getByCode(

    code: any,
    setperiodo: any,
    setCodeCadenaMaster: any,
    setcodeUsuario: any,
    setcodeTienda: any,
    setListaDocumentos: any
) {
    var dataResponse = await Repository.getByCode(code)
    if (dataResponse != null) {
        setperiodo(dataResponse.Periodo);
        setCodeCadenaMaster(dataResponse.CodeCadenaMaster);
        var dataResponseUsuarioSelect = [];
        if (dataResponse.Personas != null) {
            dataResponseUsuarioSelect = dataResponse.Personas.map(
                (element: any) => {
                    var item = {
                        value: element.CodeUsuario,
                        label: element.NombreCompleto,
                    };
                    return item;

                }
            );
        }
        setcodeUsuario(dataResponseUsuarioSelect);

        var dataResponseTiendaSelect = [];
        if (dataResponse.Tiendas != null) {
            dataResponseTiendaSelect = dataResponse.Tiendas.map(
                (element: any) => {
                    var item = {
                        value: element.CodeTienda,
                        label: element.NombreTienda,
                    };
                    return item;
                }
            );
        }
        setcodeTienda(dataResponseTiendaSelect);
        var dataResponseDocumentoSelect = [];
        if (dataResponse.Documentos != null) {
            dataResponseDocumentoSelect = dataResponse.Documentos.map(
                (element: any) => {
                    var item = {
                        CodeDocumento: element.CodeDocumento,
                        NombreDocumento: element.NombreDocumento,
                    };
                    return item;

                }
            );
        }
        setListaDocumentos(dataResponseDocumentoSelect);

    }
}

export async function addMemberTienda(codeTienda: any, setCodeTienda: any, ListaComboTienda: any, horarioTienda: string, setHorarioTienda: any, ListaHorarioTienda: any, setListaHorarioTienda: any) {
    var ValNombreTienda = "";
    var ValCodeTienda = "";

    console.log("codeTienda" + codeTienda);

    const MySwal = withReactContent(Swal)

    if (codeTienda != null && codeTienda != "") {
        ValNombreTienda = codeTienda.label;
        ValCodeTienda = codeTienda.value;
    } else {
        MySwal.fire({
            title: <p>Alerta</p>,
            text: "Seleccione una Tienda",
            icon: "warning",
            showConfirmButton: true,
            showCancelButton: false,
            confirmButtonText: "OK",

        });
        return;
    }

    var existe = false
    ListaHorarioTienda.forEach((item: any) => {
        if (item.CodeTienda == ValCodeTienda) {
            console.log(item)
            existe = true
            return
        }
    });
    console.log("Existe?: " + existe)

    if (existe) {
        MySwal.fire({
            title: <p>Alerta</p>,
            text: "Ya se agregó la tienda",
            icon: "warning",
            showConfirmButton: true,
            showCancelButton: false,
            confirmButtonText: "OK",
        });
        return;
    }


    var objMember = {
        CodeTienda: ValCodeTienda,
        NombreTienda: ValNombreTienda,
        Horario: horarioTienda,
    };

    var newListaHorarioTienda = ListaHorarioTienda.map((item: any) => {
        return item;
    });
    newListaHorarioTienda.push(objMember);
    setListaHorarioTienda(newListaHorarioTienda);
    setCodeTienda("");
    setHorarioTienda("");
}

export async function save(
    router: NavigateFunction,
    codeDocumentoGenerado: string,
    codeUsuario: any,
    periodo: any,
    ListaHorarioTienda: any,
    codeDocumento: any,
    codeCadenaMaster: any,
) {

    const MySwal = withReactContent(Swal)
    var question = "¿Desea generar los documentos?"
    MySwal.fire({
        title: <p>{question}</p>,
        icon: "question",
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: "Sí",
        cancelButtonText: "Cancelar"
    }).then(async (result: any) => {
        if (result.isConfirmed) {
            console.log("Code a actualizar: " + codeDocumentoGenerado)

            var listUsuario = codeUsuario.map((item: any) => {
                var newItem = {

                    CodeUsuario: item.value,
                };
                return newItem;
            });

            var listTienda = ListaHorarioTienda.map((item: any) => {
                var newItem = {
                    CodeTienda: item.CodeTienda,
                    Horario: item.Horario
                };
                return newItem;
            });

            var listDocumento = codeDocumento.map((item: any) => {
                var newItem = {

                    CodeDocumento: item.CodeDocumento,
                };
                return newItem;

            });
            var periodoMes = moment(periodo).format('YYYYMM');
            if (codeDocumentoGenerado != null && codeDocumentoGenerado != "") {
                const objSave = {
                    codeDocumentoGenerado: codeDocumentoGenerado,
                    Periodo: periodoMes,
                    Persona: listUsuario,
                    Tienda: listTienda,
                    Documento: listDocumento,
                    CodeCadenaMaster: codeCadenaMaster,

                };
                var dataResponse = await Repository.update(objSave)
                if (dataResponse.Code == CODE_SERVER_RETURN.VAL) {
                    MySwal.fire({
                        title: <p>{dataResponse.Message}</p>,
                        icon: "warning",
                        showConfirmButton: true,
                        showCancelButton: false,
                        confirmButtonText: "OK",

                    });

                } else if (dataResponse.Code == CODE_SERVER_RETURN.ERROR) {
                    MySwal.fire({
                        title: <p>{dataResponse.Message}</p>,
                        icon: "error",
                        showConfirmButton: true,
                        showCancelButton: false,
                        confirmButtonText: "OK",

                    });
                } else {
                    toast.success('Guardado satisfactoriamente!')
                    toConsulta(router)
                }

            } else {
                const objSave = {
                    Periodo: periodoMes,
                    Persona: listUsuario,
                    Tienda: listTienda,
                    Documento: listDocumento,
                    CodeCadenaMaster: codeCadenaMaster,
                };
                var dataResponse = await Repository.save(objSave)
                if (dataResponse.Code == CODE_SERVER_RETURN.VAL) {
                    MySwal.fire({
                        title: <p>{dataResponse.Message}</p>,
                        icon: "warning",
                        showConfirmButton: true,
                        showCancelButton: false,
                        confirmButtonText: "OK",

                    });

                } else if (dataResponse.Code == CODE_SERVER_RETURN.ERROR) {
                    MySwal.fire({
                        title: <p>{dataResponse.Message}</p>,
                        icon: "error",
                        showConfirmButton: true,
                        showCancelButton: false,
                        confirmButtonText: "OK",

                    });
                } else {
                    toast.success('Guardado satisfactoriamente!')
                    toConsulta(router)
                }
            }
        }
    })
}


export async function doConsultar(setListaConsulta: any, FilterCodeUsuario: string, FilterCodeTienda: string, FilterPeriodo: Date) {
    var dataResponse = await Repository.getData(FilterCodeUsuario, FilterCodeTienda, FilterPeriodo)
    setListaConsulta(dataResponse)
}
export async function getListaComboUsuario(setListaComboUsuario: any) {

    var dataResponse = await Repository.getListaComboUsuario()
    var dataResponseSelect = dataResponse.map((item: any) => {
        var newItem = {
            value: item.Id,
            label: item.Texto,
        };
        return newItem;
    });
    setListaComboUsuario(dataResponseSelect)
}
export async function getListaComboCadenaMaster(setListaComboCadenaMaster: any) {
    var dataResponse = await Repository.getListaComboCadenaMaster()
    setListaComboCadenaMaster(dataResponse)
}
export async function getListaComboTienda(codeCadenaMaster: string, setListaComboTienda: any) {
    if (codeCadenaMaster != "-" && codeCadenaMaster != '0') {
        var dataResponse = await Repository.getListaComboTienda(codeCadenaMaster)
        setListaComboTienda(dataResponse)
    } else {
        setListaComboTienda(dataResponse)
    }
    var dataResponseSelect = dataResponse.map((item: any) => {
        var newItem = {
            value: item.Id,
            label: item.Texto,
        };
        return newItem;
    });
    setListaComboTienda(dataResponseSelect)
}
export function toQuestionEliminarDocumentos(row: any, ListaCadenas: any, setListaDocumentos: any) {
    var indice = -1
    ListaCadenas.forEach((item: any, index: number) => {
        if (item.CodeDocumento == row.CodeDocumento) {
            console.log(item)
            indice = index
            return
        }
    });

    if (indice > -1) {
        ListaCadenas.splice(indice, 1);
        var newListaCadenas = ListaCadenas.map((item: any) => { return item })
        setListaDocumentos(newListaCadenas)
    }
}
export async function geListaCadenaDocumentoDetalleByCodeCadena(code: any, setListaDocumentos: any) {
    var dataResponse = await Repository.geListaCadenaDocumentoDetalleByCodeCadena(code)

    console.log(dataResponse)
    if (dataResponse != null) {
        setListaDocumentos(dataResponse)
    }

}

export async function getListaComboTiendaConsulta(setListaComboTienda: any) {
    var dataResponse = await Repository.getListaComboTiendaConsulta()
    setListaComboTienda(dataResponse)
}
export async function getListaComboUsuarioConsulta(setListaComboUsuario: any) {

    var dataResponse = await Repository.getListaComboUsuario()

    setListaComboUsuario(dataResponse)
}

export async function doDescargarDocumentoCombinado(row: any) {
    var dataResponse = await Repository.doDescargarDocumentoCombinado(row.IdUsuario, row.Periodo)
}
export async function Mensaje(message: any) {
    const MySwal = withReactContent(Swal)
    var message = message || "Tiene que completar los campos"
    MySwal.fire({
        title: <p>{message}</p>,
        icon: "warning",
        showConfirmButton: true,
        confirmButtonText: "Aceptar",
    }
    )
}
