import { useMemo } from 'react';
import { type MRT_ColumnDef } from 'material-react-table';
import * as ClientAPI from "..//utils/methods";
import * as Endpoints from "../api/endpoint";
import Moment from 'moment';
import moment from 'moment';

export type DataPrecioFecha = {
  FechaHoraRegistro: Date
};

export type DataPrecioTienda = {
  FechaRegistro: Date
  FechaHoraRegistro: Date
};
export function GetColumns() {
  const columns = useMemo<MRT_ColumnDef<DataPrecioFecha>[]>(
    () => [
      {
        accessorKey: 'NombreTienda',
        header: 'Tienda',
        size: 200,
      },
      {
        accessorKey: 'Precio',
        header: 'Precio',
        size: 50,
      },
      {
        id: 'FechaHoraRegistro',
        header: 'Fecha hora registro',
        size: 100,
        accessorFn: (row) => {
          return moment(row.FechaHoraRegistro).format("DD/MM/YYYY HH:mm:ss");
        },
      },
      {
        accessorKey: 'UsuarioRegistro',
        header: 'Usuario',
        size: 200,
      }
    ],
    [],
  );
  ;
  return columns
}


export function GetColumnsTienda() {
  const columns = useMemo<MRT_ColumnDef<DataPrecioTienda>[]>(
    () => [
      {
        id: 'FechaRegistro',
        header: 'Fecha',
        size: 100,
        accessorFn: (row) => {
          return moment(row.FechaRegistro).format("DD/MM/YYYY");
        },
      },
      {
        accessorKey: 'Precio',
        header: 'Precio',
        size: 50,
      },
      {
        id: 'FechaHoraRegistro',
        header: 'Fecha hora registro',
        size: 100,
        accessorFn: (row) => {
          return moment(row.FechaHoraRegistro).format("DD/MM/YYYY HH:mm:ss");
        },
      },
      {
        accessorKey: 'UsuarioRegistro',
        header: 'Usuario',
        size: 200,
      }
    ],
    [],
  );
  ;
  return columns
}

export async function getListaComboCadenaMaster(){
  var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_SECURITY + Endpoints.ListEndpoint.GET_LISTA_COMBO_CADENAMASTER, "")
  if(dataResponse.Data != null){
    return dataResponse.Data;
  }else {
    return null
  }
}

export async function getListaComboTienda(setCodeCadenaMaster : string) {
  var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint. GET_LISTA_COMBO_TIENDA +  "/" + setCodeCadenaMaster , "")
  if (dataResponse.Data != null) {
    return dataResponse.Data;
  } else {
    return null
  }
}

export async function getListaComboMarca(){
  var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.GET_LISTA_COMBO_MARCA , "")
  if(dataResponse.Data != null){
    return dataResponse.Data;
  }else {
    return null
  }
}
export async function getListaComboCategoria(setCodeMarca : string){
  var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.GET_LISTA_COMBO_CATEGORIA + "/" + setCodeMarca , "")
  if(dataResponse.Data != null){
    return dataResponse.Data;
  }else {
    return null
  }
}
export async function geListaProducto( codeMarca : string , codeCategoria : string)

{
  codeMarca = (codeMarca == "" || codeMarca == "0")? "-": codeMarca
  codeCategoria = (codeCategoria == "" || codeCategoria == "0")? "-": codeCategoria
  var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.GET_LISTA_PRODUCTOMARCA + "/" + codeMarca  + "/" + codeCategoria  ,  "")
 console.log(dataResponse)
 
  if (dataResponse.Data != null) {
    return dataResponse.Data;
  } else {
    return []
  }
}
export async function getAnalisisPrecio( FechaInicial: Date, FechaFinal: Date, codeCadenaMaster: string, codeTienda: string, codeMarca: string, codeCategoria: string, idsProducto: string)
{
  codeCadenaMaster = (codeCadenaMaster == "" || codeCadenaMaster == "0")? "-": codeCadenaMaster
  codeTienda = (codeTienda == "" || codeTienda == "0")? "-": codeTienda
  codeMarca = (codeMarca == "" || codeMarca == "0")? "-": codeMarca
  codeCategoria = (codeCategoria == "" || codeCategoria == "0")? "-": codeCategoria
  idsProducto = (idsProducto == "" || idsProducto == "0")? "-": idsProducto

  var CFechaInicial = Moment(FechaInicial).format("DD-MM-YYYY") 
  var CFechaFinal = Moment(FechaFinal).format("DD-MM-YYYY") 

  var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.GET_ANALISIS_PRECIO  + "/" + CFechaInicial  + "/" + CFechaFinal + "/" + codeCadenaMaster + "/" + codeTienda + "/" + codeMarca + "/" + codeCategoria + "/" + idsProducto,  "")
 console.log(dataResponse)
 
  if (dataResponse.Data != null) {
    return dataResponse.Data;
  } else {
    return []
  }
}

export async function geListaComboProducto(  codeCategoria : string)
{
  
  var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.GET_LISTA_COMBOPRODUCTO  + "/" + codeCategoria,  "")
 console.log(dataResponse)
 
  if (dataResponse.Data != null) {
    return dataResponse.Data;
  } else {
    return []
  }
}


export async function getListaDataPrecio(codeCadenaMaster: string, codeTienda: string, codeProducto: string, Fecha: string){

  codeCadenaMaster = (codeCadenaMaster == "" || codeCadenaMaster == "0")? "-": codeCadenaMaster
  codeTienda = (codeTienda == "" || codeTienda == "0")? "-": codeTienda
  codeProducto = (codeProducto == "" || codeProducto == "0")? "-": codeProducto
  console.log("Fecha: " + Fecha)
  var CFechaInicial = Fecha.replaceAll("/", "-")

  var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.GET_DATA_ANALISIS_PRECIO + "/" + codeCadenaMaster + "/" + codeTienda + "/" + codeProducto + "/" + CFechaInicial,  "")
 
  if (dataResponse.Data != null) {
    return dataResponse.Data;
  } else {
    return []
  }
}


export async function getListaDataPrecioBar(FechaInicial: Date, FechaFinal: Date, codeProducto: string, nombreTienda: string){

  codeProducto = (codeProducto == "" || codeProducto == "0")? "-": codeProducto

  var CFechaInicial = Moment(FechaInicial).format("DD-MM-YYYY") 
  var CFechaFinal = Moment(FechaFinal).format("DD-MM-YYYY") 

  var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.GET_DATA_ANALISIS_PRECIO_BAR + "/" + CFechaInicial  + "/" + CFechaFinal + "/"  + codeProducto + "/" + nombreTienda,  "")
 
  if (dataResponse.Data != null) {
    return dataResponse.Data;
  } else {
    return []
  }
}