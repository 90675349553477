import { NavigateFunction } from "react-router-dom";
import * as Repository from "../repositories/registrometaventa-repository";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CODE_SERVER_RETURN } from "../utils/contants";
import moment from "moment";
export function toNuevo(router: NavigateFunction) {
  router("/main/registrometaventa/mantenimiento/nuevo");
}
export function toConsulta(router: NavigateFunction) {
  router("/main/registrometaventa/consulta");
}
export async function toEditar(router: NavigateFunction, row: any) {
  var codeEditar = row.CodeMetaVentaTienda;
  router("/main/registrometaventa/mantenimiento/" + codeEditar);
}

export async function toQuestionInactivaActiva(
  row: any,
  setListaConsulta: any
) {
  const MySwal = withReactContent(Swal);
  var question =
    row.Estado == 1
      ? "¿Desea eliminar el registro?"
      : "¿Desea activar el registro?";
  MySwal.fire({
    title: <p>{question}</p>,
    icon: "question",
    showConfirmButton: true,
    showCancelButton: true,
    confirmButtonText: "Sí",
    cancelButtonText: "Cancelar",
  }).then((result: any) => {
    if (result.isConfirmed) {
      toInactivaActiva(row, setListaConsulta);
    }
  });
}
async function toInactivaActiva(row: any, setListaConsulta: any) {
  var codeEliminar = row.CodeMetaVentaTienda;
  const objSave = {
    CodeMetaVentaTienda: codeEliminar,
  };
  if (row.Estado == 1) {
    var dataResponse = await Repository.inactiva(objSave);
  } else if (row.Estado == 0) {
    var dataResponse = await Repository.activa(objSave);
  }
  setListaConsulta();
}

export async function save(
  router: NavigateFunction,
  codeMetaTiendaVenta: string,
  codeUsuario: string,
  CodeTienda: string,
  metaMensual: number,
  metaSemanal: number,
  periodo: any
) {
  const MySwal = withReactContent(Swal);
  var question = "¿Desea guardar el registro?";
  MySwal.fire({
    title: <p>{question}</p>,
    icon: "question",
    showConfirmButton: true,
    showCancelButton: true,
    confirmButtonText: "Sí",
    cancelButtonText: "Cancelar",
  }).then(async (result: any) => {
    if (result.isConfirmed) {
      console.log("Code a actualizar: " + codeMetaTiendaVenta);

      var periodoMes = moment(periodo).format("YYYYMM");
      if (codeMetaTiendaVenta != null && codeMetaTiendaVenta != "") {
        const objSave = {
          CodeMetaVentaTienda: codeMetaTiendaVenta,

          CodeUsuario: codeUsuario,
          CodeTienda: CodeTienda,
          MetaSemanal: metaSemanal,
          MetaMensual: metaMensual,
          Periodo: periodoMes,
        };
        var dataResponse = await Repository.update(objSave);
        if (dataResponse.Code == CODE_SERVER_RETURN.VAL) {
          MySwal.fire({
            title: <p>{dataResponse.Message}</p>,
            icon: "warning",
            showConfirmButton: true,
            showCancelButton: false,
            confirmButtonText: "Ok",
          });
        } else if (dataResponse.Code == CODE_SERVER_RETURN.ERROR) {
          MySwal.fire({
            title: <p>{dataResponse.Message}</p>,
            icon: "error",
            showConfirmButton: true,
            showCancelButton: false,
            confirmButtonText: "OK",
          });
        } else {
          toast.success("Guardado satisfactoriamente!");
          toConsulta(router);
        }
      } else {
        const objSave = {
          CodeUsuario: codeUsuario,
          CodeTienda: CodeTienda,
          MetaSemanal: metaSemanal,
          MetaMensual: metaMensual,
          Periodo: periodoMes,
        };
        var dataResponse = await Repository.save(objSave);
        if (dataResponse.Code == CODE_SERVER_RETURN.VAL) {
          MySwal.fire({
            title: <p>{dataResponse.Message}</p>,
            icon: "warning",
            showConfirmButton: true,
            showCancelButton: false,
            confirmButtonText: "Ok",
          });
        } else if (dataResponse.Code == CODE_SERVER_RETURN.ERROR) {
          MySwal.fire({
            title: <p>{dataResponse.Message}</p>,
            icon: "error",
            showConfirmButton: true,
            showCancelButton: false,
            confirmButtonText: "OK",
          });
        } else {
          toast.success("Guardado satisfactoriamente!");
          toConsulta(router);
        }
      }
    }
  });
}

export async function Mensaje() {
  const MySwal = withReactContent(Swal);
  var message = "Tiene que completar los campos";
  MySwal.fire({
    title: <p>{message}</p>,
    icon: "warning",
    showConfirmButton: true,
    confirmButtonText: "Aceptar",
  });
}

export async function doConsultar(
  setListaConsulta: any,
  FiltercodeTienda: string,
  FiltercodeUsuario: string,
  Filterperiodo: Date
) {
  var dataResponse = await Repository.getData(
    FiltercodeTienda,
    FiltercodeUsuario,
    Filterperiodo
  );
  setListaConsulta(dataResponse);
}
export async function getListaComboTienda(setListaComboTienda: any) {
  var dataResponse = await Repository.getListaComboTienda();
  setListaComboTienda(dataResponse);
}
export async function getListaComboUsuario(setListaComboUsuario: any) {
  var dataResponse = await Repository.getListaComboUsuario();
  setListaComboUsuario(dataResponse);
}
export async function getListaComboCadenaMaster(
  setListaComboCadenaMaster: any
) {
  var dataResponse = await Repository.getListaComboCadenaMaster();
  setListaComboCadenaMaster(dataResponse);
}
export async function getListaComboTiendaCadena(
  codeCadenaMaster: string,
  setListaComboTiendaCadena: any
) {
  if (codeCadenaMaster != "-" && codeCadenaMaster != "0") {
    var dataResponse = await Repository.getListaComboTiendaCadena(
      codeCadenaMaster
    );
    setListaComboTiendaCadena(dataResponse);
  } else {
    setListaComboTiendaCadena(dataResponse);
  }
}
export async function getByCode(
  code: any,
  setCodeCadenaMaster: any,
  setCodeUsuario: any,
  setCodeTienda: any,
  setPeriodo: any,
  setMetaMensual: any,
  setMetaSemanal: any,
  setListaComboTiendaCadena: any
) {
  var dataResponse = await Repository.getByCode(code);

  if (dataResponse != null) {
    setCodeCadenaMaster(dataResponse.CodeCadenaMaster);
    getListaComboTiendaCadena(
      dataResponse.CodeCadenaMaster,
      setListaComboTiendaCadena
    );
    setCodeUsuario(dataResponse.CodeUsuario);
    setCodeTienda(dataResponse.CodeTienda);

    var periodostring = dataResponse.Periodo + "01";
    var PeriodoDate = moment(periodostring, "YYYYMMDD").toDate();

    setPeriodo(PeriodoDate);

    setMetaMensual(dataResponse.MetaMensual);
    setMetaSemanal(dataResponse.MetaSemanal);
  }
}
