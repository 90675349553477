import { useMemo } from 'react';
import { type MRT_ColumnDef } from 'material-react-table';
import * as ClientAPI from "../utils/methods";
import * as Endpoints from "../api/endpoint";
import Moment from 'moment';
import moment from 'moment';
type Incidencia = {

};
export function GetColumns() {
  const columns = useMemo<MRT_ColumnDef<Incidencia>[]>(
    () => [
      {
        accessorKey: 'NombreCompleto',
        header: 'Nombre Usuario',
        size: 20,
      },
      {
        accessorKey: 'NombreTienda',
        header: 'Nombre Tienda',
        size: 100,
      },
      {
        accessorKey: 'NombreCadena',
        header: 'Cadena',
        size: 100,
      },
      {
        accessorKey: 'Fecha',
        header: 'Fecha',
        size: 100,
      },
      {
        accessorKey: 'FechaHoraRegistro',
        header: 'Fecha Registro',
        size: 100,
      },
      {
        accessorKey: 'TipoIncidencia',
        header: 'Tipo Incidencia',
        size: 100,
      },
      {
        accessorKey: 'Comentario',
        header: 'Comentario',
        size: 100,
      },
    ],
    [],
  );
  ;
  return columns
}
export async function getData(idPerfil : string, idTipoIncidencia :string, FilterFechaInicial :Date, FilterFechaFinal: Date )   {
  idPerfil = (idPerfil == "" || idPerfil == null)? "0": idPerfil
  idTipoIncidencia = (idTipoIncidencia == "" || idTipoIncidencia == null)? "0": idTipoIncidencia

  var CFilterFechaInicial = Moment(FilterFechaInicial).format("DD-MM-YYYY") 
  var CFilterFechaFinal = Moment(FilterFechaFinal).format("DD-MM-YYYY")
  var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.GET_LISTA_INCIDENCIA   + "/" + CFilterFechaInicial  + "/" + CFilterFechaFinal  + "/" + idPerfil   + "/" + idTipoIncidencia,  "")
  if (dataResponse.Data != null) {
    return dataResponse.Data;
  } else {
    return []
  }
}

export async function getListaComboPerfil() {
    var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.GET_LISTA_COMBO_PERFIL, "")
    if (dataResponse.Data != null) {
      return dataResponse.Data;
    } else {
      return []
    }
  }
  export async function getListaComboIncidencia() {
    var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.GET_LISTA_COMBO_INCIDENCIA , "")
    console.log(dataResponse)
    if (dataResponse.Data != null) {
      return dataResponse.Data;
    } else {
      return []
    }
  }

export async function doExport(idPerfil: string, idTipoIncidencia : string, FilterFechaDesde: Date, FilterFechaHasta : Date ) {
  
  var CFilterFechaDesde = Moment(FilterFechaDesde).format("DD-MM-YYYY") 
  var CFilterFechaHasta = Moment(FilterFechaHasta).format("DD-MM-YYYY")
  idPerfil = (idPerfil == "" || idPerfil == null)? "0": idPerfil
 idTipoIncidencia = (idTipoIncidencia == "" || idTipoIncidencia == null)? "0": idTipoIncidencia
  var dataResponse = await ClientAPI.RequestDownload(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.GET_LISTA_INCIDENCIA_EXCEL  + "/" + idPerfil + "/" + idTipoIncidencia  + "/" + CFilterFechaDesde + "/" + CFilterFechaHasta, 'DescargarExcelIncidencia.xlsx')
  
}