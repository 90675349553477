import ContentHeader from "../layout/ContentHeader";
import { useParams, useNavigate } from "react-router-dom";
import * as Controller from "../../../controllers/documento-controller";
import React from "react";
import SelectSimple from "../layout/SelectSimple";
import Label from "../layout/Label";
import Textbox from "../layout/Textbox";
export default function NuevoDocumento() {
  const [ListaConsulta, setListaConsulta] = React.useState([]);
  const [ListaComboTipoArchivo, setListaComboTipoArchivo] = React.useState([]);
  const [tituloVentana, setTituloVentana] = React.useState("Nuevo Documento");
  const [file, setFile] = React.useState(null);
  const [urlFile, setUrlFile] = React.useState("");
  const { code } = useParams();
  const router = useNavigate();
  const [codeDocumento, setCodeDocumento] = React.useState("");
  const [nombreDocumento, setNombreDocumento] = React.useState("");
  const [contenido, setContenido] = React.useState("");
  const [idTipoArchivo, setIdTipoArchivo] = React.useState(0);
  const [valnombreDocumento, setValNombreDocumento] = React.useState(false);
  const [validTipoArchivo, setValIdTipoArchivo] = React.useState(false);
  const [isSubmit, setIsSubmit] = React.useState(false);
  React.useEffect(() => {
    Controller.getListaComboTipoArchivo(setListaComboTipoArchivo);

    if (code != "nuevo") {
      setTituloVentana("Editar Documento");
      setCodeDocumento(code!);
      Controller.getByCode(
        code,
        setNombreDocumento,
        setIdTipoArchivo,
        setContenido,
        setUrlFile
      );
    }
  }, []);
  function validarCampos() {
    var Result = true;
    setIsSubmit(true);
    if (nombreDocumento == "" || nombreDocumento == null) {
      Result = false;
      setValNombreDocumento(false);
    } else {
      setValNombreDocumento(true);
    }
    if (idTipoArchivo == 0 || idTipoArchivo == 0 || idTipoArchivo == null) {
      Result = false;
      setValIdTipoArchivo(false);
    } else {
      setValIdTipoArchivo(true);
    }
    if (Result) {
      Controller.save(
        router,
        codeDocumento,
        nombreDocumento,
        idTipoArchivo,
        contenido,
        file,
        urlFile
      );
    } else {
      Controller.Mensaje();
    }
  }

  function onChangeFileUpload(e: any) {
    var fileToUpload = e.target.files[0];
    //const reader = new FileReader();
    //var url = reader.readAsDataURL(file);
    setFile(fileToUpload);
  }
  return (
    <div className="content-wrapper">
      <ContentHeader
        title={tituloVentana}
        homePath={"Home"}
        currentPath={tituloVentana}
      ></ContentHeader>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card card-primary">
                <div className="card-header">
                  <div className="d-flex flex-row gap-2">
                    <div className="align-items-center">
                      <i
                        className="bx bx-arrow-back arrow-back"
                        onClick={() => Controller.toConsulta(router)}
                      ></i>
                    </div>
                    <div className="">
                      <h4 className="card-title">{tituloVentana}</h4>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-6">
                      <h4>Datos</h4>
                      <hr />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-2">
                      <Label required={true}>Tipo Documento</Label>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <SelectSimple
                          value={idTipoArchivo}
                          setValue={setIdTipoArchivo}
                          valueError={validTipoArchivo}
                          setValueError={setValIdTipoArchivo}
                          isSubmit={isSubmit}
                          lista={ListaComboTipoArchivo}
                        >
                          --Seleccion--
                        </SelectSimple>
                      </div>
                    </div>
                    <div className="col-lg-2">
                      <Label required={true}>Nombres Documento</Label>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <Textbox
                          value={nombreDocumento}
                          valueError={valnombreDocumento}
                          setValue={setNombreDocumento}
                          setValueError={setValNombreDocumento}
                          isSubmit={isSubmit}
                          upperCase={true}
                        />
                      </div>
                    </div>
                  </div>
                  {/*<div className='row'>
                  <div className='col-lg-2'>
                    <label >Contenido</label>
                  </div>
                  <div className='col-lg-4'>
                    <div className='form-group'>
                    <Textbox
                       value={contenido}
                   
                       setValue={setContenido}
                     
                       upperCase={true}/>
                    </div>
                  </div>
                    </div> */}

                  <div className="row ">
                    <div className="col-lg-6">
                      <h4>Archivos </h4>
                      <hr />
                    </div>
                  </div>
                  <div className="row mt-5">
                    <div className="col-lg-2">
                      <Label>Adjuntar Archivo</Label>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <input
                          accept="docx/*"
                          type="file"
                          onChange={onChangeFileUpload}
                        />
                      </div>
                    </div>
                    <div>
                      {urlFile != null && urlFile != "" ? (
                        <a href={urlFile}>
                          <button className="btn btn-primary">
                            Descargar Archivo
                          </button>
                        </a>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  <div className="row mt-5">
                    <div className="col-lg-6">
                      <div className="form-group">
                        <button
                          className="btn btn-default"
                          onClick={() => Controller.toConsulta(router)}
                        >
                          Cancelar
                        </button>
                        <button
                          className="btn btn-primary"
                          onClick={() => validarCampos()}
                        >
                          Guardar
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
