export default function Indicator({
  children,
  title,
}: {
  children: React.ReactNode;
  title: String;
}) {
  return (
    <div className="border">
      <div className="d-flex justify-content-center border bg-light">
        <h5 className="mt-1">{title}</h5>
      </div>
      <div className="card-body">
        <div className="d-flex justify-content-center">
          <span className="text-indicator">{children}</span>
        </div>
      </div>
    </div>
  );
}
