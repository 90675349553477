import { useMemo } from "react";
import { type MRT_ColumnDef } from "material-react-table";
import * as ClientAPI from "../utils/methods";
import * as Endpoints from "../api/endpoint";
import moment from "moment";
type RegistroMetaVenta = {
  IdMarca: string;
  NombreMarca: string;
};
export function GetColumns() {
  const columns = useMemo<MRT_ColumnDef<RegistroMetaVenta>[]>(
    () => [
      {
        accessorKey: "IdMetaVentaTienda",
        header: "ID",
        size: 100,
      },
      {
        accessorKey: "NombreTienda",
        header: "Nombre Tienda",
        size: 400,
      },
      {
        accessorKey: "NombreCompleto",
        header: "Nombre Usuario",
        size: 400,
      },
      {
        accessorKey: "Periodo",
        header: "Periodo",
        size: 400,
      },
    ],
    []
  );
  return columns;
}
export async function getData(
  FiltercodeTienda: string,
  FiltercodeUsuario: string,
  Filterperiodo: Date
) {
  FiltercodeTienda =
    FiltercodeTienda == "" || FiltercodeTienda == "0" ? "-" : FiltercodeTienda;
  FiltercodeUsuario =
    FiltercodeUsuario == "" || FiltercodeUsuario == "0"
      ? "-"
      : FiltercodeUsuario;

  var FilterPeriodoFormat = moment(Filterperiodo).format("YYYYMM");
  var dataResponse = await ClientAPI.RequestGet(
    Endpoints.URL_BASE_ADMIN +
      Endpoints.ListEndpoint.GET_LISTA_REGISTROMETAVENTA +
      "/" +
      FiltercodeTienda +
      "/" +
      FiltercodeUsuario +
      "/" +
      FilterPeriodoFormat,
    ""
  );
  if (dataResponse.Data != null) {
    return dataResponse.Data;
  } else {
    return [];
  }
}

export async function getListaComboTienda() {
  var dataResponse = await ClientAPI.RequestGet(
    Endpoints.URL_BASE_ADMIN +
      Endpoints.ListEndpoint.GET_LISTA_COMBO_TIENDA +
      "/-",
    ""
  );
  if (dataResponse.Data != null) {
    return dataResponse.Data;
  } else {
    return null;
  }
}
export async function getListaComboUsuario() {
  var dataResponse = await ClientAPI.RequestGet(
    Endpoints.URL_BASE_ADMIN +
      Endpoints.ListEndpoint.GET_LISTA_COMBO_PROGRAMACIONUSUARIO,
    ""
  );
  if (dataResponse.Data != null) {
    return dataResponse.Data;
  } else {
    return null;
  }
}
export async function getListaComboCadenaMaster() {
  var dataResponse = await ClientAPI.RequestGet(
    Endpoints.URL_BASE_SECURITY +
      Endpoints.ListEndpoint.GET_LISTA_COMBO_CADENAMASTER,
    ""
  );
  if (dataResponse.Data != null) {
    return dataResponse.Data;
  } else {
    return null;
  }
}
export async function getListaComboTiendaCadena(setCodeCadenaMaster: string) {
  var dataResponse = await ClientAPI.RequestGet(
    Endpoints.URL_BASE_ADMIN +
      Endpoints.ListEndpoint.GET_LISTA_COMBO_TIENDA +
      "/" +
      setCodeCadenaMaster,
    ""
  );
  if (dataResponse.Data != null) {
    return dataResponse.Data;
  } else {
    return null;
  }
}

export async function save(objSave: any) {
  var dataResponse = await ClientAPI.RequestPost(
    Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.INSERTAR_METATIENDAVENTA,
    "",
    objSave
  );
  if (dataResponse.Data != null) {
    return dataResponse.Data;
  } else {
    return [];
  }
}
export async function update(objSave: any) {
  var dataResponse = await ClientAPI.RequestPost(
    Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.UPDATE_METATIENDAVENTA,
    "",
    objSave
  );
  if (dataResponse.Data != null) {
    return dataResponse.Data;
  } else {
    return null;
  }
}
export async function getByCode(code: string) {
  var dataResponse = await ClientAPI.RequestGet(
    Endpoints.URL_BASE_ADMIN +
      Endpoints.ListEndpoint.GET_METAVENTATIENDA_BYCODE +
      "/" +
      code,
    ""
  );
  return dataResponse.Data;
}

export async function inactiva(objSave: any) {
  var dataResponse = await ClientAPI.RequestPost(
    Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.INACTIVO_METAVENTATIENDA,
    "",
    objSave
  );
  if (dataResponse.Data != null) {
    return dataResponse.Data;
  } else {
    return null;
  }
}
export async function activa(objSave: any) {
  var dataResponse = await ClientAPI.RequestPost(
    Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.ACTIVO_METAVENTATIENDA,
    "",
    objSave
  );
  if (dataResponse.Data != null) {
    return dataResponse.Data;
  } else {
    return null;
  }
}
