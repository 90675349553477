
import * as Repository from "../repositories/checkinouttienda-repository";

export async function doConsultar(setListaConsulta: any, idPerfil: string, FilterFechaDesde: Date, FilterFechaHasta: Date, FilterNombreCompleto: string) {
    var dataResponse = await Repository.getData(idPerfil, FilterFechaDesde, FilterFechaHasta, FilterNombreCompleto)
    setListaConsulta(dataResponse)
}
export async function getListaComboPerfil(setListaComboPerfil: any) {
    var dataResponse = await Repository.getListaComboPerfil()
    setListaComboPerfil(dataResponse)
}

export async function doExportar(idPerfil: string, FilterFechaDesde: Date, FilterFechaHasta: Date, FilterNombreCompleto: string) {
    var dataResponse = await Repository.doExport(idPerfil, FilterFechaDesde, FilterFechaHasta, FilterNombreCompleto)
}  