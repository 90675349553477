import { useMemo } from 'react';
import { type MRT_ColumnDef } from 'material-react-table';
import * as ClientAPI from "../utils/methods";
import * as Endpoints from "../api/endpoint";
type Tienda = {
  IdTienda: string;
  IdEmpresa: string;
  CodigoTienda: string;
  NombreTienda: string;
  Direccion: string;
  Ubigeo: string;
  Horario: string;
  Contacto: string;
  Observacion: string;
};
export function GetColumns() {
  const columns = useMemo<MRT_ColumnDef<Tienda>[]>(
    () => [
      {
        accessorKey: 'IdTienda',
        header: 'ID',
        size: 30,
      },
      {
        accessorKey: 'NombreEmpresa',
        header: 'Empresa',
        size: 200,
      },

      {
        accessorKey: 'NombreCadena',
        header: 'Cadena',
        size: 300,
      },
     
      {
        accessorKey: 'NombreTienda',
        header: 'Nombre Tienda',
        size: 300,
      },
      {
        accessorKey: 'CodigoTienda',
        header: 'Codigo Tienda',
        size: 200,
      },
      {
        accessorKey: 'Direccion',
        header: 'Direccion',
        size: 200,
      },
      {
        accessorKey: 'NombreUbigeo',
        header: 'Ubigeo',
        size: 200,
      },

      {
        accessorKey: 'Horariodesde',
        header: 'Horario Desde',
        size: 200,
      },

      {
        accessorKey: 'Horariohasta',
        header: 'Horario Hasta',
        size: 200,
      },

      {
        accessorKey: 'NombreComercial',
        header: 'Nombre Comercial',
        size: 200,
      },
      {
        accessorKey: 'Observacion',
        header: 'Observacion',
        size: 200,
      },
    ],
    [],
  );
  return columns
}
export async function getData(FilterCadenaMaster: string, FilterCodigoTienda: string, FilterNombreTienda: string) {
  FilterCadenaMaster = (FilterCadenaMaster == "" || FilterCadenaMaster == "0")? "-": FilterCadenaMaster
  FilterCodigoTienda = (FilterCodigoTienda == "" || FilterCodigoTienda == "0")? "-": FilterCodigoTienda
  FilterNombreTienda = (FilterNombreTienda == "" || FilterNombreTienda == "0")? "-": FilterNombreTienda
      
  var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.GET_LISTA_TIENDA + "/" + FilterCadenaMaster + "/" + FilterCodigoTienda + "/" + FilterNombreTienda, "")
  if (dataResponse.Data != null) {
    return dataResponse.Data;
  } else {
    return []
  }
}
export async function getByCode(codeby: string) {
  var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.GET_TIENDA_BYCODE + "/" + codeby, "")
  console.log(dataResponse)
  return dataResponse.Data;
}


export async function getByCodeTienda(code: string) {
  var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_SECURITY + Endpoints.ListEndpoint.GET_TIENDA_AMASTER_BYCODE + "/" + code, "")
  return dataResponse.Data;
}


export async function save(objSave: any) {
  var dataResponse = await ClientAPI.RequestPost(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.INSERTAR_TIENDA, "", objSave)
  console.log(dataResponse)
  if (dataResponse.Data != null) {
    return dataResponse.Data;
  } else {
    return []
  }
}
export async function update(objSave: any) {
  var dataResponse = await ClientAPI.RequestPost(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.UPDATE_TIENDA, "", objSave)
  if (dataResponse.Data != null) {
    return dataResponse.Data;
  } else {
    return null
  }
}
export async function inactiva(objSave: any) {
  var dataResponse = await ClientAPI.RequestPost(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.INACTIVO_TIENDA, "", objSave)
  if (dataResponse.Data != null) {
    return dataResponse.Data;
  } else {
    return null
  }
}
export async function activa(objSave: any) {
  var dataResponse = await ClientAPI.RequestPost(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.ACTIVO_TIENDA, "", objSave)
  if (dataResponse.Data != null) {
    return dataResponse.Data;
  } else {
    return null
  }
}



export async function getListaComboCadenaMaster() {
  var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_SECURITY + Endpoints.ListEndpoint.GET_LISTA_COMBO_CADENAMASTER, "")
  if (dataResponse.Data != null) {
    return dataResponse.Data;
  } else {
    return null
  }
}


export async function getListaTiendaMaster(setCodeCadenaMaster: string) {
  var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_SECURITY + Endpoints.ListEndpoint.GET_LISTA_COMBO_TIENDAMASTER + "/" + setCodeCadenaMaster, "")
  if (dataResponse.Data != null) {
    return dataResponse.Data;
  } else {
    return []
  }

}

export async function doExport(FilterNombreCadenaMaster: string, FilterCodigoTienda : string, FilterNombreTiendaMaster : string) {
  FilterNombreCadenaMaster = FilterNombreCadenaMaster == "" ? "-" : FilterNombreCadenaMaster
  FilterCodigoTienda = FilterCodigoTienda == "" ? "-" : FilterCodigoTienda

  var dataResponse = await ClientAPI.RequestDownload(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.GET_LISTA_TIENDA_EXCEL + "/" + FilterNombreCadenaMaster + "/" + FilterCodigoTienda + "/" +  FilterNombreTiendaMaster, 'DescargarExcelTienda.xlsx')
}
