import React, { useMemo } from 'react';
import { MaterialReactTable, type MRT_ColumnDef } from 'material-react-table';
import { useNavigate } from "react-router-dom";
import { Box, IconButton } from '@mui/material';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Email as EmailIcon,
} from '@mui/icons-material';
import ContentHeader from '../layout/ContentHeader';
import ContentBody from '../layout/ContentBody';
import * as Controller from "../../../controllers/equipotrabajo-controller";
import * as Repository from "../../../repositories/equipotrabajo-repository";
import Textbox from '../layout/Textbox';
export default function ConsultaEquipoTrabajo(): React.JSX.Element {
  const router = useNavigate()
  const [FilterNombreEquipoTrabajo, setFilterNombreEquipoTrabajo] = React.useState('')
  const [FilterEmpresa, setFilterEmpresa] = React.useState('')
  const [ListaConsulta, setListaConsulta] = React.useState([])
  React.useEffect(() => {
    Controller.doConsultar(setListaConsulta, FilterEmpresa, FilterNombreEquipoTrabajo)
  }, [])
  return (<div className="content-wrapper">
    <ContentHeader title={"Consulta Equipo Trabajo"} homePath={"Home"} currentPath={"Mantenimiento Equipo Trabajo"} >
    </ContentHeader>
    <ContentBody title={"Consulta Equipo Trabajo"}>
      <div className='row'>
        <div className='col-lg-2'>
          <label>Nombres del Equipo</label>
        </div>
        <div className='col-lg-4 '>
          <div className='form-group'>
            <Textbox
              value={FilterNombreEquipoTrabajo}
              setValue={setFilterNombreEquipoTrabajo} />

          </div>
        </div>
      </div>
      <div className='row mt-2 mb-2'>
        <div className='col-gl-6'>
          <div className='form-group'>
            <button className='btn btn-primary' onClick={() => Controller.toNuevo(router)}>Nuevo</button>
            <button className='btn btn-info' onClick={() => Controller.doConsultar(setListaConsulta, FilterEmpresa, FilterNombreEquipoTrabajo)}>Consultar</button>
            <button className='btn btn-success' onClick={() => Controller.doExportar(FilterEmpresa, FilterNombreEquipoTrabajo)}  >Exportar</button>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <MaterialReactTable columns={Repository.GetColumns()} data={ListaConsulta}
            enableRowActions
            renderRowActions={({ row, table }) => (
              <div>
                <IconButton onClick={() => Controller.toEditar(router, row.original)}>
                  <EditIcon />
                </IconButton>
                <IconButton onClick={() => Controller.toQuestionInactivaActiva(row.original, setListaConsulta)}>
                  <DeleteIcon />
                </IconButton>
              </div>
            )} />
        </div>
      </div>
    </ContentBody>
  </div>
  )
}