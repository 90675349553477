import { useMemo } from 'react';
import { type MRT_ColumnDef } from 'material-react-table';
import * as ClientAPI from "../utils/methods";
import * as Endpoints from "../api/endpoint";
import Moment from 'moment';
import moment from 'moment';
type Analisisosa = {

};
export function GetColumns() {
  const columns = useMemo<MRT_ColumnDef<Analisisosa>[]>(
    () => [
      {
        accessorKey: 'NombreProducto',
        header: 'Producto',
        size: 20,
      },
    ],
    [],
  );
  ;
  return columns
}

type AnalisisosaDetalle = {
  FechaHoraRegistro: Date,
};
export function GetColumnsResumenDetalles() {
  const columns = useMemo<MRT_ColumnDef<AnalisisosaDetalle>[]>(
    () => [
      {
        id: 'FechaHoraRegistro',
        header: 'Fecha hora registro',
        size: 50,
        accessorFn: (row) => {
          return moment(row.FechaHoraRegistro).format("DD/MM/YYYY HH:mm");
        },
      },
      {
        accessorKey: 'NombreEstadoProducto',
        header: 'Estado',
        size: 20,
      },
    ],
    [],
  );
  ;
  return columns
}

type AnalisisosaResumen = {
  NombreProducto: string,
  UltimaFechaHoraRegistro: Date,
};
export function GetColumnsResumen() {
  const columns = useMemo<MRT_ColumnDef<AnalisisosaResumen>[]>(
    () => [
      {
        accessorKey: 'NombreProducto',
        header: 'Producto',
        size: 20,
      },
      {
        accessorKey: 'NombreEstadoProducto',
        header: 'Ultimo Estado',
        size: 20,
      },
      {
        id: 'UltimaFechaHoraRegistro',
        header: 'Último Reporte',
        size: 50,
        accessorFn: (row) => {
          return moment(row.UltimaFechaHoraRegistro).format("DD/MM/YYYY HH:mm");
        },
      }
    ],
    [],
  );
  ;
  return columns
}
export async function getListaComboCadenaMaster() {
  var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_SECURITY + Endpoints.ListEndpoint.GET_LISTA_COMBO_CADENAMASTER, "")
  if (dataResponse.Data != null) {
    return dataResponse.Data;
  } else {
    return null
  }
}
export async function getListaComboTienda(setCodeCadenaMaster: string) {
  var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.GET_LISTA_COMBO_TIENDA + "/" + setCodeCadenaMaster, "")
  if (dataResponse.Data != null) {
    return dataResponse.Data;
  } else {
    return null
  }
}
export async function getListaComboMarca() {
  var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.GET_LISTA_COMBO_MARCA, "")
  if (dataResponse.Data != null) {
    return dataResponse.Data;
  } else {
    return null
  }
}
export async function getListaComboCategoria(setCodeMarca: string) {
  var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.GET_LISTA_COMBO_CATEGORIA + "/" + setCodeMarca, "")
  if (dataResponse.Data != null) {
    return dataResponse.Data;
  } else {
    return null
  }
}
export async function geListaProducto(codeMarca: string, codeCategoria: string) {
  codeMarca = (codeMarca == "" || codeMarca == "0") ? "-" : codeMarca
  codeCategoria = (codeCategoria == "" || codeCategoria == "0") ? "-" : codeCategoria
  var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.GET_LISTA_PRODUCTOMARCA + "/" + codeMarca + "/" + codeCategoria, "")
 
  if (dataResponse.Data != null) {
    return dataResponse.Data;
  } else {
    return []
  }
}
export async function getData(FilterFechaDesde: Date, FilterFechaHasta: Date, codeTienda: string, codeProducto: string, codeMarca: string, codeCategoria: string) {
  codeTienda = (codeTienda == "" || codeTienda == "0") ? "-" : codeTienda
  codeProducto = (codeProducto == "" || codeProducto == "0") ? "-" : codeProducto
  codeMarca = (codeMarca == "" || codeMarca == "0") ? "-" : codeMarca
  codeCategoria = (codeCategoria == "" || codeCategoria == "0") ? "-" : codeCategoria

  var CFilterFechaDesde = Moment(FilterFechaDesde).format("DD-MM-YYYY")
  var CFilterFechaHasta = Moment(FilterFechaHasta).format("DD-MM-YYYY")
  var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.GET_ANALISIS_OSA + "/" + CFilterFechaDesde + "/" + CFilterFechaHasta + "/" + codeTienda + "/" + codeProducto + "/" + codeMarca + "/" + codeCategoria, "")
  if (dataResponse.Data != null) {
    return dataResponse.Data;
  } else {
    return []
  }
}
export async function getDataDetalle(FilterFechaDesde: Date, FilterFechaHasta: Date, codeCadenaMaster: string, codeTienda: string, codeProducto: string) {
  codeCadenaMaster = (codeCadenaMaster == "" || codeCadenaMaster == "0") ? "-" : codeCadenaMaster
  codeTienda = (codeTienda == "" || codeTienda == "0") ? "-" : codeTienda
  codeProducto = (codeProducto == "" || codeProducto == "0") ? "-" : codeProducto

  var CFilterFechaDesde = Moment(FilterFechaDesde).format("DD-MM-YYYY")
  var CFilterFechaHasta = Moment(FilterFechaHasta).format("DD-MM-YYYY")
  var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.GET_LISTA_OSADETALLEPRODUCTO + "/" + CFilterFechaDesde + "/" + CFilterFechaHasta + "/" + codeCadenaMaster + "/" + codeTienda + "/" + codeProducto, "")
  if (dataResponse.Data != null) {
    return dataResponse.Data;
  } else {
    return []
  }
}


export async function getAnalisisOsa( FechaInicial: Date, FechaFinal: Date, codeCadenaMaster: string, codeTienda: string, codeMarca: string, codeCategoria: string, idsProducto: string)
{
  codeCadenaMaster = (codeCadenaMaster == "" || codeCadenaMaster == "0")? "-": codeCadenaMaster
  codeTienda = (codeTienda == "" || codeTienda == "0")? "-": codeTienda
  codeMarca = (codeMarca == "" || codeMarca == "0")? "-": codeMarca
  codeCategoria = (codeCategoria == "" || codeCategoria == "0")? "-": codeCategoria
  idsProducto = (idsProducto == "" || idsProducto == "0")? "-": idsProducto

  var CFechaInicial = Moment(FechaInicial).format("DD-MM-YYYY") 
  var CFechaFinal = Moment(FechaFinal).format("DD-MM-YYYY") 

  var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.GET_ANALISIS_OSA + "/" + CFechaInicial  + "/" + CFechaFinal + "/" + codeCadenaMaster + "/" + codeTienda + "/" + codeMarca + "/" + codeCategoria + "/" + idsProducto,  "")
 console.log(dataResponse)
 
  if (dataResponse.Data != null) {
    return dataResponse.Data;
  } else {
    return []
  }
}