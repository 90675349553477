import { useMemo } from 'react';
import { type MRT_ColumnDef } from 'material-react-table';
import * as ClientAPI from "../utils/methods";
import * as Endpoints from "../api/endpoint";
import Moment from 'moment';
import moment from 'moment';

type IndicadorMecaderismo = {
  FechaHoraGenerado: Date,
  UrlDocumento: string,
};
export function GetColumns() {
  const columns = useMemo<MRT_ColumnDef<IndicadorMecaderismo>[]>(
    () => [
      {
        accessorKey: 'Periodo',
        header: 'Periodo',
        size: 100,
      },
      {
        id: 'FechaHoraGenerado',
        header: 'Fecha hora generado',
        size: 200,
        accessorFn: (row) => {
          return moment(row.FechaHoraGenerado).format("DD/MM/YYYY HH:mm:ss");
        },
      },
      {
        accessorKey: 'NombreCompleto',
        header: 'Persona',
        size: 300,
      },

      {
        accessorKey: 'NombreTiendas',
        header: 'Tiendas',
        size: 400,
      },
      {
        accessorKey: 'NroDocumentos',
        header: 'Cant. Documentos',
        size: 300,
      }
    ],
    [],
  );
  ;
  return columns
}

type Documento = {
  CodeDocumento: string,
  NombreDocumento: string
};

type HorarioTienda = {
  CodeTienda: string,
  NombreTienda: string,
  Horario: string
};

export function GetTColumns() {
  const columns = useMemo<MRT_ColumnDef<Documento>[]>(
    () => [
      {
        accessorKey: 'NombreDocumento',
        header: 'Nombres de Documentos',
        size: 400,
      },
    ],
    [],
  );
  ;
  return columns
}

export function GetHTColumns() {
  const columns = useMemo<MRT_ColumnDef<HorarioTienda>[]>(
    () => [
      {
        accessorKey: 'NombreTienda',
        header: 'Tienda',
        size: 400,
      },
      {
        accessorKey: 'Horario',
        header: 'Horario',
        size: 100,
      },
    ],
    [],
  );
  ;
  return columns
}

export async function getData(FilterCodeUsuario : string,FilterCodeTienda : string, FilterPeriodo: Date) {
  FilterCodeUsuario = (FilterCodeUsuario == "" || FilterCodeUsuario == "0")? "-": FilterCodeUsuario
  FilterCodeTienda = (FilterCodeTienda == "" || FilterCodeTienda == "0")? "-": FilterCodeTienda

  var FilterPeriodoFormat = moment(FilterPeriodo).format('YYYYMM');

  var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.GET_LISTA_DOCUMENTOGENERADO  + "/" + FilterCodeUsuario + "/" + FilterCodeTienda + "/" + FilterPeriodoFormat, "")
  if (dataResponse.Data != null) {
    return dataResponse.Data;
  } else {
    return []
  }
}
  export async function getListaComboUsuario() {
    var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_SECURITY + Endpoints.ListEndpoint.GET_USUARIOPERSONA , "")
    if (dataResponse.Data != null) {
      return dataResponse.Data;
    } else {
      return null
    }
  }
  export async function getListaComboCadenaMaster(){
    var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_SECURITY + Endpoints.ListEndpoint.GET_LISTA_COMBO_CADENAMASTER, "")
    if(dataResponse.Data != null){
      return dataResponse.Data;
    }else {
      return null
    }
  }

  export async function getListaComboTienda(setCodeCadenaMaster : string) {
    var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint. GET_LISTA_COMBO_TIENDA +  "/" + setCodeCadenaMaster , "")
    if (dataResponse.Data != null) {
      return dataResponse.Data;
    } else {
      return null
    }
  }

  export async function geListaCadenaDocumentoDetalleByCodeCadena(code: any) {
    var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_SECURITY + Endpoints.ListEndpoint.GET_LISTA_CADENAMASTER_DOCUMENTO_BYCODEDOCUMENTO + "/" + code, "")
   
    if (dataResponse.Data != null) {
      return dataResponse.Data;
    } else {
      return []
    }
  }
  export async function update(objSave: any) {
    var dataResponse = await ClientAPI.RequestPost(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.UPDATE_DOCUMENTOGENERADO, "", objSave)

    if (dataResponse != null) {
      return dataResponse;
    } else {
      return null
    }
  }
  export async function save(objSave: any) {
    var dataResponse = await ClientAPI.RequestPost(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.INSERTAR_DOCUMENTOGENERADO, "", objSave)

    if (dataResponse != null) {
      return dataResponse;
    } else {
      return null
    }
  }
  export async function getByCode(code: string) {
    var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.GET_DOCUMENTOGENERADO_BYCODE + "/" + code, "")
    
    return dataResponse.Data;
  }
  export async function getListaComboTiendaConsulta(){
    var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.GET_LISTA_COMBO_TIENDA + "/-" , "")
    if(dataResponse.Data != null){
      return dataResponse.Data;
    }else {
      return null
    }
  }

  export async function doDescargarDocumentoCombinado(IdUsuario: number, Periodo: number) {
    var dataResponse = await ClientAPI.RequestDownload(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.DESCARGA_DOCUMENTOGENERADOPERSONA + "/" + IdUsuario + "/" + Periodo, 'DocumentoCombinado.pdf')
    
  }

  export async function inactiva(objSave: any){
    var dataResponse = await ClientAPI.RequestPost(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.INACTIVO_DOCUMENTOGENERADODETALLE, "", objSave)
    if(dataResponse.Data != null){
      return dataResponse.Data;
    }else {
      return null
    }
  }