import * as Repository from "../repositories/analisisstock-repository";

export async function getListaComboCadenaMaster(
  setListaComboCadenaMaster: any
) {
  var dataResponse = await Repository.getListaComboCadenaMaster();
  setListaComboCadenaMaster(dataResponse);
}
export async function getListaComboTienda(
  codeCadenaMaster: string,
  setListaComboTienda: any
) {
  if (codeCadenaMaster != "-" && codeCadenaMaster != "0") {
    var dataResponse = await Repository.getListaComboTienda(codeCadenaMaster);
    setListaComboTienda(dataResponse);
  } else {
    setListaComboTienda(dataResponse);
  }
}
export async function getListaComboMarca(setListaComboMarca: any) {
  var dataResponse = await Repository.getListaComboMarca();
  setListaComboMarca(dataResponse);
}
export async function getListaComboCategoria(
  codeMarca: string,
  setListaComboCategoria: any
) {
  if (codeMarca != "-" && codeMarca != "0") {
    var dataResponse = await Repository.getListaComboCategoria(codeMarca);
    setListaComboCategoria(dataResponse);
  } else {
    setListaComboCategoria(dataResponse);
  }
}
export async function getListaProducto(
  setListaProducto: any,
  codeMarca: string,
  codeCategoria: string
) {
  var dataResponse = await Repository.geListaProducto(codeMarca, codeCategoria);

  console.log(dataResponse);
  if (dataResponse != null) {
    setListaProducto(dataResponse);
  }
}

export async function getAnalisisStock(
  FechaInicial: Date,
  FechaFinal: Date,
  codeCadenaMaster: string,
  codeTienda: string,
  codeMarca: string,
  codeCategoria: string,
  codeProducto: string,
  setDataStock: any,
  setDataStockBar: any,
  setDataStockCompare: any,
  setOriginalDataStock: any,
  setOriginalDataStockBar: any,
  setOriginalDataStockCompare: any,
  setDataStockBarMax: any,
  setDataStockBarMin: any,
  setDataStockBarMode: any,
) {
  /*var idsProducto = "-";
  if (listaProducto.length > 0) {
    idsProducto = "";
    listaProducto.forEach((element: any) => {
      idsProducto = idsProducto + element.IdProducto + ",";
    });
    idsProducto = idsProducto.substring(0, idsProducto.length-1)
  }*/
  var dataResponse = await Repository.getAnalisisStock(
    FechaInicial,
    FechaFinal,
    codeCadenaMaster,
    codeTienda,
    codeMarca,
    codeCategoria,
    codeProducto,
  );

  if (dataResponse != null) {
    var GraphicLine = dataResponse.GraphicLine;
    var GraphicBar = dataResponse.GraphicBar;
    var GraphicLineCompare = dataResponse.GraphicLineCompare;
    
    setGraphicData(GraphicLine, setDataStock, setOriginalDataStock);
    setGraphicData(GraphicBar, setDataStockBar, setOriginalDataStockBar);
    setGraphicData(GraphicLineCompare, setDataStockCompare, setOriginalDataStockCompare);
    setIndicator(GraphicBar, setDataStockBarMax, setDataStockBarMin, setDataStockBarMode);
  }
}

async function setIndicator(graphic: any, setDataStockBarMax: any, setDataStockBarMin: any, setDataStockBarMode: any) {
  setDataStockBarMax(graphic.Maximun);
  setDataStockBarMin(graphic.Minimun);
  setDataStockBarMode(graphic.Mode);
}

async function setGraphicData(graphic: any, setGraphic: any, setOriginalData: any) {
    var labels = graphic.Labels
    var dataResult = { labels, datasets: graphic.DataSets }
    console.log(dataResult)
    setGraphic(dataResult)
    setOriginalData(graphic)
}

export async function geListaComboProducto( codeProducto : string, setListaProducto: any) {
  var dataResponse = await Repository.geListaComboProducto(codeProducto)

  console.log(dataResponse)
  if (dataResponse != null) {
      setListaProducto(dataResponse)
  }
}

export async function getListaDataStock(codeCadenaMaster: string, codeTienda: string, codeProducto: string, Fecha: string, setListaDataStock: any){
  var dataResponse = await Repository.getListaDataStock(codeCadenaMaster, codeTienda, codeProducto, Fecha);

  if (dataResponse != null) {
    setListaDataStock(dataResponse)
  }
}

export async function getListaDataStockBar(FechaInicial: Date, FechaFinal: Date, codeProducto: string, nombreTienda: string, setListaDataStockBar: any){
  var dataResponse = await Repository.getListaDataStockBar(FechaInicial, FechaFinal, codeProducto, nombreTienda);

  if (dataResponse != null) {
    setListaDataStockBar(dataResponse)
  }
}