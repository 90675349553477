import React, { useMemo } from 'react';
import { MaterialReactTable, type MRT_ColumnDef } from 'material-react-table';
import { useNavigate } from "react-router-dom";
import { Box, IconButton } from '@mui/material';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Email as EmailIcon,
} from '@mui/icons-material';
import ContentHeader from '../layout/ContentHeader';
import ContentBody from '../layout/ContentBody';
import * as Controller from "../../../controllers/documento-controller";
import * as Repository from "../../../repositories/documento-repository";
import SelectSimple from '../layout/SelectSimple';
export default function ConsultaDocumento(): React.JSX.Element {
  const router = useNavigate()
  const [ListaComboTipoArchivo, setListaComboTipoArchivo] = React.useState([])
  const [ListaConsulta, setListaConsulta] = React.useState([])
  const [FilterIdTipoArchivo, setFilterTipoArchivo] = React.useState(0)
  const [FilterNombreDocumento, setFilterNombreDocumento] = React.useState('')
  React.useEffect(() => {
    Controller.getListaComboTipoArchivo(setListaComboTipoArchivo)
    Controller.doConsultar(setListaConsulta, FilterIdTipoArchivo, FilterNombreDocumento)
  }, [])
  return (<div className="content-wrapper">
    <ContentHeader title={"Consulta Documento"} homePath={"Home"} currentPath={"Mantenimiento Documento"} >
    </ContentHeader>
    <ContentBody title={"Consulta Documento"}>
      <div className='row'>
        <div className='col-lg-2 '>
          <label>Tipo Documento</label>
        </div>
        <div className='col-lg-4 '>
          <div className='form-group'>
            <SelectSimple
              className={"form-control-text"}
              lista={ListaComboTipoArchivo}
              value={FilterIdTipoArchivo}
              setValue={setFilterTipoArchivo}>
              --TODOS--
            </SelectSimple>
          </div>
        </div>
        <div className='col-lg-2'>
          <label>Nombre Archivo</label>
        </div>
        <div className='col-lg-4'>
          <div className='form-group'>
            <input type="text" className="form-control" value={FilterNombreDocumento}
              onChange={e => { setFilterNombreDocumento(e.currentTarget.value); }} />
          </div>
        </div>
      </div>
      <div className='row mt-2 mb-2'>
        <div className='col-gl-6'>
          <div className='form-group'>
            <button className='btn btn-primary' onClick={() => Controller.toNuevo(router)}>Nuevo</button>
            <button className='btn btn-info' onClick={() => Controller.doConsultar(setListaConsulta, FilterIdTipoArchivo, FilterNombreDocumento)}>Consultar</button>
            <button className='btn btn-success' onClick={() => Controller.doExportar(FilterIdTipoArchivo, FilterNombreDocumento)}  >Exportar</button>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <MaterialReactTable columns={Repository.GetColumns()} data={ListaConsulta}
            enableRowActions
            renderRowActions={({ row, table }) => (
              <div style={{ width: 100 }}>
              <div className='row'>
              <div className="col-lg-4">
                <IconButton onClick={() => Controller.toEditar(router, row.original)}>
                  <EditIcon />
                </IconButton>
                </div>
                <div className="col-lg-4">
                <IconButton onClick={() => Controller.toQuestionInactivaActiva(row.original, setListaConsulta)}>
                  <DeleteIcon />
                </IconButton>
              </div>
              </div>
              </div>
            )} />
        </div>
      </div>
    </ContentBody>
  </div>
  )
}