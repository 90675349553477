import { useMemo } from 'react';
import { type MRT_ColumnDef } from 'material-react-table';
import * as ClientAPI from "../utils/methods";
import * as Endpoints from "../api/endpoint";
import Moment from 'moment';
type IndicadorMecaderismo = {
 
};
export function GetColumns() {
  const columns = useMemo<MRT_ColumnDef<IndicadorMecaderismo>[]>(
    () => [
    
      {
        accessorKey: 'NombreCompleto',
        header: 'Nombre ',
        size: 100,
      },
     
      {
        accessorKey: 'NumeroDocumentoIdentidad',
        header: 'Documento',
        size: 100,
      },
      {
        accessorKey: 'Fecha',
        header: 'Fecha ',
        size: 100,
      },
      {
        accessorKey: 'Cobeertura',
        header: 'Cobertura',
        size: 100,
      },
      {
        accessorKey: 'Asistencia',
        header: 'Asistencia',
        size: 100,
      },
      {
        accessorKey: 'CumplimientoTarea',
        header: 'Cumplimiento Tarea ',
        size: 100,
      },
      
      {
        accessorKey: 'Exhibiciones',
        header: 'Exhibiciones ',
        size: 100,
      },

      {
        accessorKey: 'SOS',
        header: 'SOS ',
        size: 100,
      },


    ],
    [],
  );
  ;
  return columns
}
export async function getData(  FilterFechaInicio :Date, FilterFechaFinal: Date, FilterNombreCompleto : string  )   {
  var CFilterFechaInicio = Moment(FilterFechaInicio).format("DD-MM-YYYY") 
  var CFilterFechaFinal = Moment(FilterFechaFinal).format("DD-MM-YYYY")
  var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.GET_LISTA_INDICADORMERCADERISMO   + "/" + CFilterFechaInicio  + "/" + CFilterFechaFinal   + "/" + FilterNombreCompleto,   "")
  if (dataResponse.Data != null) {
    return dataResponse.Data;
  } else {
    return []
  }
}
export async function doExport( FilterFechaDesde: Date, FilterFechaHasta : Date ) {
  
  var CFilterFechaDesde = Moment(FilterFechaDesde).format("DD-MM-YYYY") 
  var CFilterFechaHasta = Moment(FilterFechaHasta).format("DD-MM-YYYY")
 
  var dataResponse = await ClientAPI.RequestDownload(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.GET_LISTA_INDICADORMERCADERISTA_EXCEL  + "/" +  CFilterFechaDesde + "/" + CFilterFechaHasta, 'DescargarExcelMercaderista.xlsx')
  
}