import React, { useMemo } from 'react';
import { MaterialReactTable, type MRT_ColumnDef } from 'material-react-table';
import { useNavigate } from "react-router-dom";
import ContentHeader from '../layout/ContentHeader';
import ContentBody from '../layout/ContentBody';
import * as Controller from "../../../controllers/dashboardstock-controller";
import * as Repository from "../../../repositories/dashboardstock-repository";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SelectSimple from '../layout/SelectSimple';
import es from "date-fns/locale/es"; // the locale you want
registerLocale("es", es); // register it with the name you want  
export default function ConsultaDashboardstock(): React.JSX.Element {
    const router = useNavigate()
    const [ListaConsulta, setListaConsulta] = React.useState([])
    const [ListaComboCadena, setListaComboCadena] = React.useState([])
    const [ListaComboTienda, setListaComboTienda] = React.useState([])
    const [ListaComboMarca, setListaComboMarca] = React.useState([])
    const [ListaComboCategoria, setListaComboCategoria] = React.useState([])
    const [FilterCadena, setFilterCadena] = React.useState('')
    const [FilterTienda, setFilterTienda] = React.useState('')
    const [FilterCodeMarca, setFilterCodeMarca] = React.useState('')
    const [FilterCodeCategoria, setFilterCategoria] = React.useState('')
    const [FilterCodeSubCategoria, setFilterSubCategoria] = React.useState('')
    const [FilterFechaDesde, setFilterFechaInicio] = React.useState(new Date)
    const [FilterFechaHasta, setFilterFechaHasta] = React.useState(new Date)
    
    React.useEffect(() => {
        Controller.getListaComboTienda(setListaComboTienda)
        Controller.getListaComboCadena(setListaComboCadena)
        Controller.getListaComboMarca(setListaComboMarca)
        Controller.doConsultar(setListaConsulta, FilterCodeMarca, FilterCodeCategoria, FilterCodeSubCategoria, FilterCadena, FilterTienda, FilterFechaDesde, FilterFechaHasta)
        Controller.getListaComboCategoria(setListaComboCategoria)

    }, [])
    return (<div className="content-wrapper">
        <ContentHeader title={"Consulta de Stock Tienda "} homePath={"Home"} currentPath={"Mantenimiento Stock Tienda "} >
        </ContentHeader>
        <ContentBody title={"Consulta Stock Tienda"}>
            <div className="card-body">
                <div className='row'>
                    <div className='col-lg-2'>
                        <label>Fecha Desde</label>
                    </div>
                    <div className='col-lg-4 '>
                        <div className='form-group'>
                            <DatePicker locale= "es" className='form-control-text' selected={FilterFechaDesde} onChange={(date: any) => setFilterFechaInicio(date)} dateFormat={"dd/MM/yyyy"} />
                        </div>
                    </div>
                    <div className='col-lg-2'>
                        <label>Fecha Hasta</label>
                    </div>
                    <div className='col-lg-4 '>
                        <div className='form-group'>
                            <DatePicker locale="es" className='form-control-text' selected={FilterFechaHasta} onChange={(date: any) => setFilterFechaHasta(date)}dateFormat={"dd/MM/yyyy"} />
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-lg-2'>
                        <label>Cadena</label>
                    </div>
                    <div className='col-lg-4 '>
                        <SelectSimple 
                            className={"form-control-text"} 
                            lista={ListaComboCadena} 
                            value={FilterCadena} 
                            setValue={setFilterCadena}>
                            --TODOS--
                        </SelectSimple>
                    </div>
               
                    <div className='col-lg-2 '>
                        <label>Tienda</label>
                    </div>
                    <div className='col-lg-4 '>
                        <div className='form-group'>
                            <SelectSimple 
                                className={"form-control-text"} 
                                lista={ListaComboTienda} 
                                value={FilterTienda} 
                                setValue={setFilterTienda}>
                                --TODOS--
                            </SelectSimple>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-lg-2'>
                        <label>Marca</label>
                    </div>
                    <div className='col-lg-4 '>
                        <div className='form-group'>
                            <SelectSimple 
                                value={FilterCodeCategoria}
                                setValue={setFilterCodeMarca}
                                lista={ListaComboMarca}
                                 >
                                --TODOS--
                            </SelectSimple>
                        </div>
                    </div>
                </div>
                <div className=' row'>
                    <div className='col-lg-2 '>
                        <label>Categoria</label>
                    </div>
                    <div className='col-lg-4'>
                        <div className='form-group'>
                            <SelectSimple 
                               value={FilterCodeCategoria}
                               setValue={setFilterCategoria}
                               lista={ListaComboCategoria}
                                >
                               --TODOS--
                            </SelectSimple>
                        </div>
                    </div>
                </div>
                <div className='row mt-2 mb-2'>
                    <div className='col-lg-6'>
                        <div className='form-group'>

                            <button className='btn btn-info' onClick={() => Controller.doConsultar(setListaConsulta, FilterCodeMarca, FilterCodeCategoria, FilterCodeSubCategoria, FilterCadena, FilterTienda, FilterFechaDesde, FilterFechaHasta)}>Consultar</button>
                            <button className='btn btn-success' onClick={() => Controller.doExportar(FilterCodeMarca, FilterCodeCategoria, FilterCodeSubCategoria, FilterCadena, FilterTienda, FilterFechaDesde, FilterFechaHasta)}  >Exportar</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <MaterialReactTable columns={Repository.GetColumns()} data={ListaConsulta}
                        
                        renderRowActions={({ row, table }) => (
                            <div>
                            </div>
                        )} />
                </div>
            </div>
        </ContentBody>
    </div>
    )
}
