import * as EndPoint from "../api/endpoint"

export function RequestGet(URL: string, token: string) {
  var tokenJwt = localStorage.getItem("TOKEN_JWT")
  try {
    return fetch(URL, {
      method: "GET", mode: "cors", headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + tokenJwt,
        'Content-type': 'application/json'
      }
    }).then(function (res) {
      return res.json();
    }).then(function (response) {
      return response;
    }).catch((error) => {
      console.log(error);
    })
  } catch (e) {
    console.log(e);
  }

}

export function RequestDownload(URL: string, NombreDescarga: string) {
  var tokenJwt = localStorage.getItem("TOKEN_JWT")
  try {
    return fetch(URL, {
      method: "GET", mode: "cors", headers: {
        'Authorization': 'Bearer ' + tokenJwt,
        'Content-type': 'application/json'
      }
    }).then(function (res) {
      return res.blob();
    }).then(function (response) {
      let url = window.URL.createObjectURL(response);
      let a = document.createElement('a');
      a.href = url;
      a.download = NombreDescarga;
      a.click();
    }).catch((error) => {
      console.log(error);
    })
  } catch (e) {
    console.log(e);
  }

}

export function RequestPost(URL: string, token: string, body: object) {
  var tokenJwt = localStorage.getItem("TOKEN_JWT")
  try {
    return fetch(URL, {
      method: "POST", mode: 'cors',
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + tokenJwt,
        'Content-type': 'application/json'
      },
      body: JSON.stringify(body)
    }).then(function (res) {
      return res.json()
    }).then(function (response) {
      return response
    }).catch((error) => {
      console.log(error)
    })
  } catch (e) {
    console.log(e);
  }


}


export function RequestPostUpload(URL: string, file: FormData) {
  var tokenJwt = localStorage.getItem("TOKEN_JWT")
  try {
    return fetch(URL, {
      method: "POST", mode: 'cors',
      headers: {
        'Authorization': 'Bearer ' + tokenJwt,
      },
      body: file
    }).then(function (res) {
      return res.json()
    }).then(function (response) {
      return response
    }).catch((error) => {
      console.log(error)
    })
  } catch (e) {
    console.log(e);
  }


}

export function RequestPostApiKey(URL: string, body: object) {

  try {
    return fetch(URL, {
      method: "POST", mode: 'cors',
      headers: {
        'Accept': 'application/json',
        'ApiKey': EndPoint.API_KEY,
        'Content-type': 'application/json'
      },
      body: JSON.stringify(body)
    }).then(function (res) {
      return res.json()
    }).then(function (response) {
      return response
    }).catch((error) => {
      console.log(error)
    })
  } catch (e) {
    console.log(e);
  }


}