import ContentHeader from "../layout/ContentHeader";
import { useParams, useNavigate } from "react-router-dom";
import * as Controller from "../../../controllers/categoria-controller";
import React from "react";
import SelectSimple from "../layout/SelectSimple";
import Textbox from "../layout/Textbox";
import Label from "../layout/Label";
export default function NuevoCategoria() {
  const { code } = useParams();
  const router = useNavigate();
  const [ListaComboMarca, setListaComboMarca] = React.useState([]);
  const [codeCategoria, setCodeCategoria] = React.useState("");
  const [nombreCategoria, setNombreCategoria] = React.useState("");
  const [codeMarca, setCodeMarca] = React.useState("");
  const [valCodeMarca, setValCodeMarca] = React.useState(false);
  const [tituloVentana, setTituloVentana] = React.useState("Nueva Categoria");
  const [valnombreCategoria, setValNombreCategoria] = React.useState(false);
  const [isSubmit, setIsSubmit] = React.useState(false);
  React.useEffect(() => {
    Controller.getListaComboMarca(setListaComboMarca);
    if (code != "nuevo") {
      setTituloVentana("Editar Categoria");
      setCodeCategoria(code!);
      Controller.getByCode(code, setCodeMarca, setNombreCategoria);
    }
  }, []);
  function validarCampos() {
    var Result = true;
    setIsSubmit(true);
    if (nombreCategoria == "" || nombreCategoria == null) {
      Result = false;
      setValNombreCategoria(false);
    } else {
      setValNombreCategoria(true);
    }

    if (codeMarca == "" || codeMarca == "0" || codeMarca == null) {
      Result = false;
      setValCodeMarca(false);
    } else {
      setValCodeMarca(true);
    }

    if (Result) {
      Controller.save(router, codeCategoria, nombreCategoria, codeMarca);
    } else {
      Controller.Mensaje();
    }
  }
  return (
    <div className="content-wrapper">
      <ContentHeader
        title={tituloVentana}
        homePath={"Home"}
        currentPath={tituloVentana}
      ></ContentHeader>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card card-primary">
                <div className="card-header">
                  <div className="d-flex flex-row gap-2">
                    <div className="align-items-center">
                      <i
                        className="bx bx-arrow-back arrow-back"
                        onClick={() => Controller.toConsulta(router)}
                      ></i>
                    </div>
                    <div className="">
                      <h4 className="card-title">{tituloVentana}</h4>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-6">
                      <h4>Datos</h4>
                      <hr />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-2">
                      <Label required={true}>Marca</Label>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <SelectSimple
                          value={codeMarca}
                          setValue={setCodeMarca}
                          valueError={valCodeMarca}
                          setValueError={setValCodeMarca}
                          isSubmit={isSubmit}
                          lista={ListaComboMarca}
                        >
                          --Seleccion--
                        </SelectSimple>
                      </div>
                    </div>
                    <div className="col-lg-2">
                      <Label required={true}>Categoria</Label>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <Textbox
                          value={nombreCategoria}
                          valueError={valnombreCategoria}
                          setValue={setNombreCategoria}
                          setValueError={setValNombreCategoria}
                          isSubmit={isSubmit}
                          upperCase={true}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mt-5">
                    <div className="col-lg-6">
                      <div className="form-group">
                        <button
                          className="btn btn-default"
                          onClick={() => Controller.toConsulta(router)}
                        >
                          Cancelar
                        </button>
                        <button
                          className="btn btn-primary"
                          onClick={() => validarCampos()}
                        >
                          Guardar
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
