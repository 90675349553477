import { useMemo } from 'react';
import { type MRT_ColumnDef } from 'material-react-table';
import * as ClientAPI from "../utils/methods";
import * as Endpoints from "../api/endpoint";
import Moment from 'moment';
import moment from 'moment';
type ChechinOutTienda = {

};
export function GetColumns() {
  const columns = useMemo<MRT_ColumnDef<ChechinOutTienda>[]>(
    () => [
      {
        accessorKey: 'NombreProducto',
        header: 'Producto',
        size: 20,
      },
    ],
    [],
  );
  ;
  return columns
}

export async function getListaComboCadenaMaster(){
  var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_SECURITY + Endpoints.ListEndpoint.GET_LISTA_COMBO_CADENAMASTER, "")
  if(dataResponse.Data != null){
    return dataResponse.Data;
  }else {
    return null
  }
}

export async function getListaComboTienda(setCodeCadenaMaster : string) {
  var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint. GET_LISTA_COMBO_TIENDA +  "/" + setCodeCadenaMaster , "")
  if (dataResponse.Data != null) {
    return dataResponse.Data;
  } else {
    return null
  }
}

export async function getListaComboMarca(){
  var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.GET_LISTA_COMBO_MARCA , "")
  if(dataResponse.Data != null){
    return dataResponse.Data;
  }else {
    return null
  }
}
export async function getListaComboCategoria(setCodeMarca : string){
  var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.GET_LISTA_COMBO_CATEGORIA + "/" + setCodeMarca , "")
  if(dataResponse.Data != null){
    return dataResponse.Data;
  }else {
    return null
  }
}

export async function geListaProducto( codeMarca : string , codeCategoria : string)
{
  codeMarca = (codeMarca == "" || codeMarca == "0")? "-": codeMarca
  codeCategoria = (codeCategoria == "" || codeCategoria == "0")? "-": codeCategoria
  var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.GET_LISTA_PRODUCTOMARCA + "/" + codeMarca  + "/" + codeCategoria,  "")
 console.log(dataResponse)
 
  if (dataResponse.Data != null) {
    return dataResponse.Data;
  } else {
    return []
  }
}


export async function getAnalisisFrente( FechaInicial: Date, FechaFinal: Date, codeCadenaMaster: string, codeTienda: string, codeMarca: string, codeCategoria: string)
{
  codeCadenaMaster = (codeCadenaMaster == "" || codeCadenaMaster == "0")? "-": codeCadenaMaster
  codeTienda = (codeTienda == "" || codeTienda == "0")? "-": codeTienda
  codeMarca = (codeMarca == "" || codeMarca == "0")? "-": codeMarca
  codeCategoria = (codeCategoria == "" || codeCategoria == "0")? "-": codeCategoria

  var CFechaInicial = Moment(FechaInicial).format("DD-MM-YYYY") 
  var CFechaFinal = Moment(FechaFinal).format("DD-MM-YYYY") 

  var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.GET_ANALISIS_FRENTE  + "/" + CFechaInicial  + "/" + CFechaFinal + "/" + codeCadenaMaster + "/" + codeTienda + "/" + codeMarca + "/" + codeCategoria,  "")
 console.log(dataResponse)
 
  if (dataResponse.Data != null) {
    return dataResponse.Data;
  } else {
    return []
  }
}